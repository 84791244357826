import { Link } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { BetmenList } from '../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../common/components/BetmenList/BetmenListItemCardCell'
import { BetmenPage } from '../../../common/components/BetmenPage'
import { AddButton } from '../../../common/components/AddButton/AddButton'
import { Status, StatusEnum } from '../../../common/components/Status/Status'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useInstitutionList } from '../../../common/hooks/useInstitutionList'
import { PAGE_SIZE } from '../../../config'
import { InstitutionModal } from '../components/InstitutionModal'

type RouteParams = {
  institutionId?: string
}

const InstitutionAdmin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { institutionId } = useParams<RouteParams>()

  const [institutionModalOpen, setInstitutionModalOpen] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')

  const getVariables = (offset: number) => ({
    nameSearchTerm: searchTerm,
    paginationInput: {
      offset,
      limit: PAGE_SIZE,
    },
  })

  const {
    loading,
    institutions,
    fetchMoreInstitutions,
    hasMoreInstitutions,
    refetch,
  } = useInstitutionList({ variables: getVariables(0) })

  const fetchMoreRows = (offset: number) =>
    fetchMoreInstitutions({ variables: getVariables(offset) })

  useEffect(() => {
    refetch()
  }, [refetch, searchTerm])

  const selectedInstitution = institutions.find(
    (institution) => institution.id === institutionId
  )

  const headerItems = [
    { name: 'institution:name', actions: false },
    { name: 'common:InstitutionAddress', actions: false },
    { name: 'common:neak', actions: false },
    { name: 'common:status', actions: false },
    { name: 'common:actions', actions: true },
  ]

  return (
    <BetmenPage
      title={t('institution:institutions')}
      withoutPadding
      headerRightContent={
        <AddButton
          handleAdd={() => setInstitutionModalOpen(true)}
          buttonText={t(`doctor:addInstitution`)}
        />
      }
    >
      <BetmenList
        dataSource={institutions}
        loading={loading}
        fetchMore={fetchMoreRows}
        hasMoreItem={hasMoreInstitutions}
        gridColumnSizes={[5, 4, 1, 1, 1]}
        filters={
          <BetmenListFilter
            searchPlaceholder={t(`doctor:searchInstitution`)}
            searchTerm={searchTerm}
            handleSearch={(value) => setSearchTerm(value || '')}
          />
        }
        header={
          <BetmenListHeader>
            {headerItems.map((headerItem, i: number) => (
              <BetmenListHeaderCell
                actions={headerItem.actions}
                key={`header_${i}`}
              >
                {t(headerItem.name)}
              </BetmenListHeaderCell>
            ))}
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {institutions.map((institution) => (
            <BetmenListItemCard key={'wp+' + institution.id}>
              <BetmenListItemCardCell>
                {institution.name}
              </BetmenListItemCardCell>

              <BetmenListItemCardCell>
                {institution.mapLink ? (
                  <Link
                    href={institution.mapLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {institution.concatenatedAddress}
                  </Link>
                ) : (
                  <span>{institution.concatenatedAddress}</span>
                )}
              </BetmenListItemCardCell>

              <BetmenListItemCardCell>
                {institution.neak}
              </BetmenListItemCardCell>

              <BetmenListItemCardCell>
                <Status
                  status={
                    institution.isActive
                      ? StatusEnum.Active
                      : StatusEnum.Inactive
                  }
                />
              </BetmenListItemCardCell>

              <BetmenListActions
                edit={
                  !!institution.eesztId // institutions with eesztId are not editable as they are synced with EESZT
                    ? undefined
                    : () => {
                        history.push(`/admin/institutions/${institution.id}`)
                      }
                }
              />
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>

      <InstitutionModal
        isOpen={institutionModalOpen || !!selectedInstitution}
        setIsOpen={setInstitutionModalOpen}
        selectedInstitution={selectedInstitution}
        onComplete={() => history.push('/admin/institutions')}
      />
    </BetmenPage>
  )
}

export { InstitutionAdmin }
