import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  createSurveySchema,
  createSurveySchemaVariables,
} from '../../../../models/graphqlTypes'

import { CREATE_SURVEY_SCHEMA } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  createSurveySchema,
  createSurveySchemaVariables
>

type Return = MutationTuple<createSurveySchema, createSurveySchemaVariables>

export const useSurveySchemaCreate = (options: Options = {}): Return => {
  return useMutation<createSurveySchema, createSurveySchemaVariables>(
    CREATE_SURVEY_SCHEMA,
    options
  )
}
