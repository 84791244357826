import omit from 'lodash/omit'
import { SurveyQuestionEnum } from '../../../../models/graphqlTypes'
import {
  QuestionInSection,
  Section,
} from '../components/EditSurvey/editSurvey.types'
import { SurveySchema } from '../types/surveyStore.types'

export const NEW_QUESTION_KEY_PREFIX = 'new-question'
export const EXISTING_QUESTION_KEY_PREFIX = 'existing-question'
export const REORDER_QUESTIONS_KEY_PREFIX = 'reorder-question'

export const ANSWER_TIME_FOR_QUESTION_IN_MIN = 0.5

export const MAXIMUM_NUMBER_OF_FILES = 10

export const getOrderedSections = (sections: Section[]): Section[] => {
  return sections
    .sort((a, b) => a.order - b.order)
    .map((section) => ({
      ...section,
      questions: section.questions.sort(
        (a, b) => a.orderInSection - b.orderInSection
      ),
    }))
}

export const getNumberOfQuestionsInSurvey = (sections: Section[]): number =>
  sections
    .map((section) => section.questions.length)
    .reduce((partialSum, next) => partialSum + next, 0)

export const getReorderedSections = (
  sections: Section[],
  sectionOrder: number,
  currQuestionIndex: number,
  targetQuestionIndex: number
): Section[] => {
  return sections.map((section) => {
    if (
      section.order === sectionOrder &&
      currQuestionIndex !== targetQuestionIndex
    ) {
      return {
        order: sectionOrder,
        name: section.name,
        questions: section.questions.map((q, i, arr) => {
          const question = arr[i]
          // If currQuestionIndex and targetQuestionIndex higher or lower than the currently
          // examined question.orderInSection, it means there is no change in order for this question.
          const questionOrderNotChanged =
            (question.orderInSection < currQuestionIndex &&
              question.orderInSection < targetQuestionIndex) ||
            (question.orderInSection > currQuestionIndex &&
              question.orderInSection > targetQuestionIndex)
          if (questionOrderNotChanged) {
            return question
          }
          if (question.orderInSection === currQuestionIndex) {
            return {
              ...question,
              orderInSection: targetQuestionIndex,
            }
          }
          const questionMovedBeforeCurrQuestion =
            question.orderInSection < currQuestionIndex &&
            question.orderInSection >= targetQuestionIndex
          return {
            ...question,
            orderInSection: questionMovedBeforeCurrQuestion
              ? question.orderInSection + 1
              : question.orderInSection - 1,
          }
        }),
      }
    }
    return section
  })
}

export const getSectionsAfterQuestionAdded = (
  sections: Section[],
  newQuestion: QuestionInSection,
  sectionOrder: number
): Section[] => {
  return sections.map((section) => {
    if (section.order === sectionOrder) {
      return {
        order: sectionOrder,
        name: section.name,
        questions: [
          ...section.questions.map((q, i, arr) => {
            const question = arr[i]
            return {
              ...question,
              orderInSection:
                question.orderInSection < newQuestion.orderInSection
                  ? question.orderInSection
                  : question.orderInSection + 1,
            }
          }),
          newQuestion,
        ],
      }
    }
    return section
  })
}

const isNextSectionOrderChangedOnly = (
  oldQuestion: QuestionInSection,
  newQuestion: QuestionInSection
): boolean => {
  if (newQuestion.type !== SurveyQuestionEnum.MultipleChoice) {
    return false
  }

  const oldQuestionWithoutNextSectionOrder = {
    ...oldQuestion,
    isJumpToNextSectionAvailable: false,
    choices: oldQuestion.choices?.map((choice) => ({
      name: choice.name,
    })),
    aspects: oldQuestion.aspects?.map((choice) => ({
      name: choice.name,
    })),
  }
  const newQuestionWithoutNextSectionOrder = {
    ...newQuestion,
    isJumpToNextSectionAvailable: false,
    choices: newQuestion.choices?.map((choice) => ({
      name: choice.name,
    })),
    aspects: newQuestion.aspects?.map((choice) => ({
      name: choice.name,
    })),
  }

  return (
    JSON.stringify(oldQuestionWithoutNextSectionOrder) ===
    JSON.stringify(newQuestionWithoutNextSectionOrder)
  )
}

export const getSectionsAfterQuestionUpdated = (
  sections: Section[],
  newQuestion: QuestionInSection,
  sectionOrder: number,
  surveySchemaId?: string
): Section[] => {
  return sections.map((section) => {
    if (section.order === sectionOrder) {
      return {
        order: sectionOrder,
        name: section.name,
        questions: section.questions.map((q) => {
          if (q.orderInSection === newQuestion.orderInSection) {
            // As jump to section order not the part of question
            // we should check if only this fields are changed and not remove the id,
            // so backend won't create new question
            return isNextSectionOrderChangedOnly(q, newQuestion)
              ? newQuestion
              : {
                  ...omit(newQuestion, 'id'),
                  choices: newQuestion.choices?.map((choice) => ({
                    ...choice,
                    idInDb: '',
                  })),
                  aspects: newQuestion.aspects?.map((aspect) => ({
                    ...aspect,
                    idInDb: '',
                  })),
                  isGlobal: false,
                  isSurveySpecific: newQuestion.isSurveySpecific,
                  surveySchemaId,
                }
          }
          return q
        }),
      }
    }
    return section
  })
}

export const getSectionsAfterQuestionRemoved = (
  sections: Section[],
  questionToRemove: QuestionInSection,
  sectionOrder: number
): Section[] => {
  return sections.map((section) => {
    if (section.order === sectionOrder) {
      return {
        order: sectionOrder,
        name: section.name,
        questions: section.questions.filter(
          (q) => !(q.orderInSection === questionToRemove.orderInSection)
        ),
      }
    }
    return section
  })
}

export const renameDuplicatedSurveyTitles = (
  list: SurveySchema[]
): SurveySchema[] =>
  list.map((listItem, i, arr) => {
    const prevItemsWithSameTitleCount = arr.slice(0, i).filter((item) => {
      return item.title === listItem.title
    }).length
    return prevItemsWithSameTitleCount > 0
      ? {
          ...listItem,
          title: `${listItem.title} (${prevItemsWithSameTitleCount + 1})`,
        }
      : listItem
  })
