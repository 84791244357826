import { QueryHookOptions, useQuery, NetworkStatus } from '@apollo/client'
import { getPatients, getPatientsVariables } from '../../models/graphqlTypes'
import { GET_PATIENTS_BY_DOCTOR } from '../../views/doctor/DoctorPatients/operations/doctorPatientOperations'
import { PatientListItemType } from '../../views/doctor/DoctorPatients/types/patientList.types'
import { useMemo } from 'react'

type UsePatientsListOptions = QueryHookOptions<
  getPatients,
  getPatientsVariables
>

export type UsePatientsListReturn = {
  isLoading: boolean
  data: PatientListItemType[]
  total: number
  hasMore: boolean
  fetchMoreLoading: boolean
  refetch: () => void
  fetchMore: (options: UsePatientsListOptions) => void
}

export const usePatientsList = (
  options: UsePatientsListOptions
): UsePatientsListReturn => {
  const { data, loading, refetch, fetchMore, networkStatus } = useQuery<
    getPatients,
    getPatientsVariables
  >(GET_PATIENTS_BY_DOCTOR, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  const totalPatients = data?.listPatientsForDoctor?.total || 0

  const patientList = useMemo(() => {
    const rawPatientList = data?.listPatientsForDoctor.patients || []

    return rawPatientList.map(({ patient, closedAt }) => ({
      id: patient?.id ?? '',
      user: patient?.user ?? null,
      firstName: patient?.firstName ?? '',
      lastName: patient?.lastName ?? '',
      title: patient?.title ?? '',
      tajNumber: patient?.tajNumber ?? '',
      phone: patient?.phone ?? '',
      birthDate: patient?.birthDate ?? null,
      weight: patient?.weight ?? null,
      height: patient?.height ?? null,
      isDeletableForDoctor: patient?.isDeletableForDoctor ?? false,
      isActive: !closedAt,
    }))
  }, [data?.listPatientsForDoctor.patients])

  const hasMore = patientList.length < totalPatients

  return {
    isLoading: loading,
    fetchMoreLoading: networkStatus === NetworkStatus.fetchMore,
    fetchMore,
    refetch,
    data: patientList,
    total: totalPatients,
    hasMore,
  }
}
