import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  removeSurveyFromTreatment,
  removeSurveyFromTreatmentVariables,
} from '../../../../models/graphqlTypes'
import { REMOVE_SURVEY_TO_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<
  removeSurveyFromTreatment,
  removeSurveyFromTreatmentVariables
>

type Return = MutationTuple<
  removeSurveyFromTreatment,
  removeSurveyFromTreatmentVariables
>

export const useRemoveSurveyFromTreatment = (options: Options = {}): Return => {
  return useMutation<
    removeSurveyFromTreatment,
    removeSurveyFromTreatmentVariables
  >(REMOVE_SURVEY_TO_TREATMENT, options)
}
