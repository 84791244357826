import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  updateTreatment,
  updateTreatmentVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<updateTreatment, updateTreatmentVariables>

type Return = MutationTuple<updateTreatment, updateTreatmentVariables>

export const useTreatmentUpdate = (options: Options = {}): Return => {
  return useMutation(UPDATE_TREATMENT, options)
}
