import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  updateSurveyFillableFromDate,
  updateSurveyFillableFromDateVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_SURVEY_FILLABLE_FROM_DATE } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<
  updateSurveyFillableFromDate,
  updateSurveyFillableFromDateVariables
>

type Return = MutationTuple<
  updateSurveyFillableFromDate,
  updateSurveyFillableFromDateVariables
>

export const useUpdateSurveyFillableFromDate = (
  options: Options = {}
): Return => {
  return useMutation<
    updateSurveyFillableFromDate,
    updateSurveyFillableFromDateVariables
  >(UPDATE_SURVEY_FILLABLE_FROM_DATE, options)
}
