import { MutationHookOptions, useQuery } from '@apollo/client'
import {
  getDoctorProfilesForDoctor,
  getDoctorProfilesForDoctorVariables,
} from '../../models/graphqlTypes'
import { GET_DOCTOR_PROFILES_FOR_DOCTOR } from '../../operations/doctorProfileOperations'
import { DoctorProfile } from '../../views/doctor/SurveyStore/components/Surveys/SendSurvey/sendSurvey.types'

type Options = MutationHookOptions<
  getDoctorProfilesForDoctor,
  getDoctorProfilesForDoctorVariables
>

type DoctorsListReturnType = {
  loading: boolean
  doctors: DoctorProfile[]
}

export const useDoctorsForSendSurvey = (
  options: Options = {}
): DoctorsListReturnType => {
  const { data, loading } = useQuery<
    getDoctorProfilesForDoctor,
    getDoctorProfilesForDoctorVariables
  >(GET_DOCTOR_PROFILES_FOR_DOCTOR, options)

  const doctors = data?.getDoctorProfiles.profiles || []

  return { loading, doctors }
}
