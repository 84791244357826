import { useQuery } from '@apollo/client'
import { GET_CITY_LIST } from '../../operations/cityOperations'
import { getCities } from '../../models/graphqlTypes'

type Return = {
  data: string[]
  loading: boolean
}

export const useCities = (): Return => {
  const { data, loading } = useQuery<getCities, Record<string, unknown>>(
    GET_CITY_LIST,
    {
      fetchPolicy: 'cache-and-network',
    }
  )

  return { data: data?.getLocations ?? [], loading }
}
