import React from 'react'
import { Box, Typography } from '@mui/material'
import { useColorPalette } from '../../hooks/helper/useColor'

type BoxWithLabelProps = {
  label?: string
  size?: 'small' | 'medium'
  children: React.ReactNode
}
const BoxWithLabel: React.FC<React.PropsWithChildren<BoxWithLabelProps>> = ({
  label,
  size,
  children,
}) => {
  const colorPalette = useColorPalette()

  return !label ? (
    <>{children}</>
  ) : (
    <Box py={1} style={{ clear: 'both' }} flexGrow={1}>
      <Typography
        color={colorPalette.grey[400]}
        variant="body2"
        sx={{ fontSize: size === 'small' ? '12px' : '14px' }}
      >
        {label}
      </Typography>
      {children}
    </Box>
  )
}

export { BoxWithLabel }
