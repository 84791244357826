import { Card, Stack } from '@mui/material'
import React from 'react'
import { ScrollablePanel } from '../../../../../common/components/ScrollablePanel'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { mapSurveySchemaQuestionsToSections } from '../../utils/survey.mapper'
import {
  getNumberOfQuestionsInSurvey,
  getOrderedSections,
} from '../../utils/survey.util'
import { SurveyFillTime } from '../SurveyDetails/SurveyFillTime'
import { SurveyQuestionNumber } from '../SurveyDetails/SurveyQuestionNumber'
import { ViewSection } from './ViewSection'
import { ViewSurveyDetailsCore } from './ViewSurveyDetailsCore'
import { ViewSurveyPatientDescription } from './ViewSurveyPatientDescription'
import { SurveySchemaById } from '../../types/surveyStore.types'

interface Props {
  surveySchema: SurveySchemaById
}

export const ViewSurvey: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
}) => {
  const { t } = useTranslation()

  const sections = !!surveySchema.surveySchemaQuestions.length
    ? mapSurveySchemaQuestionsToSections(surveySchema?.surveySchemaQuestions)
    : [{ order: 1, questions: [], name: '' }]

  const orderedSections = getOrderedSections(sections)
  const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)

  const filteredSections = orderedSections.filter(
    (section) => !!section.questions.length
  )

  return (
    <ScrollablePanel title={t('survey:survey.details')}>
      <Card
        sx={{
          px: { xs: 2, md: 3 },
          pb: 3,
          gap: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ViewSurveyDetailsCore surveySchema={surveySchema} />
      </Card>

      <Stack direction={{ xs: 'column', sm: 'row' }} gap={5} sx={{ p: 3 }}>
        <SurveyQuestionNumber numberOfQuestions={numberOfQuestionsInSurvey} />
        <SurveyFillTime numberOfQuestions={numberOfQuestionsInSurvey} />
      </Stack>

      <ViewSurveyPatientDescription
        surveySchema={surveySchema}
        numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
      />

      {filteredSections.map((section, i) => (
        <ViewSection key={i} section={section} />
      ))}
    </ScrollablePanel>
  )
}
