import { useMutation } from '@apollo/client'
import {
  createAppointmentDependency,
  createAppointmentDependencyVariables,
  getTreatment,
} from '../../../../models/graphqlTypes'
import { CREATE_APPOINTMENT_DEPENDENCY } from '../../../../operations/appointmentOperations'
import { GET_TREATMENT } from '../../../../operations/treatmentOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { Appointment } from '../types/treatments.types'

type AppointmentDependencyCreateData = {
  treatmentId: string
  appointment: Appointment
  dependentAppointment: Appointment
}

type AppointmentDependencyCreateOptions = Pick<
  MutationHookOptions<
    createAppointmentDependency,
    createAppointmentDependencyVariables
  >,
  'onCompleted'
>

type Return = MutationTuple<
  createAppointmentDependency,
  createAppointmentDependencyVariables
>

export const useAppointmentDependencyCreate = (
  data: AppointmentDependencyCreateData,
  options: AppointmentDependencyCreateOptions
): Return => {
  const { treatmentId, appointment, dependentAppointment } = data
  const { onCompleted } = options

  return useMutation<
    createAppointmentDependency,
    createAppointmentDependencyVariables
  >(CREATE_APPOINTMENT_DEPENDENCY, {
    onCompleted: onCompleted,
    update: (cache, { data }) => {
      const createdDependency = data?.createAppointmentDependency
      const cachedQuery = cache.readQuery<getTreatment>({
        query: GET_TREATMENT,
        variables: {
          treatmentId: treatmentId,
        },
      })
      if (!cachedQuery || !createdDependency) {
        return
      }

      const newAppointments =
        cachedQuery.getTreatment.appointments?.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              dependent: [
                ...item.dependent,
                {
                  ...createdDependency,
                  appointment: dependentAppointment,
                },
              ],
            }
          }

          return item
        }) ?? []

      cache.writeQuery<getTreatment>({
        query: GET_TREATMENT,
        data: {
          getTreatment: {
            ...cachedQuery.getTreatment,
            appointments: newAppointments,
          },
        },
      })
    },
  })
}
