import React, { useEffect, useRef, useState } from 'react'
import { PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material'
import { Box, TablePagination } from '@mui/material'
import { Alert } from '@mui/material'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'

import { useDoctorPatientEESZTData } from '../hooks/useDoctorPatientEESZTData'
import { ResponsiveTable } from '../../../../common/components/ResponsiveTable'

import { EHRDocumentTypes } from '../../../../utils/ehr'

const SLOW_LOADING_WARNING_TIMEOUT = 3000

type Props = {
  patientId: string
}

const PatientEeszt: React.FC<React.PropsWithChildren<Props>> = ({
  patientId,
}) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const [showLoadingWarning, toggleLoadingWarning] = useState(false)

  const selectedLanguage = useSelectedLanguage()

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const { loading, hasDORError, permissionError, total, patientEeszt } =
    useDoctorPatientEESZTData({
      skip: false,
      patientId,
      paginationInput: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
    })

  useEffect(() => {
    if (loading) {
      timeout.current = setTimeout(() => {
        toggleLoadingWarning(true)
      }, SLOW_LOADING_WARNING_TIMEOUT)
    } else {
      toggleLoadingWarning(false)
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [loading])

  const jwtToken = localStorage.getItem('token')
  const tableData = patientEeszt.map((record) => {
    return {
      id: record.documentId,
      uploadDate: t('common:formattedDate', {
        date: record.timestamp,
      }),
      description: record.hospitalDescr,
      documentType: EHRDocumentTypes.find((dt) => dt.id === record.documentType)
        ?.name[selectedLanguage],
      doctorName: t('common:formattedName', record.doctor),
      downloadLink: `/api/eeszt_record/${record.EESZTId}/${jwtToken}`,
    }
  })

  return (
    <>
      {showLoadingWarning && (
        <Box py={1}>
          <Alert severity="warning">{t('eeszt:slowQueryWarning')}</Alert>
        </Box>
      )}
      {hasDORError && (
        <Box py={1}>
          <Alert severity="warning">{t('eeszt:dorWarning')}</Alert>
        </Box>
      )}
      {permissionError && (
        <Box py={1}>
          <Alert severity="error">{t('eeszt:missingKATEvent')}</Alert>
        </Box>
      )}
      <ResponsiveTable
        hideColumns={{
          700: ['description'],
          600: ['description', 'doctorName'],
        }}
        isLoading={loading}
        data={tableData}
        columns={[
          {
            title: t('eeszt:record.uploadDate'),
            field: 'uploadDate',
          },
          {
            title: t('eeszt:record.documentType'),
            field: 'documentType',
          },
          {
            title: t('common:doctorName'),
            field: 'doctorName',
          },
          {
            title: t('eeszt:record.department'),
            field: 'description',
          },
        ]}
        rowActions={[
          {
            icon: <PictureAsPdfIcon />,
            tooltip: 'download',
            onClick: (_, record) => {
              window.open(record.downloadLink)
            },
          },
        ]}
      >
        <Box width="100%" display="flex" justifyContent="flex-end">
          <TablePagination
            component="div"
            count={total}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            labelDisplayedRows={({ from, to, count }) =>
              t('table:pagination.labelDisplayedRows', {
                from,
                to,
                count,
              })
            }
            labelRowsPerPage={t('table:pagination.labelRowsPerPage')}
            rowsPerPageOptions={[10, 25, 50, 100]}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Box>
      </ResponsiveTable>
    </>
  )
}

export { PatientEeszt }
