import { Theme } from '@mui/material/styles/createTheme'
import React from 'react'
import {
  Link,
  Typography,
  useMediaQuery,
  Stack,
  TypographyProps,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { AvatarForIcon } from './AvatarForIcon'
import { MailOutline as MailOutlineIcon } from '@mui/icons-material'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
  },
}))

interface Props extends TypographyProps {
  isFooterOpen?: boolean
}

export const Contact: React.FC<React.PropsWithChildren<Props>> = ({
  isFooterOpen = false,
  ...typoProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Stack direction="row" gap={2}>
      {isFooterOpen && mdUp && (
        <AvatarForIcon>
          <MailOutlineIcon fontSize="inherit" />
        </AvatarForIcon>
      )}
      <Stack mt={isFooterOpen ? 2.5 : 0}>
        {isFooterOpen && (
          <Typography variant="h6" mb={1}>
            {t('footer:contact.title')}
          </Typography>
        )}

        <Link
          className={classes.link}
          href={`mailto:${t('footer:contact.email')}`}
          underline="hover"
          target="_blank"
        >
          <Typography {...typoProps}>{t('footer:contact.email')}</Typography>
        </Link>
      </Stack>
    </Stack>
  )
}
