import { QueryHookOptions, useQuery, NetworkStatus } from '@apollo/client'
import {
  getSurveyQuestions,
  getSurveyQuestionsVariables,
} from '../../../../models/graphqlTypes'
import { Question } from '../components/Questions/questions.types'
import { GET_SURVEY_QUESTIONS } from '../operations/questionOperations'

type Options = QueryHookOptions<getSurveyQuestions, getSurveyQuestionsVariables>

export type ReturnData = {
  loading: boolean
  surveyQuestions: Question[]
  totalQuestions: number
  hasMoreQuestions: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreQuestions: (options: Options) => void
}

export const useSurveyQuestions = (options: Options = {}): ReturnData => {
  const {
    data: questionData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveyQuestions, getSurveyQuestionsVariables>(
    GET_SURVEY_QUESTIONS,
    {
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )

  const totalQuestions = questionData?.getSurveyQuestions?.total || 0
  const surveyQuestions: Question[] =
    questionData?.getSurveyQuestions?.surveyQuestions.map((question) => {
      return {
        id: question.id,
        title: question.title,
        description: question.description ?? null,
        type: question.type,
        isGlobal: question.isGlobal,
        user: question.user,
        data: question.data,
        choices: question.mcSurveyQuestionChoices,
        aspects: question.sclSurveyQuestionAspects,
      }
    }) || []
  const hasMoreQuestions = surveyQuestions.length < totalQuestions

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreQuestions: fetchMore,
    refetch,
    surveyQuestions,
    totalQuestions,
    hasMoreQuestions,
  }
}
