import React, { useCallback, useEffect, useState } from 'react'
import {
  Box,
  Divider,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { useQuery, useMutation } from '@apollo/client'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useUserProfile } from '../../../common/hooks/useUserProfile'

import {
  GET_PATIENT_PROFILE,
  UPDATE_PATIENT_PROFILE,
} from '../../../operations/patientProfileOperations'
import {
  getPatientProfile,
  getPatientProfileVariables,
  updatePatientProfile,
  updatePatientProfileVariables,
  EditAblePatientData,
} from '../../../models/graphqlTypes'
import { ValidPhonePattern } from '../../../utils/isValidPhone'
import { ScrollablePanel } from '../../../common/components/ScrollablePanel'
import { Controller, useForm } from 'react-hook-form'
import { SaveButton } from '../../../common/components/SaveButton'
import { PasswordReset } from '../../../common/components/PasswordReset'
import { prettifyBloodType } from '../../../utils/bloodType'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { useStoreActions } from '../../../store/store.hooks'
import { useCitiesByPostalCode } from '../../../common/hooks/useCitiesByPostalCode'

const FIRST_COL_WIDTH = 150

interface PatientFormData extends EditAblePatientData {
  emergencyContactName?: string
  emergencyContactPhone?: string
}

const PatientProfile: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const [cityOptions, setCityOptions] = useState<string[]>([''])

  const {
    watch,
    register,
    reset,
    formState: { errors },
    handleSubmit,
    trigger,
    control,
    setValue,
  } = useForm<PatientFormData>({
    mode: 'all',
    defaultValues: { addressCity: '' },
  })
  const watchAllFields = watch()

  const resetFormWithPatientProfile = (
    patientProfile: getPatientProfile['getPatientProfile']
  ) => {
    reset({
      gender: patientProfile.gender,
      title: patientProfile.title,
      phone: patientProfile.phone,
      addressCity: patientProfile.addressCity || '',
      addressPostalCode: patientProfile.addressPostalCode,
      addressStreet: patientProfile.addressStreet,
      height: patientProfile.height,
      weight: patientProfile.weight,
      takenMedicines: patientProfile.takenMedicines,
      medicineIntolerance: patientProfile.medicineIntolerance,
      emergencyContactName: patientProfile.emergencyContacts?.[0]?.name || '',
      emergencyContactPhone: patientProfile.emergencyContacts?.[0]?.phone || '',
    })
  }

  const profile = useUserProfile()
  const patientId = profile?.id

  const {
    data: patientProfileData,
    refetch: patientProfileRefetch,
    loading: isLoadingPatientProfile,
  } = useQuery<getPatientProfile, getPatientProfileVariables>(
    GET_PATIENT_PROFILE,
    {
      skip: !patientId,
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data.getPatientProfile) {
          resetFormWithPatientProfile(data.getPatientProfile)
        }
      },
      variables: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        patientId: patientId!,
      },
    }
  )

  const patientProfile = patientProfileData?.getPatientProfile

  const [updatePatientProfileMutation, { loading: isUpdatingProfile }] =
    useMutation<updatePatientProfile, updatePatientProfileVariables>(
      UPDATE_PATIENT_PROFILE,
      {
        onCompleted: () => {
          patientProfileRefetch()
          reset(watchAllFields)
          setToast({
            text: t('notification:surveySuccesfullySent'),
            type: 'success',
          })
        },
      }
    )

  const { refetch, loading: isCitiesLoading } = useCitiesByPostalCode({
    variables: { postalCode: patientProfile?.addressPostalCode || '' },
    onCompleted: (data) => {
      const cities = data.getLocationByPostalCode ?? []
      setCityOptions(cities)
    },
  })

  useEffect(() => {
    const { unsubscribe } = watch(async (value, { name }) => {
      if (
        name === 'addressPostalCode' &&
        value.addressPostalCode?.length === 4
      ) {
        const { data } = await refetch({ postalCode: value.addressPostalCode })
        setValue('addressCity', data.getLocationByPostalCode[0] || '')
      }
    })
    return () => unsubscribe()
  }, [refetch, setValue, watch])

  const submitForm = useCallback(
    (formFields: PatientFormData) => {
      const height = formFields.height as number
      const weight = formFields.weight as number
      const emergencyContacts = [
        {
          name: formFields.emergencyContactName,
          phone: formFields.emergencyContactPhone,
        },
      ]

      delete formFields.emergencyContactName
      delete formFields.emergencyContactPhone

      if (!patientId) {
        return
      }

      updatePatientProfileMutation({
        variables: {
          patientId,
          updatePatientInput: {
            ...formFields,
            emergencyContacts,
            height: isNaN(height) ? undefined : +height,
            weight: isNaN(weight) ? undefined : +weight,
          },
        },
      })
    },
    [patientId, updatePatientProfileMutation]
  )

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  return (
    <ScrollablePanel
      title={t('patients:personalData')}
      headerRightContent={
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <PasswordReset />
          </Box>
          <Box>
            <SaveButton
              isSaving={isUpdatingProfile}
              onClick={handleSubmit(submitForm)}
            />
          </Box>
        </Box>
      }
      isLoading={isLoadingPatientProfile}
    >
      <Box display="flex">
        <Box maxWidth={500} width="100%">
          <Stack direction="row" flexWrap="wrap">
            <Box width={FIRST_COL_WIDTH} my={1}>
              <Typography variant="subtitle1">{t('patients:title')}</Typography>
              <Typography variant="body2" fontWeight="600">
                {patientProfile?.title || '-'}
              </Typography>
            </Box>
            <Box width={FIRST_COL_WIDTH} my={1}>
              <Typography variant="subtitle1">
                {t('patients:data.lastName')}
              </Typography>
              <Typography variant="body2" fontWeight="600">
                {patientProfile?.lastName || '-'}
              </Typography>
            </Box>
            <Box width={FIRST_COL_WIDTH} my={1}>
              <Typography variant="subtitle1">
                {t('patients:data.firstName')}
              </Typography>
              <Typography variant="body2" fontWeight="600">
                {patientProfile?.firstName || '-'}
              </Typography>
            </Box>
          </Stack>
          <Stack direction="row" my={1} flexWrap="wrap">
            <Typography width={FIRST_COL_WIDTH} variant="subtitle1">
              {t('patients:taj')}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {patientProfile?.tajNumber}
            </Typography>
          </Stack>
          <Stack direction="row" my={1} flexWrap="wrap">
            <Typography width={FIRST_COL_WIDTH} variant="subtitle1">
              {t('patients:birthdate')}
            </Typography>
            <Typography variant="body2" fontWeight="600">
              {t('common:dateFormatted', {
                date: patientProfile?.birthDate,
              })}
            </Typography>
          </Stack>
          {!!patientProfile?.bloodType && (
            <Stack direction="row" flexWrap="wrap">
              <Typography width={FIRST_COL_WIDTH} variant="subtitle1">
                {t('patients:bloodType')}
              </Typography>
              <Typography variant="body2" fontWeight="600">
                {prettifyBloodType(patientProfile.bloodType)}
              </Typography>
            </Stack>
          )}

          <Divider />

          <Typography my={2} variant="subtitle1">
            {t('patients:data.info')}
          </Typography>

          <form>
            <Stack gap={1}>
              <Stack direction="row" flexWrap="wrap">
                <Typography
                  width={FIRST_COL_WIDTH}
                  variant="subtitle1"
                  mt={1}
                  pr={2}
                >
                  {t(`patients:phone`)}
                </Typography>
                <Stack flexGrow={1}>
                  <TextField
                    {...register('phone', {
                      pattern: {
                        value: ValidPhonePattern,
                        message: t('messages:warnings.notPhone'),
                      },
                      required,
                    })}
                    size="small"
                    fullWidth
                    placeholder={'36 12 3456789'}
                    error={!!errors.phone}
                    helperText={errors.phone?.message}
                    onBlur={() => trigger()}
                  />
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap">
                <Typography
                  width={FIRST_COL_WIDTH}
                  variant="subtitle1"
                  mt={1}
                  pr={2}
                >
                  {t(`patients:address`)}
                </Typography>

                <Stack flexGrow={1}>
                  <Stack direction="row" gap={1} flexWrap="wrap">
                    <Box width={130}>
                      <BoxWithLabel
                        label={t(`patients:addressPostalCode`)}
                        size="small"
                      >
                        <TextField
                          {...register('addressPostalCode', {
                            pattern: {
                              value: /[0-9]{4}/,
                              message: t('messages:warnings.notPostalCode'),
                            },
                            maxLength: {
                              value: 4,
                              message: t('messages:warnings.notPostalCode'),
                            },
                          })}
                          size="small"
                          error={!!errors.addressPostalCode}
                          helperText={errors.addressPostalCode?.message}
                          onBlur={() => trigger()}
                        />
                      </BoxWithLabel>
                    </Box>

                    {!isCitiesLoading &&
                      cityOptions.includes(watch('addressCity') ?? '') && (
                        <BoxWithLabel
                          label={t(`patients:addressCity`)}
                          size="small"
                        >
                          <Controller
                            name="addressCity"
                            control={control}
                            render={({ field }) => (
                              <Select {...field} size="small" fullWidth>
                                {cityOptions.map((city: string, i: number) => (
                                  <MenuItem key={i} value={city}>
                                    {city}
                                  </MenuItem>
                                ))}
                              </Select>
                            )}
                          />
                        </BoxWithLabel>
                      )}
                  </Stack>

                  <BoxWithLabel
                    label={t(`patients:addressStreet`)}
                    size="small"
                  >
                    <TextField
                      {...register('addressStreet')}
                      size="small"
                      fullWidth
                    />
                  </BoxWithLabel>
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap">
                <Typography
                  width={FIRST_COL_WIDTH}
                  variant="subtitle1"
                  mt={1}
                  pr={2}
                >
                  {t(`patients:body`)}
                </Typography>

                <Stack direction="row" gap={1} flexGrow={1} flexWrap="wrap">
                  <Box width={130}>
                    <BoxWithLabel label={t(`patients:height`)} size="small">
                      <TextField
                        {...register('height')}
                        size="small"
                        type="number"
                        fullWidth
                      />
                    </BoxWithLabel>
                  </Box>
                  <BoxWithLabel label={t(`patients:weight`)} size="small">
                    <TextField
                      {...register('weight')}
                      size="small"
                      type="number"
                      fullWidth
                    />
                  </BoxWithLabel>
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap">
                <Typography
                  width={FIRST_COL_WIDTH}
                  variant="subtitle1"
                  mt={1}
                  pr={2}
                >
                  {t(`patients:medicine`)}
                </Typography>
                <Stack flexGrow={1}>
                  <BoxWithLabel
                    label={t(`patients:takenMedicines`)}
                    size="small"
                  >
                    <TextField
                      {...register('takenMedicines', { required })}
                      size="small"
                      onBlur={() => trigger()}
                      fullWidth
                      multiline
                      error={!!errors.takenMedicines}
                      helperText={errors.takenMedicines?.message}
                    />
                  </BoxWithLabel>
                  <BoxWithLabel
                    label={t(`patients:medicineIntolerance`)}
                    size="small"
                  >
                    <TextField
                      {...register('medicineIntolerance', { required })}
                      size="small"
                      onBlur={() => trigger()}
                      fullWidth
                      multiline
                      error={!!errors.medicineIntolerance}
                      helperText={errors.medicineIntolerance?.message}
                    />
                  </BoxWithLabel>
                </Stack>
              </Stack>

              <Stack direction="row" flexWrap="wrap">
                <Typography
                  width={FIRST_COL_WIDTH}
                  variant="subtitle1"
                  mt={1}
                  pr={2}
                >
                  {t(`patients:emergencyContact`)}
                </Typography>
                <Stack flexGrow={1}>
                  <BoxWithLabel
                    label={t(`patients:emergencyContactName`)}
                    size="small"
                  >
                    <TextField
                      {...register('emergencyContactName')}
                      size="small"
                      fullWidth
                    />
                  </BoxWithLabel>
                  <BoxWithLabel
                    label={t(`patients:emergencyContactPhone`)}
                    size="small"
                  >
                    <TextField
                      {...register('emergencyContactPhone', {
                        pattern: {
                          value: ValidPhonePattern,
                          message: t('messages:warnings.notPhone'),
                        },
                      })}
                      size="small"
                      onBlur={() => trigger()}
                      fullWidth
                      multiline
                      error={!!errors.emergencyContactPhone}
                      helperText={errors.emergencyContactPhone?.message}
                    />
                  </BoxWithLabel>
                </Stack>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Box>
    </ScrollablePanel>
  )
}

export { PatientProfile }
