import React from 'react'
import { Paper, Popover, PopoverProps } from '@mui/material'

interface Props extends PopoverProps {
  filterAnchorState: [
    HTMLElement | null,
    React.Dispatch<React.SetStateAction<HTMLElement | null>>,
  ]
}

export const FilterPopover: React.FC<React.PropsWithChildren<Props>> = ({
  filterAnchorState,
  children,
  ...props
}) => {
  const [filterAnchor, setFilterAnchor] = filterAnchorState

  return (
    <>
      {filterAnchor && (
        <Popover
          anchorEl={filterAnchor}
          onClose={() => setFilterAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          {...props}
        >
          <Paper sx={{ p: 2, minWidth: 300, maxWidth: 500 }}>{children}</Paper>
        </Popover>
      )}
    </>
  )
}
