import React from 'react'
import {
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  MenuItem,
  ButtonGroup,
  useTheme,
} from '@mui/material'
import {
  AddCircleOutline as AddCircleOutlineIcon,
  AddCircle as AddCircleIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material'

import { Loading } from '../../common/components/Loading'

import { ArrowUpIcon } from '../../common/icons/ArrowUpIcon'
import { BellIcon } from '../../common/icons/BellIcon'
import { CalendarAddIcon } from '../../common/icons/CalendarAddIcon'
import { CalendarIcon } from '../../common/icons/CalendarIcon'
import { CaretUpIcon } from '../../common/icons/CaretUpIcon'
import { CloseIcon } from '../../common/icons/CloseIcon'
import { CogIcon } from '../../common/icons/CogIcon'
import { DashboardIcon } from '../../common/icons/DashboardIcon'
import { DoctorIcon } from '../../common/icons/DoctorIcon'
import { HelpIcon } from '../../common/icons/HelpIcon'
import { ListEditIcon } from '../../common/icons/ListEditIcon'
import { LocationIcon } from '../../common/icons/LocationIcon'
import { LockIcon } from '../../common/icons/LockIcon'
import { LogoutIcon } from '../../common/icons/LogoutIcon'
import { MenuIcon } from '../../common/icons/MenuIcon'
import { MessageIcon } from '../../common/icons/MessageIcon'
import { PhoneIcon } from '../../common/icons/PhoneIcon'
import { ProfileIcon } from '../../common/icons/ProfileIcon'
import { TimeIcon } from '../../common/icons/TimeIcon'
import { UsersIcon } from '../../common/icons/UsersIcon'

const options = [
  {
    value: '1st',
    label: 'First',
  },
  {
    value: '2nd',
    label: 'Second',
  },
  {
    value: '3rd',
    label: 'Third',
  },
]

const UiKit: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme()

  const icons = [
    { icon: <ArrowUpIcon />, name: 'ArrowUp' },
    { icon: <BellIcon />, name: 'Bell' },
    { icon: <CalendarAddIcon />, name: 'CalendarAdd' },
    { icon: <CalendarIcon />, name: 'Calendar' },
    { icon: <CaretUpIcon />, name: 'CaretUp' },
    { icon: <CloseIcon />, name: 'Close' },
    { icon: <CogIcon />, name: 'Cog' },
    { icon: <DashboardIcon />, name: 'Dashboard' },
    { icon: <DoctorIcon />, name: 'Doctor' },
    { icon: <HelpIcon />, name: 'Help' },
    { icon: <ListEditIcon />, name: 'ListEdit' },
    { icon: <LocationIcon />, name: 'Location' },
    { icon: <LockIcon />, name: 'Lock' },
    { icon: <LogoutIcon />, name: 'Logout' },
    { icon: <MenuIcon />, name: 'Menu' },
    { icon: <MessageIcon />, name: 'Message' },
    { icon: <PhoneIcon />, name: 'Phone' },
    { icon: <ProfileIcon />, name: 'Profile' },
    { icon: <TimeIcon />, name: 'Time' },
    { icon: <UsersIcon />, name: 'Users' },
  ]

  return (
    <>
      <Typography variant="h4">Colors</Typography>

      <Grid container justifyContent="space-between" sx={{ mt: 2, mb: 10 }}>
        <Grid item>
          <Box width={100} height={100} bgcolor={theme.palette.common.black} />
          {'Dark'}
        </Grid>
        <Grid item>
          <Box width={100} height={100} bgcolor={theme.palette.primary.main} />
          {'Blue'}
        </Grid>
        <Grid item>
          <Box width={100} height={100} bgcolor={theme.palette.primary.dark} />
          {'Dark Blue'}
        </Grid>
        <Grid item>
          <Box width={100} height={100} bgcolor={theme.palette.success.main} />
          {'Turquoise'}
        </Grid>
        <Grid item>
          <Box width={100} height={100} bgcolor={theme.palette.error.main} />
          {'Red'}
        </Grid>
      </Grid>

      <Typography variant="h4">Typography</Typography>
      <Box mt={2} mb={10}>
        <Typography variant="h1">Headline 1</Typography>
        <Typography variant="h2">Headline 2</Typography>
        <Typography variant="h3">Headline 3</Typography>
        <Typography variant="h4">Headline 4</Typography>
        <Typography variant="h5">Headline 5</Typography>
        <Typography variant="h6">Headline 6</Typography>
        <Typography variant="body1">Body 1</Typography>
        <Typography variant="body2">Body 2</Typography>
        <Typography variant="subtitle1">Subtitle 1</Typography>
        <Typography variant="subtitle2">Subtitle 2</Typography>
        <Typography variant="button">Button</Typography>
        <br />
        <Typography variant="caption">Caption</Typography>
        <br />
        <Typography variant="overline">Overline</Typography>
        <br />
      </Box>

      <Typography variant="h4">Icons</Typography>
      <Box mt={2} mb={10}>
        {icons.map(({ icon, name }) => (
          <Box key={name} p={2} display="flex" alignItems="center">
            <Box mr={2}>{icon}</Box>
            <Typography>{name}</Typography>
          </Box>
        ))}
      </Box>

      <Typography variant="h4">Full Buttons</Typography>
      <Box mt={2} mb={10}>
        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button size="large">Large Button</Button>
          </Box>
          <Box ml={2}>
            <Button>Regular Button</Button>
          </Box>
          <Box ml={2}>
            <Button size="small">Small Button</Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button>Async Action</Button>
          </Box>
          <Box ml={2}>
            <Button disabled startIcon={<Loading inline size={12} />}>
              Loading
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button color="secondary" size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button color="secondary">Regular Button</Button>
          </Box>
          <Box ml={2}>
            <Button color="secondary" size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button disabled size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button disabled>Regular Button</Button>
          </Box>
          <Box ml={2}>
            <Button disabled size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button startIcon={<AddCircleOutlineIcon />} size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button startIcon={<AddCircleOutlineIcon />}>Regular Button</Button>
          </Box>
          <Box ml={2}>
            <Button startIcon={<AddCircleIcon />} size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button size="large">
              <Box display="flex" mx={-2} my={0.5}>
                <AddCircleOutlineIcon fontSize="large" />
              </Box>
            </Button>
          </Box>
          <Box ml={2}>
            <Button>
              <Box display="flex" mx={-2} my={0.5}>
                <AddCircleOutlineIcon />
              </Box>
            </Button>
          </Box>
          <Box ml={2}>
            <Button size="small">
              <Box display="flex" mx={-2} my={0.5}>
                <AddCircleIcon fontSize="small" />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>

      <Typography variant="h4">Outlined Buttons</Typography>
      <Box mt={2} mb={10}>
        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button variant="outlined" size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined">Regular Button</Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button variant="outlined" color="secondary" size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" color="secondary">
              Regular Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" color="secondary" size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button variant="outlined" disabled size="large">
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" disabled>
              Regular Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" disabled size="small">
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineIcon />}
              size="large"
            >
              Large Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" startIcon={<AddCircleOutlineIcon />}>
              Regular Button
            </Button>
          </Box>
          <Box ml={2}>
            <Button
              variant="outlined"
              startIcon={<AddCircleIcon />}
              size="small"
            >
              Small Button
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <Button variant="outlined" size="large">
              <AddCircleOutlineIcon sx={{ display: 'flex', mx: -2, my: 0.5 }} />
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined">
              <AddCircleOutlineIcon
                fontSize="small"
                sx={{ display: 'flex', mx: -2, my: 0.5 }}
              />
            </Button>
          </Box>
          <Box ml={2}>
            <Button variant="outlined" size="small">
              <AddCircleIcon
                fontSize="small"
                sx={{ display: 'flex', mx: -2, my: 0.5 }}
              />
            </Button>
          </Box>
        </Box>

        <Box mt={2} display="flex">
          <Box ml={2}>
            <ButtonGroup color="primary" variant="outlined" size="small">
              <Button>
                <ChevronLeftIcon
                  fontSize="small"
                  sx={{ display: 'flex', mx: -2, my: 0.5 }}
                />
              </Button>
              <Button>
                <ChevronRightIcon
                  fontSize="small"
                  sx={{ display: 'flex', mx: -2, my: 0.5 }}
                />
              </Button>
            </ButtonGroup>
          </Box>
        </Box>
      </Box>

      <Typography variant="h4">Native select</Typography>
      <Box mt={2} mb={10} display="flex">
        <TextField
          label="Native select"
          placeholder="Placeholder text"
          value=""
          select
          SelectProps={{
            native: true,
          }}
        >
          {options.map(({ value, label }) => (
            <option key={value} value={value}>
              {label}
            </option>
          ))}
        </TextField>
        <Box ml={2}>
          <TextField
            label="Native select"
            placeholder="Placeholder text"
            value=""
            select
            SelectProps={{
              native: true,
            }}
          >
            {options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </TextField>
        </Box>
      </Box>

      <Typography variant="h4">Dropdown</Typography>
      <Box mt={2} mb={10} display="flex">
        <TextField label="Dropdown" value="" select>
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </TextField>
        <Box ml={2}>
          <TextField label="Dropdown" value="" select>
            {options.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      <Typography variant="h4">Text input</Typography>
      <Box mt={2} mb={10}>
        <Box mt={4} display="flex">
          <TextField
            label="Large Input Long Text"
            placeholder="Placeholder text"
          />
          <Box ml={2}>
            <TextField
              size="small"
              label="Small Input"
              placeholder="Placeholder text"
            />
          </Box>
        </Box>

        <Box mt={4} display="flex">
          <TextField
            label="Large Input Long Text"
            placeholder="Placeholder text"
            multiline
            minRows={4}
          />
          <Box ml={2}>
            <TextField
              size="small"
              label="Small Input"
              placeholder="Placeholder text"
              multiline
              minRows={4}
            />
          </Box>
        </Box>

        <Box mt={4} display="flex">
          <TextField label="Large Input" value="Filled input" />
          <Box ml={2}>
            <TextField size="small" label="Small Input" value="Filled input" />
          </Box>
        </Box>

        <Box mt={4} display="flex">
          <TextField
            label="Large Input"
            value="Error input"
            helperText="Something wrong."
            error
          />
          <Box ml={2}>
            <TextField
              size="small"
              label="Small Input"
              value="Error input"
              helperText="Something wrong."
              error
            />
          </Box>
        </Box>

        <Box mt={4} display="flex">
          <TextField label="Large Input" placeholder="Disabled text" disabled />
          <Box ml={2}>
            <TextField
              size="small"
              label="Small Input"
              placeholder="Disabled text"
              disabled
            />
          </Box>
        </Box>

        <Box mt={4} display="flex" alignItems="center">
          <Typography variant="subtitle2">Label on left</Typography>
          <Box ml={4}>
            <TextField placeholder="Placeholder text" />
          </Box>
        </Box>

        <Box mt={4} display="flex" alignItems="center">
          <Typography variant="subtitle2">Label on left</Typography>
          <Box ml={4}>
            <TextField size="small" placeholder="Placeholder text" />
          </Box>
        </Box>

        <Box mt={4} display="flex">
          <Box mt={2}>
            <Typography variant="subtitle2">Label on left</Typography>
          </Box>
          <Box ml={4}>
            <TextField
              placeholder="Placeholder text"
              helperText="Helper text."
            />
          </Box>
        </Box>

        <Box my={4} display="flex">
          <Box mt={1}>
            <Typography variant="subtitle2">Label on left</Typography>
          </Box>
          <Box ml={4}>
            <TextField
              size="small"
              placeholder="Placeholder text"
              helperText="Helper text."
            />
          </Box>
        </Box>
      </Box>
    </>
  )
}

export { UiKit }
