import { TreatmentSchema } from '../views/doctor/TreatmentSchema/types/treatmentSchemas.types'

/**
 * Whether the protocol is shareable by the current user.
 * (not global, owns it, not disabled)
 * @param treatment The treatment/protocol to observe
 * @param userId The current logged in user
 * @returns true if the protocol is shareable
 */
export const isTreatmentSchemaShareable = (
  treatment: Pick<TreatmentSchema, 'user' | 'isGlobal' | 'disabled'>,
  userId?: string
): boolean =>
  !!treatment.user &&
  !!userId &&
  treatment.user.id === userId &&
  !treatment.disabled

/**
 * Whether the protocol is editable by the current user.
 * (owns the protocol)
 * @param treatment The treatment/protocol to observe
 * @param userId The current logged in user
 * @returns true if the protocol is editable (owns the protocol)
 */
export const isTreatmentSchemaEditable = (
  treatment: Pick<TreatmentSchema, 'user'>,
  userId?: string
): boolean => !!treatment.user && !!userId && treatment.user.id === userId

/**
 * Whether the current user is able to unsubcribe themselves from the treatment schema.
 * (The user is not the owner and the treatment is shared with them)
 * @param treatment The treatment/protocol to observe
 * @param userId The current logged in user
 * @returns true if the protocol is unsubscribeable
 */
export const canUnsubscribeFromTreatmentSchema = (
  treatment: Pick<TreatmentSchema, 'user' | 'sharedWith'>,
  userId?: string
): boolean => {
  const sharedWithMe = treatment.sharedWith
    ?.map((user) => user.id)
    .includes(userId ?? '')

  return !!treatment.user && treatment.user.id !== userId && sharedWithMe
}
