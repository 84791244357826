import React, { useContext } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useFormContext } from 'react-hook-form'
import { useSurveySchemaCreate } from '../../hooks/useSurveySchemaCreate'
import { useSurveySchemaUpdate } from '../../hooks/useSurveySchemaUpdate'
import { useHistory } from 'react-router-dom'
import { mapSectionsToCreateSurveyQuestionToSurveySchemaInputs } from '../../utils/survey.mapper'
import { Section } from '../EditSurvey/editSurvey.types'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import { SurveyContext } from '../EditSurveyState'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { getNumberOfQuestionsInSurvey } from '../../utils/survey.util'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

interface Props extends ButtonProps {
  surveySchema?: SurveySchemaWithoutUser
  sections: Section[]
}

export const SaveSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  sections,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const { setSuccessToast, setErrorToast, setCustomErrorToast } =
    useNotifications()
  const history = useHistory()
  const userType = useUserType()
  const { questionFormMethods, setShouldSave } = useContext(SurveyContext)

  const { handleSubmit, watch, reset } = useFormContext()
  const status = watch('status')

  const [createSurvey] = useSurveySchemaCreate()
  const [updateSurvey] = useSurveySchemaUpdate({
    refetchQueries: ['getSurveySchemaById'],
  })

  const onSave = handleSubmit(async (formValues) => {
    const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)
    if (numberOfQuestionsInSurvey === 0) {
      setCustomErrorToast(t('survey:error.noQuestions'))
      return
    }

    const invalidQuestionForms = questionFormMethods.filter(
      (qf) => !qf.formState.isValid
    )
    if (invalidQuestionForms.length) {
      invalidQuestionForms.forEach((iqf) => iqf.trigger())
      return
    }

    const surveySchemaData = {
      title: formValues.title,
      description: formValues.description,
      patientDescription: formValues.patientDescription,
      professionIds: formValues.professions.map(({ id }: { id: string }) => id),
      categories: formValues.categories,
      referenceUrls: formValues.referenceUrls,
      snomedCode: formValues.snomedCode,
      bnoCodeIds: formValues.bnoCodes.map(({ id }: { id: string }) => id),
      questions:
        mapSectionsToCreateSurveyQuestionToSurveySchemaInputs(sections),
    }

    if (surveySchema) {
      await updateSurvey({
        variables: {
          surveySchemaData: {
            id: surveySchema.id,
            ...surveySchemaData,
          },
        },
        onCompleted: () => {
          setSuccessToast('successUpdateSurvey')
        },
        onError: (e) => setErrorToast(e),
      })
    } else {
      await createSurvey({
        variables: {
          surveySchemaData,
        },
        onCompleted: (response) => {
          history.push(
            `/${userType?.toLowerCase()}/survey/${
              response.createSurveySchema.id
            }`
          )
          setSuccessToast('successInsertSurvey')
        },
        onError: (e) => setErrorToast(e),
      })
    }

    reset(formValues)
    setShouldSave(false)
  })

  return (
    <>
      {status !== SurveySchemaStatusEnum.Archived && (
        <Button
          data-cy="SurveySchema-Button-save"
          onClick={onSave}
          {...buttonProps}
        >
          {t('common:save')}
        </Button>
      )}
    </>
  )
}
