import React from 'react'
import clsx from 'clsx'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Box, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TreatmentStatus } from '../../../../../models/graphqlTypes'

import { useHistory } from 'react-router-dom'
import { PatientHomeTreatment } from '../../types/patientEvents.types'

type Props = {
  selectedTreatmentId?: string | null
  treatments: PatientHomeTreatment[]
}

type PillProps = {
  id: string | null
  title: string
  isDeleted?: boolean
  selectedTreatmentId?: string | null
}

const useStyles = makeStyles((theme) => ({
  showPastEventsButton: {
    borderRadius: theme.spacing(1.75),
    height: theme.spacing(3.5),
    '&.selected': {
      borderColor: theme.palette.primary.light,
      color: theme.palette.primary.light,
    },
    '&.deleted': {
      opacity: 0.4,
    },
  },
  button: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}))

const Pill: React.FC<React.PropsWithChildren<PillProps>> = ({
  id,
  title,
  isDeleted,
  selectedTreatmentId,
}) => {
  const history = useHistory()
  const classes = useStyles()
  const selected = id === selectedTreatmentId
  const link = `/patient/home/treatments/${id || ''}`
  return (
    <Button
      key={id}
      className={clsx(classes.showPastEventsButton, classes.button, {
        selected,
        deleted: isDeleted,
      })}
      size="small"
      variant="outlined"
      onClick={() => history.push(link)}
    >
      {title}
    </Button>
  )
}

const TreatmentFilter: React.FC<React.PropsWithChildren<Props>> = ({
  selectedTreatmentId,
  treatments,
}) => {
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const treatmentsArray = [...treatments].sort((a, b) => {
    if (a.status === TreatmentStatus.Cancelled) {
      return 1
    }
    if (b.status === TreatmentStatus.Cancelled) {
      return -1
    }
    return 0
  })

  const shouldHideFilters = treatmentsArray.length < 2

  return shouldHideFilters ? (
    <Box pt={1.5} />
  ) : (
    <Box px={1.5} pt={1.5} pb={1}>
      <Box pr={1} pb={0.5} display="inline">
        {t('common:filter')}:
      </Box>
      <Pill
        selectedTreatmentId={selectedTreatmentId}
        id={null}
        title={t('appointment:allAppointments')}
      />
      {treatments.map((treatment) => (
        <Pill
          selectedTreatmentId={selectedTreatmentId}
          key={treatment.id}
          id={treatment.id}
          isDeleted={treatment.status === TreatmentStatus.Cancelled}
          title={treatment.title[selectedLanguage]}
        />
      ))}
    </Box>
  )
}

export { TreatmentFilter }
