import { useQuery } from '@apollo/client'
import { GET_DOCTOR_OWN_ASSISTANTS } from '../operations/doctorOwnAssistantsOperations'
import { getDoctorOwnAssistants } from '../../../../models/graphqlTypes'
import { DoctorOwnAssistant } from '../types/doctorAssistants.types'

type Return = {
  data: DoctorOwnAssistant[]
  loading: boolean
  refetch: () => void
}
export const useDoctorOwnAssistants = (): Return => {
  const { data, loading, refetch } = useQuery<getDoctorOwnAssistants>(
    GET_DOCTOR_OWN_ASSISTANTS,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    }
  )

  return {
    loading,
    data: data?.getDoctorOwnAssistants ?? [],
    refetch,
  }
}
