import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CalendarIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (
  props
) => {
  return (
    <SvgIcon viewBox="0 0 16 16" width="16" height="16" {...props}>
      <g transform="translate(-30.000000, -312.000000)" fillRule="nonzero">
        <g transform="translate(30.000000, 310.000000)">
          <g transform="translate(0.000000, 2.000000)">
            <path d="M1.50133333,16 C0.674,16 0.00133333333,15.3273333 0.00133333333,14.5 L0.00133333333,3.5 C0.00133333333,2.67266667 0.674,2 1.50133333,2 L4.00133333,2 L4.00133333,0.5 C4.00133333,0.224 4.22533333,0 4.50133333,0 C4.77733333,0 5.00133333,0.224 5.00133333,0.5 L5.00133333,2 L11.0013333,2 L11.0013333,0.5 C11.0013333,0.224 11.2253333,0 11.5013333,0 C11.7773333,0 12.0013333,0.224 12.0013333,0.5 L12.0013333,2 L14.5013333,2 C15.3286667,2 16.0013333,2.67266667 16.0013333,3.5 L16.0013333,14.5 C16.0013333,15.3273333 15.3286667,16 14.5013333,16 L1.50133333,16 Z M1.00133333,14.5 C1.00133333,14.776 1.22533333,15 1.50133333,15 L14.5013333,15 C14.7773333,15 15.0013333,14.776 15.0013333,14.5 L15.0013333,7 L1.00133333,7 L1.00133333,14.5 Z M15.0013333,6 L15.0013333,3.5 C15.0013333,3.224 14.7773333,3 14.5013333,3 L12.0013333,3 L12.0013333,4 C12.0013333,4.276 11.7773333,4.5 11.5013333,4.5 C11.2253333,4.5 11.0013333,4.276 11.0013333,4 L11.0013333,3 L5.00133333,3 L5.00133333,4 C5.00133333,4.276 4.77733333,4.5 4.50133333,4.5 C4.22533333,4.5 4.00133333,4.276 4.00133333,4 L4.00133333,3 L1.50133333,3 C1.22533333,3 1.00133333,3.224 1.00133333,3.5 L1.00133333,6 L15.0013333,6 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { CalendarIcon }
