import { MutationTuple } from '@apollo/client/react/types/types'
import { gql, useMutation } from '@apollo/client'
import {
  ChangeLanguage,
  ChangeLanguageVariables,
} from '../../models/graphqlTypes'
import { CHANGE_LANGUAGE } from '../../operations/userOperations'

type Return = MutationTuple<ChangeLanguage, ChangeLanguageVariables>

export const useLanguageChange = (): Return => {
  return useMutation<ChangeLanguage, ChangeLanguageVariables>(CHANGE_LANGUAGE, {
    update: (cache, { data }) => {
      if (!data?.changeLanguage) {
        return
      }
      const { changeLanguage } = data
      cache.writeFragment<{ language: string }>({
        id: cache.identify({ __typename: 'User', id: changeLanguage.id }),
        data: { language: changeLanguage.language },
        fragment: gql`
          fragment UserLanguageChore on User {
            language
          }
        `,
      })
    },
  })
}
