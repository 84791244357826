import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const BellIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 24} ${props.height ?? 24}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g transform="translate(-1264.000000, -30.000000)" fillRule="nonzero">
        <g transform="translate(1264.000000, 23.000000)">
          <g transform="translate(0.000000, 7.000000)">
            <path d="M7.992,19.2008 C7.616,19.2008 7.2408,19.1048 6.9072,18.9224 C6.3752,18.632 5.988,18.1512 5.8176,17.5696 C5.724,17.252 5.9064,16.9184 6.224,16.8248 C6.2792,16.8088 6.336,16.8008 6.3928,16.8008 C6.6568,16.8008 6.8936,16.9776 6.9688,17.2312 C7.0496,17.5056 7.232,17.732 7.4824,17.8696 C7.64,17.9552 7.816,18.0008 7.9928,18.0008 C8.0944,18.0008 8.1968,17.9856 8.296,17.9568 C8.6464,17.8536 8.9176,17.5824 9.0208,17.232 C9.0952,16.9784 9.332,16.8008 9.5968,16.8008 C9.6536,16.8008 9.7096,16.8088 9.7648,16.8248 C10.0824,16.9184 10.2656,17.252 10.172,17.5696 C9.9536,18.3144 9.3784,18.8896 8.6336,19.108 C8.424,19.1696 8.208,19.2008 7.992,19.2008 Z" />
            <path d="M0.7928,15.6008 C0.5648,15.6008 0.3536,15.4688 0.2536,15.264 C0.152,15.056 0.1784,14.8136 0.32,14.6312 C0.3248,14.624 1.3928,13.08 1.3928,8.4008 C1.3928,4.9664 4.0056,2.1328 7.3928,1.828 L7.3928,0.6008 C7.3928,0.2696 7.6616,0.0008 7.9928,0.0008 C8.324,0.0008 8.5928,0.2696 8.5928,0.6008 L8.5928,1.828 C11.98,2.1328 14.5928,4.9664 14.5928,8.4008 C14.5928,13.4432 15.5896,14.5496 15.5992,14.56 C15.7736,14.7192 15.8368,14.9856 15.7488,15.2144 C15.6576,15.4488 15.4392,15.6008 15.1928,15.6008 L0.7928,15.6008 Z M14.1608,14.4008 C13.7752,13.4152 13.392,11.6248 13.392,8.4008 C13.392,5.4232 10.9696,3.0008 7.992,3.0008 C5.0144,3.0008 2.592,5.4232 2.592,8.4008 C2.592,11.5664 2.128,13.4224 1.7552,14.4008 L14.1608,14.4008 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { BellIcon }
