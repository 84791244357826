import { useQuery } from '@apollo/client'
import {
  institutionAdminProfiles,
  institutionAdminProfilesVariables,
} from '../../../models/graphqlTypes'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { useMemo } from 'react'
import { GET_INSTITUTION_ADMIN_PROFILES } from '../../../operations/institutionOperations'
import { profileChore } from '../../../models/graphqlTypes'

type Return = {
  profiles: profileChore[]
  loading: boolean
}

type Options = QueryHookOptions<
  institutionAdminProfiles,
  institutionAdminProfilesVariables
>

export const useInstitutionAdminProfileList = (
  options: Options = {}
): Return => {
  const { data: { institutionAdminProfiles: profiles = [] } = {}, loading } =
    useQuery<institutionAdminProfiles, institutionAdminProfilesVariables>(
      GET_INSTITUTION_ADMIN_PROFILES,
      {
        notifyOnNetworkStatusChange: true,
        ...options,
      }
    )

  return useMemo(() => {
    return {
      profiles,
      loading,
    }
  }, [loading, profiles])
}
