import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphqlTypes'

export const RedirectHandler: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const userType = useUserType()
  const { pathname } = useLocation()

  const loginRoutePath = '/login/'
  const isRedirectAfterLogin = pathname.indexOf(loginRoutePath) === 0
  if (isRedirectAfterLogin) {
    const redirectTo = decodeURIComponent(pathname.slice(loginRoutePath.length))
    return <Redirect to={redirectTo} />
  }

  if (userType === UserType.Patient) {
    return <Redirect to="/patient/home" />
  }

  if (userType === UserType.Doctor) {
    return <Redirect to="/doctor/home" />
  }

  if (userType === UserType.Assistant) {
    return <Redirect to="/assistant/home" />
  }

  if (userType === UserType.Admin) {
    return <Redirect to="/admin/home" />
  }

  return null
}
