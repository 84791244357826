import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { Box, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ScrollablePanel } from '../../../../../common/components/ScrollablePanel'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { GetAggregatedSurveyResult } from './GetAggregatedSurveyResult'
import { GetSurveyResultByPatient } from './GetSurveyResultByPatient'
import { SurveyResultHeader } from './SurveyResultHeader'
import { SurveyResultQuestions } from './SurveyResultQuestions'
import { useUserType } from '../../../../../common/hooks/useUserType'
import {
  SurveyResultHeaderForm,
  SurveySchemaById,
} from '../../types/surveyStore.types'
import { FormProvider, useForm } from 'react-hook-form'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'

const useStyles = makeStyles((theme) => ({
  textPrimary: {
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.common.black,
    },
  },
}))

interface Props {
  surveySchema: SurveySchemaById
}

export const SurveyResult: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const userType = useUserType()

  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)

  const formMethods = useForm<SurveyResultHeaderForm>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
      startDate: null,
      endDate: null,
    },
  })

  return (
    <FormProvider {...formMethods}>
      <Box
        display="flex"
        height="100%"
        flexDirection="column"
        overflow="hidden"
        sx={{ backgroundColor: '#EFEEF3' }}
      >
        <Box px={{ xs: 1, md: 2 }} pt={0.5} pb={1}>
          <Button
            variant="text"
            startIcon={<KeyboardBackspaceIcon />}
            sx={{ mb: 1.5 }}
            onClick={() => history.push(`/${userType?.toLowerCase()}/surveys`)}
            classes={{ textPrimary: classes.textPrimary }}
          >
            {t('survey:result.backToSurveyList')}
          </Button>
          <SurveyResultHeader
            surveySchema={surveySchema}
            tabState={[selectedTabIndex, setSelectedTabIndex]}
          />
        </Box>
        <Box overflow="hidden" px={2}>
          <ScrollablePanel withoutPadding>
            {selectedTabIndex === 0 && (
              <SurveyResultQuestions
                surveySchema={surveySchema}
                isPreview={true}
              />
            )}
            {selectedTabIndex === 1 && (
              <GetAggregatedSurveyResult surveySchema={surveySchema} />
            )}
            {selectedTabIndex === 2 && (
              <GetSurveyResultByPatient surveySchema={surveySchema} />
            )}
          </ScrollablePanel>
        </Box>
      </Box>
    </FormProvider>
  )
}
