import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { ANSWER_TIME_FOR_QUESTION_IN_MIN } from '../../utils/survey.util'
import { SurveyContext } from '../EditSurveyState'
import { SurveySchemaById } from '../../types/surveyStore.types'

interface Props {
  numberOfQuestionsInSurvey: number
}

export const EditSurveyPatientDescription: React.FC<
  React.PropsWithChildren<Props>
> = ({ numberOfQuestionsInSurvey }) => {
  const { t } = useTranslation()
  const { isReadonly } = useContext(SurveyContext)

  const {
    formState: { errors },
    register,
  } = useFormContext<SurveySchemaById>()

  return (
    <Card>
      <CardHeader
        sx={{ px: 3 }}
        title={t('survey:patientDescription.title')}
      ></CardHeader>
      <CardContent sx={{ px: 3, pt: 0 }}>
        <Stack gap={1.5}>
          <TextField
            {...register('patientDescription', {
              required: t('messages:warnings.required') as string,
            })}
            fullWidth
            variant="outlined"
            multiline
            error={!!errors.patientDescription}
            helperText={errors.patientDescription?.message}
            placeholder={t('survey:petientDescription.placeholder')}
            disabled={isReadonly}
          />
          <Typography variant="body2">
            {t('survey:description.time', {
              time: numberOfQuestionsInSurvey * ANSWER_TIME_FOR_QUESTION_IN_MIN,
            })}
          </Typography>
          <Typography variant="italic">
            {t('survey:description.thankyou')}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
