import React, { useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useLanguageChange } from '../../../hooks/useLanguageChange'
import { useSelectedLanguage } from '../../../hooks/useSelectedLanguage'
import { useTranslation } from '../../../hooks/helper/useTranslation'
import dayjs from 'dayjs'
import 'dayjs/locale/hu'
import 'dayjs/locale/en-gb'
import i18n from '../../../../i18n'
import FlagHu from '../../../../assets/icons/hungary.svg'
import FlagEn from '../../../../assets/icons/uk.svg'
import { useHistory } from 'react-router'
import { Language } from '../../../../models/graphqlTypes'

const languages = [
  {
    translationKey: 'common:languages.en',
    value: Language.en,
    flag: FlagEn,
  },
  {
    translationKey: 'common:languages.hu',
    value: Language.hu,
    flag: FlagHu,
  },
]

type Props = {
  isDark?: boolean
}

const useStyles = makeStyles((theme) => ({
  paper: {
    background: ({ isDark }: Props) =>
      theme.palette.common[isDark ? 'black' : 'white'],
    border: ({ isDark }: Props) =>
      isDark ? `1px solid ${theme.palette.common.white}` : 'initial',
  },
  menuItem: {
    justifyContent: 'left',
  },
}))

const dayjsLangCodeOverrides: { [language: string]: string } = {
  en: 'en-gb',
}

const LanguageSelector: React.FC<React.PropsWithChildren<Props>> = ({
  isDark,
}) => {
  const { t, toggleDebug, debug } = useTranslation()
  const classes = useStyles({ isDark })
  const history = useHistory()
  const [changeLanguage] = useLanguageChange()

  const translatorModeEnabled = process.env.REACT_APP_TRANSLATOR_MODE === 'true'

  const selectedLanguage = useSelectedLanguage()

  const handleLanguageChange = (value: Language) => {
    i18n.changeLanguage(value)
    dayjs.locale(dayjsLangCodeOverrides[value] || value)
    return changeLanguage({ variables: { language: value } })
  }

  const i18nCurrentLanguage = i18n.language
  const dayjsCurrentLanguage = dayjs.locale()
  useEffect(() => {
    if (i18nCurrentLanguage !== selectedLanguage) {
      i18n.changeLanguage(selectedLanguage)
    }
    if (dayjsCurrentLanguage !== selectedLanguage) {
      dayjs.locale(dayjsLangCodeOverrides[selectedLanguage] || selectedLanguage)
    }
  }, [selectedLanguage, i18nCurrentLanguage, dayjsCurrentLanguage])

  return (
    <Box px={isDark ? 0 : 2} py={0.5} mx={isDark ? -2 : 0}>
      {languages.map(({ value, flag, translationKey }) => {
        const isSelectedLanguage = selectedLanguage === value

        return (
          <Box key={value} py={0.5}>
            <Button
              className={classes.menuItem}
              variant="text"
              color="inherit"
              fullWidth
              disabled={isSelectedLanguage}
              onClick={() => handleLanguageChange(value)}
            >
              <Box
                display="flex"
                flexDirection={isDark ? 'row-reverse' : 'row'}
                alignItems="center"
                px={0.75}
                color={isDark ? 'common.white' : undefined}
                bgcolor={isDark ? 'common.black' : undefined}
              >
                <Box
                  component="img"
                  height={18}
                  pl={isDark ? 2 : 0}
                  pr={!isDark ? 2 : 0}
                  src={flag}
                  alt="flag-icon"
                />
                <Typography
                  variant={isDark ? 'body1' : 'body2'}
                  color={isDark && isSelectedLanguage ? 'primary' : 'inherit'}
                >
                  {t(translationKey)}
                </Typography>
              </Box>
            </Button>
          </Box>
        )
      })}
      {translatorModeEnabled && (
        <Button
          fullWidth
          variant={debug ? 'contained' : 'outlined'}
          onClick={() => {
            toggleDebug()
            history.go(0)
          }}
        >
          {t('common:translatorMode')}
        </Button>
      )}
    </Box>
  )
}

export { LanguageSelector }
