import { NetworkStatus, QueryResult, useQuery } from '@apollo/client'
import {
  getTreatmentSchemas,
  getTreatmentSchemasVariables,
} from '../../../../models/graphqlTypes'
import { GET_TREATMENT_SCHEMAS } from '../operations/treatmentSchemaOperations'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { renameDuplicatedTreatmentTitles } from '../utils/treatment.util'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { TreatmentSchema } from '../types/treatmentSchemas.types'

type Options = QueryHookOptions<
  getTreatmentSchemas,
  getTreatmentSchemasVariables
>

type Return = {
  loading: boolean
  refetch: QueryResult<
    getTreatmentSchemas,
    getTreatmentSchemasVariables
  >['refetch']
  treatmentSchemas: TreatmentSchema[]
  totalSchemas: number
  hasMoreSchemas: boolean
  fetchingMore: boolean
  fetchMoreSchemas: (options: Options) => void
}

export const useTreatmentSchemas = (options: Options = {}): Return => {
  const {
    data: { getTreatmentSchemas } = {},
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getTreatmentSchemas, getTreatmentSchemasVariables>(
    GET_TREATMENT_SCHEMAS,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )

  const selectedLanguage = useSelectedLanguage()

  const totalSchemas = getTreatmentSchemas?.total || 0
  const treatmentSchemas: TreatmentSchema[] = renameDuplicatedTreatmentTitles(
    getTreatmentSchemas?.treatmentSchemas || [],
    selectedLanguage
  )
  const hasMoreSchemas = treatmentSchemas.length < totalSchemas

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSchemas: fetchMore,
    refetch,
    treatmentSchemas,
    totalSchemas,
    hasMoreSchemas,
  }
}
