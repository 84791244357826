import { isNumber, orderBy } from 'lodash'
import { useMemo, useState, useEffect, useCallback } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { Language } from '../../../../models/graphqlTypes'
import { epochStartDate } from '../../../../utils/constants'
import { localDayjs } from '../../../../utils/localDate'
import { useSetCustomDayOfSchemaSchedule } from './useSetCustomDayOfSchemaSchedule'
import {
  CUSTOM_DAY_ERROR,
  DayConstraints,
  LocalSchedule,
  LocalSurvey,
} from '../components/treatmentSchemaSchedule.types'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useSetCustomDayOfTreatmentSchemaSurvey } from './useSetCustomDaysOfTreatmentSchemaSurvey'
import {
  TreatmentSchemaSchedule,
  TreatmentSchemaSurvey,
} from '../types/treatmentSchemas.types'

const getDayConstraintsInNumbers = (
  schedule: TreatmentSchemaSchedule | TreatmentSchemaSurvey
): DayConstraints => {
  const minDay =
    schedule.__typename === 'PlainTreatmentElement'
      ? localDayjs(schedule.date.start).diff(epochStartDate, 'day')
      : null

  const maxDay =
    schedule.__typename === 'PlainTreatmentElement' && schedule.date.end
      ? localDayjs(schedule.date.end).diff(epochStartDate, 'day')
      : null

  return {
    minDay,
    maxDay,
  }
}

const validateNewOrderedScheduleList = (
  localSchedules: Array<LocalSchedule | LocalSurvey>,
  maxDuration: number | null
): Array<LocalSchedule | LocalSurvey> => {
  let currentMaxDay = 0
  const newList = localSchedules.map((schedule) => {
    let dayError

    const dayConstraints = getDayConstraintsInNumbers(schedule)
    const { customDays } = schedule

    // Validating the custom day
    if (isNumber(customDays)) {
      // Note: the days are not in an increasing order
      if (customDays < currentMaxDay) {
        dayError = CUSTOM_DAY_ERROR.ORDER_ERROR
      }

      // Note: the custom set date is not in the constrained interval
      if (
        (dayConstraints.minDay && dayConstraints.minDay > customDays) ||
        (dayConstraints.maxDay && dayConstraints.maxDay < customDays)
      ) {
        dayError = CUSTOM_DAY_ERROR.DEPENDENCY_ERROR
      }

      if (maxDuration && customDays >= maxDuration) {
        dayError = CUSTOM_DAY_ERROR.EXCEEDS_MAX_DURATION
      }

      // Setting the maximum day if there is no error
      if (!dayError && customDays > currentMaxDay) {
        currentMaxDay = customDays
      }
    }

    const newLocalScheduleListItem = {
      ...schedule,
      dayConstraints,
      dayError,
    }
    return newLocalScheduleListItem
  })

  return newList
}

const renameDuplicatedScheduleTitles = (
  list: Array<LocalSchedule | LocalSurvey>,
  selectedLanguage: Language
): Array<LocalSchedule | LocalSurvey> => {
  return list.map((listItem, i, arr) => {
    const prevItemsWithSameTitleCount = arr.slice(0, i).filter((item) => {
      return item.__typename === 'PlainTreatmentElement'
        ? item.doctorTitle?.[selectedLanguage] ===
            (listItem as LocalSchedule).doctorTitle?.[selectedLanguage]
        : item.surveySchema?.title ===
            (listItem as LocalSurvey).surveySchema?.title
    }).length
    return prevItemsWithSameTitleCount > 0
      ? listItem.__typename === 'PlainTreatmentElement'
        ? {
            ...listItem,
            doctorTitle: {
              en: `(${prevItemsWithSameTitleCount + 1}) ${
                listItem.doctorTitle?.en
              }`,
              hu: `(${prevItemsWithSameTitleCount + 1}) ${
                listItem.doctorTitle?.hu
              }`,
              __typename: 'MultiLangText',
            },
          }
        : {
            ...listItem,
            surveySchema: {
              ...listItem.surveySchema,
              title: `(${prevItemsWithSameTitleCount + 1}) ${
                listItem.surveySchema.title
              }`,
            },
          }
      : listItem
  })
}

type ReturnType = {
  localSchedulesAndSurveys: Array<LocalSchedule | LocalSurvey>
  hasAnyScheduleError: boolean
  handleOnDragEnd: (result: DropResult) => void
  handleDayChanged: (id: string, newDay?: number) => void
  handleSurveyDayChanged: (id: string, newDay?: number) => void
}

export const useLocalTreatmentSchemaScheduleList = (
  schedules: Array<TreatmentSchemaSchedule | TreatmentSchemaSurvey>,
  maxDuration: number | null
): ReturnType => {
  const selectedLanguage = useSelectedLanguage()
  const [locallyOrderedScheduleList, setLocallyOrderedScheduleList] = useState<
    Array<LocalSchedule | LocalSurvey>
  >([])

  const hasAnyError = useMemo(
    () => locallyOrderedScheduleList.some((schedule) => !!schedule.dayError),
    [locallyOrderedScheduleList]
  )

  const [setCustomDaysOfSchedule] = useSetCustomDayOfSchemaSchedule()
  const [setCustomDayOfSurvey] = useSetCustomDayOfTreatmentSchemaSurvey()

  const onDayChangedCallback = useCallback(
    async (id: string, newDay?: number) => {
      await setCustomDaysOfSchedule({
        variables: {
          customDays: newDay,
          scheduleId: id,
        },
      })
    },
    [setCustomDaysOfSchedule]
  )

  const onSurveyDayChangedCallback = useCallback(
    async (id: string, newDay?: number) => {
      await setCustomDayOfSurvey({
        variables: {
          customDays: newDay,
          treatmentSchemaToSurveyId: id,
        },
      })
    },
    [setCustomDayOfSurvey]
  )

  useEffect(() => {
    if (
      locallyOrderedScheduleList.length &&
      locallyOrderedScheduleList[0].customDays !== 0
    ) {
      locallyOrderedScheduleList[0].__typename === 'PlainTreatmentElement'
        ? onDayChangedCallback(locallyOrderedScheduleList[0].id, 0)
        : onSurveyDayChangedCallback(locallyOrderedScheduleList[0].id, 0)
    }
  }, [
    locallyOrderedScheduleList,
    onDayChangedCallback,
    onSurveyDayChangedCallback,
  ])

  // Effect to update the local list and orders when the schedule list changes (after Add, Remove)
  useEffect(() => {
    setLocallyOrderedScheduleList((oldLocalSchedules) => {
      // Remove deleted schedules from the order, and refresh existing ones
      const newList = oldLocalSchedules.reduce(
        (acc, curr) => {
          const newVersionOfSchedule = schedules.find((s) => s.id === curr.id)
          if (newVersionOfSchedule) {
            acc.push(
              newVersionOfSchedule.__typename === 'PlainTreatmentElement'
                ? {
                    ...newVersionOfSchedule,
                    dayConstraints: { minDay: null, maxDay: null },
                  }
                : newVersionOfSchedule
            )
          }
          return acc
        },
        [] as Array<LocalSchedule | LocalSurvey>
      )

      // Add new schedules to the end of the order
      schedules.forEach((newSchedule) => {
        if (
          !oldLocalSchedules.some(
            (oldSchedule) => oldSchedule.id === newSchedule.id
          )
        ) {
          newList.push(
            newSchedule.__typename === 'PlainTreatmentElement'
              ? {
                  ...newSchedule,
                  dayConstraints: { minDay: null, maxDay: null },
                }
              : newSchedule
          )
        }
      })
      const orderedNewList = orderBy(newList, 'customDays')
      return validateNewOrderedScheduleList(orderedNewList, maxDuration)
    })
  }, [schedules, maxDuration])

  // order Changes
  const onDragEndOrderChanger = useCallback(
    (result: DropResult) => {
      const { destination, source } = result
      if (!destination) {
        return
      }

      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      ) {
        return
      }
      setLocallyOrderedScheduleList((oldList) => {
        const newList = [...oldList]
        const movedOrder = newList[source.index]

        if (movedOrder.__typename === 'TreatmentSchemaToSurvey') {
          const newDay =
            destination.index === 0
              ? 1
              : newList[destination.index - 1].customDays
          onSurveyDayChangedCallback(movedOrder.id, newDay ?? undefined)
        }

        newList.splice(source.index, 1)
        newList.splice(destination.index, 0, movedOrder)

        return validateNewOrderedScheduleList(newList, maxDuration)
      })
    },
    [maxDuration, onSurveyDayChangedCallback]
  )

  return useMemo(
    () => ({
      localSchedulesAndSurveys: renameDuplicatedScheduleTitles(
        locallyOrderedScheduleList,
        selectedLanguage
      ),
      hasAnyScheduleError: hasAnyError,
      handleOnDragEnd: onDragEndOrderChanger,
      handleDayChanged: onDayChangedCallback,
      handleSurveyDayChanged: onSurveyDayChangedCallback,
    }),
    [
      locallyOrderedScheduleList,
      selectedLanguage,
      hasAnyError,
      onDragEndOrderChanger,
      onDayChangedCallback,
      onSurveyDayChangedCallback,
    ]
  )
}
