import { Card, CardContent, CardHeader, Stack, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { ANSWER_TIME_FOR_QUESTION_IN_MIN } from '../../utils/survey.util'
import { SurveySchemaById } from '../../types/surveyStore.types'

interface Props {
  numberOfQuestionsInSurvey: number
  surveySchema: SurveySchemaById
}

export const ViewSurveyPatientDescription: React.FC<
  React.PropsWithChildren<Props>
> = ({ numberOfQuestionsInSurvey, surveySchema }) => {
  const { t } = useTranslation()

  return (
    <Card>
      <CardHeader sx={{ px: 3 }} title={t('survey:patientDescription.title')} />
      <CardContent sx={{ px: 3, pt: 0 }}>
        <Stack gap={1.5}>
          <Typography variant="italic">
            {surveySchema.patientDescription}
          </Typography>
          <Typography variant="body2">
            {t('survey:description.time', {
              time: numberOfQuestionsInSurvey * ANSWER_TIME_FOR_QUESTION_IN_MIN,
            })}
          </Typography>
          <Typography variant="italic">
            {t('survey:description.thankyou')}
          </Typography>
        </Stack>
      </CardContent>
    </Card>
  )
}
