import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { PopoverCard } from '../PopoverCard/PopoverCard'

export const MoreActionsMenu: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [actionsMenuAnchor, setActionsMenuAnchor] =
    useState<HTMLButtonElement | null>(null)

  return (
    <>
      <IconButton onClick={(e) => setActionsMenuAnchor(e.currentTarget)}>
        <MoreVertIcon fontSize="large" />
      </IconButton>

      {actionsMenuAnchor && (
        <PopoverCard
          actionsMenuAnchor={actionsMenuAnchor}
          setActionsMenuAnchor={setActionsMenuAnchor}
        >
          {children}
        </PopoverCard>
      )}
    </>
  )
}
