import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CloseIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 16}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g>
        <path
          d="M-1.75,8.753 C-2.164,8.753 -2.5,8.417 -2.5,8.003 C-2.5,7.589 -2.164,7.253 -1.75,7.253 L17.75,7.253 C18.164,7.253 18.5,7.589 18.5,8.003 C18.5,8.417 18.164,8.753 17.75,8.753 L-1.75,8.753 Z"
          transform="translate(8, 8) rotate(-45) translate(-8, -8)"
        />
        <path
          d="M-1.75,8.753 C-2.164,8.753 -2.5,8.417 -2.5,8.003 C-2.5,7.589 -2.164,7.253 -1.75,7.253 L17.75,7.253 C18.164,7.253 18.5,7.589 18.5,8.003 C18.5,8.417 18.164,8.753 17.75,8.753 L-1.75,8.753 Z"
          transform="translate(8, 8) rotate(-315) translate(-8, -8)"
        />
      </g>
    </SvgIcon>
  )
}

export { CloseIcon }
