import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay'
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar'
import { styled, Theme } from '@mui/material/styles'
import dayjs, { Dayjs } from 'dayjs'
import React, { Dispatch } from 'react'
import { BORDER_RADIUS } from '../../../../../theme'
import { IconButtonProps } from '@mui/material/IconButton'

interface CustomIconButtonProps extends IconButtonProps {
  'data-cy'?: string
}

// eslint-disable-next-line
const CustomPickersDay = styled<any>(PickersDay)<PickersDayProps<Dayjs>>(
  ({ theme }) => ({
    fontSize: '1.4rem',
    borderRadius: BORDER_RADIUS,
    margin: '0 2px',
    '&.Mui-selected': {
      color: (theme as Theme).palette.background.default,
    },
    '&.Mui-disabled': {
      color: (theme as Theme).palette.background.default,
    },
    '&.MuiPickersDay-today:not(.Mui-selected)': {
      border: 'none',
      color: (theme as Theme).palette.primary.main,
    },
  })
)

interface CalendarMonthProps {
  minDate?: Date | string
  selectedDate: Date | string
  handleDateChange: Dispatch<Date>
}

const CalendarMonth: React.FC<React.PropsWithChildren<CalendarMonthProps>> = ({
  minDate = '2018-01-01',
  selectedDate,
  handleDateChange,
}) => {
  const handleChange = (newDate: Dayjs | null) => {
    if (!newDate) {
      return
    }
    handleDateChange(newDate.toDate())
  }

  return (
    <DateCalendar<Dayjs>
      openTo="day"
      views={['day']}
      minDate={dayjs(minDate)}
      value={dayjs(selectedDate)}
      // here we can disable the days that are not operating (weekends)
      shouldDisableDate={() => false}
      onChange={handleChange}
      slots={{
        day: CustomPickersDay,
      }}
      className="CalendarMonth-Container"
      slotProps={{
        previousIconButton: {
          'data-cy': 'CalendarMonth-LeftArrowButton',
        } as CustomIconButtonProps,
        nextIconButton: {
          'data-cy': 'CalendarMonth-RightArrowButton',
        } as CustomIconButtonProps,
      }}
    />
  )
}

export { CalendarMonth }
