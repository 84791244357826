import { Box, Hidden } from '@mui/material'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { MonthView } from '../MonthView'
import { NoFilterResultContentComponent } from './NoFilterResultContent'
import {
  TreatmentAppointmentItem,
  TreatmentAppointmentItemComponentViewModel as TreatmentItemComponentViewModel,
} from '../Appointment/TreatmentAppointmentItem'
import {
  TreatmentSurveyItem,
  TreatmentSurveyItemComponentViewModel,
} from '../Survey/TreatmentSurveyItem'
import {
  AppointmentsOrSurveys,
  GroupedAppointmentsAndSurveys,
} from '../../utils/appointment.util'
import { ArrowProps } from './Arrow'
import { Appointment, Survey } from '../../types/treatments.types'

export interface TreatmentGroupedAppointmentsComponentViewModel {
  treatmentItemViewModel: Omit<
    TreatmentItemComponentViewModel,
    | 'order'
    | 'appointment'
    | 'hasPast'
    | 'showPast'
    | 'isHoveredInCalendar'
    | 'setHoveredDay'
    | 'disableLinking'
    | 'canLinkTo'
    | 'onClick'
  >
  filteredAppointment: Appointment[]
  groupedAppointmentsAndSurveys: GroupedAppointmentsAndSurveys
  filterValue: string
  showPast: boolean
  isAllOrderedAppointmentsPast: boolean | number
  visibleAppointments: Appointment[]
  visibleSurveys: Survey[]
  widthOfArrows: number
  arrowProps: ArrowProps[]
  linkingDisabledIds: string[]
  onClickAppointment: (appointmentId: string) => void
  onClickSurvey: (survey: Survey) => void
  editSurvey: (survey: Survey) => void
}

interface Props {
  vm: TreatmentGroupedAppointmentsComponentViewModel
}

export const TreatmentGroupedAppointmentsComponent: React.FC<
  React.PropsWithChildren<Props>
> = ({ vm }) => {
  const {
    groupedAppointmentsAndSurveys,
    filterValue,
    showPast,
    isAllOrderedAppointmentsPast,
    visibleAppointments,
    visibleSurveys,
    widthOfArrows,
    filteredAppointment,
    treatmentItemViewModel,
    linkingDisabledIds,
    onClickAppointment,
    onClickSurvey,
    editSurvey,
  } = vm
  const [hoveredDay, setHoveredDay] = useState<string | null>(null)

  const getIsHoveredInCalendar = (date: Date): boolean => {
    const dateString = dayjs(date).format('[calendar]:YYYY-MM-DD')
    return dateString === hoveredDay
  }

  const renderTreatmentAppointmentItem = (appointment: Appointment) => {
    const treatmentAppointmentVm: TreatmentItemComponentViewModel = {
      ...treatmentItemViewModel,
      order: filteredAppointment.indexOf(appointment),
      appointment,
      hasPast: groupedAppointmentsAndSurveys.hasPast,
      showPast,
      isHoveredInCalendar: getIsHoveredInCalendar(appointment.proposedDate),
      disableLinking: linkingDisabledIds.includes(appointment.id),
      canLinkTo: !!(
        treatmentItemViewModel.linking &&
        treatmentItemViewModel.linking.id !== appointment.id
      ),
      onClick: onClickAppointment,
      setHoveredDay,
    }
    return (
      <TreatmentAppointmentItem
        key={appointment.id}
        vm={treatmentAppointmentVm}
      />
    )
  }

  const renderTreatmentSurveyItem = (survey: Survey) => {
    const treatmentSurveyItemComponentViewModel: TreatmentSurveyItemComponentViewModel =
      {
        firstRef: treatmentItemViewModel.firstRef,
        hasPast: groupedAppointmentsAndSurveys.hasPast,
        isHoveredInCalendar: getIsHoveredInCalendar(survey.fillableFrom),
        showPast,
        survey,
        nextSurvey: treatmentItemViewModel.nextSurvey,
        nextAppointment: treatmentItemViewModel.nextAppointment,
        firstFutureSurvey: treatmentItemViewModel.firstFutureSurvey,
        firstFutureAppointment: treatmentItemViewModel.firstFutureAppointment,
        isOwnTreatment: treatmentItemViewModel.isOwnTreatment,
        isAssistantOfTreatment: treatmentItemViewModel.isAssistantOfTreatment,
        isDraft: treatmentItemViewModel.isDraft,
        onClick: onClickSurvey,
        onHover: treatmentItemViewModel.onHover,
        setHoveredDay,
        setShowPast: treatmentItemViewModel.setShowPast,
        editSurvey: editSurvey,
      }
    return (
      <TreatmentSurveyItem
        key={survey.id}
        vm={treatmentSurveyItemComponentViewModel}
      />
    )
  }

  const getSortedAppointmentsAndSurveys = (
    groupedItem: AppointmentsOrSurveys
  ) =>
    [...groupedItem.appointments, ...groupedItem.surveys].sort((a, b) => {
      const dateA =
        a.__typename === 'Appointment' ? a.proposedDate : a.fillableFrom
      const dateB =
        b.__typename === 'Appointment' ? b.proposedDate : b.fillableFrom

      return new Date(dateA).getTime() - new Date(dateB).getTime()
    })

  return (
    <>
      {Object.entries(groupedAppointmentsAndSurveys.groups).map(
        ([groupKey, groupedItem]) => {
          const isAllAppointmentsPast = !groupedItem.appointments.find(
            (appointment) =>
              dayjs().startOf('day') <= dayjs(appointment.proposedDate)
          )
          const isAllSurveysPast = !groupedItem.surveys.find(
            (survey) => dayjs().startOf('day') <= dayjs(survey.fillableFrom)
          )

          if (isAllAppointmentsPast && isAllSurveysPast && !showPast) {
            return filterValue && isAllOrderedAppointmentsPast ? (
              <NoFilterResultContentComponent filterValue={filterValue} />
            ) : (
              <Box key={groupKey} />
            )
          }

          const thisMonth =
            groupedItem.appointments[0]?.proposedDate ??
            groupedItem.surveys[0]?.fillableFrom
          const hoveredDayObj = dayjs(hoveredDay as string)
          const isHoveredInThisMonth =
            hoveredDayObj.isAfter(dayjs(thisMonth).startOf('month')) &&
            hoveredDayObj.isBefore(dayjs(thisMonth).endOf('month'))

          return (
            <Box key={groupKey} display="flex">
              <Hidden mdDown>
                <Box width={260} flexShrink={0}>
                  <MonthView
                    hoveredDay={isHoveredInThisMonth ? hoveredDay : null}
                    setHoveredDay={setHoveredDay}
                    month={thisMonth}
                    visibleAppointments={visibleAppointments}
                    visibleSurveys={visibleSurveys}
                  />
                </Box>
              </Hidden>
              <Box flexGrow={1} mt={{ md: 1.75 }} mr={`${widthOfArrows}px`}>
                {getSortedAppointmentsAndSurveys(groupedItem).map(
                  (appointmentOrSurvey) =>
                    appointmentOrSurvey.__typename === 'Appointment'
                      ? renderTreatmentAppointmentItem(appointmentOrSurvey)
                      : renderTreatmentSurveyItem(appointmentOrSurvey)
                )}
              </Box>
            </Box>
          )
        }
      )}
    </>
  )
}
