import { Typography, Tooltip, TypographyProps } from '@mui/material'
import React, { useEffect, useState } from 'react'

const isOverflowing = (el: HTMLElement | null): boolean => {
  if (!el) {
    return false
  }
  return el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
}

interface Props extends TypographyProps {
  text: string
  parentId: string
}

export const MultilineTextWithTooltip: React.FC<
  React.PropsWithChildren<Props>
> = ({ text, parentId, ...typoProps }) => {
  const [disabled, setDisabled] = useState<boolean>(false)

  useEffect(() => {
    const parent = document.getElementById(parentId)
    if (!isOverflowing(parent)) {
      setDisabled(true)
    }
  }, [parentId])

  return (
    <Tooltip title={text} placement="top" disableHoverListener={disabled}>
      <Typography sx={{ cursor: 'default' }} {...typoProps}>
        {text}
      </Typography>
    </Tooltip>
  )
}
