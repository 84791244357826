import {
  Box,
  Typography,
  TextField,
  TextFieldProps,
  Collapse,
  Button,
  Stack,
  Card,
  CardContent,
} from '@mui/material'
import React, { FC, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Language, MultiLangTextInput } from '../../../../models/graphqlTypes'

type MultiLangCollapseInputFields = {
  en: string
  hu: string
}

type MultiLangCollapseProps = {
  title: string
  name: string
  onSave: (newVaule: MultiLangTextInput) => void
  initValue?: MultiLangTextInput | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors?: any
  required?: boolean
} & TextFieldProps

export const MultiLangCollapse: FC<
  React.PropsWithChildren<MultiLangCollapseProps>
> = ({
  title,
  name,
  onSave,
  errors: fromParent,
  initValue,
  required = false,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    clearErrors,
  } = useForm<MultiLangCollapseInputFields>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: initValue || {
      hu: '',
      en: '',
    },
  })

  const formErrors = fromParent ? fromParent[name] : null
  const { t } = useTranslation()

  const handleClose = useCallback(() => {
    reset(
      initValue || {
        hu: '',
        en: '',
      }
    )
    clearErrors()
    setIsOpen(false)
  }, [reset, clearErrors, initValue])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const sortedLanguages = Object.keys(Language).sort((language) =>
    language === 'hu' ? -1 : 0
  )

  const currentLanguage = 'hu'

  const onSubmit = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (data: any) => {
      setIsOpen(false)
      onSave(data)
    },
    [onSave]
  )

  return (
    <div>
      <Box my={1} width="100%" data-cy={`MultiLangCollapse-${name}`}>
        <Typography variant="subtitle2">{title}</Typography>
        <TextField
          {...props}
          name={`${name}.${currentLanguage}`}
          error={!!formErrors}
          helperText={formErrors ? formErrors.message : ''}
          InputProps={{
            readOnly: true,
          }}
          onClick={handleOpen}
          onFocus={handleOpen}
          variant="outlined"
          value={initValue?.[currentLanguage]}
          fullWidth
          multiline={props?.multiline}
          maxRows={props?.maxRows}
          minRows={props?.minRows}
        />
        <Collapse in={isOpen}>
          <Card>
            <CardContent>
              <Box>
                {sortedLanguages.map((lang) => {
                  const fieldError = errors
                    ? errors[lang as keyof MultiLangCollapseInputFields]
                    : null
                  return (
                    <Box my={1} key={lang}>
                      <Typography variant="subtitle2">
                        {t(`common:languages.${lang}`)}
                      </Typography>
                      <TextField
                        {...register(
                          lang as keyof MultiLangCollapseInputFields,
                          {
                            required: {
                              value: required,
                              message: t('messages:warnings.required'),
                            },
                          }
                        )}
                        error={!!fieldError}
                        helperText={fieldError ? fieldError.message : ''}
                        fullWidth
                        defaultValue={
                          initValue?.[lang as keyof MultiLangTextInput]
                        }
                      />
                    </Box>
                  )
                })}
                <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
                  <Button variant="outlined" onClick={handleClose}>
                    {t('common:cancel')}
                  </Button>
                  <Button
                    data-cy="MultiLangCollapse-Button-save"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('common:save')}
                  </Button>
                </Stack>
              </Box>
            </CardContent>
          </Card>
        </Collapse>
      </Box>
    </div>
  )
}
