import { TFunction } from 'react-i18next'

export const getProfessionString = (
  professions: { id: string; name: string }[],
  t: TFunction
): string => {
  return professions
    ? professions
        .map((profession) => t(`professions:${profession.name}`))
        .join(', ')
    : t('common:emptyField')
}
