import React, { useState } from 'react'
import { Link as RouterLink, useHistory } from 'react-router-dom'
import {
  Box,
  Hidden,
  Typography,
  Link,
  Divider,
  IconButton,
  Collapse,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Chat as ChatIcon, Info as InfoIcon } from '@mui/icons-material'

import { Avatar } from '../../../../common/components/Avatar'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'
import { DoctorPatientProfile } from '../types/doctorPatients.types'

type Props = {
  patient: DoctorPatientProfile
}
type InfoRecord = {
  label?: string
  value?: number | string | null
}

const useStyles = makeStyles((theme) => ({
  patientName: {
    [theme.breakpoints.down('md')]: {
      fontSize: '1.4rem',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.2rem',
    },
  },
}))

const InfoCol: React.FC<React.PropsWithChildren<InfoRecord>> = ({
  label,
  value,
}) => (
  <Box
    display="flex"
    flexDirection="row"
    alignItems="center"
    mr={{ sm: 1.5, md: 2 }}
  >
    {label && (
      <Box mr={1} display="inline">
        <Typography variant="subtitle1" color="textSecondary">
          {label}:
        </Typography>
      </Box>
    )}
    <Typography variant="subtitle1" color="textSecondary">
      {value}
    </Typography>
  </Box>
)

const PatientHeadlineInfo: React.FC<React.PropsWithChildren<Props>> = ({
  patient,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()
  const userType = useUserType()

  const [showDetails, setShowDetails] = useState(false)

  const patientDetails = (
    <>
      <InfoCol label={t('common:taj')} value={patient?.tajNumber} />
      <InfoCol
        label={t('common:birthdate')}
        value={
          t('common:intlDateFormattedLongMonth', {
            date: patient?.birthDate,
          }) as string
        }
      />
      <InfoCol value={patient?.user?.email} />
      <InfoCol value={patient?.phone} />
    </>
  )

  const sendMessageButton = (
    <IconButton
      onClick={() => history.push(`/messages/${patient.user?.id}`)}
      color="primary"
      size="large"
    >
      <ChatIcon fontSize="large" />
    </IconButton>
  )

  return (
    <>
      <Box
        width="100vw"
        py={1}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="flex-start"
          flexShrink={0}
          alignItems="center"
          flexWrap="wrap"
        >
          <Hidden smDown>
            <Box mr={{ sm: 0, md: 1 }} display="flex">
              <Avatar {...patient} size="tiny" />
            </Box>
          </Hidden>

          <Typography className={classes.patientName} variant="h6">
            {t('common:formattedNameFull', patient)}
          </Typography>

          <Hidden lgUp>
            <Box ml={{ sm: 1 }}>
              <IconButton
                onClick={() => setShowDetails((prev) => !prev)}
                color="primary"
                size="large"
              >
                <InfoIcon fontSize="large" />
              </IconButton>
            </Box>
          </Hidden>

          <Hidden smDown>
            <Box mx={{ lg: 1 }}>{sendMessageButton}</Box>
          </Hidden>

          <Hidden lgDown>{patientDetails}</Hidden>
        </Box>

        <Link
          underline="none"
          component={RouterLink}
          to={`/${userType}/patients`}
        >
          {t('treatment:backToPatiens')}
        </Link>
      </Box>

      <Divider />

      <Box flexShrink={0}>
        <Hidden lgUp smDown>
          <Collapse in={showDetails}>
            <Box flexDirection="row" display="flex" my={1} mx={2}>
              {patientDetails}
            </Box>
            <Divider />
          </Collapse>
        </Hidden>
        <Hidden smUp>
          <Collapse in={showDetails}>
            <Box
              display="flex"
              p={2}
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>{patientDetails}</Box>
              {sendMessageButton}
            </Box>
            <Divider />
          </Collapse>
        </Hidden>
      </Box>
    </>
  )
}

export { PatientHeadlineInfo }
