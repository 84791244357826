import { Box, Divider } from '@mui/material'
import React, { useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePermissions } from '../../../../../common/hooks/usePermissions'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { TreatmentStatus } from '../../../../../models/graphqlTypes'
import { AddAppointmentModal } from '../Appointment/AddAppointmentModal'
import { AddProtocolModal } from './AddProtocolModal'
import { AppointmentDependencyModal } from '../Appointment/AppointmentDependencyModal'
import { AppointmentEmptyState } from '../Appointment/AppointmentEmptyState'
import { AppointmentModal } from '../Appointment/AppointmentModal'
import { AddSurveyModal, AddSurveyModalParams } from '../Survey/AddSurveyModal'
import { NoFilterResultContentComponent } from './NoFilterResultContent'
import { TreatmentLog } from './TreatmentLog'
import { TreatmentPanelHeader } from './TreatmentPanelHeader'
import { TreatmentPanelSubHeader } from './TreatmentPanelSubHeader'
import { TreatmentSurveyPreview } from '../Survey/TreatmentSurveyPreview'
import { AppointmentsWithArrows } from './AppointmentsWithArrows'
import {
  AddAppointmentModalParams,
  RouteParams,
  TreatmentPanelTab,
} from '../../types/treatmentPanel.types'
import { useSurveyOperations } from './hooks/useSurveyOperations'
import { AppointmentDeletedState } from '../Appointment/AppointmentDeletedState'
import { useAppointmentOperations } from './hooks/useAppointmentOperations'
import { useTreatmentGroupedAppointmentsComponentViewModel } from './hooks/useTreatmentGroupedAppointmentsComponentViewModel'
import { Appointment, Treatment } from '../../types/treatments.types'

interface Props {
  treatment: Treatment
  refetchTreatment: () => void
}

export const TreatmentPanel: React.FC<React.PropsWithChildren<Props>> = ({
  treatment,
  refetchTreatment,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { patientId, treatmentId, appointmentId, action } =
    useParams<RouteParams>()
  const userType = useUserType()
  const { isOwnTreatment, isAssistantOfTreatment } = usePermissions(
    treatment?.doctor.id
  )

  const firstRef = useRef<HTMLElement>(null)

  const [isAddProtocolModal, toggleAddProtocolModal] = useState(false)
  const [addSurveyModal, toggleAddSurveyModal] = useState<AddSurveyModalParams>(
    { isOpen: false }
  )
  const [showArrows, toggleShowArrows] = useState<boolean>(false)
  const [activeTabIndex, setActiveTabIndex] = useState<number>(
    TreatmentPanelTab.Events
  )
  const [addAppointmentModalOpen, toggleAddAppointmentModal] =
    useState<AddAppointmentModalParams>({ isOpen: false })
  const [showPast, setShowPast] = useState(false)
  const [filterValue, setFilterValue] = useState<string>('')
  const [linkingDisabledIds, setLinkingDisabledIds] = useState<string[]>([])
  const [linkingAppointments, setLinkingAppointments] = useState<Appointment[]>(
    []
  )
  const [hoveredAppointmentId, setHoveredAppointmentId] = useState<
    string | null
  >(null)

  const isEditRoute: boolean = action === 'edit'
  const showLinkingModal = linkingAppointments.length === 2

  const appointments = useMemo(() => treatment.appointments || [], [treatment])
  const isDeleted = treatment?.status === TreatmentStatus.Cancelled
  const treatmentLog = treatment?.treatmentLogs || []

  const currentAppointment = appointments.find(
    (appointment) => appointment.id === appointmentId
  )

  const clearFilterValue = () => {
    const searchInput = document.getElementById(
      'treatmentSearchInput'
    ) as HTMLInputElement
    if (searchInput) {
      searchInput.value = ''
    }
    setFilterValue('')
  }

  const treatmentGroupedAppointmentsViewModel =
    useTreatmentGroupedAppointmentsComponentViewModel({
      treatment,
      filterValue,
      showPast,
      setShowPast,
      hoveredAppointmentId,
      setHoveredAppointmentId,
      linkingDisabledIds,
      setLinkingDisabledIds,
      linkingAppointments,
      setLinkingAppointments,
      firstRef,
      clearFilterValue,
      toggleAddAppointmentModal,
      toggleAddSurveyModal,
    })

  const { editAppointment, cloneAppointment } = useAppointmentOperations({
    clearFilterValue,
    toggleAddAppointmentModal,
  })

  const { survey, setSurvey, onAddSurveyModalClose, onAddSaveSurveyModalSave } =
    useSurveyOperations({
      treatmentId,
      patientUserId: treatment.patient.user?.id ?? '',
      refetchTreatment,
      toggleAddSurveyModal,
      clearFilterValue,
    })

  const showAppointmentEmptyState = useMemo(
    () => !isDeleted && !appointments.length && isOwnTreatment,
    [isDeleted, appointments, isOwnTreatment]
  )

  const isNoFilteredResultContent = useMemo(
    () =>
      !!appointments.length &&
      treatmentGroupedAppointmentsViewModel.filteredAppointment.length < 1,
    [
      appointments.length,
      treatmentGroupedAppointmentsViewModel.filteredAppointment.length,
    ]
  )

  return (
    <Box height="100%" overflow="auto" display="flex">
      <Box width="100%">
        {treatment && (
          <Box height="100%" width="100%" display="flex" flexDirection="column">
            <TreatmentPanelHeader
              treatment={treatment}
              activeTabIndex={activeTabIndex}
              setActiveTabIndex={setActiveTabIndex}
            />

            <Divider />

            {activeTabIndex === TreatmentPanelTab.TreatmentLog && (
              <TreatmentLog treatmentLogs={treatmentLog} />
            )}

            {activeTabIndex === TreatmentPanelTab.Events && (
              <>
                {!!appointments.length && (
                  <TreatmentPanelSubHeader
                    treatment={treatment}
                    isOwnTreatment={isOwnTreatment}
                    showArrows={showArrows}
                    toggleArrowsVisibility={() => toggleShowArrows(!showArrows)}
                    toggleAddSurveyModal={toggleAddSurveyModal}
                    setFilterValue={setFilterValue}
                    toggleAddAppointmentModal={toggleAddAppointmentModal}
                    toggleAddProtocolModal={toggleAddProtocolModal}
                  />
                )}

                {isDeleted && <AppointmentDeletedState treatment={treatment} />}

                <>
                  {showAppointmentEmptyState && (
                    <AppointmentEmptyState
                      toggleAddAppointment={() =>
                        toggleAddAppointmentModal({ isOpen: true })
                      }
                      toggleAddProtocol={() => toggleAddProtocolModal(true)}
                      toggleAddSurvey={() =>
                        toggleAddSurveyModal({ isOpen: true })
                      }
                    />
                  )}

                  {isNoFilteredResultContent ? (
                    <NoFilterResultContentComponent filterValue={filterValue} />
                  ) : (
                    <AppointmentsWithArrows
                      treatmentGroupedAppointmentsViewModel={
                        treatmentGroupedAppointmentsViewModel
                      }
                      showArrows={showArrows}
                    />
                  )}
                </>
              </>
            )}
          </Box>
        )}
      </Box>
      {treatment && (
        <>
          {!!appointmentId && (
            <AppointmentModal
              appointment={currentAppointment}
              isOpen={!!appointmentId}
              editAppointment={editAppointment}
              cloneAppointment={cloneAppointment}
              onClose={() =>
                history.push(
                  `/${userType}/patients/${patientId}/${treatmentId}`
                )
              }
              isOwnTreatment={isOwnTreatment}
              isAssistantOfTreatment={isAssistantOfTreatment}
              treatment={treatment}
            />
          )}

          {addAppointmentModalOpen.isOpen && (
            <AddAppointmentModal
              appointment={addAppointmentModalOpen.appointment}
              treatment={treatment}
              isOpen={addAppointmentModalOpen.isOpen}
              doctor={addAppointmentModalOpen.doctor}
              appointmentType={addAppointmentModalOpen.appointmentType}
              initProposedDate={addAppointmentModalOpen.proposedDate}
              onDone={() => {
                clearFilterValue()
                refetchTreatment()
              }}
              onClose={() => toggleAddAppointmentModal({ isOpen: false })}
            />
          )}

          {isEditRoute && (
            <AddAppointmentModal
              appointment={currentAppointment}
              treatment={treatment}
              isOpen={isEditRoute}
              onDone={() => {
                clearFilterValue()
                refetchTreatment()
              }}
              onClose={() => {
                history.push(
                  `/${userType}/patients/${patientId}/${treatmentId}/${appointmentId}`
                )
                toggleAddAppointmentModal({ isOpen: false })
              }}
            />
          )}

          {showLinkingModal && (
            <AppointmentDependencyModal
              isOpen={showLinkingModal}
              onClose={() => {
                setLinkingAppointments([])
                setLinkingDisabledIds([])
              }}
              onDone={() => {
                setLinkingAppointments([])
                setLinkingDisabledIds([])
              }}
              isOwnTreatment={isOwnTreatment}
              treatment={treatment}
              linking={linkingAppointments}
            />
          )}

          {isAddProtocolModal && (
            <AddProtocolModal
              isOpen={isAddProtocolModal}
              onDone={() => {
                clearFilterValue()
                refetchTreatment()
              }}
              onClose={() => toggleAddProtocolModal(false)}
              treatmentId={treatmentId}
              treatmentBnoCodes={treatment.bnoCodes}
            />
          )}

          {addSurveyModal.isOpen && treatment.patient.user && (
            <AddSurveyModal
              params={addSurveyModal}
              onSave={onAddSaveSurveyModalSave}
              onClose={onAddSurveyModalClose}
              title={
                !!addSurveyModal.survey
                  ? t('treatment:doctor.modifySurveyDialogTitle')
                  : t('treatment:doctor.assignSurveyDialogTitle')
              }
              submitButtonText={t('common:save')}
            />
          )}

          {!!survey?.surveySchema.id && (
            <TreatmentSurveyPreview
              isOpen={!!survey}
              surveySchemaId={survey?.surveySchema.id}
              surveyId={survey.id}
              treatmentId={treatment.id}
              close={() => setSurvey(null)}
            />
          )}
        </>
      )}
    </Box>
  )
}
