import { useMutation } from '@apollo/client'
import {
  createNewTreatmentSchema,
  createNewTreatmentSchemaVariables,
} from '../../../../models/graphqlTypes'
import { CREATE_NEW_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useHistory } from 'react-router-dom'
import { useUserType } from '../../../../common/hooks/useUserType'

type Options = MutationHookOptions<
  createNewTreatmentSchema,
  createNewTreatmentSchemaVariables
>

type Return = MutationTuple<
  createNewTreatmentSchema,
  createNewTreatmentSchemaVariables
>

export const useTreatmentSchemaCreate = (options: Options = {}): Return => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()

  return useMutation<
    createNewTreatmentSchema,
    createNewTreatmentSchemaVariables
  >(CREATE_NEW_TREATMENT_SCHEMA, {
    variables: {
      schema: {
        title: {
          en: t('treatment:protocol.newTitle', { lng: 'en' }),
          hu: t('treatment:protocol.newTitle', { lng: 'hu' }),
        },
        description: {
          en: t('treatment:protocol.noDescription', { lng: 'en' }),
          hu: t('treatment:protocol.noDescription', { lng: 'hu' }),
        },
      },
    },
    onCompleted: ({ createNewTreatmentSchema: emptySchema }) => {
      history.push(
        `/${userType?.toLowerCase()}/treatment-option/${emptySchema.id}`
      )
    },
    ...options,
  })
}
