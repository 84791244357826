import { gql } from '@apollo/client'
import React, { useState } from 'react'
import { useTranslation } from '../../common/hooks/helper/useTranslation'

import { ScrollablePanel } from '../../common/components/ScrollablePanel'
import { PasswordReset } from '../../common/components/PasswordReset'
import { useForm } from 'react-hook-form'
import { Box, Grid, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { BoxWithLabel } from '../../common/components/BoxWithLabel'
import { InputStatusAdornment } from '../../common/components/InputStatusAdornment'
import { useUserProfile } from '../../common/hooks/useUserProfile'
import { ValidPhonePattern } from '../../utils/isValidPhone'
import { SaveButton } from '../../common/components/SaveButton'
import { useAssistantProfileUpdate } from './hooks/useAssistantProfileUpdate'
import { FieldNamesMarkedBoolean } from 'react-hook-form/dist/types/form'

const useStyles = makeStyles(() => ({
  headerContainer: {
    width: 'auto',
  },
}))

type FormValues = {
  title: string
  phone: string
}

const AssistantProfile: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const profile = useUserProfile()
  const profileId = profile?.id
  const [savedFields, setSavedFields] = useState<
    FieldNamesMarkedBoolean<FormValues>
  >({})

  const { handleSubmit, register, reset, formState } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      title: profile?.title ?? '',
      phone: profile?.phone ?? '',
    },
  })
  const { dirtyFields, isSubmitting, isValid, isDirty, errors } = formState

  const [updateAssistantProfile] = useAssistantProfileUpdate({
    onCompleted: (data) => {
      reset({
        title: data.updateAssistantProfile.title ?? '',
        phone: data.updateAssistantProfile.phone ?? '',
      })
    },
    update: (cache, { data }) => {
      if (!data?.updateAssistantProfile) {
        return
      }
      const { updateAssistantProfile } = data
      cache.writeFragment<{ title: string | null; phone: string | null }>({
        id: cache.identify({
          __typename: 'AssistantProfile',
          id: updateAssistantProfile.id,
        }),
        fragment: gql`
          fragment AssistantProfileUpdateChore on AssistantProfile {
            title
            phone
          }
        `,
        data: {
          ...updateAssistantProfile,
          phone: updateAssistantProfile.phone ?? '',
        },
      })
    },
  })
  const onSubmit = handleSubmit(async (formValues) => {
    if (!profileId) {
      return
    }
    await updateAssistantProfile({
      variables: {
        updateAssistantInput: {
          id: profileId,
          title: formValues.title,
          phone: formValues.phone,
        },
      },
    })
    setSavedFields(dirtyFields)
  })

  return (
    <ScrollablePanel
      containerComponent="form"
      onSubmit={onSubmit}
      title={t('patients:personalData')}
      headerRightContent={
        <Grid container spacing={1} className={classes.headerContainer}>
          <Grid item>
            <SaveButton
              type="submit"
              isSaving={isSubmitting}
              disabled={!isValid || !isDirty}
            />
          </Grid>
          <Grid item>
            <PasswordReset />
          </Grid>
        </Grid>
      }
    >
      <Box width={400} maxWidth="100%">
        <BoxWithLabel label={t(`profile:doctor.title`)}>
          <TextField
            {...register('title')}
            size="small"
            fullWidth
            InputProps={{
              endAdornment: (
                <InputStatusAdornment
                  modified={dirtyFields?.title}
                  saved={savedFields?.title}
                />
              ),
            }}
          />
        </BoxWithLabel>

        <BoxWithLabel label={t(`profile:doctor.phone`)}>
          <TextField
            {...register('phone', {
              pattern: {
                value: ValidPhonePattern,
                message: 'messages:warnings.notPhone',
              },
            })}
            size="small"
            fullWidth
            placeholder="+36 1 234 5678 #4433"
            error={!!errors.phone}
            helperText={
              errors.phone?.message ? t(errors.phone?.message) : undefined
            }
            InputProps={{
              endAdornment: (
                <InputStatusAdornment
                  error={!!errors.phone}
                  modified={dirtyFields?.phone}
                  saved={savedFields?.phone}
                  onErrorClick={() => {
                    reset()
                  }}
                />
              ),
            }}
          />
        </BoxWithLabel>
      </Box>
    </ScrollablePanel>
  )
}

export { AssistantProfile }
