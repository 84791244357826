import React from 'react'
import { Box, Typography } from '@mui/material'

type EmptyStateProps = {
  icon?: React.ReactNode
  title: string
  description?: string
  warn?: string
  children?: React.ReactNode
}
const EmptyState: React.FC<React.PropsWithChildren<EmptyStateProps>> = ({
  icon,
  title,
  description,
  warn,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      py={1}
      textAlign="center"
    >
      {!!icon && <Box mb={4}>{icon}</Box>}
      <Typography variant="h4">{title}</Typography>
      <Box pt={2} maxWidth={460}>
        <Typography variant="body1">{description}</Typography>
      </Box>
      <Box pt={2} maxWidth={460}>
        <Typography fontWeight={700} variant="body1">
          {warn}
        </Typography>
      </Box>
      {children}
    </Box>
  )
}

export { EmptyState }
