import { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { apolloClient } from '../../../ApolloProvider'

type Return = () => void

export const useLogout = (): Return => {
  const history = useHistory()

  return useCallback(() => {
    localStorage.removeItem('token')
    history.push('/login')
    return apolloClient.clearStore()
  }, [history])
}
