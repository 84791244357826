import React, { JSX, useState } from 'react'
import { useMutation } from '@apollo/client'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'

import {
  deleteAppointment as deleteAppointmentData,
  deleteAppointmentVariables,
} from '../../../../models/graphqlTypes'
import { DELETE_APPOINTMENT } from '../../../../operations/appointmentOperations'
import { ConfirmDialog } from '../../../../common/components/dialogs/ConfirmDialog'
import { ConfirmDialogValue } from '../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useStoreActions } from '../../../../store/store.hooks'

type Props = {
  appointmentId?: string
  onDeleteComplete: () => void
}

type Return = {
  confirmDeleteModal: JSX.Element
  openDeleteModal: () => void
  isDeletingAppointment: boolean
  isDeletingAppointmentErrored: boolean
}

export const useAppointmentDeleteModal = ({
  appointmentId,
  onDeleteComplete,
}: Props): Return => {
  const setToast = useStoreActions((actions) => actions.toast.setToast)
  const { t } = useTranslation()

  const [deleteModalOpen, setDeleteModalOpen] =
    useState<ConfirmDialogValue>(false)
  const openDeleteModal = () => {
    setDeleteModalOpen(true)
  }

  const closeModal = () => {
    setDeleteModalOpen(false)
  }

  const [deleteAppointment, { loading: isDeletingAppointment, error }] =
    useMutation<deleteAppointmentData, deleteAppointmentVariables>(
      DELETE_APPOINTMENT,
      {
        refetchQueries: ['getTreatment', 'getTreatmentLogs'],
        onCompleted: (data) => {
          const deletedFromEeszt = !!data.deleteAppointment?.eesztBookingId
          const successMessage = deletedFromEeszt
            ? t('appointment:deleteSuccessEeszt')
            : t('appointment:deleteSuccess')
          setToast({
            text: successMessage,
            type: 'success',
          })
          onDeleteComplete()
        },
      }
    )

  const handleDelete = () => {
    if (!appointmentId) {
      return
    }
    deleteAppointment({ variables: { appointmentId } })
    closeModal()
  }

  const confirmDeleteModal = (
    <ConfirmDialog
      isAlertingDialog
      valueState={[deleteModalOpen, setDeleteModalOpen]}
      title={t(`treatment:doctor.deleteDialogTitle`)}
      text={t(`treatment:doctor.deleteDialogText`)}
      dontClose={true}
      onAccept={handleDelete}
      onCancel={closeModal}
    />
  )
  return {
    openDeleteModal,
    confirmDeleteModal,
    isDeletingAppointment,
    isDeletingAppointmentErrored: !!error,
  }
}
