import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  uploadFile,
  uploadFileVariables,
} from '../../../../models/graphqlTypes'
import { UPLOAD_FILE } from '../operations/patientSurveyOperations'

type Options = MutationHookOptions<uploadFile, uploadFileVariables>

type Return = MutationTuple<uploadFile, uploadFileVariables>

export const usePatientFileUpload = (options: Options = {}): Return => {
  return useMutation<uploadFile, uploadFileVariables>(UPLOAD_FILE, options)
}
