import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import { updatePoc, updatePocVariables } from '../../../../models/graphqlTypes'
import { UPDATE_POC } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<updatePoc, updatePocVariables>

type Return = MutationTuple<updatePoc, updatePocVariables>

export const useUpdatePoc = (options: Options = {}): Return => {
  return useMutation<updatePoc, updatePocVariables>(UPDATE_POC, options)
}
