import { Box } from '@mui/material'
import React, { useEffect } from 'react'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { Loading } from '../../../../../common/components/Loading'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useAggregatedSurveyResults } from '../../hooks/useAggregatedSurveyResults'
import { AggregatedSurveyResult } from './AggregatedSurveyResult'
import { SurveySchemaById } from '../../types/surveyStore.types'
import { useFormContext } from 'react-hook-form'

interface Props {
  surveySchema: SurveySchemaById
}

export const GetAggregatedSurveyResult: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveySchema }) => {
  const { t } = useTranslation()
  const { watch } = useFormContext()

  const { loading, aggregatedSurveyResults, refetch } =
    useAggregatedSurveyResults({
      variables: {
        surveySchemaId: surveySchema.id,
        dateIntervalInput: {
          startDate: watch('startDate'),
          endDate: watch('endDate'),
        },
      },
    })

  useEffect(() => {
    refetch()
  }, [refetch])

  return (
    <>
      {loading && <Loading />}
      {!aggregatedSurveyResults && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('survey:surveySchemaNotFoundTitle')}
            description={t('survey:surveySchemaNotFoundMessage', {
              surveySchemaId: surveySchema.id,
            })}
          />
        </Box>
      )}
      {aggregatedSurveyResults && !loading && (
        <AggregatedSurveyResult
          surveySchema={surveySchema}
          aggregatedSurveyResults={aggregatedSurveyResults}
        />
      )}
    </>
  )
}
