import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const LockIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 24} ${props.height ?? 24}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g transform="translate(-826.000000, -465.000000)" fillRule="nonzero">
        <g id="Group-2" transform="translate(500.000000, 190.000000)">
          <g id="Group" transform="translate(40.000000, 259.000000)">
            <g id="lock-1" transform="translate(286.000000, 16.000000)">
              <path
                d="M1.6875,18 C0.75675,18 0,17.24325 0,16.3125 L0,8.4375 C0,7.50675 0.75675,6.75 1.6875,6.75 L2.25,6.75 L2.25,4.5 C2.25,2.019 4.269,0 6.75,0 C9.231,0 11.25,2.019 11.25,4.5 L11.25,6.75 L11.8125,6.75 C12.74325,6.75 13.5,7.50675 13.5,8.4375 L13.5,16.3125 C13.5,17.24325 12.74325,18 11.8125,18 L1.6875,18 Z M1.6875,7.875 C1.377,7.875 1.125,8.127 1.125,8.4375 L1.125,16.3125 C1.125,16.623 1.377,16.875 1.6875,16.875 L11.8125,16.875 C12.123,16.875 12.375,16.623 12.375,16.3125 L12.375,8.4375 C12.375,8.127 12.123,7.875 11.8125,7.875 L1.6875,7.875 Z M10.125,6.75 L10.125,4.5 C10.125,2.63925 8.61075,1.125 6.75,1.125 C4.88925,1.125 3.375,2.63925 3.375,4.5 L3.375,6.75 L10.125,6.75 Z"
                id="Shape"
              />
              <path
                d="M6.75,14.0625 C6.4395,14.0625 6.1875,13.8105 6.1875,13.5 L6.1875,11.25 C6.1875,10.9395 6.4395,10.6875 6.75,10.6875 C7.0605,10.6875 7.3125,10.9395 7.3125,11.25 L7.3125,13.5 C7.3125,13.8105 7.0605,14.0625 6.75,14.0625 Z"
                id="Path"
              />
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { LockIcon }
