import React, { useState } from 'react'
import {
  Button,
  TextField,
  Typography,
  Box,
  InputAdornment,
  Zoom,
  useTheme,
  Link,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'

import { ProfileIcon } from '../../../common/icons/ProfileIcon'
import { ValidEmailPattern } from '../../../utils/isValidEmail'
import { useInitiatePasswordReset } from '../../../common/hooks/useInitiatePasswordReset'

const RequestPasswordReset: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [errorType, setErrorType] = useState<string | null>(null)
  const [completed, setCompleted] = useState<boolean>(false)
  const {
    watch,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: { email: '' },
  })

  const [startPasswordReset] = useInitiatePasswordReset({
    onCompleted: () => setCompleted(true),
    onError: (error) => setErrorType(error.message),
  })

  const loginUser = (email: string) => {
    startPasswordReset({ variables: { email } })
  }

  const onSubmit = handleSubmit((values) => loginUser(values?.email))

  return (
    <>
      <Box mt={3}>
        <Typography component="h1" variant="h3">
          {t('auth:passwordReminder')}
        </Typography>
      </Box>
      <Box mt={1} flexGrow={1} display="flex" alignItems="center">
        <Typography
          component="p"
          variant="body1"
          style={{ wordBreak: 'break-word' }}
        >
          {completed
            ? t('auth:passwordResetEmailInstructions', {
                email: watch('email'),
              })
            : t('auth:passwordReminderDescription')}
        </Typography>
      </Box>
      {!completed && (
        <Box width="100%" mt={3}>
          <form onSubmit={onSubmit} noValidate>
            <TextField
              {...register('email', {
                pattern: {
                  value: ValidEmailPattern,
                  message: 'messages:warnings.notEmail',
                },
                required: {
                  value: true,
                  message: 'messages:warnings.emptyField',
                },
                setValueAs: (value) => value.toLowerCase(),
              })}
              variant="outlined"
              margin="normal"
              error={!!errors.email}
              helperText={
                errors.email?.message ? t(errors.email?.message) : undefined
              }
              required
              fullWidth
              label={t('auth:email')}
              autoComplete="off"
              autoFocus
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <ProfileIcon
                      width={16}
                      height={18}
                      sx={{ color: `${theme.palette.common.black}66` }}
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Zoom in={!!errorType}>
              <Box mt={3}>
                {errorType && (
                  <Typography variant="body2" color="error">
                    {t(`auth:passwordResetErrorType.${errorType}`)}
                  </Typography>
                )}
              </Box>
            </Zoom>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
              >
                {t('auth:sendPasswordResetToken')}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      <Box my={3}>
        <Link component={RouterLink} to="/login">
          <Typography variant="subtitle2" color="textSecondary">
            {t('common:back')}
          </Typography>
        </Link>
      </Box>
    </>
  )
}

export { RequestPasswordReset }
