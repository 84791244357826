import { Stack, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'

interface Props {
  question: QuestionInSection
  questionOrder: number
}

export const QuestionTitle: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  questionOrder,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  return (
    <Stack>
      <Stack direction="row">
        <Typography variant="italic">
          {t('survey:question.order', {
            questionOrder,
          })}
        </Typography>
        {question.isRequired && (
          <Typography variant="italic" color="error">
            *
          </Typography>
        )}
      </Stack>
      <Typography variant="body2">{question.title}</Typography>
      <Typography variant="italic" color={colorPalette.grey[300]}>
        {question.description}
      </Typography>
    </Stack>
  )
}
