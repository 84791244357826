import { MutationTuple } from '@apollo/client/react/types/types'
import {
  setCustomDaysOfTreatmentSchemaSurvey,
  setCustomDaysOfTreatmentSchemaSurveyVariables,
} from '../../../../models/graphqlTypes'
import { useMutation } from '@apollo/client'
import { SET_CUSTOM_DAYS_OF_TREATMENT_SCHEMA_SURVEY } from '../operations/treatmentSchemaOperations'

type Return = MutationTuple<
  setCustomDaysOfTreatmentSchemaSurvey,
  setCustomDaysOfTreatmentSchemaSurveyVariables
>

export const useSetCustomDayOfTreatmentSchemaSurvey = (): Return =>
  useMutation<
    setCustomDaysOfTreatmentSchemaSurvey,
    setCustomDaysOfTreatmentSchemaSurveyVariables
  >(SET_CUSTOM_DAYS_OF_TREATMENT_SCHEMA_SURVEY, {
    refetchQueries: ['getTreatmentSchema'],
  })
