import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  deleteInvitation,
  deleteInvitationVariables,
} from '../../../models/graphqlTypes'
import { DELETE_INVITE } from '../operations/admin.operations'

type Options = MutationHookOptions<deleteInvitation, deleteInvitationVariables>

type Return = MutationTuple<deleteInvitation, deleteInvitationVariables>

export const useDeleteInvite = (options: Options = {}): Return => {
  return useMutation<deleteInvitation, deleteInvitationVariables>(
    DELETE_INVITE,
    options
  )
}
