import UploadFileIcon from '@mui/icons-material/UploadFile'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Question } from '../Questions/questions.types'

interface Props {
  question: Question
}

export const ViewFileUploadQuestion: React.FC<
  React.PropsWithChildren<Props>
> = ({ question }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const fileUploadData = question.data?.fileUploadData

  return (
    <Box py={1} ml={5}>
      <Typography variant="italic" color={colorPalette.grey[300]}>
        {question.description}
      </Typography>

      <Stack gap={1} my={2}>
        {!!fileUploadData?.validFileTypes.length && (
          <Typography variant="body2">
            {t('survey:file.acceptedFormats')}:{' '}
            {fileUploadData?.validFileTypes.join(', ')}
          </Typography>
        )}
        <Typography variant="body2">
          {t('survey:file.maxAmountOfFiles')}: {fileUploadData?.maxItemsCount}
        </Typography>
        <Typography variant="body2">
          {t('survey:file.maxSizeOfFiles')}:{' '}
          {t(`survey:file.size.${fileUploadData?.maxFileSize}`)}
        </Typography>
      </Stack>

      <Button
        startIcon={<UploadFileIcon />}
        disabled
        fullWidth={false}
        variant="outlined"
      >
        {t('survey:file.uploadButtonTitle')}
      </Button>
    </Box>
  )
}
