import React, { FC, RefObject } from 'react'
import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import { AppointmentHeader } from './AppointmentHeader'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import dayjs from 'dayjs'
import { ArrowUpIcon } from '../../../../../common/icons/ArrowUpIcon'
import { Appointment, Survey } from '../../types/treatments.types'

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  hovered: {
    borderColor: theme.palette.grey[800],
    borderWidth: 2,
  },
  past: {
    color: theme.palette.grey[600],
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
}))

export interface TreatmentAppointmentItemComponentViewModel {
  appointment: Appointment
  nextAppointment?: Appointment
  firstFutureAppointment?: Appointment
  nextSurvey?: Survey
  firstFutureSurvey?: Survey
  isDraft: boolean
  isOwnTreatment: boolean
  isAssistantOfTreatment: boolean
  order: number
  hasPast: boolean
  showPast: boolean
  linking: Appointment | null
  disableLinking: boolean
  canLinkTo: boolean
  firstRef: RefObject<HTMLElement | null>
  setShowPast: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (appointmentId: string) => void
  onHover: (v: string | null) => void
  isHoveredInCalendar: boolean
  setHoveredDay: React.Dispatch<React.SetStateAction<string | null>>
  editAppointment: (appointment: Appointment) => void
  cloneAppointment: (appointment: Appointment) => void
  onLink: (appointment: Appointment) => void
}

interface Props {
  vm: TreatmentAppointmentItemComponentViewModel
}

const TreatmentAppointmentItemComponent: FC<React.PropsWithChildren<Props>> = ({
  vm: {
    firstRef,
    appointment,
    nextAppointment,
    firstFutureAppointment,
    nextSurvey,
    firstFutureSurvey,
    order,
    isDraft,
    isOwnTreatment,
    isAssistantOfTreatment,
    showPast,
    hasPast,
    isHoveredInCalendar,
    linking,
    disableLinking,
    canLinkTo,
    setShowPast,
    onClick,
    onHover,
    setHoveredDay,
    editAppointment,
    cloneAppointment,
    onLink,
  },
}) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const isPast = dayjs().startOf('day') > dayjs(appointment.proposedDate)
  const isNext =
    nextAppointment?.id === appointment.id &&
    (!nextSurvey || nextSurvey.fillableFrom >= nextAppointment.proposedDate)
  const isFuture =
    firstFutureAppointment?.id === appointment.id &&
    (!firstFutureSurvey ||
      firstFutureSurvey.fillableFrom >= firstFutureAppointment.proposedDate)
  const handleLink = () => {
    onLink(appointment)
  }

  return !showPast && isPast ? null : (
    <Box
      {...{
        ref: isNext ? firstRef : null,
      }}
      order={order}
    >
      {hasPast && isNext && (
        <Box ml={-1.5} mb={2}>
          <Button
            variant="text"
            size="small"
            disabled={showPast}
            onClick={() => setShowPast(true)}
          >
            <ArrowUpIcon sx={{ mr: 1, fontSize: '1.0rem' }} />
            {t('appointment:range.past')}
          </Button>
        </Box>
      )}
      {isNext && (
        <Box mb={1}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('appointment:range.next').toUpperCase()}
          </Typography>
        </Box>
      )}
      {isFuture && (
        <Box mb={1}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('appointment:range.future').toUpperCase()}
          </Typography>
        </Box>
      )}
      <Box
        className={clsx(classes.container, {
          [classes.past]: isPast,
          [classes.hovered]: isHoveredInCalendar,
        })}
        border={1}
        borderRadius={1.3}
        mb={1}
        pl={2}
        pr={1}
        id={`arrow-row-${appointment.id}`}
        onMouseEnter={() => {
          onHover(appointment.id)
          const date = appointment?.proposedDate
          const hoverDateString = dayjs(date).format('YYYY-MM-DD')
          setHoveredDay(hoverDateString)
        }}
        onMouseLeave={() => {
          onHover(null)
          setHoveredDay(null)
        }}
        onClick={() => onClick(appointment.id)}
      >
        <AppointmentHeader
          appointment={appointment}
          isPast={isPast}
          isAssistantOfTreatment={isAssistantOfTreatment}
          isOwnTreatment={isOwnTreatment}
          isDraft={isDraft}
          editAppointment={editAppointment}
          cloneAppointment={cloneAppointment}
          linking={linking}
          disableLinking={disableLinking}
          canLinkTo={canLinkTo}
          onLink={handleLink}
        />
      </Box>
    </Box>
  )
}

const TreatmentAppointmentItem = React.memo(TreatmentAppointmentItemComponent)

export { TreatmentAppointmentItem }
