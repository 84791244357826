import React, { useRef, useLayoutEffect } from 'react'
import { useTheme, Box, Typography } from '@mui/material'
import { useTranslation } from '../../common/hooks/helper/useTranslation'

import { UserType } from '../../models/graphqlTypes'
import { Avatar } from '../../common/components/Avatar'
import { MessagePartnerProfile } from './MessagePartnerProfile'
import { Partner, Message } from './messageBoard.types'

type Props = {
  message: Message
  partner: Partner
  shouldScrollIntoView?: boolean
}

const MessageBoardMessage: React.FC<React.PropsWithChildren<Props>> = ({
  message,
  partner,
  shouldScrollIntoView,
}) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const messageBoxRef = useRef<HTMLDivElement | null>(null)

  const isReceived = message.senderUserId === partner.user.id
  const isDoctor = partner.user.userType === UserType.Doctor
  const profile: MessagePartnerProfile | null | undefined = isDoctor
    ? partner.user.doctorProfile
    : partner.user.patientProfile

  useLayoutEffect(() => {
    if (shouldScrollIntoView && messageBoxRef.current) {
      messageBoxRef.current.scrollIntoView(false)
    }
  }, [messageBoxRef, shouldScrollIntoView])

  return (
    <Box display="flex">
      <div ref={messageBoxRef} />
      {isReceived && profile && (
        <Avatar
          firstName={profile.firstName}
          lastName={profile.lastName}
          size="small"
        />
      )}
      <Box flexGrow={1}>
        <Box
          border={1}
          borderRadius="6px"
          borderColor={
            isReceived ? theme.palette.grey[300] : theme.palette.primary.light
          }
          bgcolor={
            isReceived
              ? theme.palette.grey[100]
              : `${theme.palette.primary.main}44`
          }
          whiteSpace="pre-wrap"
          p={2}
          ml={{ xs: isReceived ? 0 : 12, md: isReceived ? 0 : 24 }}
          mr={{ xs: isReceived ? 12 : 0, md: isReceived ? 24 : 0 }}
        >
          <Typography variant="body2">{message.message}</Typography>
        </Box>

        <Box
          color={theme.palette.grey[400]}
          textAlign={isReceived ? 'left' : 'right'}
        >
          <Typography variant="caption">
            {t('common:datePrintedDayWithHours', {
              date: message.createdAt,
            })}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export { MessageBoardMessage }
