import { Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SendSurveyToType } from './sendSurvey.types'

export const SendSurveyToTypeSelector: React.FC = ({}) => {
  const { control } = useFormContext()
  const { t } = useTranslation('survey')

  return (
    <>
      <Typography variant="body2" mb={1}>
        {t('sendSurvey.subtitle')}
      </Typography>
      <Controller
        render={({ field }) => (
          <RadioGroup {...field}>
            <FormControlLabel
              value={SendSurveyToType.Patient}
              control={<Radio />}
              label={t('sendSurvey.patient')}
            />
            <FormControlLabel
              value={SendSurveyToType.Doctor}
              control={<Radio />}
              label={t('sendSurvey.doctor')}
            />
            <FormControlLabel
              value={SendSurveyToType.Assistant}
              control={<Radio />}
              label={t('sendSurvey.assistant')}
            />
          </RadioGroup>
        )}
        name="sendTo"
        control={control}
      />
    </>
  )
}
