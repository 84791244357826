import React, { useEffect } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  createInstitution as CreateInstitution,
  createInstitutionVariables,
  updateInstitution as UpdateInstitution,
  updateInstitutionVariables,
  institutionChore,
} from '../../../models/graphqlTypes'
import { useMutation } from '@apollo/client'
import {
  CREATE_INSTITUTION,
  UPDATE_INSTITUTION,
} from '../../../operations/institutionOperations'
import { SaveButton } from '../../../common/components/SaveButton'
import { FRAGMENT_INSTITUTION_CHORE } from '../../../operations/fragments'

type roomFrom = {
  name: string
  addressPostalCode: string
  addressCity: string
  addressAddress: string
  phoneNumber: string
  email: string
  website: string
  neak: string
  active: string
}

interface InstitutionModal {
  selectedInstitution: institutionChore | null | undefined
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onComplete?: () => void
}

const InstitutionModal: React.FC<React.PropsWithChildren<InstitutionModal>> = ({
  isOpen,
  setIsOpen,
  onComplete,
  selectedInstitution,
}) => {
  const { t } = useTranslation()

  const isEdit = !!selectedInstitution

  const {
    formState: { errors },
    register,
    handleSubmit,
    reset,
    control,
  } = useForm<roomFrom>({
    mode: 'onBlur',
    defaultValues: {
      name: '',
      addressPostalCode: '',
      addressCity: '',
      addressAddress: '',
      phoneNumber: '',
      email: '',
      website: '',
      neak: '',
      active: '',
    },
  })

  useEffect(() => {
    if (isEdit) {
      reset({
        name: selectedInstitution?.name,
        addressPostalCode: selectedInstitution?.addressPostalCode || '',
        addressCity: selectedInstitution?.addressCity || '',
        addressAddress: selectedInstitution?.addressAddress || '',
        phoneNumber: selectedInstitution?.phoneNumber || '',
        email: selectedInstitution?.email || '',
        website: selectedInstitution?.website || '',
        neak: selectedInstitution?.neak || '',
        active: !!selectedInstitution?.isActive ? 'active' : 'inactive',
      })
    }
  }, [selectedInstitution, isEdit, reset])

  const closeModal = () => {
    setIsOpen(false)
    onComplete && onComplete()
    reset({
      name: '',
      addressPostalCode: '',
      addressCity: '',
      addressAddress: '',
      phoneNumber: '',
      email: '',
      website: '',
      neak: '',
      active: '',
    })
  }

  const [createInstitution, { loading: isCreateLoading }] = useMutation<
    CreateInstitution,
    createInstitutionVariables
  >(CREATE_INSTITUTION, {
    refetchQueries: ['listInstitutions'],
    onCompleted: () => {
      closeModal()
      onComplete && onComplete()
    },
  })

  const [updateInstitution, { loading: isUpdateLoading }] = useMutation<
    UpdateInstitution,
    updateInstitutionVariables
  >(UPDATE_INSTITUTION, {
    onCompleted: () => {
      closeModal()
      onComplete && onComplete()
    },
    update: (cache, { data }) => {
      if (!data?.updateInstitution) {
        return
      }
      cache.writeFragment<institutionChore>({
        id: cache.identify({
          __typename: 'Institution',
          id: data.updateInstitution.id,
        }),
        data: data.updateInstitution,
        fragmentName: 'institutionChore',
        fragment: FRAGMENT_INSTITUTION_CHORE,
      })
    },
  })

  const onSubmit = handleSubmit(
    ({
      name,
      addressPostalCode,
      addressCity,
      addressAddress,
      phoneNumber,
      email,
      website,
      neak,
      active,
    }) => {
      const institutionInput = {
        name,
        addressPostalCode,
        addressCity,
        addressAddress,
        phoneNumber,
        email,
        website,
        neak,
        isActive: active === 'active',
      }

      if (isEdit && selectedInstitution?.id) {
        return updateInstitution({
          variables: {
            id: selectedInstitution?.id,
            institutionInput,
          },
        })
      } else {
        return createInstitution({
          variables: {
            institutionInput,
          },
        })
      }
    }
  )

  const required = {
    value: true,
    message: t('messages:warnings.required'),
  }

  const isLoading = isCreateLoading || isUpdateLoading

  return (
    <form>
      <Dialog open={isOpen} onClose={closeModal}>
        <DialogTitle>
          {isEdit
            ? t('institution:admin.editInstitutionInfoTitle')
            : t('institution:admin.addInstitutionInfoTitle')}
        </DialogTitle>
        <DialogContent sx={{ width: { sm: 400 } }}>
          <Box mb={3}>
            <DialogContentText>
              {t('institution:admin.addInstitutionInfoPanelText')}
            </DialogContentText>
          </Box>

          <Box my={1}>
            <FormControl error={!!errors.active}>
              <Controller
                name="active"
                control={control}
                rules={{ required }}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Box display="flex">
                      <FormControlLabel
                        value="active"
                        control={<Radio />}
                        label={t('common:active')}
                      />
                      <FormControlLabel
                        value="inactive"
                        control={<Radio />}
                        label={t('common:inactive')}
                      />
                    </Box>
                  </RadioGroup>
                )}
              />
              <FormHelperText>{errors.active?.message}</FormHelperText>
            </FormControl>
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('institution:name')}</Typography>

            <TextField
              {...register('name', {
                required,
              })}
              fullWidth
              variant="outlined"
              error={!!errors.name}
              helperText={errors?.name?.message}
            />
          </Box>

          <Box my={1}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle2">
                  {t('common:addressPostalCode')}
                </Typography>
                <TextField
                  {...register('addressPostalCode', {
                    maxLength: 4,
                    minLength: 4,
                  })}
                  fullWidth
                  type="number"
                  variant="outlined"
                  error={!!errors.addressPostalCode}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="subtitle2">
                  {t('common:addressCity')}
                </Typography>
                <TextField
                  {...register('addressCity')}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">
              {t('common:InstitutionAddress')}
            </Typography>
            <TextField
              {...register('addressAddress')}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('common:phone')}</Typography>
            <TextField
              {...register('phoneNumber')}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('common:email')}</Typography>
            <TextField {...register('email')} fullWidth variant="outlined" />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('common:website')}</Typography>
            <TextField {...register('website')} fullWidth variant="outlined" />
          </Box>

          <Box my={1}>
            <Typography variant="subtitle2">{t('common:neak')}</Typography>
            <TextField {...register('neak')} fullWidth variant="outlined" />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" onClick={closeModal}>
            {t('common:cancel')}
          </Button>
          <SaveButton
            isSaving={isLoading}
            size="medium"
            text={isEdit ? t('common:save') : t('common:add')}
            onClick={onSubmit}
          />
        </DialogActions>
      </Dialog>
    </form>
  )
}

export { InstitutionModal }
