import { Box } from '@mui/material'
import React from 'react'
import {
  TreatmentGroupedAppointmentsComponent,
  TreatmentGroupedAppointmentsComponentViewModel,
} from './TreatmentGroupedAppointments'
import { Arrow } from './Arrow'
import { useScaleArrows } from './hooks/useScaleArrows'

interface AppointmentsWithArrowsProps {
  treatmentGroupedAppointmentsViewModel: TreatmentGroupedAppointmentsComponentViewModel
  showArrows: boolean
}
const AppointmentsWithArrows: React.FC<
  React.PropsWithChildren<AppointmentsWithArrowsProps>
> = ({ treatmentGroupedAppointmentsViewModel, showArrows }) => {
  const { scaleArrows } = useScaleArrows()
  const { arrowProps } = treatmentGroupedAppointmentsViewModel

  return (
    <Box display="flex" flexDirection="column" p={2} position="relative">
      <TreatmentGroupedAppointmentsComponent
        vm={treatmentGroupedAppointmentsViewModel}
      />
      {showArrows &&
        arrowProps.map((arrowProp, i) => (
          <Arrow key={i} {...{ ...arrowProp, scale: scaleArrows }} />
        ))}
    </Box>
  )
}

export { AppointmentsWithArrows }
