import React from 'react'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { FillQuestionByType } from './FillQuestionByType'
import { QuestionTitle } from './QuestionTitle'

interface Props {
  question: QuestionInSection
  questionOrder: number
  setNextSectionOrder?: (order: number) => void
  isPreview: boolean
  readOnly?: boolean
}

export const FillQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  questionOrder,
  isPreview,
  setNextSectionOrder,
  readOnly,
}) => {
  return (
    <>
      <QuestionTitle question={question} questionOrder={questionOrder} />
      <FillQuestionByType
        question={question}
        isPreview={isPreview}
        setNextSectionOrder={setNextSectionOrder}
        readOnly={readOnly}
      />
    </>
  )
}
