import React from 'react'
import { alpha, Box, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { ViewSurveyDetailsLabel } from './ViewSurveyDetailsLabel'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { isValidHttpUrl } from '../../../../../common/components/Reference/referenceUtil'

interface Props {
  surveySchema: SurveySchemaWithoutUser
  surveySchemaStatus?: SurveySchemaStatusEnum
}

export const ViewSurveyDetailsCore: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveySchema, surveySchemaStatus }) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const status = surveySchemaStatus ?? surveySchema.status

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={{ xs: 'center', md: 'space-between' }}
      flex={1}
    >
      <Stack sx={{ pt: 2 }}>
        <Typography mb={2} variant="h5">
          {surveySchema.title}
        </Typography>

        <Box>
          <table>
            <tbody>
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {t('treatment:protocol.profession')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2">
                    {surveySchema.professions
                      .map((profession) => profession.name)
                      .join(', ')}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {t('treatment:protocol.bnoCode')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2">
                    {!surveySchema.bnoCodes.length
                      ? '-'
                      : surveySchema.bnoCodes
                          .map((item) => `${item.code} - ${item.title}`)
                          .join(', ')}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {t('treatment:protocol.snowmedCode')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2">
                    {!surveySchema.snomedCode ? '-' : surveySchema.snomedCode}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td style={{ verticalAlign: 'top' }}>
                  <Typography variant="subtitle1">
                    {t('treatment:protocol.url')}
                  </Typography>
                </td>
                <td>
                  {!surveySchema.referenceUrls?.length
                    ? '-'
                    : surveySchema.referenceUrls?.map((reference, i) => (
                        <Typography key={i} variant="subtitle2">
                          {isValidHttpUrl(reference.url) ? (
                            <Link
                              variant="subtitle1"
                              href={reference.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {reference.name}
                            </Link>
                          ) : (
                            reference.url
                          )}
                        </Typography>
                      ))}
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="subtitle1" sx={{ pr: 4 }}>
                    {t('survey:surveys.category')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle2">
                    {surveySchema.categories
                      .map((category) =>
                        t(`survey:survey.type.${category.toLowerCase()}`)
                      )
                      .join(', ')}
                  </Typography>
                </td>
              </tr>
              <tr>
                <td>
                  <Typography variant="subtitle1">
                    {t('survey:description.title')}
                  </Typography>
                </td>
                <td>
                  <Typography variant="subtitle1">
                    {surveySchema.description}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </Box>
      </Stack>

      <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 1, md: 2 }}>
        <ViewSurveyDetailsLabel
          title={t(`survey:status.${status.toLowerCase()}`)}
          color={
            status === SurveySchemaStatusEnum.Active
              ? colorPalette.success.main
              : alpha(colorPalette.common.black, 0.2)
          }
        />
        {surveySchema.isGlobal && (
          <ViewSurveyDetailsLabel
            title={t('survey:survey.isGlobal')}
            color={colorPalette.warning.main}
          />
        )}
      </Stack>
    </Stack>
  )
}
