import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const CaretUpIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (
  props
) => {
  const viewBox = `0 0 ${props.width ?? 10} ${props.height ?? 6}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M5,0.192307692 C5.25450088,0.191992702 5.49680818,0.301304269 5.665,0.492307692 L9.43846154,4.78076923 C9.61313736,4.97028611 9.67146219,5.23954667 9.59086672,5.48435815 C9.51027124,5.72916964 9.3034,5.91112382 9.05030365,5.95981192 C8.79720731,6.00850003 8.53759388,5.91628343 8.37192308,5.71884615 L5.07230769,1.96884615 C5.05405032,1.9480174 5.02769781,1.93607338 5,1.93607338 C4.97230219,1.93607338 4.94594968,1.9480174 4.92769231,1.96884615 L1.62807692,5.71961538 C1.46240612,5.91705266 1.20279269,6.00926926 0.949696345,5.96058116 C0.696599998,5.91189305 0.48972876,5.72993887 0.409133284,5.48512738 C0.328537809,5.2403159 0.386862637,4.97105534 0.561538462,4.78153846 L4.33384615,0.493846154 C4.50232596,0.302380374 4.74496171,0.192549871 5,0.192307692 Z" />
    </SvgIcon>
  )
}

export { CaretUpIcon }
