import { Appointment, AppointmentDoctor } from './treatments.types'

export enum TreatmentPanelTab {
  Events = 0,
  TreatmentLog = 1,
}

export interface AddAppointmentModalParams {
  isOpen: boolean
  appointmentType?: string
  doctor?: AppointmentDoctor
  appointment?: Appointment
  proposedDate?: Date
}

export type RouteParams = {
  patientId: string
  treatmentId: string
  appointmentId?: string
  action?: string
}
