import React, { FC } from 'react'
import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { AppointmentStatus } from '../../../../../models/graphqlTypes'
import dayjs from 'dayjs'

import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material'
import {
  Appointment,
  CalculatedScheduleElement,
} from '../../types/treatments.types'

type Props = {
  element: CalculatedScheduleElement
  appointment: Appointment
  isAllSelected: boolean
}

export const AppointmentDiffItem: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { element, appointment, isAllSelected } = props
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const wasProposed =
    appointment.status !== AppointmentStatus.BetmenBooked &&
    appointment.status !== AppointmentStatus.EesztBooked
  const oldDate = element.isModified
    ? element.oldDate?.start
    : element.date.start
  const oldFormat = wasProposed
    ? 'common:intlDateFormattedLongMonth'
    : 'common:intlDateFormattedWithHours'
  const isSameDay = dayjs(element.date.start).isSame(dayjs(oldDate), 'day')
  const diff = Math.round(dayjs(element.date.start).diff(oldDate, 'hour') / 24)

  return (
    <Box display="flex" pb={1} flexDirection="column">
      <Box>
        <Typography variant="subtitle1" color="primary">
          <b>{element.doctorTitle?.[selectedLanguage]}</b>
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box width="30%">
          <Typography variant="subtitle2">
            {t(oldFormat, { date: oldDate })}
          </Typography>
        </Box>
        <Box width="10%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <ArrowForwardIcon />
          </Box>
        </Box>
        <Box width="30%">
          <Typography variant="subtitle2">
            {t('common:intlDateFormattedLongMonth', {
              date: element.date.start,
            })}
          </Typography>
        </Box>
        <Box flex={1} display="flex" pl={2} alignItems="flex-end">
          <Typography variant="subtitle2">
            {isSameDay || !element.isModified
              ? t('common:unChanged')
              : `${diff} ${t('common:timingInterval.d')}`}
          </Typography>
        </Box>
        {!!diff && (
          <FormControlLabel
            control={<Checkbox checked={isAllSelected} disabled />}
            labelPlacement="start"
            label=""
          />
        )}
      </Box>
    </Box>
  )
}
