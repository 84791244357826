import { useQuery } from '@apollo/client'
import {
  appointmentInfoChore,
  listAppointmentInfos,
} from '../../models/graphqlTypes'
import { LIST_APPOINTMENT_INFO } from '../../operations/appointmentOperations'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { useSelectedLanguage } from './useSelectedLanguage'

type Options = QueryHookOptions<listAppointmentInfos>

type ReturnTypes = {
  loading: boolean
  appointmentTypes: appointmentInfoChore[]
  orderedAppointmentTypes: appointmentInfoChore[]
}

export const useAppointmentTypes = (options: Options = {}): ReturnTypes => {
  const selectedLanguage = useSelectedLanguage()

  const { data: appointmentTypeData, loading } = useQuery<listAppointmentInfos>(
    LIST_APPOINTMENT_INFO,
    options
  )

  const appointmentTypes = appointmentTypeData?.listAppointmentInfos || []

  const orderedAppointmentTypes = appointmentTypes
    .slice()
    .sort((firstAppointment, secondAppointment) =>
      firstAppointment.doctorTitle[selectedLanguage].localeCompare(
        secondAppointment.doctorTitle[selectedLanguage]
      )
    )

  return {
    loading,
    appointmentTypes,
    orderedAppointmentTypes,
  }
}
