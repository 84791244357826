import React from 'react'
import { FileUploadQuestionResult } from './FileUploadQuestionResult'
import { MultipleChoiceQuestionResult } from './MultipleChoiceQuestionResult'
import { ScaleQuestionResult } from './ScaleQuestionResult'
import { SimpleTextQuestionResult } from './SimpleTextQuestionResult'
import { QuestionResults } from '../../types/surveyStore.types'

interface Props {
  questionResult: QuestionResults
}

export const QuestionResultByType: React.FC<React.PropsWithChildren<Props>> = ({
  questionResult,
}) => {
  switch (questionResult.__typename) {
    case 'SimpleTextQuestionResult':
      return <SimpleTextQuestionResult questionResult={questionResult} />
    case 'MultipleChoiceQuestionResult':
      return <MultipleChoiceQuestionResult questionResult={questionResult} />
    case 'ScaleQuestionResult':
      return <ScaleQuestionResult questionResult={questionResult} />
    case 'FileUploadQuestionResult':
      return <FileUploadQuestionResult questionResult={questionResult} />
    default:
      return null
  }
}
