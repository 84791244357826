import { Box, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { Question } from '../Questions/questions.types'

interface Props {
  question: Question
}

export const ViewSimpleTextQuestion: React.FC<
  React.PropsWithChildren<Props>
> = ({ question }) => {
  const colorPalette = useColorPalette()

  return (
    <Box sx={{ ml: 5, mt: 1 }}>
      <Typography variant="italic" color={colorPalette.grey[300]}>
        {question.description}
      </Typography>
    </Box>
  )
}
