import { Box, Grid, Stack } from '@mui/material'
import React, { FC } from 'react'
import {
  BetmenListHeaderCell,
  BetmenListHeaderCellProps,
} from './BetmenListHeaderCell'

export const BetmenListHeader: FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Stack sx={{ px: { xs: 3, md: 4 }, mb: 1 }}>
      <Grid container alignItems="center">
        {React.Children.map(children, (child, i) => {
          if (
            React.isValidElement<BetmenListHeaderCellProps>(child) &&
            (child.type === BetmenListHeaderCell || child.type === Box)
          ) {
            return React.cloneElement(child, {
              ...(typeof child.props === 'object' ? child.props : {}),
              cellindex: i,
            })
          }

          console.warn(
            'Invalid child component found in BetmenListHeader. Allowed: BetmenListHeaderCell',
            child
          )
          return child
        })}
      </Grid>
    </Stack>
  )
}
