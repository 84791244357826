import { IconButton, Toolbar, Tooltip, Typography } from '@mui/material'
import React, { FC } from 'react'
import { DataTableFreeAction } from './interfaces'

type Props = {
  title?: string
  actions?: DataTableFreeAction[]
}

export const DataTableToolbar: FC<React.PropsWithChildren<Props>> = (props) => {
  const { title, actions } = props
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: '1 1 100%' }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>

      {actions?.map((action, idx) => (
        <Tooltip key={idx} title={action.tooltip}>
          <IconButton onClick={action.onClick}>{action.icon}</IconButton>
        </Tooltip>
      ))}
    </Toolbar>
  )
}
