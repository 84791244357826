import React from 'react'
import { SurveyQuestionEnum } from '../../../../../models/graphqlTypes'
import { Question } from '../Questions/questions.types'
import { ViewFileUploadQuestion } from './ViewFileUploadQuestion'
import { ViewMultipleChoiceQuestion } from './ViewMultipleChoiceQuestion'
import { ViewScaleQuestion } from './ViewScaleQuestion'
import { ViewSimpleTextQuestion } from './ViewSimpleTextQuestion'

interface Props {
  question: Question
}

export const ViewQuestionByType: React.FC<React.PropsWithChildren<Props>> = ({
  question,
}) => {
  switch (question.type) {
    case SurveyQuestionEnum.SimpleText:
      return <ViewSimpleTextQuestion question={question} />
    case SurveyQuestionEnum.MultipleChoice:
      return <ViewMultipleChoiceQuestion question={question} />
    case SurveyQuestionEnum.Scale:
      return <ViewScaleQuestion question={question} />
    case SurveyQuestionEnum.FileUpload:
      return <ViewFileUploadQuestion question={question} />
    default:
      return null
  }
}
