import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const LogoutIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 15}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <g>
        <path d="M8.1632,10.56256 C8.0352,10.56256 7.91424,10.51264 7.824,10.42176 C7.63712,10.23488 7.63712,9.93024 7.824,9.74272 L9.4048,8.16192 L0.4832,8.16192 C0.21824,8.16192 0.0032,7.94688 0.0032,7.68192 C0.0032,7.41696 0.21824,7.20192 0.4832,7.20192 L9.40416,7.20192 L7.82336,5.62112 C7.73248,5.53088 7.68256,5.40992 7.68256,5.28192 C7.68256,5.15392 7.73248,5.03296 7.82336,4.94272 C7.9136,4.85184 8.03456,4.80192 8.16256,4.80192 C8.29056,4.80192 8.41152,4.85184 8.50176,4.94272 L10.90176,7.34272 C10.94656,7.38752 10.98176,7.44 11.00608,7.49952 C11.008,7.50464 11.01056,7.5104 11.01248,7.51616 C11.03232,7.568 11.04256,7.62496 11.04256,7.68192 C11.04256,7.7376 11.03232,7.79328 11.01248,7.84704 C11.0112,7.85088 11.00992,7.85408 11.00864,7.85728 C10.98176,7.92384 10.94592,7.97632 10.90112,8.02176 L8.50176,10.42112 C8.41216,10.51264 8.2912,10.56256 8.1632,10.56256 Z" />
        <path d="M8.17216,14.88192 C5.42976,14.88192 2.88448,13.2928 1.68768,10.83392 C1.63136,10.71872 1.62368,10.5888 1.66528,10.4672 C1.70688,10.3456 1.79392,10.24832 1.90912,10.192 C1.97568,10.16 2.04608,10.144 2.11904,10.144 C2.30464,10.144 2.46976,10.24768 2.55104,10.41408 C3.28064,11.91296 4.54976,13.03744 6.12544,13.58144 C6.78848,13.81056 7.4752,13.9264 8.1664,13.9264 C9.11296,13.9264 10.03008,13.71328 10.8928,13.29344 C12.39168,12.56384 13.51616,11.29472 14.06016,9.71904 C14.60416,8.14336 14.50176,6.45056 13.77216,4.95168 C12.73472,2.82048 10.52864,1.4432 8.15104,1.4432 C7.2032,1.4432 6.28736,1.65504 5.4304,2.07232 C4.24,2.65152 3.28064,3.57568 2.65728,4.74304 C2.57344,4.89984 2.41152,4.99712 2.2336,4.99712 C2.15552,4.99712 2.07744,4.97728 2.00768,4.9408 C1.77408,4.816 1.68576,4.5248 1.80992,4.2912 C2.52992,2.94336 3.63584,1.87776 5.00992,1.20896 C5.99936,0.72704 7.056,0.4832 8.1504,0.4832 C10.8928,0.4832 13.43808,2.07232 14.63488,4.53184 C15.47648,6.26112 15.59488,8.2144 14.96704,10.03264 C14.33984,11.85088 13.04128,13.3152 11.31264,14.1568 C10.3232,14.63808 9.2672,14.88192 8.17216,14.88192 Z" />
      </g>
    </SvgIcon>
  )
}

export { LogoutIcon }
