import React, { useState } from 'react'
import { Box, Hidden, SwipeableDrawer } from '@mui/material'
import { Toast } from './Toast'
import { Header } from './Header'
import { DrawerNav } from './DrawerNav'
import { HEADER_HEIGHT } from './constants'
import { Footer } from './Footer'

const MainLayout: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false)

  return (
    <>
      <Box height="100vh" display="flex" flexDirection="column">
        <Box flexShrink={0}>
          <Header
            isSideMenuOpen={isSideMenuOpen}
            setIsSideMenuOpen={setIsSideMenuOpen}
          />
        </Box>
        <Box
          id="scrollable-content"
          mt={HEADER_HEIGHT / 8}
          position="relative"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          style={{ overflowY: 'auto' }}
          height="100%"
        >
          <Box height="100%">{children}</Box>
        </Box>
        <Footer />
      </Box>

      <Toast />

      <Hidden mdUp>
        <SwipeableDrawer
          anchor="right"
          open={isSideMenuOpen}
          onOpen={() => setIsSideMenuOpen(true)}
          onClose={() => setIsSideMenuOpen(false)}
          ModalProps={{
            keepMounted: true,
          }}
        >
          <DrawerNav
            isSideMenuOpen={isSideMenuOpen}
            setIsSideMenuOpen={setIsSideMenuOpen}
          />
        </SwipeableDrawer>
      </Hidden>
    </>
  )
}

export { MainLayout }
