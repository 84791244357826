import { useQuery } from '@apollo/client'
import { QueryHookOptions, QueryResult } from '@apollo/client/react/types/types'
import { useHistory } from 'react-router-dom'
import { Me, UserType } from '../../models/graphqlTypes'
import { GET_ME } from '../../operations/userOperations'
import { useStoreState } from '../../store/store.hooks'
import { useLogout } from '../../components/Login/hooks/useLogout'

type Options = Pick<QueryHookOptions<Me>, 'fetchPolicy'>

export const useMe = (options: Options = {}): QueryResult<Me> => {
  const token = localStorage.getItem('token')
  const history = useHistory()
  const isFirstLogin = useStoreState((state) => state.auth?.isFirstLogin)
  const logout = useLogout()

  return useQuery<Me>(GET_ME, {
    skip: !token,
    onCompleted: ({ me }) => {
      if (me.isLockedOut) {
        return logout()
      }
      if (me.userType === UserType.Doctor && isFirstLogin) {
        history.push(`/${me.userType.toLowerCase()}/profile`)
      }
    },
    onError: () => logout(),
    ...options,
  })
}
