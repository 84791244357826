import { useQuery } from '@apollo/client'
import { LIST_PROFILES } from '../../operations/profileOperations'
import {
  listProfiles,
  listProfilesVariables,
  profileChore,
} from '../../models/graphqlTypes'
import { QueryHookOptions } from '@apollo/client/react/types/types'

type Return = {
  users: profileChore[]
  loading: boolean
}

type Options = QueryHookOptions<listProfiles, listProfilesVariables>

export const useProfileList = (options: Options = {}): Return => {
  const { data: { listProfiles: users = [] } = {}, loading } = useQuery<
    listProfiles,
    listProfilesVariables
  >(LIST_PROFILES, options)

  return {
    users,
    loading,
  }
}
