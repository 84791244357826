import { AddCircle as AddIcon, Clear as ClearIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'

import { ResponsiveTable } from '../../../../common/components/ResponsiveTable'
import {
  DataTableColDef,
  DataTableRowAction,
} from '../../../../common/components/ResponsiveTable/interfaces'
import { useDeleteInvite } from '../../hooks/useDeleteInvite'
import { useInvitedDoctorsByAdmin } from '../../hooks/useInvitedDoctorsByAdmin'
import { InviteDoctorModal } from './InviteDoctorModal'
import { invitedDoctor } from '../../../../models/graphqlTypes'

type InviteTableRowData = {
  id: string
  name: string
  email: string
  lastLogin: null | Date | string
  status: string
  time: string
  createdAt: string
}

const InvitesWidget: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  const [isDeleting, setIsDeleting] = useState<null | string>(null)
  const [inviteDoctorModalOpen, setInviteDoctorModelOpen] = useState(false)

  const {
    invitedDoctors,
    loading,
    refetch: invitesRefetch,
  } = useInvitedDoctorsByAdmin()

  const [deleteInvite, { loading: isDeletingInvite }] = useDeleteInvite({
    onCompleted: () => {
      setIsDeleting(null)
      invitesRefetch()
    },
  })

  const inviteMapper = (invite: invitedDoctor): InviteTableRowData => {
    return {
      id: invite.id,
      name: t('common:formattedNameFull', invite.doctorProfile || ''),
      email: invite.email,
      lastLogin: invite.lastLogin,
      status: invite.lastLogin
        ? t('common:invite.accepted')
        : t('common:invite.pending'),
      time: t('common:formattedDate', {
        date: invite.createdAt,
      }),
      createdAt: invite.createdAt,
    }
  }

  const doctorTableData = invitedDoctors.map(inviteMapper)
  const hideColumns = {
    800: ['email'],
    600: ['email', 'status'],
    400: ['email', 'status', 'createdAt'],
  }

  const columns: DataTableColDef<InviteTableRowData>[] = [
    {
      title: t('patients:name'),
      field: 'name',
    },
    {
      title: t('common:email'),
      field: 'email',
    },
    {
      title: t('common:status'),
      field: 'status',
    },
    {
      title: t('common:invite.time'),
      field: 'time',
      sortComparator: (a, b) => (a.createdAt > b.createdAt ? 1 : -1),
    },
  ]
  const deleteInviteAction: DataTableRowAction<InviteTableRowData> = (
    rowData
  ) => ({
    disabled: !!rowData.lastLogin,
    icon: (
      <ClearIcon
        color={rowData.lastLogin ? 'disabled' : 'error'}
        fontSize="large"
      />
    ),
    tooltip: t('common:invite.delete'),
    onClick: (_event, rowData) => setIsDeleting(rowData.id),
  })

  return (
    <>
      <Box mt={2}>
        <ResponsiveTable
          isLoading={loading}
          hideColumns={hideColumns}
          columns={columns}
          data={doctorTableData}
          title={t('common:invite.doctor.widgetTitle')}
          defaultSort="desc"
          defaultSortBy="time"
          freeActions={[
            {
              icon: <AddIcon color="primary" fontSize="large" />,
              tooltip: t('common:invite.doctor.inviteButton'),
              onClick: () => setInviteDoctorModelOpen(true),
            },
          ]}
          rowActions={[deleteInviteAction]}
        />
      </Box>

      <InviteDoctorModal
        isOpen={inviteDoctorModalOpen}
        setIsOpen={setInviteDoctorModelOpen}
        onComplete={invitesRefetch}
      />

      <Dialog open={!!isDeleting} onClose={() => setIsDeleting(null)}>
        <DialogTitle>{t('common:invite.delete')}</DialogTitle>
        <DialogContent sx={{ width: { sm: 400 } }}>
          <DialogContentText>
            {t('common:invite.confirmDelete')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleting(null)} variant="outlined">
            {t('common:cancel')}
          </Button>
          <Button
            disabled={isDeletingInvite}
            onClick={() =>
              isDeleting &&
              deleteInvite({ variables: { deleteUserId: isDeleting } })
            }
            color="error"
            variant="contained"
          >
            {t('common:delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { InvitesWidget }
