import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  cancelTreatment,
  cancelTreatmentVariables,
} from '../../../../models/graphqlTypes'
import { CANCEL_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<cancelTreatment, cancelTreatmentVariables>

type Return = MutationTuple<cancelTreatment, cancelTreatmentVariables>

export const useTreatmentCancel = (options: Options = {}): Return => {
  return useMutation<cancelTreatment, cancelTreatmentVariables>(
    CANCEL_TREATMENT,
    options
  )
}
