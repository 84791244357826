import React, { Dispatch, SetStateAction } from 'react'
import { useLogout } from '../../components/Login/hooks/useLogout'
import { useUserProfile } from '../hooks/useUserProfile'
import { useTranslation } from '../hooks/helper/useTranslation'
import { Box, IconButton } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DRAWER_WIDTH, HEADER_HEIGHT } from './constants'
import { DrawerNavItem } from './DrawerNavItem'
import { Nav } from './Nav'
import { CloseIcon } from '../icons/CloseIcon'
import { LanguageSelector } from '../components/selectors/LanguageSelector'
import { Avatar } from '../components/Avatar'
import { useMenuItems } from '../hooks/useMenuItems'

const useStyles = makeStyles((theme) => ({
  navButton: {
    color: theme.palette.common.white,
    padding: 0,
  },
}))

type Props = {
  isSideMenuOpen: boolean
  setIsSideMenuOpen: Dispatch<SetStateAction<boolean>>
}
const DrawerNav: React.FC<React.PropsWithChildren<Props>> = ({
  setIsSideMenuOpen,
  isSideMenuOpen,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const logout = useLogout()
  const profile = useUserProfile()
  const menuItems = useMenuItems()

  return (
    <Box
      px={4}
      flexGrow={1}
      display="flex"
      flexDirection="column"
      width={DRAWER_WIDTH}
      bgcolor="common.black"
      color="common.white"
    >
      <Box
        height={HEADER_HEIGHT}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" alignItems="center" mr={1}>
          <Box mr={1}>
            <Avatar
              firstName={profile?.firstName ?? ''}
              lastName={profile?.lastName ?? ''}
              size="tiny"
            />
          </Box>
          {t('common:formattedNameFull', {
            title: profile?.title ?? '',
            firstName: profile?.firstName ?? '',
            lastName: profile?.lastName ?? '',
          })}
        </Box>

        <IconButton
          className={classes.navButton}
          onClick={() => setIsSideMenuOpen(!isSideMenuOpen)}
          size="large"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box mt={2} display="flex" flexDirection="column">
        <Nav isColumn={true} onClick={() => setIsSideMenuOpen(false)} />
      </Box>
      <Box mt={2} flexGrow={1}>
        {menuItems.map(({ link, translationKey, slug }) => (
          <DrawerNavItem
            key={translationKey}
            slug={slug}
            link={link}
            onClick={() => setIsSideMenuOpen(false)}
          >
            {translationKey}
          </DrawerNavItem>
        ))}
        <DrawerNavItem
          slug="logout"
          onClick={() => {
            logout()
            setIsSideMenuOpen(false)
          }}
        >
          auth:logout
        </DrawerNavItem>
        <Box my={4}>
          <LanguageSelector isDark />
        </Box>
      </Box>
    </Box>
  )
}

export { DrawerNav }
