import { Button } from '@mui/material'
import React from 'react'
import { SurveyStatusEnum } from '../../../../../models/graphqlTypes'
import dayjs from 'dayjs'
import { PatientSurvey } from '../../../../patient/PatientEvents/types/patientEvents.types'
import { useTranslation } from 'react-i18next'

interface Props {
  survey: PatientSurvey
}

export const SurveyActionButton: React.FC<React.PropsWithChildren<Props>> = ({
  survey,
}) => {
  const { t } = useTranslation('survey')
  const { status, fillableUntil } = survey

  const hasExpired = dayjs(fillableUntil).isBefore(new Date())

  if (hasExpired && status !== SurveyStatusEnum.Finished) {
    return (
      <Button variant="text" size="small" sx={{ paddingX: 0 }}>
        {t('button.resend')}
      </Button>
    )
  }

  switch (status) {
    case SurveyStatusEnum.Untouched:
      return (
        <Button variant="text" size="small" sx={{ paddingX: 0 }}>
          {t('button.fillout')}
        </Button>
      )
    case SurveyStatusEnum.InProgress:
      return (
        <Button variant="text" size="small" sx={{ paddingX: 0 }}>
          {t('button.continue')}
        </Button>
      )

    case SurveyStatusEnum.Finished:
      return (
        <Button variant="text" size="small" sx={{ paddingX: 0 }}>
          {t('button.result')}
        </Button>
      )
  }
}
