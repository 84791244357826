import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { Box, Divider, Typography, useTheme } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { DayBackground } from './DayBackground'
import { Appointment, Survey } from '../../types/treatments.types'

interface MonthView {
  month?: string
  visibleAppointments: Appointment[]
  visibleSurveys: Survey[]
  hoveredDay: string | null
  setHoveredDay: React.Dispatch<React.SetStateAction<string | null>>
}

const useStyles = makeStyles((theme) => ({
  appointment: {
    marginTop: 1,
    marginBottom: 1,
    '&:first-child': {
      marginTop: 0,
      borderTopLeftRadius: theme.spacing(1),
      borderTopRightRadius: theme.spacing(1),
    },
    '&:last-child': {
      borderBottomLeftRadius: theme.spacing(1),
      borderBottomRightRadius: theme.spacing(1),
      marginBottom: 0,
    },
  },
  day: {
    color: theme.palette.grey[600],
    borderWidth: 0,
    borderStyle: 'solid',
  },
  today: {
    borderWidth: 1,
    borderRadius: theme.spacing(1),
  },
  hovered: {
    borderRadius: theme.spacing(0.75),
    borderWidth: 2,
  },
  pointer: {
    cursor: 'pointer',
  },
  weekend: {
    color: theme.palette.grey[300],
  },
}))

const PlainMonthView: React.FC<React.PropsWithChildren<MonthView>> = ({
  month,
  visibleAppointments,
  visibleSurveys,
  hoveredDay,
  setHoveredDay,
}) => {
  const rows = [0, 1, 2, 3, 4, 5]
  const days = [1, 2, 3, 4, 5, 6, 7]
  const theme = useTheme()
  const classes = useStyles()

  const monthObj = dayjs(month)
  const startOfMonth = monthObj.startOf('month')
  const endOfMonth = monthObj.endOf('month')
  const startingDay = startOfMonth.day() || 7
  const yearString = monthObj.format('YYYY.')
  const monthString = monthObj.format('MMMM')
  const monthStart = dayjs().startOf('month')
  const isCurrentMonth = dayjs(month).isSame(monthStart, 'month')

  const uppercasedMonth = monthString[0].toUpperCase() + monthString.slice(1)
  const calendarTitle = `${yearString} ${uppercasedMonth}`

  const getDateOfAppointmentOrSurvey = (item: Appointment | Survey): Date =>
    item.__typename === 'Appointment' ? item.proposedDate : item.fillableFrom

  const eventsInMonth = [...visibleAppointments, ...visibleSurveys].filter(
    (item) =>
      dayjs(getDateOfAppointmentOrSurvey(item)).isSame(monthObj, 'month')
  )

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box display="flex" alignItems="baseline" mb={0.5} px={1} width="100%">
        <Typography variant="body2">{calendarTitle}</Typography>
        <Box flexGrow={1} ml={0.5}>
          <Divider />
        </Box>
      </Box>
      {rows.map((row) => {
        return (
          <Box key={row} display="flex" pt={0.5}>
            {days.map((day) => {
              const date = row * 7 + day - startingDay + 1
              const actualDate = startOfMonth.date(date)
              const isPreviousMonth = actualDate < startOfMonth
              const isNextMonth = actualDate > endOfMonth
              const isToday = dayjs().isSame(actualDate, 'day')
              const isNotThisMonth = isPreviousMonth || isNextMonth

              const isHovered =
                hoveredDay && dayjs(hoveredDay).isSame(actualDate, 'day')

              const eventThisDay = eventsInMonth
                .filter((item) =>
                  dayjs(getDateOfAppointmentOrSurvey(item)).isSame(
                    actualDate,
                    'day'
                  )
                )
                .slice(0, 5)

              const hasEvent = eventThisDay.length > 0
              const hoverDateString = dayjs(actualDate).format(
                '[calendar]:YYYY-MM-DD'
              )

              return (
                <Box
                  key={day}
                  width={32}
                  height={32}
                  position="relative"
                  onMouseEnter={
                    hasEvent ? () => setHoveredDay(hoverDateString) : undefined
                  }
                  onMouseLeave={
                    hasEvent ? () => setHoveredDay(null) : undefined
                  }
                >
                  <Box
                    position="absolute"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textAlign="center"
                    width="100%"
                    height="100%"
                    className={clsx(classes.day, {
                      [classes.today]: isToday && isCurrentMonth,
                      [classes.hovered]: isHovered && !isNotThisMonth,
                      [classes.pointer]: hasEvent,
                      [classes.weekend]: day > 5,
                    })}
                    zIndex={1}
                  >
                    <Typography variant="body2">
                      {isNotThisMonth ? '' : date.toString().padStart(2, '0')}
                    </Typography>
                  </Box>
                  <DayBackground
                    marginStyles={classes.appointment}
                    theme={theme}
                    actualDate={actualDate}
                    eventThisDay={eventThisDay}
                  />
                </Box>
              )
            })}
          </Box>
        )
      })}
    </Box>
  )
}

const MonthView = React.memo(PlainMonthView)

export { MonthView }
