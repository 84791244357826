import { Box, Typography, Card, useTheme } from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'

interface PageWithScrollableCardProps {
  pageTitle: string
  markdownTextInCard: string
}

const PageWithScrollableCard: React.FC<
  React.PropsWithChildren<PageWithScrollableCardProps>
> = ({ pageTitle, markdownTextInCard }) => {
  const theme = useTheme()

  return (
    <Box
      bgcolor={theme.palette.grey[200]}
      display="flex"
      flexDirection="column"
      flex={1}
      overflow="hidden"
    >
      <Box
        px={{ xs: 2, md: 3 }}
        pt={1.375}
        pb={1.5}
        flexShrink={0}
        display="flex"
        alignItems="center"
        borderBottom={`1px solid ${theme.palette.grey[300]}`}
      >
        <Typography variant="h6">{pageTitle}</Typography>
      </Box>

      <Box overflow="hidden" display="flex" flex={1}>
        <Card
          elevation={0}
          sx={{
            marginY: 2,
            marginX: { xs: 2, md: 12 },
          }}
        >
          <Box
            pl={5}
            pr={{ xs: 5, lg: 20 }}
            py={3}
            sx={{ overflow: 'auto', height: '100%' }}
          >
            <ReactMarkdown>{markdownTextInCard}</ReactMarkdown>
          </Box>
        </Card>
      </Box>
    </Box>
  )
}

export { PageWithScrollableCard }
