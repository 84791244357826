import { Stack, Grid, Typography, Radio, Box } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import get from 'lodash/get'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import {
  InputAspectAnswer,
  ScaleOptionInput,
} from '../../../../../models/graphqlTypes'
import { getDefaultScaleMinMax } from '../../utils/question.util'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { AspectInForm } from '../Questions/questions.types'
import { MultilineTextWithTooltip } from '../../../../../common/components/MultilineTextWithTooltip/MultilineTextWithTooltip'

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
  },
})

interface Props {
  question: QuestionInSection
  answerIndex: number
  isPreview: boolean
  readOnly?: boolean
}

export const FillScaleQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  answerIndex,
  isPreview,
  readOnly,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    setValue,
    watch,
    formState: { errors },
    control,
  } = useFormContext()

  const scaleOptions = question.data?.scaleData?.options ?? []
  const { scaleMin, scaleMax } = scaleOptions.length
    ? getDefaultScaleMinMax(scaleOptions)
    : { scaleMin: 1, scaleMax: 1 }

  const arrayToGenerate = Array.from(
    { length: scaleMax - scaleMin + 1 },
    (_, i) => i + scaleMin
  )

  const { aspects = [] } = question
  const pathToAnswer = `answers.${answerIndex}.scaleQuestionAnswer.aspectAnswers`
  const aspectAnswers: InputAspectAnswer[] = watch(pathToAnswer) ?? []
  const setAspectAnswers = (newAnswers: InputAspectAnswer[]) =>
    setValue(pathToAnswer, newAnswers)

  const errorToAnswer = get(errors, pathToAnswer)

  const findChosenValue = (
    aspect: AspectInForm,
    index: number
  ): number | undefined => {
    const aspectAnswer = aspectAnswers.find(
      // OR needed beacuse aspectId can be empty as aspect is not mandatory
      // If we are in preview mode and question didn't saved yet, there is no idInDb
      (answer) =>
        answer.aspectId === aspect.idInDb ||
        !answer.aspectId ||
        answer.aspectId === index.toString()
    )
    return aspectAnswer?.scaleValue
  }

  const chooseValue = (idInDb: string, value: number, index: number) => {
    // For not saved questions id not not defined
    // so we use index to identify them
    const aspectId = !!idInDb ? idInDb : index.toString()
    const isAspectIdInAnswers = aspectAnswers.find(
      (a) => a.aspectId === aspectId
    )
    const newAspectAnswers: InputAspectAnswer[] = isAspectIdInAnswers
      ? aspectAnswers.map((a: InputAspectAnswer) => {
          if (aspectId === a.aspectId) {
            return { aspectId, scaleValue: value }
          }
          return a
        })
      : [...aspectAnswers, { aspectId, scaleValue: value }]
    setAspectAnswers(newAspectAnswers)
  }

  const firstGridItemSize = arrayToGenerate.length > 10 ? 1 : 2

  return (
    <Stack>
      <Grid container alignItems="flex-end" mt={1}>
        <Grid item xs={firstGridItemSize} />

        {question.data?.scaleData?.options.map(
          (option: ScaleOptionInput, i: number) => (
            <Grid item xs={1} key={option.value}>
              <Stack spacing={1}>
                <Box
                  px={1}
                  id={`option_${i}`}
                  className={classes.multiLineEllipsis}
                >
                  <MultilineTextWithTooltip
                    text={option.label || ''}
                    parentId={`option_${i}`}
                    textAlign="center"
                    variant="subtitle2"
                  />
                </Box>
                <Typography textAlign="center" variant="body1">
                  {option.value}
                </Typography>
              </Stack>
            </Grid>
          )
        )}
      </Grid>

      {aspects.map((aspect: AspectInForm, i: number) => (
        <Controller
          key={`aspect_${i}`}
          name={pathToAnswer}
          control={control}
          rules={{
            validate: () =>
              !isPreview &&
              question.isRequired &&
              findChosenValue(aspect, i) === undefined
                ? (t('messages:warnings.requiredQuestion') as string)
                : true,
          }}
          render={({ field: { onBlur } }) => (
            <Grid container alignItems="center">
              <Grid
                item
                xs={firstGridItemSize}
                display="flex"
                justifyContent="flex-end"
                className={classes.multiLineEllipsis}
                id={`aspect_${i}`}
              >
                <MultilineTextWithTooltip
                  text={aspect.name}
                  parentId={`aspect_${i}`}
                />
              </Grid>

              {arrayToGenerate.map((value: number, valueIndex: number) => (
                <Grid
                  item
                  xs={1}
                  key={valueIndex}
                  display="flex"
                  justifyContent="center"
                >
                  <Radio
                    onBlur={onBlur}
                    checked={findChosenValue(aspect, i) === value}
                    onChange={() => chooseValue(aspect.idInDb || '', value, i)}
                    disabled={readOnly}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        />
      ))}
      {!!errorToAnswer && (
        <Typography variant="subtitle2" color="error">
          {errorToAnswer?.message?.toString()}
        </Typography>
      )}
    </Stack>
  )
}
