import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { Box, useTheme, Fade } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { useParams } from 'react-router-dom'

import { useQuery } from '@apollo/client'
import { getPartners } from '../../models/graphqlTypes'
import { GET_PARTNERS } from '../../operations/messageOperations'

import { MessageBoardPartners } from './MessageBoardPartners'
import { MessageBoardChat } from './MessageBoardChat'
import { Partner } from './messageBoard.types'

type RouteParams = {
  partnerId?: string
}

const MessageBoard: React.FC<React.PropsWithChildren<unknown>> = () => {
  const history = useHistory()
  const theme = useTheme()
  const { partnerId } = useParams<RouteParams>()
  const [selectedPartner, setSelectedPartner] = useState<
    Partner | null | undefined
  >()

  const { data: partnersData } = useQuery<getPartners>(GET_PARTNERS, {
    fetchPolicy: 'network-only',
  })

  const partners = useMemo(() => {
    return partnersData?.getPartners
      .slice()
      .sort(
        (firstPartner, secondPartner) =>
          Number(new Date(firstPartner.lastMessageAt)) -
          Number(new Date(secondPartner.lastMessageAt))
      )
      .reverse()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnersData?.getPartners])

  const selectPartner = useCallback(
    (partner?: Partner | null) => {
      setSelectedPartner(partner)
      history.push(`/messages/${partner ? partner.id : ''}`)
    },
    [history]
  )

  useEffect(() => {
    if (partnerId && partners) {
      selectPartner(partners.find((partner) => partner.id === partnerId))
    }
  }, [partnerId, partners, selectPartner])

  return (
    <>
      <Box display="flex" flexGrow={1} position="relative" height="100%">
        <Box
          padding={2}
          borderRight={{
            xs: 'none',
            md: `1px solid ${theme.palette.grey[300]}`,
          }}
          width={{ xs: '100%', md: 350 }}
          style={{ overflowY: 'auto' }}
          flexShrink={0}
        >
          <MessageBoardPartners
            partners={partners}
            onSelectPartner={(partner) => selectPartner(partner)}
            selectedPartnerId={selectedPartner?.id}
          />
        </Box>
        <Fade in={!!selectedPartner}>
          <Box
            flexGrow={1}
            height="100%"
            width={{ xs: '100%', md: 'auto' }}
            maxWidth={1250}
            position={{ xs: 'absolute', md: 'initial' }}
            bgcolor={theme.palette.common.white}
          >
            {selectedPartner && (
              <MessageBoardChat
                partner={selectedPartner}
                onBack={() => selectPartner(null)}
              />
            )}
          </Box>
        </Fade>
      </Box>
    </>
  )
}

export { MessageBoard }
