import { useMutation } from '@apollo/client'
import { ADD_SCHEMA_DEPENDENCY } from '../operations/treatmentSchemaOperations'
import { MutationTuple } from '@apollo/client/react/types/types'
import {
  addSchemaDependency,
  addSchemaDependencyVariables,
} from '../../../../models/graphqlTypes'

type Return = MutationTuple<addSchemaDependency, addSchemaDependencyVariables>

export const useSchemaDependencyAdd = (): Return => {
  return useMutation(ADD_SCHEMA_DEPENDENCY)
}
