import { useMemo } from 'react'
import { isNumber } from 'lodash'
import { TreatmentSchemaSchedule } from '../types/treatmentSchemas.types'

type ScheduleGroups = {
  hasCustomDays: TreatmentSchemaSchedule[]
  notSpecified: TreatmentSchemaSchedule[]
}

/**
 * Separates a schedules list to two lists.
 * hasCustomDays: Have specific custom days, (explicitly set or has a dependency)
 * notSpecified: there isn't any time defining information of the schedule
 * @param schedules The schedules of a treatment fetched from BE
 * @returns ScheduleGroups
 */
export const groupSchedules = (
  schedules: TreatmentSchemaSchedule[]
): ScheduleGroups =>
  schedules.slice().reduce<{
    hasCustomDays: TreatmentSchemaSchedule[]
    notSpecified: TreatmentSchemaSchedule[]
  }>(
    (acc, schedule) => {
      // Note: if one has customDays, then it should have dependencies, but there are schedules that only have customDays, without dependencies
      const hasCustomDays = isNumber(schedule.customDays)
      if (hasCustomDays) {
        acc.hasCustomDays.push(schedule)
      } else {
        acc.notSpecified.push(schedule)
      }
      return acc
    },
    { hasCustomDays: [], notSpecified: [] }
  )

/** Sorts the schedules so that independent (not connected to any other) schedules will be at the end of the list */
export const useSortedSchemaSchedules = (
  schedules: TreatmentSchemaSchedule[]
): TreatmentSchemaSchedule[] => {
  return useMemo(() => {
    const { hasCustomDays, notSpecified } = groupSchedules(schedules)

    // sorts the schedules based on their customDays in an increasing order
    const sortedSpecified = hasCustomDays.sort((a, b) => {
      return (a.customDays ?? 0) - (b.customDays ?? 0)
    })

    // sorts not specified schedules by their created time
    const sortedNotSpecified = notSpecified.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    })
    return [...sortedSpecified, ...sortedNotSpecified]
  }, [schedules])
}
