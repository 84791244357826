import { useQuery } from '@apollo/client'
import {
  getInvitedDoctorsByAdmin,
  invitedDoctor,
} from '../../../models/graphqlTypes'
import { GET_INVITED_DOCTORS_BY_ADMIN } from '../operations/admin.operations'

interface ReturnData {
  invitedDoctors: invitedDoctor[]
  loading: boolean
  refetch: () => void
}

export const useInvitedDoctorsByAdmin = (): ReturnData => {
  const { data, loading, refetch } = useQuery<getInvitedDoctorsByAdmin>(
    GET_INVITED_DOCTORS_BY_ADMIN
  )

  return {
    invitedDoctors: data?.getInvitedDoctorsByAdmin || [],
    loading,
    refetch,
  }
}
