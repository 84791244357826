import { Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { FileDisplay } from '../FillQuestion/FileDisplay'
import { UploadFileInput } from '../FillQuestion/fileUpload.types'
import { FileUploadQuestionResults } from '../../types/surveyStore.types'

interface Props {
  questionResult: FileUploadQuestionResults
}

export const FileUploadQuestionResult: React.FC<
  React.PropsWithChildren<Props>
> = ({ questionResult }) => {
  const { t } = useTranslation()
  const { documents, totalSubmitters } = questionResult

  const files: UploadFileInput[] = documents.map((doc) => ({
    file: new File([], doc.fileName),
    documentId: doc.documentId,
  }))

  return (
    <>
      <Typography variant="subtitle2" sx={{ my: 1 }}>
        {t('survey:result.numberOfAnswers', {
          numberOfAnswers: totalSubmitters,
        })}
      </Typography>
      <FileDisplay files={files} />
    </>
  )
}
