import { Typography, Grid, Radio, Box, Checkbox } from '@mui/material'
import isFunction from 'lodash/isFunction'
import get from 'lodash/get'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { ChoiceInForm } from '../Questions/questions.types'

interface Props {
  question: QuestionInSection
  setNextSectionOrder?: (order: number) => void
  answerIndex: number
  isPreview: boolean
  readOnly?: boolean
}

export const FillMultipleChoiceQuestion: React.FC<
  React.PropsWithChildren<Props>
> = ({ question, setNextSectionOrder, answerIndex, isPreview, readOnly }) => {
  const { t } = useTranslation()
  const {
    setValue,
    watch,
    formState: { errors },
    control,
  } = useFormContext()

  const multipleChoice = question.data?.multipleChoice

  const pathToAnswer = `answers.${answerIndex}.mcQuestionAnswer.selectedChoiceIds`
  const chosenChoiceIds: string[] = watch(pathToAnswer) ?? []
  const setChosenChoiceId = (newId: string[]) => setValue(pathToAnswer, newId)
  const errorToAnswer = get(errors, pathToAnswer)

  const chooseChoice = (
    choice: ChoiceInForm,
    index: number,
    question: QuestionInSection
  ) => {
    // For not saved choices id is not defined
    // so we use question.idForRender + index to identify them
    const choiceId = !!choice.idInDb
      ? choice.idInDb
      : `${question.idForRender}-${index.toString()}`

    if (multipleChoice) {
      setChosenChoiceId(
        chosenChoiceIds.includes(choiceId)
          ? chosenChoiceIds.filter((c) => c !== choiceId)
          : [...chosenChoiceIds, choiceId]
      )
    } else {
      if (!!choice.idInDb) {
        const otherChoiceIdsForSameQuestion =
          question.choices?.map((c) => c.idInDb) ?? []
        setChosenChoiceId([
          ...chosenChoiceIds.filter(
            (c) => !otherChoiceIdsForSameQuestion.includes(c)
          ),
          choiceId,
        ])
      } else {
        setChosenChoiceId([
          ...chosenChoiceIds.filter((c) => !c.includes(question.idForRender)),
          choiceId,
        ])
      }
    }
  }

  const getIsChoiceChecked = (choice: ChoiceInForm, index: number) =>
    chosenChoiceIds.includes(choice.idInDb as string) ||
    chosenChoiceIds.includes(`${question.idForRender}-${index.toString()}`)

  return (
    <Box>
      <Controller
        name={pathToAnswer}
        control={control}
        rules={{
          validate: () =>
            !isPreview && question.isRequired && chosenChoiceIds?.length < 1
              ? (t('messages:warnings.requiredQuestion') as string)
              : true,
        }}
        defaultValue={chosenChoiceIds}
        render={({ field: { onBlur } }) => (
          <Box mt={1}>
            {question.choices?.map((choice: ChoiceInForm, index: number) => (
              <Grid key={`choice_${index}`} container alignItems="center">
                <Grid item xs={1} display="flex" justifyContent="center">
                  {multipleChoice ? (
                    <Checkbox
                      onBlur={onBlur}
                      onClick={() => {
                        chooseChoice(choice, index, question)
                      }}
                      checked={getIsChoiceChecked(choice, index)}
                      disabled={readOnly}
                    />
                  ) : (
                    <Radio
                      onBlur={onBlur}
                      onClick={() => {
                        chooseChoice(choice, index, question)
                        if (
                          choice.nextSectionOrder &&
                          isFunction(setNextSectionOrder)
                        ) {
                          setNextSectionOrder(choice.nextSectionOrder)
                        }
                      }}
                      checked={getIsChoiceChecked(choice, index)}
                      disabled={readOnly}
                    />
                  )}
                </Grid>
                <Grid item xs>
                  <Typography variant="body1">{choice.name}</Typography>
                </Grid>
              </Grid>
            ))}
          </Box>
        )}
      />

      {!!errorToAnswer && (
        <Typography variant="subtitle2" color="error">
          {errorToAnswer?.message?.toString()}
        </Typography>
      )}
    </Box>
  )
}
