import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveyById,
  getSurveyByIdVariables,
} from '../../../../models/graphqlTypes'
import { GET_PATIENT_SURVEY_BY_ID } from '../operations/patientSurveyOperations'
import { PatientSurveyById } from '../types/patientEvents.types'

type Options = QueryHookOptions<getSurveyById, getSurveyByIdVariables>

type Return = {
  survey?: PatientSurveyById
  loading: boolean
  refetch: () => void
}
export const usePatientSurveyToFill = (options: Options = {}): Return => {
  const {
    data: { getSurvey: survey } = {},
    loading,
    refetch,
  } = useQuery<getSurveyById, getSurveyByIdVariables>(
    GET_PATIENT_SURVEY_BY_ID,
    options
  )

  return {
    loading,
    survey,
    refetch,
  }
}
