import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const UsersIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" width="16" height="16" {...props}>
      <g transform="translate(-30.000000, -262.000000)" fillRule="nonzero">
        <g transform="translate(30.000000, 260.000000)">
          <g transform="translate(0.000000, 2.000000)">
            <path d="M7.66666667,5.5 C6.15,5.5 4.91666667,4.26666667 4.91666667,2.75 C4.91666667,2.36333333 4.99733333,1.98666667 5.15733333,1.63 C5.15733333,1.63 5.16533333,1.61266667 5.16866667,1.60666667 C5.622,0.627333333 6.59933333,0 7.66666667,0 C9.18333333,0 10.4166667,1.23333333 10.4166667,2.75 C10.4166667,2.764 10.416,2.778 10.4146667,2.792 C10.4173333,2.82933333 10.4153333,2.86666667 10.4086667,2.904 C10.328,4.36133333 9.12666667,5.5 7.66666667,5.5 Z M5.91866667,2.67666667 C5.91733333,2.70133333 5.91666667,2.72533333 5.91666667,2.75 C5.91666667,3.71466667 6.702,4.5 7.66666667,4.5 C8.36466667,4.5 8.98733333,4.08133333 9.26466667,3.464 C9.066,3.488 8.86733333,3.5 8.67,3.5 C7.688,3.5 6.728,3.21 5.91866667,2.67666667 Z M6.27133333,1.69866667 C6.96066667,2.21666667 7.8,2.5 8.66,2.5 C8.90333333,2.5 9.14533333,2.47733333 9.386,2.43333333 C9.236,1.61466667 8.518,1 7.66666667,1 C7.118,1 6.60066667,1.26333333 6.27133333,1.69866667 Z" />
            <path d="M4.96533333,7.5 C4.82466667,7.5 4.68933333,7.44066667 4.59533333,7.336 C4.41,7.132 4.42533333,6.81533333 4.62933333,6.63 C5.466,5.86933333 6.54533333,5.45066667 7.66666667,5.45066667 C8.788,5.45066667 9.86733333,5.86933333 10.7046667,6.63 C10.9086667,6.81533333 10.924,7.132 10.7386667,7.336 C10.644,7.44 10.5093333,7.5 10.3686667,7.5 C10.244,7.5 10.1246667,7.454 10.0326667,7.37 C9.38,6.77733333 8.53933333,6.45066667 7.66666667,6.45066667 C6.794,6.45066667 5.95333333,6.77733333 5.30133333,7.37 C5.20933333,7.454 5.08933333,7.5 4.96533333,7.5 Z" />
            <path d="M12.3333333,13.5 C10.8173333,13.5 9.58333333,12.266 9.58333333,10.75 C9.58333333,10.364 9.664,9.98733333 9.82333333,9.63066667 C9.82333333,9.63066667 9.83133333,9.614 9.83466667,9.60733333 C10.2873333,8.62866667 11.2653333,8 12.3333333,8 C13.8493333,8 15.0833333,9.234 15.0833333,10.75 C15.0833333,10.764 15.0826667,10.778 15.0813333,10.792 C15.084,10.8293333 15.082,10.8666667 15.0753333,10.904 C14.9946667,12.3613333 13.7933333,13.5 12.3333333,13.5 Z M10.5853333,10.674 C10.584,10.6993333 10.5833333,10.7246667 10.5833333,10.7506667 C10.5833333,11.716 11.368,12.5006667 12.3333333,12.5006667 C13.032,12.5006667 13.6546667,12.0813333 13.9313333,11.4633333 C13.7366667,11.4866667 13.542,11.498 13.348,11.498 C12.3673333,11.498 11.4006667,11.2073333 10.5853333,10.674 Z M10.9373333,9.69933333 C11.628,10.2146667 12.4753333,10.496 13.3466667,10.496 C13.5833333,10.496 13.82,10.4746667 14.052,10.4333333 C13.902,9.614 13.1846667,9 12.3333333,9 C11.784,9 11.266,9.26333333 10.9373333,9.69933333 Z" />
            <path d="M9.16666667,16.0006667 C9.056,16.0006667 8.94666667,15.9633333 8.85933333,15.8946667 C8.754,15.8126667 8.68666667,15.694 8.67066667,15.5613333 C8.654,15.4286667 8.69066667,15.298 8.77266667,15.1926667 C8.99933333,14.902 9.262,14.6386667 9.554,14.4113333 C10.354,13.7866667 11.314,13.4566667 12.3293333,13.4566667 C13.734,13.4566667 15.0333333,14.0893333 15.8946667,15.1926667 C15.9766667,15.298 16.0133333,15.4286667 15.9966667,15.5613333 C15.9806667,15.694 15.9133333,15.812 15.808,15.8946667 C15.7206667,15.9626667 15.6113333,16 15.5006667,16 C15.3453333,16 15.202,15.93 15.106,15.8073333 C14.4353333,14.9486667 13.4233333,14.456 12.3293333,14.456 C11.5386667,14.456 10.792,14.7126667 10.1686667,15.1993333 C9.942,15.3766667 9.73733333,15.5813333 9.56066667,15.8073333 C9.46533333,15.93 9.322,16.0006667 9.16666667,16.0006667 Z" />
            <path d="M3.66666667,13.5 C2.15,13.5 0.916666667,12.266 0.916666667,10.75 C0.916666667,10.3633333 0.997333333,9.98666667 1.15733333,9.63066667 C1.15733333,9.63066667 1.16466667,9.61466667 1.16866667,9.608 C1.62066667,8.628 2.59866667,8 3.66666667,8 C5.18333333,8 6.41666667,9.234 6.41666667,10.75 C6.41666667,10.764 6.416,10.778 6.41466667,10.792 C6.41733333,10.8293333 6.41533333,10.8666667 6.40866667,10.904 C6.328,12.3613333 5.12666667,13.5 3.66666667,13.5 Z M1.91866667,10.674 C1.91733333,10.6993333 1.91666667,10.7246667 1.91666667,10.7506667 C1.91666667,11.716 2.702,12.5006667 3.66666667,12.5006667 C4.36533333,12.5006667 4.988,12.0813333 5.26466667,11.4633333 C5.07,11.4866667 4.87533333,11.498 4.68133333,11.498 C3.70066667,11.498 2.734,11.2073333 1.91866667,10.674 Z M2.27066667,9.69933333 C2.96133333,10.2146667 3.80866667,10.496 4.68066667,10.496 C4.91733333,10.496 5.154,10.4753333 5.386,10.4333333 C5.236,9.61466667 4.518,9 3.66666667,9 C3.11733333,9 2.59933333,9.264 2.27066667,9.69933333 Z" />
            <path d="M6.83333333,16.0006667 C6.678,16.0006667 6.534,15.9306667 6.43866667,15.808 C5.76866667,14.9493333 4.756,14.4566667 3.662,14.4566667 C2.87133333,14.4566667 2.12466667,14.7133333 1.502,15.2 C1.27533333,15.3766667 1.07066667,15.5813333 0.894,15.808 C0.798,15.93 0.654666667,16 0.499333333,16 C0.388666667,16 0.279333333,15.9626667 0.192,15.894 C0.0866666667,15.812 0.02,15.6933333 0.00333333333,15.5606667 C-0.0133333333,15.428 0.0233333333,15.2973333 0.105333333,15.192 C0.332666667,14.9013333 0.595333333,14.638 0.886666667,14.4106667 C1.68666667,13.786 2.64666667,13.456 3.662,13.456 C5.06666667,13.456 6.366,14.0886667 7.22733333,15.192 C7.30933333,15.2966667 7.346,15.428 7.32933333,15.5606667 C7.31266667,15.6933333 7.246,15.8113333 7.14066667,15.894 C7.05333333,15.9626667 6.944,16.0006667 6.83333333,16.0006667 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { UsersIcon }
