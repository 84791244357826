import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'

import { Box, Collapse, TextField } from '@mui/material'

import { FormProvider, useForm, Controller } from 'react-hook-form'
import { institutionChore, roomChore } from '../../../models/graphqlTypes'
import { Alert } from '@mui/material'

import { BoxWithLabel } from '../BoxWithLabel'
import { SaveButton } from '../SaveButton'
import { useEeszPasswordLogin } from '../../hooks/eeszt/useEesztPasswordLogin'
import { InstitutionSelector } from '../selectors/InstitutionSelector'
import { RoomSelector } from '../selectors/RoomSelector'

type FormValues = {
  eesztPassword: string
  institution: institutionChore | null
  room: roomChore | null
}

export const EesztPasswordLogin: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      eesztPassword: '',
      institution: null,
      room: null,
    },
  })

  const {
    control,
    watch,
    clearErrors,
    handleSubmit,
    formState: { errors, isValid },
  } = methods

  const institution = watch('institution')

  const { loginToEeszt, loading, error } = useEeszPasswordLogin()

  const errorText = error?.message.replace(/[\W_]/g, '-')

  const onSubmit = handleSubmit(({ eesztPassword, institution, room }) => {
    if (!institution || !room) {
      return Promise.reject()
    }
    return loginToEeszt({
      variables: {
        stsLoginInput: { password: eesztPassword },
        institutionId: institution.id,
        institutionUnitId: room.id,
      },
    })
  })

  return (
    <FormProvider {...methods}>
      <Box mb={1}>
        <Alert severity="info">{t(`eeszt:auth.notLoggedInMessage`)}</Alert>
      </Box>
      <InstitutionSelector
        label={t('institution:name')}
        multiple={false}
        name="institution"
        queryOptions={{
          fetchPolicy: 'network-only',
          variables: { hasEESZTCertificate: true },
        }}
      />
      <RoomSelector
        name="room"
        disabled={!institution}
        rooms={institution?.rooms ?? []}
      />
      <BoxWithLabel label={t(`eeszt:auth.password`)}>
        <Controller
          name="eesztPassword"
          rules={{ required: 'messages:warnings.emptyField' }}
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              onChange={(e) => {
                field.onChange(e)
                clearErrors('eesztPassword')
              }}
              size="small"
              placeholder={t(`eeszt:auth.placeholder.password`)}
              type="password"
              error={!!errors.eesztPassword}
              helperText={
                errors.eesztPassword?.message
                  ? t(errors.eesztPassword?.message)
                  : undefined
              }
              value={watch('eesztPassword')}
              required
              fullWidth
            />
          )}
        />
      </BoxWithLabel>
      <Collapse in={!!error} unmountOnExit>
        <Box mt={1}>
          <Alert severity="error">{t(`eeszt:authError.${errorText}`)}</Alert>
        </Box>
      </Collapse>

      <Box display="flex" justifyContent="flex-end" mt={2}>
        <SaveButton
          fullWidth
          disabled={loading || !isValid}
          isSaving={loading}
          onClick={onSubmit}
          text={t('eeszt:auth.login')}
          inProgressText={t('eeszt:auth.inProgress')}
          completedIcon={error ? <></> : undefined}
          completedText={
            error ? t('eeszt:auth.errored') : t('eeszt:auth.complete')
          }
        />
      </Box>
    </FormProvider>
  )
}
