import React, { useState } from 'react'
import { Box, Card, CardContent, Stack, Typography } from '@mui/material'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  generateWeeks,
  useCalendarColors,
} from './ScheduleCalendar/calendarUtils'
import { ScheduleCalendarDay } from './ScheduleCalendar/ScheduleCalendarDay'
import { EventType, Week, Day } from './ScheduleCalendar/scheduleCalendar.types'
import { LocalSchedule, LocalSurvey } from './treatmentSchemaSchedule.types'
import { OPTIMAL_DURATION_MAX_LIMIT } from '../../../../config'
import { Waypoint } from 'react-waypoint'

const WEEKS_LIMIT = 20

interface Props {
  optimalDuration: number | null
  localSchedules: Array<LocalSchedule | LocalSurvey>
}

export const ScheduleCalendar: React.FC<React.PropsWithChildren<Props>> = ({
  optimalDuration,
  localSchedules,
}) => {
  const { t } = useTranslation()
  const calendarColors = useCalendarColors()
  const [weekLimit, setWeekLimit] = useState(WEEKS_LIMIT)

  const weeks = generateWeeks(
    Number(
      !optimalDuration || optimalDuration <= OPTIMAL_DURATION_MAX_LIMIT
        ? optimalDuration
        : OPTIMAL_DURATION_MAX_LIMIT
    ),
    localSchedules
  )

  return (
    <>
      <Card sx={{ minWidth: '520px' }}>
        <CardContent sx={{ padding: 3 }}>
          <Typography variant="h6">{t('treatment:calendar.title')}</Typography>
          <Stack
            direction="row"
            margin={2}
            marginBottom={3}
            justifyContent="space-around"
            gap={3}
          >
            {Object.keys(EventType).map((event: string, i: number) => (
              <Stack direction="row" key={i} gap={1}>
                <Box
                  sx={{
                    width: 40,
                    height: 24,
                    bgcolor: calendarColors[event as EventType],
                    borderRadius: 0.5,
                  }}
                />
                {t(`treatment:calendar.${event}`)}
              </Stack>
            ))}
          </Stack>
          {weeks.slice(0, weekLimit).map((week: Week, rowIndex: number) => (
            <Stack
              direction="row"
              key={`week_${rowIndex}`}
              alignItems="center"
              justifyContent="center"
              mb={(rowIndex + 1) % 4 === 0 ? 4 : 1.5}
            >
              <Typography width={80}>
                {t('treatment:protocol.week', {
                  weekNumber: rowIndex + 1,
                })}
              </Typography>
              {week.map((day: Day, colIndex: number) => (
                <ScheduleCalendarDay key={`day_${colIndex}`} day={day} />
              ))}
            </Stack>
          ))}
          <Waypoint
            onEnter={() => setWeekLimit((weekLimit) => weekLimit + WEEKS_LIMIT)}
          />
        </CardContent>
      </Card>
    </>
  )
}
