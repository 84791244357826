import dayjs from 'dayjs'
import duration, { Duration, DurationUnitType } from 'dayjs/plugin/duration'

dayjs.extend(duration)

type Return = {
  value: number
  unit: DurationUnitType
}

export const parseDistance = (distance: string): Return => {
  const unitIndex = distance.length - 1
  const value = +distance.slice(0, unitIndex)
  const unit = distance.slice(unitIndex) as DurationUnitType
  return { value, unit }
}

export const parseDistanceAsDuration = (distance: string): Duration => {
  const parsedDistance = parseDistance(distance)
  return dayjs.duration(parsedDistance.value, parsedDistance.unit)
}
