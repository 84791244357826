import { Grid, GridProps, GridSize, Typography } from '@mui/material'
import React, { FC } from 'react'
import { useBetmenList } from './BetmenList'

export interface BetmenListItemCardCellProps extends GridProps {
  gridSize?: GridSize
  cellindex?: number
}

export const BetmenListItemCardCell: FC<
  React.PropsWithChildren<BetmenListItemCardCellProps>
> = ({ children, gridSize = 'auto', ...props }) => {
  const { gridColumnSizes } = useBetmenList()
  // "cellindex" assigned to props in BetmenListItemCard
  const indexOfComponent = (props as typeof props & { cellindex: number })
    .cellindex
  const inheritedGridSize = gridColumnSizes?.[indexOfComponent]

  return (
    <Grid
      item
      xs={inheritedGridSize || gridSize}
      sx={{ pr: props.pr ?? 2, ...props?.sx }}
      {...props}
    >
      {typeof children === 'string' ? (
        <Typography variant="body2">{children}</Typography>
      ) : (
        children
      )}
    </Grid>
  )
}
