import { Box, Link } from '@mui/material'
import React, { FC } from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useTranslation } from '../hooks/helper/useTranslation'

type Props = {
  children: string
  slug?: string
  link?: string
  onClick: () => void
}

export const DrawerNavItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, slug, link, onClick } = props
  const location = useLocation()
  const { t } = useTranslation()
  const isActive = link === location.pathname

  return (
    <Box mb={2.5} color="common.white">
      <Link
        underline={isActive ? 'always' : 'hover'}
        data-cy={`DrawerNav-Link-${slug}`}
        component={RouterLink}
        to={link ? link : '#'}
        onClick={onClick}
        variant="body1"
        color="inherit"
      >
        {t(children)}
      </Link>
    </Box>
  )
}
