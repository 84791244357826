import {
  Box,
  Hidden,
  IconButton,
  Typography,
  useTheme,
  Tooltip,
} from '@mui/material'
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
  EditOutlined as EditOutlinedIcon,
  FileCopyOutlined as FileCopyOutlinedIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  RadioButtonUnchecked as RadioButtonUncheckedIcon,
} from '@mui/icons-material'
import React, { FC } from 'react'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { AppointmentStatus } from '../../../../../models/graphqlTypes'
import { useStoreActions } from '../../../../../store/store.hooks'
import { Appointment } from '../../types/treatments.types'

type Props = {
  appointment: Appointment
  isOwnTreatment: boolean
  isDraft: boolean
  isAssistantOfTreatment: boolean
  isPast: boolean
  isOpen?: boolean
  editAppointment: (appointment: Appointment) => void
  cloneAppointment: (appointment: Appointment) => void
  linking?: Appointment | null
  disableLinking: boolean
  canLinkTo: boolean
  canConnect?: boolean
  onLink?: () => void
}

const AppointmentHeader: FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    appointment,
    isOwnTreatment,
    isDraft,
    isAssistantOfTreatment,
    isPast,
    isOpen,
    editAppointment,
    cloneAppointment,
    linking = null,
    disableLinking,
    canLinkTo,
    onLink = () => null,
  } = props
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)
  const isBooked =
    appointment?.status === AppointmentStatus.BetmenBooked ||
    appointment?.status === AppointmentStatus.EesztBooked ||
    appointment?.status === AppointmentStatus.Happened
  const date = appointment?.proposedDate
  const dateString = t('common:patientFormattedDate', { date })
  const timeString = t('common:patientFormattedTime', { date })
  const shouldDisplayTime =
    appointment?.isBetmenBooked || !!appointment?.eesztBookingId
  const dateTimeString = `${dateString}${
    shouldDisplayTime ? `, ${timeString}` : ''
  }`
  const selectedLanguage = useSelectedLanguage()

  const canEditAppointment =
    (isOwnTreatment || isAssistantOfTreatment) && (!isPast || isDraft)

  const theme = useTheme()

  const linkingToPast = linking && linking.id !== appointment.id && isPast
  const canNotConnectToThis = disableLinking || (linkingToPast && !isDraft)

  const statusText = () => {
    switch (appointment?.status) {
      case AppointmentStatus.BetmenBooked:
        return t('common:occupied')
      case AppointmentStatus.EesztBooked:
        return t('common:eesztBooked')
      case AppointmentStatus.Happened:
        return t('treatment:happened')
      default:
        return t('common:proposed')
    }
  }

  const handleLink: React.MouseEventHandler<HTMLButtonElement> = (ev) => {
    ev.stopPropagation()

    if (disableLinking) {
      setToast({
        text: t('protocol:circularDependencyError'),
        type: 'error',
      })
    } else if (linkingToPast && !isDraft) {
      setToast({
        text: t('treatment:connectingToPastItems'),
        type: 'error',
      })
    } else {
      onLink()
    }
  }

  return (
    <Box
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems={{ xs: 'flex-start', md: 'center' }}
      minHeight={40}
      data-id={appointment?.id}
      data-cy={`AppointmentHeader-Box-${appointment?.id}`}
    >
      <Box width={280} flexShrink={0} mt={{ xs: 1, md: 0 }}>
        <Typography variant="body2" color="textSecondary">
          {dateTimeString}
        </Typography>
      </Box>

      <Box flex={1}>
        <Typography variant="body2">
          {appointment?.info?.doctorTitle[selectedLanguage]}
        </Typography>
      </Box>

      <Box
        display="flex"
        justifyContent={isOpen ? 'flex-start' : 'flex-end'}
        flexShrink={0}
        ml={{ md: 1 }}
      >
        {!isPast && (
          <Box
            color={
              isBooked ? theme.palette.success.dark : theme.palette.primary.dark
            }
            display="flex"
            alignItems="center"
            flexShrink={0}
            minWidth={{ lg: 90 }}
            fontSize={20}
            mr={2}
          >
            {isBooked ? (
              <CheckCircleIcon fontSize="inherit" />
            ) : (
              <RadioButtonUncheckedIcon fontSize="inherit" />
            )}
            <Hidden only="md">
              <Typography variant="subtitle2" sx={{ pl: 0.5 }}>
                {/* {isBooked
                  ? appointment.status === AppointmentStatus.EesztBooked
                    ? t('common:eesztBooked')
                    : t('common:occupied')
                  : t('common:proposed')} */}
                {statusText()}
              </Typography>
            </Hidden>
          </Box>
        )}
        <Box>
          {isOwnTreatment && (
            <Tooltip
              title={t('doctor:tooltips.clone') as string}
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                color="inherit"
                onClick={(ev) => {
                  ev.stopPropagation()
                  cloneAppointment(appointment)
                }}
                size="large"
              >
                <FileCopyOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {canEditAppointment && (
            <Tooltip
              title={t('doctor:tooltips.edit') as string}
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation()
                  editAppointment(appointment)
                }}
                size="large"
              >
                <EditOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
          {appointment && (
            <Tooltip
              title={t('doctor:tooltips.link') as string}
              enterDelay={500}
              leaveDelay={200}
            >
              <IconButton
                color={
                  canLinkTo && !canNotConnectToThis ? 'primary' : 'default'
                }
                onClick={handleLink}
                size="large"
              >
                {canNotConnectToThis ? (
                  <LinkOffIcon />
                ) : canLinkTo || !linking ? (
                  <LinkIcon />
                ) : (
                  <CloseIcon />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export { AppointmentHeader }
