import { useMutation } from '@apollo/client'
import {
  getTreatment,
  updateAppointmentDependency,
  updateAppointmentDependencyVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_APPOINTMENT_DEPENDENCY } from '../../../../operations/appointmentOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { GET_TREATMENT } from '../../../../operations/treatmentOperations'
import { Appointment } from '../types/treatments.types'

type AppointmentDependencyUpdateData = {
  treatmentId: string
  appointment: Appointment
  dependentAppointment: Appointment
}

type AppointmentDependencyUpdateOptions = Pick<
  MutationHookOptions<
    updateAppointmentDependency,
    updateAppointmentDependencyVariables
  >,
  'onCompleted'
>

type Return = MutationTuple<
  updateAppointmentDependency,
  updateAppointmentDependencyVariables
>

export const useAppointmentDependencyUpdate = (
  data: AppointmentDependencyUpdateData,
  options: AppointmentDependencyUpdateOptions
): Return => {
  const { treatmentId, appointment } = data
  const { onCompleted } = options

  return useMutation<
    updateAppointmentDependency,
    updateAppointmentDependencyVariables
  >(UPDATE_APPOINTMENT_DEPENDENCY, {
    onCompleted,
    update: (cache, { data }) => {
      const updatedDependency = data?.updateAppointmentDependency
      const cachedQuery = cache.readQuery<getTreatment>({
        query: GET_TREATMENT,
        variables: {
          treatmentId: treatmentId,
        },
      })
      if (!cachedQuery || !updatedDependency) {
        return
      }

      const newAppointments =
        cachedQuery.getTreatment.appointments?.map((item) => {
          if (item.id === appointment.id) {
            return {
              ...item,
              dependent: item.dependent.map((subItem) => {
                if (subItem.id !== updatedDependency.id) {
                  return subItem
                }
                return {
                  ...subItem,
                  ...updatedDependency,
                }
              }),
            }
          }

          return item
        }) ?? []

      cache.writeQuery<getTreatment>({
        query: GET_TREATMENT,
        data: {
          getTreatment: {
            ...cachedQuery.getTreatment,
            appointments: newAppointments,
          },
        },
      })
    },
  })
}
