import { useQuery, NetworkStatus } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'

import {
  getDashboardAppointments,
  getDashboardAppointmentsVariables,
} from '../../models/graphqlTypes'

import { DASHBOARD_APPOINTMENTS } from '../../operations/appointmentOperations'
import { DashboardAppointment } from '../components/Dashboard/dashboard.types'

type Options = QueryHookOptions<
  getDashboardAppointments,
  getDashboardAppointmentsVariables
>

type ReturnData = {
  isLoading: boolean
  appointments: DashboardAppointment[]
  totalAppointments: number
  hasMoreAppointments: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreAppointments: (options: Options) => void
}

export const useDashboardAppointments = (options: Options = {}): ReturnData => {
  const {
    data: appointmentsData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getDashboardAppointments, getDashboardAppointmentsVariables>(
    DASHBOARD_APPOINTMENTS,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      ...options,
    }
  )
  const totalAppointments =
    appointmentsData?.getDashboardAppointments?.total || 0
  const appointments =
    appointmentsData?.getDashboardAppointments?.appointments || []
  const hasMoreAppointments = appointments.length < totalAppointments
  return {
    isLoading: loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    appointments,
    totalAppointments,
    hasMoreAppointments,
    refetch,
    fetchMoreAppointments: fetchMore,
  }
}
