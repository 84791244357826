import { Edit as EditIcon } from '@mui/icons-material'
import { Box, Button, IconButton, Link, Stack, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { BetmenList } from '../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../common/components/BetmenList/BetmenListItemCardCell'
import { BetmenPage } from '../../../common/components/BetmenPage'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { InstitutionSelector } from '../../../common/components/selectors/InstitutionSelector'
import { Status, StatusEnum } from '../../../common/components/Status/Status'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { institutionChore } from '../../../models/graphqlTypes'
import { CertificateModal } from '../components/CertificateModal'
import { InstitutionAdminModal } from '../components/InstitutionAdminModal'
import { LocationModal } from '../components/LocationModal'
import { useCheckCertificate } from '../hooks/useCheckCertificate'
import { useInstitutionAdminProfileList } from '../hooks/useInstitutionAdminProfileList'
import { useInstitutionList } from '../../../common/hooks/useInstitutionList'

export type RoomTableRowData = {
  id: string
  name: institutionChore['rooms'][0]['name']
  address: institutionChore['rooms'][0]['address']
  description: institutionChore['rooms'][0]['description']
  isActive: boolean | null
  neakCode: string | null
  mapLink: string | null
}

const LocationAdminPage: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()

  const [locationModelOpen, setLocationModelOpen] = useState(false)
  const [editingAdmins, setEditingAdmins] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string | null>(null)

  const { activeInstitutions } = useInstitutionList()

  const formMethods = useForm({
    defaultValues: {
      institution: null,
    },
  })

  const institution = formMethods.watch('institution')
  const [selectedInstitution, setSelectedInstitution] = useState<
    institutionChore | null | undefined
  >()

  const [selectedRoom, setSelectedRoom] = useState<
    RoomTableRowData | null | undefined
  >()

  const currentInstitution = useMemo(() => {
    return activeInstitutions?.find(
      (institution) => institution.id === selectedInstitution?.id
    )
  }, [activeInstitutions, selectedInstitution?.id])

  const [institutionCertificateModalOpen, setInstitutionCertificateModalOpen] =
    useState(false)

  const {
    data: hasCertification,
    loading: loadingCertificate,
    refetch: refetchCertificate,
  } = useCheckCertificate({
    skip: !selectedInstitution,
    variables: {
      institutionId: selectedInstitution?.id ?? '',
    },
  })

  const { profiles: adminProfiles } = useInstitutionAdminProfileList({
    skip: !selectedInstitution,
    variables: {
      institutionId: selectedInstitution?.id ?? '',
    },
  })

  const rooms: RoomTableRowData[] = useMemo(
    () =>
      currentInstitution?.rooms.map(
        ({ id, name, address, description, neakCode, isActive, mapLink }) => {
          return {
            id,
            name,
            address,
            description,
            neakCode: neakCode ?? '',
            isActive: !!isActive,
            mapLink: mapLink ?? '',
          }
        }
      ) || [],
    [currentInstitution?.rooms]
  )

  // TODO - search on backend
  const filteredRooms = useMemo(() => {
    if (!searchTerm) {
      return rooms
    }
    return rooms.filter(
      (room) =>
        room.name.en.includes(searchTerm) ||
        room.name.hu.includes(searchTerm) ||
        room.address?.en?.includes(searchTerm) ||
        room.address?.hu?.includes(searchTerm) ||
        room.neakCode?.includes(searchTerm)
    )
  }, [rooms, searchTerm])

  useEffect(() => {
    if (!!institution) {
      setSelectedInstitution(institution)
    }
  }, [institution])

  const headerItems = [
    { name: 'common:name', actions: false },
    { name: 'common:LocationAddress', actions: false },
    { name: 'common:neak', actions: false },
    { name: 'common:status', actions: false },
    { name: 'common:actions', actions: true },
  ]

  return (
    <FormProvider {...formMethods}>
      <BetmenPage title={t('footer:menu.locationAdmin')} withoutPadding>
        <Box px={2} mt={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <Stack flex={1} maxWidth={300}>
              <InstitutionSelector
                label={t('treatment:doctor.chooseInstitution')}
                name="institution"
                multiple={false}
              />
            </Stack>
            <Stack py={1} minWidth={300} alignItems="flex-end">
              {selectedInstitution && hasCertification && (
                <Box>
                  <Typography variant="caption">
                    * {t('institution:certificateUpload.unavailable')}
                  </Typography>
                </Box>
              )}

              {selectedInstitution && (
                <Button
                  disabled={loadingCertificate}
                  onClick={() => setInstitutionCertificateModalOpen(true)}
                >
                  {t('institution:certificateUpload.userDialogTitle')}
                </Button>
              )}
            </Stack>
          </Stack>

          {selectedInstitution && (
            <BoxWithLabel label={t('institution:locationAdmin.adminUsers')}>
              <Box display="flex" alignItems="center">
                <Typography variant="body1">
                  {adminProfiles
                    .map((profile) =>
                      t('common:formattedNameFull', {
                        title: profile.title,
                        firstName: profile.firstName,
                        lastName: profile.lastName,
                      })
                    )
                    .join(', ')}
                </Typography>
                <IconButton
                  color="primary"
                  onClick={() => setEditingAdmins(true)}
                  size="large"
                >
                  <EditIcon fontSize="large" />
                </IconButton>
              </Box>
            </BoxWithLabel>
          )}
        </Box>

        {selectedInstitution && (
          <BetmenList
            dataSource={filteredRooms}
            gridColumnSizes={[5, 4, 1, 1, 1]}
            filters={
              <BetmenListFilter
                searchPlaceholder={t('doctor:searchLocation')}
                addButtonText={t(`doctor:addLocation`)}
                handleAdd={() => setLocationModelOpen(true)}
                handleSearch={setSearchTerm}
              />
            }
            header={
              <BetmenListHeader>
                {headerItems.map((headerItem, i: number) => (
                  <BetmenListHeaderCell
                    actions={headerItem.actions}
                    key={`header_${i}`}
                  >
                    {t(headerItem.name)}
                  </BetmenListHeaderCell>
                ))}
              </BetmenListHeader>
            }
          >
            <BetmenListBody>
              {filteredRooms.map((room) => (
                <BetmenListItemCard key={'wp+' + room.id}>
                  <BetmenListItemCardCell>
                    {room.name?.[selectedLanguage]}
                  </BetmenListItemCardCell>

                  <BetmenListItemCardCell>
                    {room.mapLink ? (
                      <Link
                        href={room.mapLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {room.address?.[selectedLanguage]}
                      </Link>
                    ) : (
                      <span>{room.address?.[selectedLanguage]}</span>
                    )}
                  </BetmenListItemCardCell>

                  <BetmenListItemCardCell>
                    {room.neakCode}
                  </BetmenListItemCardCell>

                  <BetmenListItemCardCell>
                    <Status
                      status={
                        room.isActive ? StatusEnum.Active : StatusEnum.Inactive
                      }
                    />
                  </BetmenListItemCardCell>

                  <BetmenListActions
                    edit={() => {
                      setSelectedRoom(room)
                      setLocationModelOpen(true)
                    }}
                  ></BetmenListActions>
                </BetmenListItemCard>
              ))}
            </BetmenListBody>
          </BetmenList>
        )}
      </BetmenPage>
      <LocationModal
        isOpen={locationModelOpen}
        setIsOpen={setLocationModelOpen}
        selectedInstitution={selectedInstitution}
        selectedRoom={selectedRoom}
        onComplete={() => setSelectedRoom(null)}
      />
      {selectedInstitution && (
        <>
          <InstitutionAdminModal
            isOpen={editingAdmins}
            onClose={() => setEditingAdmins(false)}
            institution={selectedInstitution}
          />
          <CertificateModal
            isOpen={institutionCertificateModalOpen}
            onClose={() => {
              setInstitutionCertificateModalOpen(false)
              refetchCertificate({ institutionId: selectedInstitution?.id })
            }}
            institution={selectedInstitution}
          />
        </>
      )}
    </FormProvider>
  )
}

export { LocationAdminPage }
