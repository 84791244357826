import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  updateTreatmentSchemaStatus,
  updateTreatmentSchemaStatusVariables,
} from '../../../../models/graphqlTypes'
import { useMutation, gql } from '@apollo/client'
import { UPDATE_TREATMENT_SCHEMA_STATUS } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  updateTreatmentSchemaStatus,
  updateTreatmentSchemaStatusVariables
>

type Return = MutationTuple<
  updateTreatmentSchemaStatus,
  updateTreatmentSchemaStatusVariables
>

export const useTreatmentSchemaStatusUpdate = (
  options: Options = {}
): Return => {
  return useMutation<
    updateTreatmentSchemaStatus,
    updateTreatmentSchemaStatusVariables
  >(UPDATE_TREATMENT_SCHEMA_STATUS, {
    update(cache, { data }) {
      if (!data?.updateTreatmentSchemaStatus) {
        return
      }
      cache.writeFragment<{ disabled: boolean }>({
        id: cache.identify({
          __typename: 'TreatmentSchema',
          id: data.updateTreatmentSchemaStatus.id,
        }),
        fragment: gql`
          fragment SchemaDisabledChore on TreatmentSchema {
            disabled
            isDeletable
          }
        `,
        data: data.updateTreatmentSchemaStatus,
      })
    },
    ...options,
  })
}
