import React, { MouseEventHandler } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Box, Button, IconButton, Link, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import {
  AlternateEmail as AlternateEmailIcon,
  Chat as ChatIcon,
} from '@mui/icons-material'
import { Avatar } from '../Avatar'
import { PhoneIcon } from '../../icons/PhoneIcon'
import { AssistantsInfoView } from './AssistantsInfoView'
import { getProfessionString } from '../../../utils/professions'
import { doctorProfileForPatients } from '../../../models/graphqlTypes'
import { Sizes } from '../Avatar/Avatar'
import { DoctorProfileInstitution } from './DoctorProfileInstitution'

type Props = {
  doctor: Omit<
    doctorProfileForPatients,
    'professions' | 'institutionToDoctor'
  > & {
    professions?: doctorProfileForPatients['professions']
    institutionToDoctor?: doctorProfileForPatients['institutionToDoctor']
  }
  linkToDoctor?: string
  hideAvatar?: boolean
  avatarSize?: Sizes
  hideTitle?: boolean
  hideAssistants?: boolean
  hideDetails?: boolean
  hideContactButtons?: boolean
  hideEmail?: boolean
  showInstitutions?: boolean
}

const useStyles = makeStyles(() => ({
  noPadding: {
    padding: 0,
  },
}))

const DoctorInfo: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const {
    doctor,
    linkToDoctor,
    hideAvatar,
    avatarSize,
    hideTitle = false,
    hideAssistants = false,
    hideDetails = false,
    showInstitutions = false,
    hideContactButtons = false,
    hideEmail = true,
  } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const institutions = doctor.institutionToDoctor ?? []
  const professions = doctor.professions ?? []
  const assistants = doctor.assistants || []

  const handleLinkClick: MouseEventHandler = (event) => {
    if (!linkToDoctor) {
      return
    }
    event.stopPropagation()
    history.push(linkToDoctor)
  }

  return (
    <>
      {!hideTitle && (
        <Box mb={2}>
          <Typography variant="h6">{t('appointment:therapist')}:</Typography>
        </Box>
      )}
      <Box display="flex" alignItems={hideDetails ? 'center' : 'stretch'}>
        {!hideAvatar && (
          <Avatar
            firstName={doctor.firstName}
            lastName={doctor.lastName}
            size={avatarSize ? avatarSize : hideDetails ? 'large' : 'small'}
          />
        )}
        <Box px={hideAvatar ? 0 : 2} flexGrow={1}>
          <Button
            className={classes.noPadding}
            variant="text"
            color="inherit"
            onClick={handleLinkClick}
          >
            <Typography variant={hideDetails ? 'body1' : 'body2'}>
              {t('common:formattedNameFull', {
                title: doctor.title,
                firstName: doctor.firstName,
                lastName: doctor.lastName,
              })}
            </Typography>
          </Button>
          {!hideDetails && doctor && (
            <>
              <Typography variant="subtitle1" color="textSecondary">
                {getProfessionString(professions, t)}
              </Typography>
              <Typography variant="subtitle1" color="primary">
                {doctor.user?.email}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                {doctor.phone}
              </Typography>
            </>
          )}
          {!hideContactButtons && (
            <Box
              display="flex"
              justifyContent="space-between"
              mt={hideDetails ? 0 : 2}
              maxWidth={150}
            >
              <IconButton
                onClick={(ev) => {
                  ev.stopPropagation()
                  history.push(`/messages/${doctor.user?.id}`)
                }}
                size="small"
              >
                <ChatIcon />
              </IconButton>
              <IconButton
                size="small"
                disabled={!doctor.user?.email.length}
                onClick={(ev) => ev.stopPropagation()}
                href={`mailto:${doctor.user?.email}`}
              >
                <AlternateEmailIcon />
              </IconButton>
              <IconButton
                size="small"
                disabled={!doctor.phone}
                onClick={(ev) => ev.stopPropagation()}
                href={`tel:${doctor.phone}`}
              >
                <PhoneIcon />
              </IconButton>
            </Box>
          )}
          {!hideEmail && (
            <Typography variant="subtitle1" noWrap>
              <Link href={`mailto:${doctor.user?.email}`} underline="none">
                {doctor.user?.email}
              </Link>
            </Typography>
          )}
        </Box>
      </Box>

      {showInstitutions && (
        <>
          <Box mt={2}>
            <Typography variant="h6">
              {t('profile:doctor.institutions')}:
            </Typography>
          </Box>
          {institutions?.map((institution) => (
            <DoctorProfileInstitution
              key={institution.id}
              institution={institution}
              assistants={assistants || []}
            />
          ))}
          {!institutions?.length && (
            <Typography variant="subtitle1" color="textSecondary">
              {t('common:emptyField')}
            </Typography>
          )}
        </>
      )}

      {!hideAssistants && (
        <Box my={2}>
          <Typography variant="h6">{t('menu:doctor.assistants')}:</Typography>
          <Box mt={1} ml={1}>
            <AssistantsInfoView
              assistantProfiles={assistants}
              institutions={institutions}
              typographyVariant="subtitle1"
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export { DoctorInfo }
