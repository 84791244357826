import { QueryResult, useQuery } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import { useMemo } from 'react'
import { bnoCodes, bnoCodesVariables } from '../../models/graphqlTypes'
import { GET_BNO_CODES } from '../../operations/bnoCodeOperations'
import { BnoCodeChore } from '../../models/graphqlTypes'

type Options = QueryHookOptions<bnoCodes, bnoCodesVariables>

type Return = {
  loading: boolean
  bnoCodes: BnoCodeChore[]
  refetch: QueryResult<bnoCodes, bnoCodesVariables>['refetch']
}

export const useBnoCodesData = (
  options: Options = {},
  exclude: string[] = []
): Return => {
  const { data, loading, refetch } = useQuery<bnoCodes, bnoCodesVariables>(
    GET_BNO_CODES,
    { ...options }
  )

  return useMemo(() => {
    return {
      bnoCodes: (data?.bnoCodes ?? [])
        .slice()
        .filter((code) => !exclude.includes(code.id))
        .sort((a, b) => {
          const nameA = a.code.toUpperCase() // ignore upper and lowercase
          const nameB = b.code.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        }),
      loading,
      refetch,
    }
  }, [data?.bnoCodes, loading, refetch, exclude])
}
