import React from 'react'
import { Container, Box, Paper } from '@mui/material'
import { Logo } from '../../common/components/Logo'
import { LoginFields } from './components/LoginFields'
import { RequestPasswordReset } from './components/RequestPasswordReset'
import { SetNewPassword } from './components/SetNewPassword'
import { TwoFactor } from './components/TwoFactor'

const LOGIN_HEIGHT = 500

export enum LoginViews {
  login = 'login',
  requestPasswordReset = 'requestPasswordReset',
  setNewPassword = 'setNewPassword',
  twoFactor = 'twoFactor',
}
type LoginProps = {
  view?: LoginViews
}
const Login: React.FC<React.PropsWithChildren<LoginProps>> = ({
  view = 'login',
}) => {
  return (
    <>
      <Box
        width="100%"
        height={{ xs: 300, sm: 330, md: 370, lg: 400 }}
        top={0}
        bgcolor="common.black"
        position="fixed"
        zIndex={-1}
      />
      <Container component="main" maxWidth="xs">
        <Box textAlign="center" pt={{ xs: 5, sm: 8, md: 12, lg: 15 }} mb={5}>
          <Logo />
        </Box>
        <Paper variant="outlined">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-evenly"
            height={LOGIN_HEIGHT}
            textAlign="center"
            px={{ xs: 3, sm: 5 }}
          >
            {view === LoginViews.login && <LoginFields />}
            {view === LoginViews.requestPasswordReset && (
              <RequestPasswordReset />
            )}
            {view === LoginViews.setNewPassword && <SetNewPassword />}
            {view === LoginViews.twoFactor && <TwoFactor />}
          </Box>
        </Paper>
      </Container>
    </>
  )
}

export { Login }
