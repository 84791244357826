import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material'
import React from 'react'
import { useNotifications } from '../../../../common/providers/NotificationProvider'
import { Loading } from '../../../../common/components/Loading'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useDeleteDoctorOwnPatient } from '../hooks/useDeleteDoctorOwnPatient'

interface DeletePatientModal {
  isOpen: boolean
  patientId?: string
  name?: string
  tajNumber?: string
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  refetch?: () => void
}

const DeletePatientModal: React.FC<
  React.PropsWithChildren<DeletePatientModal>
> = ({ isOpen, patientId, name, tajNumber, toggleIsOpen, refetch }) => {
  const { t } = useTranslation()
  const { setSuccessToast } = useNotifications()

  const [deleteDoctorsOwnPatient, { loading: deleteLoading }] =
    useDeleteDoctorOwnPatient({
      onCompleted: () => refetch?.(),
    })

  const handleClose = () => {
    toggleIsOpen(false)
  }

  const handleSubmit = async () => {
    if (patientId) {
      await deleteDoctorsOwnPatient({
        variables: {
          patientId,
        },
      })

      setSuccessToast('successDeletePatient')
      handleClose()
    }
  }

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>{t('treatment:doctor.deletePatient')}</DialogTitle>
      <DialogContent>
        <Box pb={1}>
          <Typography variant="subtitle2">{t('patients:name')}</Typography>
          <Typography variant="body2">{name}</Typography>
        </Box>
        <Box pb={2}>
          <Typography variant="subtitle2">{t('patients:taj')}</Typography>
          <Typography variant="body2">{tajNumber}</Typography>
        </Box>
        <DialogContentText>
          {t('treatment:doctor.deletePatientDescription')}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={deleteLoading}
          startIcon={
            deleteLoading ? (
              <Loading inline size={18} color="inherit" />
            ) : undefined
          }
        >
          {t('treatment:doctor.deletePatient')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { DeletePatientModal }
