import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  setNewPassword,
  setNewPasswordVariables,
} from '../../../models/graphqlTypes'
import { useMutation } from '@apollo/client'
import { SET_NEW_PASSWORD } from '../../../operations/userOperations'

type Options = MutationHookOptions<setNewPassword, setNewPasswordVariables>

type Return = MutationTuple<setNewPassword, setNewPasswordVariables>

export const useSetNewPassword = (options: Options = {}): Return => {
  return useMutation<setNewPassword, setNewPasswordVariables>(
    SET_NEW_PASSWORD,
    options
  )
}
