import React from 'react'
import { useFormContext } from 'react-hook-form'
import { SendSurveyToType } from './sendSurvey.types'
import { SendSurveyToPatient } from './SendSurveyToPatient'
import { SendSurveyToDoctor } from './SendSurveyToDoctor'
import { SendSurveyToAssistant } from './SendSurveyToAssistant'

export const SendSurveyRecipientSelector: React.FC = () => {
  const { watch } = useFormContext()
  const selectedType = watch('sendTo')

  switch (selectedType) {
    case SendSurveyToType.Patient:
      return <SendSurveyToPatient />
    case SendSurveyToType.Doctor:
      return <SendSurveyToDoctor />
    case SendSurveyToType.Assistant:
      return <SendSurveyToAssistant />
  }
}
