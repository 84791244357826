import React from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'

interface MessageModal {
  isOpen: boolean
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  title: string
  message: string
  testKey?: string
}

const MessageModal: React.FC<React.PropsWithChildren<MessageModal>> = ({
  isOpen,
  toggleIsOpen,
  title,
  message,
  testKey,
}) => {
  const { t } = useTranslation()

  const handleClose = () => {
    toggleIsOpen(false)
  }

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{message}</DialogContent>

      <DialogActions>
        <Button variant="outlined" onClick={handleClose} data-cy={testKey}>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { MessageModal }
