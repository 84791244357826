import { Box, Button, Stack } from '@mui/material'
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { MultiLangTextField } from '../../../../../common/components/MultiLangTextField'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useTreatmentSchemaUpdate } from '../../hooks/useTreatmentSchemaUpdate'
import {
  MultiLangDetailType,
  MultiLangFormValues,
} from './treatmentSchemaDetailsEdit.types'
import { TreatmentSchemaById } from '../../types/treatmentSchemas.types'
interface Props {
  treatmentSchema: TreatmentSchemaById
  detailType: MultiLangDetailType
  onClose: () => void
  onReset: (
    responseData:
      | TreatmentSchemaById['title']
      | TreatmentSchemaById['description']
      | null
  ) => void
}

export const TreatmentSchemaMultiLangPopup: React.FC<
  React.PropsWithChildren<Props>
> = ({ treatmentSchema, detailType, onClose, onReset }) => {
  const { t } = useTranslation()

  const methods = useForm<MultiLangFormValues>({
    mode: 'all',
    defaultValues: { [detailType]: treatmentSchema[detailType] },
    shouldUnregister: true,
  })

  const {
    handleSubmit,
    formState: { isValid },
  } = methods

  const [updateTreatmentSchema] = useTreatmentSchemaUpdate({
    onCompleted: ({ updateTreatmentSchema: responseData }) =>
      onReset(responseData[detailType]),
  })

  const onSubmit = handleSubmit(async (formValues) => {
    await updateTreatmentSchema({
      variables: {
        treatmentData: {
          id: treatmentSchema.id,
          [detailType]: formValues[detailType],
        },
      },
    })

    onClose()
  })

  return (
    <FormProvider {...methods}>
      <Box
        data-cy="TreatmentSchemaMultiLangPopup"
        component="form"
        onSubmit={onSubmit}
        sx={{
          width: { xs: '90vw', md: '50vw' },
          padding: 2,
          bgcolor: 'background.paper',
        }}
      >
        <MultiLangTextField
          name={detailType}
          title={t(`treatment:protocol.${detailType}`)}
          fullWidth
          variant="outlined"
          multiline={detailType === MultiLangDetailType.Description}
          minRows={3}
          required
        />
        <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
          <Button variant="outlined" onClick={onClose}>
            {t('common:cancel')}
          </Button>
          <Button type="submit" disabled={!isValid}>
            {t('common:save')}
          </Button>
        </Stack>
      </Box>
    </FormProvider>
  )
}
