import React, { FC } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Box, Typography, Link } from '@mui/material'
import { Avatar } from '../../../../common/components/Avatar'
import { DoctorPatientProfile } from '../types/doctorPatients.types'

type Props = {
  patientProfile: DoctorPatientProfile
}

/**
 * Header info of the patient card with an avatar and basic header informations
 */
export const HeaderInfo: FC<React.PropsWithChildren<Props>> = ({
  patientProfile,
  children,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <Avatar
        firstName={patientProfile.firstName}
        lastName={patientProfile.lastName}
        size={'large'}
      />

      <Box display="flex" flexDirection="column" flex={1} px={1}>
        <Typography variant={'h4'}>
          {t('common:formattedNameFull', {
            firstName: patientProfile.firstName,
            lastName: patientProfile.lastName,
            title: patientProfile.title,
          })}
        </Typography>
        <Link href={`mailto:${patientProfile.user?.email}`}>
          {patientProfile.user?.email}
        </Link>
        {children}
      </Box>
    </>
  )
}
