import { Box, Avatar } from '@mui/material'
import React from 'react'

const AvatarForIcon: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <Avatar
      sx={{
        width: 60,
        height: 60,
      }}
    >
      <Box
        fontSize="4rem"
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {children}
      </Box>
    </Avatar>
  )
}

export { AvatarForIcon }
