import {
  LazyQueryResultTuple,
  QueryHookOptions,
  useLazyQuery,
} from '@apollo/client'
import { FIND_ASSISTANT_PROFILE } from '../operations/doctorOwnAssistantsOperations'
import {
  findAssistantProfile,
  findAssistantProfileVariables,
} from '../../../../models/graphqlTypes'

type Options = QueryHookOptions<
  findAssistantProfile,
  findAssistantProfileVariables
>

type Return = LazyQueryResultTuple<
  findAssistantProfile,
  findAssistantProfileVariables
>

export const useFindAssistantProfile = (options: Options = {}): Return => {
  return useLazyQuery<findAssistantProfile, findAssistantProfileVariables>(
    FIND_ASSISTANT_PROFILE,
    options
  )
}
