import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { About } from '../components/About'
import { Settings } from '../components/Settings'
import { UiKit } from '../components/UiKit'

import { PatientRoutes } from '../router/PatientRoutes'
import { DoctorRoutes } from '../router/DoctorRoutes'
import { RedirectHandler } from '../router/RedirectHandler'
import { CookiePolicy } from '../components/CookieComponent/CookiePolicy'
import { MessageBoard } from '../components/MessageBoard/MessageBoard'
import { AssistantRoutes } from './AssistantRoutes'
import { EesztRssView } from '../components/EesztRssView'
import { PrivacyStatement } from '../components/PrivacyStatement'
import { AdminRoutes } from './AdminRoutes'

const AppRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isSmsEnabled = process.env.REACT_APP_ENABLE_SMS === 'true'

  return (
    <Switch>
      <Route path="/uikit">
        <UiKit />
      </Route>
      <Route path="/cookie-policy">
        <CookiePolicy />
      </Route>
      <Route path="/privacy-statement">
        <PrivacyStatement />
      </Route>
      <Route path="/about">
        <About />
      </Route>
      <Route path="/settings">
        <Settings />
      </Route>
      {isSmsEnabled && (
        <Route path="/sms">
          <Settings />
        </Route>
      )}
      <Route path={`/messages/:partnerId/`}>
        <MessageBoard />
      </Route>
      <Route path={`/messages`}>
        <MessageBoard />
      </Route>

      <Route path="/doctor">
        <DoctorRoutes />
      </Route>

      <Route path="/assistant">
        <AssistantRoutes />
      </Route>

      <Route path="/patient">
        <PatientRoutes />
      </Route>

      <Route path="/admin">
        <AdminRoutes />
      </Route>

      <Route path="/eeszt-rss">
        <EesztRssView />
      </Route>

      <Route>
        <RedirectHandler />
      </Route>
    </Switch>
  )
}

export { AppRoutes }
