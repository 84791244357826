import { RemoveRedEye } from '@mui/icons-material'
import EditIcon from '@mui/icons-material/Edit'
import { Button, ButtonProps } from '@mui/material'
import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useMe } from '../../../../../common/hooks/useMe'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useStylesForSurveyActionButtons } from '../../utils/style.util'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
interface Props extends ButtonProps {
  surveySchema: SurveySchemaWithoutUser
  isReadonly?: boolean
}

export const EditSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  isReadonly = false,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const classes = useStylesForSurveyActionButtons()

  const { watch } = useFormContext()
  const status = watch('status')

  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })

  const isEditable = useMemo(
    () =>
      status !== SurveySchemaStatusEnum.Archived &&
      (!surveySchema?.isGlobal || me?.isAdmin),
    [status, me, surveySchema]
  )

  const onEdit = () =>
    history.push(`/${userType?.toLowerCase()}/survey/${surveySchema.id}`)

  return (
    <>
      {isEditable && (
        <Button
          classes={{ textPrimary: classes.textPrimary }}
          variant="text"
          startIcon={isReadonly ? <RemoveRedEye /> : <EditIcon />}
          onClick={onEdit}
          {...buttonProps}
          data-cy={'EditSurveyButton'}
        >
          {isReadonly ? t('common:view') : t('common:edit')}
        </Button>
      )}
    </>
  )
}
