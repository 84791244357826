import { MutationHookOptions, useQuery } from '@apollo/client'
import {
  doctorProfileForList,
  listAllDoctors,
  listAllDoctorsVariables,
} from '../../models/graphqlTypes'
import { LIST_ALL_DOCTORS } from '../../operations/doctorProfileOperations'
import { useTranslation } from './helper/useTranslation'
import { useUserProfile } from './useUserProfile'

type Options = MutationHookOptions<listAllDoctors, listAllDoctorsVariables>

type DoctorsListReturnType = {
  loading: boolean
  doctors: doctorProfileForList[]
  doctorsWithoutMe: doctorProfileForList[]
}

export const useDoctorsList = (
  options: Options = {}
): DoctorsListReturnType => {
  const { data: { listAllDoctors = [] } = {}, loading } = useQuery<
    listAllDoctors,
    listAllDoctorsVariables
  >(LIST_ALL_DOCTORS, options)
  const { t } = useTranslation()
  const profile = useUserProfile()

  const doctors = listAllDoctors
    .slice()
    .sort((a, b) =>
      t('common:formattedNameFull', a).localeCompare(
        t('common:formattedNameFull', b)
      )
    )

  const doctorsWithoutMe = doctors.filter((doctor) => doctor.id !== profile?.id)

  return { doctors, doctorsWithoutMe, loading }
}
