import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { doctorProfileForPatients } from '../../../models/graphqlTypes'
import { DoctorProfile } from './DoctorProfile'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Loading } from '../Loading'

type Props = {
  doctorProfiles?: doctorProfileForPatients[]
  loading?: boolean
}

export const DoctorProfileList: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { doctorProfiles = [], loading = false } = props
  const { t } = useTranslation()

  const title = t('profile:doctorsNumber', {
    number: doctorProfiles.length,
  }).toUpperCase()

  return (
    <Box p={2}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Typography variant="subtitle1" color="textSecondary">
            {title}
          </Typography>
          <Box display={'flex'} flexWrap={'wrap'} gap={2}>
            {doctorProfiles.map((doctorProfile) => (
              <DoctorProfile
                key={doctorProfile.id}
                doctorProfile={doctorProfile}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  )
}
