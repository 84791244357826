import React from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import PreviewIcon from '@mui/icons-material/Preview'
import EditIcon from '@mui/icons-material/Edit'

interface Props extends ButtonProps {
  isPreviewOnState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const PreviewSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  isPreviewOnState,
  ...buttonProps
}) => {
  const { t } = useTranslation()

  const [isPreviewOn, setIsPreviewOn] = isPreviewOnState

  return isPreviewOn ? (
    <Button
      variant="outlined"
      startIcon={<EditIcon />}
      onClick={() => setIsPreviewOn(false)}
      {...buttonProps}
    >
      {t('survey:survey.edit')}
    </Button>
  ) : (
    <Button
      variant="outlined"
      startIcon={<PreviewIcon />}
      onClick={() => setIsPreviewOn(true)}
      {...buttonProps}
    >
      {t('survey:survey.preview.title')}
    </Button>
  )
}
