import { ApolloError } from '@apollo/client'
import React, { createContext, ReactNode, useContext } from 'react'
import { useTranslation } from '../hooks/helper/useTranslation'
import { useStoreActions } from '../../store/store.hooks'

interface NotificationContextProps {
  setSuccessToast: (
    successMessageKey: string,
    options?: { useFullKey: boolean }
  ) => void
  setErrorToast: (error: ApolloError) => void
  setCustomErrorToast: (errorMessage: string) => void
}

const defaultValues: NotificationContextProps = {
  setSuccessToast: () => {
    //
  },
  setErrorToast: () => {
    //
  },
  setCustomErrorToast: () => {
    //
  },
}

export const NotificationContext =
  createContext<NotificationContextProps>(defaultValues)

export const useNotifications = (): NotificationContextProps =>
  useContext(NotificationContext)

export const NotificationProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }: { children?: ReactNode }) => {
  const { t } = useTranslation()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const setSuccessToast = (
    successMessageKey: string,
    options: { useFullKey: boolean } = { useFullKey: false }
  ) => {
    setToast({
      text: t(
        options.useFullKey
          ? successMessageKey
          : `notification:${successMessageKey}`
      ),
      type: 'success',
    })
  }

  const setErrorToast = (error: ApolloError) => {
    setToast({
      text: t(error.message),
      type: 'error',
    })
  }

  const setCustomErrorToast = (errorMessage: string) => {
    setToast({
      text: errorMessage,
      type: 'error',
    })
  }

  const value = { setSuccessToast, setErrorToast, setCustomErrorToast }
  return (
    <NotificationContext.Provider value={value}>
      {children}
    </NotificationContext.Provider>
  )
}
