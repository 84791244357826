import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const DependencyIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (
  props
) => {
  return (
    <SvgIcon viewBox="0 0 28 27" width="28" height="27" {...props}>
      <svg
        width="28"
        height="27"
        viewBox="0 0 28 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.20312 10.5238H2.17188C1.52414 10.5238 1 9.99135 1 9.33333V2.19048C1 1.53246 1.52414 1 2.17188 1H9.20312C9.85086 1 10.375 1.53246 10.375 2.19048V9.33333C10.375 9.99135 9.85086 10.5238 9.20312 10.5238Z"
          stroke="#32C8FF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M25.6094 25.9999H18.5781C17.9304 25.9999 17.4062 25.4674 17.4062 24.8094V17.6666C17.4062 17.0085 17.9304 16.4761 18.5781 16.4761H25.6094C26.2571 16.4761 26.7812 17.0085 26.7812 17.6666V24.8094C26.7812 25.4674 26.2571 25.9999 25.6094 25.9999Z"
          stroke="#32C8FF"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M6.27344 12.9048C6.27344 13.2338 6.01137 13.5 5.6875 13.5C5.36363 13.5 5.10156 13.2338 5.10156 12.9048C5.10156 12.5758 5.36363 12.3096 5.6875 12.3096C6.01137 12.3096 6.27344 12.5758 6.27344 12.9048Z"
          fill="#32C8FF"
        />
        <path
          d="M6.27344 15.2857C6.27344 15.6147 6.01137 15.8809 5.6875 15.8809C5.36363 15.8809 5.10156 15.6147 5.10156 15.2857C5.10156 14.9567 5.36363 14.6904 5.6875 14.6904C6.01137 14.6904 6.27344 14.9567 6.27344 15.2857Z"
          fill="#32C8FF"
        />
        <path
          d="M6.27344 17.6665C6.27344 17.9955 6.01137 18.2618 5.6875 18.2618C5.36363 18.2618 5.10156 17.9955 5.10156 17.6665C5.10156 17.3375 5.36363 17.0713 5.6875 17.0713C6.01137 17.0713 6.27344 17.3375 6.27344 17.6665Z"
          fill="#32C8FF"
        />
        <path
          d="M6.27344 20.0479C6.27344 20.3769 6.01137 20.6431 5.6875 20.6431C5.36363 20.6431 5.10156 20.3769 5.10156 20.0479C5.10156 19.7189 5.36363 19.4526 5.6875 19.4526C6.01137 19.4526 6.27344 19.7189 6.27344 20.0479Z"
          fill="#32C8FF"
        />
        <path
          d="M6.27344 22.4282C6.27344 22.7573 6.01137 23.0235 5.6875 23.0235C5.36363 23.0235 5.10156 22.7573 5.10156 22.4282C5.10156 22.0992 5.36363 21.833 5.6875 21.833C6.01137 21.833 6.27344 22.0992 6.27344 22.4282Z"
          fill="#32C8FF"
        />
        <path
          d="M8.61719 22.4282C8.61719 22.7573 8.35512 23.0235 8.03125 23.0235C7.70738 23.0235 7.44531 22.7573 7.44531 22.4282C7.44531 22.0992 7.70738 21.833 8.03125 21.833C8.35512 21.833 8.61719 22.0992 8.61719 22.4282Z"
          fill="#32C8FF"
        />
        <path
          d="M10.9609 22.4282C10.9609 22.7573 10.6989 23.0235 10.375 23.0235C10.0511 23.0235 9.78906 22.7573 9.78906 22.4282C9.78906 22.0992 10.0511 21.833 10.375 21.833C10.6989 21.833 10.9609 22.0992 10.9609 22.4282Z"
          fill="#32C8FF"
        />
        <path
          d="M13.3047 22.4282C13.3047 22.7573 13.0426 23.0235 12.7188 23.0235C12.3949 23.0235 12.1328 22.7573 12.1328 22.4282C12.1328 22.0992 12.3949 21.833 12.7188 21.833C13.0426 21.833 13.3047 22.0992 13.3047 22.4282Z"
          fill="#32C8FF"
        />
        <path
          d="M15.6484 22.4282C15.6484 22.7573 15.3864 23.0235 15.0625 23.0235C14.7386 23.0235 14.4766 22.7573 14.4766 22.4282C14.4766 22.0992 14.7386 21.833 15.0625 21.833C15.3864 21.833 15.6484 22.0992 15.6484 22.4282Z"
          fill="#32C8FF"
        />
      </svg>
    </SvgIcon>
  )
}

export { DependencyIcon }
