import {
  TreatmentSchemaSchedule,
  TreatmentSchemaSurvey,
} from '../types/treatmentSchemas.types'

export interface DayConstraints {
  minDay: number | null
  maxDay: number | null
}

export enum CUSTOM_DAY_ERROR {
  ORDER_ERROR = 'orderError',
  EXCEEDS_MAX_DURATION = 'exceedsMaxDuration',
  DEPENDENCY_ERROR = 'dependencyError',
}

export interface LocalSchedule extends TreatmentSchemaSchedule {
  dayError?: CUSTOM_DAY_ERROR
  dayConstraints: DayConstraints
}

export interface LocalSurvey extends TreatmentSchemaSurvey {
  dayError?: CUSTOM_DAY_ERROR
}
