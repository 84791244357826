import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  uploadCertificate,
  uploadCertificateVariables,
} from '../../../models/graphqlTypes'
import { STORE_CERTIFICATE_TO_INSTITUTION } from '../../../operations/institutionOperations'

type Options = MutationHookOptions<
  uploadCertificate,
  uploadCertificateVariables
>

type Return = MutationTuple<uploadCertificate, uploadCertificateVariables>

export const useStoreCertificate = (options: Options = {}): Return => {
  return useMutation<uploadCertificate, uploadCertificateVariables>(
    STORE_CERTIFICATE_TO_INSTITUTION,
    options
  )
}
