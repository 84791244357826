import { Typography, Stack, Button, Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { FileUploadModal } from './FileUploadModal'
import { Controller, useFormContext } from 'react-hook-form'
import {
  DocumentAnswer,
  InputFileUploadAnswer,
} from '../../../../../models/graphqlTypes'
import get from 'lodash/get'
import { FileDisplay } from './FileDisplay'
import { getIsFileItemsCountSmaller } from './fileUpload.util'
import { UploadFileInput } from './fileUpload.types'
import { usePatientFileUpload } from '../../../../patient/PatientEvents/hooks/usePatientFileUpload'

interface Props {
  question: QuestionInSection
  answerIndex: number
  isPreview: boolean
  readOnly?: boolean
}

export const FillFileUploadQuestion: React.FC<
  React.PropsWithChildren<Props>
> = ({ question, answerIndex, isPreview, readOnly }) => {
  const { t } = useTranslation()
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const [isUploadModalOpen, setIsUploadModalOpen] = useState<boolean>(false)

  const fileUploadData = question.data?.fileUploadData ?? null
  const pathToAnswer = `answers.${answerIndex}.fileUploadQuestionAnswer.documents`
  const files: UploadFileInput[] = (watch(pathToAnswer) ?? []).map(
    (file: DocumentAnswer) => {
      return {
        file: new File([], file.fileName),
        documentId: file.documentId,
      }
    }
  )
  const errorToAnswer = get(errors, pathToAnswer)

  const [uploadFile] = usePatientFileUpload()

  const onSubmit = async (files: UploadFileInput[]) => {
    const filesToSurvey: InputFileUploadAnswer[] = await Promise.all(
      files.map(async (file) => {
        if (file.documentId) {
          return {
            documentId: file.documentId,
            fileName: file.file.name,
          }
        } else {
          const uploadResult = await uploadFile({
            variables: {
              file: file.file,
              documentType: 'SurveyQuestionAnswerFile',
            },
          })
          const documentId = uploadResult.data?.uploadDocument || ''
          return {
            documentId,
            fileName: file.file.name,
          }
        }
      })
    )

    setValue(pathToAnswer, filesToSurvey)
  }

  return (
    <>
      <Box>
        <Stack gap={1} my={1}>
          {!!fileUploadData?.validFileTypes.length && (
            <Typography variant="subtitle1">
              {t('survey:file.acceptedFormats')}:{' '}
              {fileUploadData?.validFileTypes.join(', ')}
            </Typography>
          )}
          <Typography variant="subtitle1">
            {t('survey:file.maxAmountOfFiles')}: {fileUploadData?.maxItemsCount}
          </Typography>
          <Typography variant="subtitle1">
            {t('survey:file.maxSizeOfFiles')}:{' '}
            {t(`survey:file.size.${fileUploadData?.maxFileSize}`)}
          </Typography>
        </Stack>

        <FileDisplay
          files={files}
          setFiles={
            !readOnly || isPreview
              ? (newFiles: UploadFileInput[]) => {
                  const filesToSurvey = newFiles.map((file) => ({
                    fileName: file.file.name,
                    documentId: file.documentId,
                  }))
                  setValue(pathToAnswer, filesToSurvey)
                }
              : undefined
          }
        />

        {(!readOnly || isPreview) &&
          getIsFileItemsCountSmaller(fileUploadData, files) && (
            <Controller
              name={pathToAnswer}
              rules={{
                validate: () =>
                  !isPreview && question.isRequired && files?.length < 1
                    ? (t('messages:warnings.requiredQuestion') as string)
                    : true,
              }}
              render={({ field: { onBlur } }) => (
                <Button
                  onBlur={onBlur}
                  startIcon={<UploadFileIcon />}
                  disabled={isPreview}
                  fullWidth={false}
                  variant="outlined"
                  onClick={() => setIsUploadModalOpen(true)}
                >
                  {t('survey:file.uploadButtonTitle')}
                </Button>
              )}
            />
          )}
        {!!errorToAnswer && (
          <Typography variant="subtitle2" color="error" mt={1}>
            {errorToAnswer.message?.toString()}
          </Typography>
        )}
      </Box>

      {isUploadModalOpen && (
        <FileUploadModal
          open={isUploadModalOpen}
          question={question}
          onClose={() => setIsUploadModalOpen(false)}
          onSave={onSubmit}
          savedFiles={files}
        />
      )}
    </>
  )
}
