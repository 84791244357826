import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  createSurveyQuestion,
  createSurveyQuestionVariables,
} from '../../../../models/graphqlTypes'

import { CREATE_SURVEY_QUESTION } from '../operations/questionOperations'

type Options = MutationHookOptions<
  createSurveyQuestion,
  createSurveyQuestionVariables
>

type Return = MutationTuple<createSurveyQuestion, createSurveyQuestionVariables>

export const useSurveyQuestionCreate = (options: Options = {}): Return => {
  return useMutation<createSurveyQuestion, createSurveyQuestionVariables>(
    CREATE_SURVEY_QUESTION,
    options
  )
}
