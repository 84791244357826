import { IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'

type Props = {
  searchTerm?: string
  handleSearch: (searchTerm: string) => void
}

export const TableSearch: React.FC<React.PropsWithChildren<Props>> = ({
  searchTerm,
  handleSearch,
}) => {
  const { t } = useTranslation()

  return (
    <TextField
      size="small"
      variant="outlined"
      placeholder={t(`common:search`)}
      value={searchTerm}
      onChange={(e) => handleSearch(e.target.value)}
      sx={{ width: '350px', pl: 2, pb: 1 }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <>
            {!!searchTerm && (
              <InputAdornment position="end">
                <IconButton onClick={() => handleSearch('')} edge="end">
                  <CloseIcon fontSize="small" />
                </IconButton>
              </InputAdornment>
            )}
          </>
        ),
      }}
    />
  )
}
