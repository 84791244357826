import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { Login, LoginVariables } from '../../../models/graphqlTypes'
import { LOGIN } from '../../../operations/userOperations'

type Return = MutationTuple<Login, LoginVariables>

type Options = MutationHookOptions<Login, LoginVariables>

export const useLogin = (options: Options = {}): Return => {
  return useMutation<Login, LoginVariables>(LOGIN, options)
}
