import { Card } from '@mui/material'
import React, { useCallback, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { getOrderedSections } from '../../utils/survey.util'
import { Section, QuestionInSection } from '../EditSurvey/editSurvey.types'
import { mapSurveySchemaQuestionsToSections } from '../../utils/survey.mapper'
import { FillQuestion } from '../FillQuestion/FillQuestion'
import { getInitialAnswers } from '../../../../patient/PatientEvents/util/fillSurvey.util'
import { PatientSurveyResult, Survey } from '../../types/surveyStore.types'

interface Props {
  surveySchema: Survey['surveySchema']
  surveyResult?: PatientSurveyResult
  isPreview?: boolean
}

export const SurveyResultQuestions: React.FC<
  React.PropsWithChildren<Props>
> = ({ surveySchema, surveyResult, isPreview }) => {
  const sections = !!surveySchema.surveySchemaQuestions.length
    ? mapSurveySchemaQuestionsToSections(surveySchema?.surveySchemaQuestions)
    : [{ order: 1, questions: [], name: '' }]

  const orderedSections = getOrderedSections(sections)

  const filteredSections = orderedSections
    .filter((section) => !!section.questions.length)
    .sort((aSection, bSection) => aSection.order - bSection.order)
    .map((section) => ({
      ...section,
      questions: section.questions.sort(
        (aQuestion, bQuestion) =>
          (aQuestion.orderInSurvey || aQuestion.orderInSection) -
          (bQuestion.orderInSurvey || bQuestion.orderInSection)
      ),
    }))

  const getAnswers = useCallback(
    () =>
      surveyResult
        ? getInitialAnswers({
            surveySchema,
            surveyQuestionAnswers: surveyResult?.surveyQuestionAnswers?.map(
              (answer) => ({
                ...answer,
                surveyQuestionId: answer.surveyQuestion?.id,
              })
            ),
          } as Survey)
        : [],
    [surveyResult, surveySchema]
  )

  const formMethods = useForm({
    defaultValues: {
      answers: getAnswers(),
    },
  })

  // This reset is necessary because we need to update the answer values
  // in the form when the user switches between patient results
  useEffect(
    () => formMethods.reset({ answers: getAnswers() }),
    [getAnswers, formMethods]
  )

  return (
    <>
      <FormProvider {...formMethods}>
        {filteredSections.map((section: Section) =>
          section.questions.map((question: QuestionInSection) => (
            <Card sx={{ p: 3, mb: 2 }} key={question.idForRender}>
              <FillQuestion
                questionOrder={question.orderInSurvey || 0}
                question={question}
                isPreview={!!isPreview}
                readOnly={true}
              />
            </Card>
          ))
        )}
      </FormProvider>
    </>
  )
}
