import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'
import {
  getSurveys,
  getSurveysVariables,
} from '../../../../models/graphqlTypes'
import { GET_PATIENT_SURVEYS } from '../operations/patientSurveyOperations'
import { PatientSurvey } from '../types/patientEvents.types'

type Options = QueryHookOptions<getSurveys, getSurveysVariables>

type ReturnData = {
  loading: boolean
  patientSurveys: PatientSurvey[]
  totalSurveys: number
  hasMoreSurveys: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreSurveys: (options: Options) => void
}

export const usePatientSurveys = (options: Options = {}): ReturnData => {
  const {
    data: surveyData,
    loading,
    refetch,
    fetchMore,
    networkStatus,
  } = useQuery<getSurveys, getSurveysVariables>(GET_PATIENT_SURVEYS, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    ...options,
  })

  const totalSurveys = surveyData?.getOwnSurveys.total || 0
  const patientSurveys: PatientSurvey[] =
    surveyData?.getOwnSurveys.surveys || []
  const hasMoreSurveys = patientSurveys.length < totalSurveys

  return {
    loading,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreSurveys: fetchMore,
    refetch,
    patientSurveys,
    totalSurveys,
    hasMoreSurveys,
  }
}
