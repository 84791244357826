import React from 'react'
import { Card } from '@mui/material'
import { EditSurveyButton } from './EditSurveyButton'
import { DeleteSurveyButton } from './DeleteSurveyButton'
import { SendSurveyButton } from './SendSurveyButton'
import { PublishSurveyButton } from './PublishSurveyButton'
import { DuplicateSurveyButton } from './DuplicateSurveyButton'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

interface Props {
  surveySchema: SurveySchemaWithoutUser
  refetchSurveys?: () => void
  hideSensitiveMenuItems?: boolean
}

export const SurveyActionsMenu: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  refetchSurveys,
  hideSensitiveMenuItems = false,
}) => {
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: 1.5,
      }}
    >
      <EditSurveyButton
        surveySchema={surveySchema}
        sx={{ justifyContent: 'flex-start' }}
        isReadonly={hideSensitiveMenuItems}
      />
      <SendSurveyButton
        surveySchema={surveySchema}
        sx={{ justifyContent: 'flex-start' }}
      />
      <DuplicateSurveyButton
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        sx={{ justifyContent: 'flex-start' }}
      />
      {!hideSensitiveMenuItems && (
        <>
          <PublishSurveyButton
            surveySchema={surveySchema}
            variant="text"
            sx={{ justifyContent: 'flex-start' }}
          />
          <DeleteSurveyButton
            surveySchema={surveySchema}
            refetchSurveys={refetchSurveys}
            sx={{ justifyContent: 'flex-start' }}
          />
        </>
      )}
    </Card>
  )
}
