import { Edit as EditIcon } from '@mui/icons-material'
import React, { MouseEventHandler } from 'react'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Box, Grid, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUserType } from '../../../../common/hooks/useUserType'
import { useHistory } from 'react-router-dom'
import {
  treatmentChore,
  TreatmentStatus,
} from '../../../../models/graphqlTypes'
import { usePermissions } from '../../../../common/hooks/usePermissions'
import { DoctorPatientTreatment } from '../types/treatments.types'

type Props = {
  treatment: DoctorPatientTreatment
  patientId: string
  onEdit?: (value: treatmentChore) => void
}

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
}))

const TreatmentHeader: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { treatment, patientId, onEdit } = props
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useStyles()

  const selectedLanguage = useSelectedLanguage()
  const userType = useUserType()

  const { isOwnTreatment, isAssistantOfTreatment } = usePermissions(
    treatment?.doctor.id
  )

  const isDraft = treatment.status === TreatmentStatus.Draft

  const ownAppointmentsCount = treatment.doctorAppointments?.length || 0
  const nextOwnAppointmentDate = treatment.doctorAppointments?.length
    ? treatment.doctorAppointments[0].proposedDate
    : null
  const nextAppointmentDate = nextOwnAppointmentDate

  const handleEditClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    onEdit?.(treatment)
  }

  return (
    <Box
      key={treatment.id}
      className={classes.container}
      border={1}
      borderRadius="8px"
      mb={1}
      py={isOwnTreatment && onEdit ? 0.5 : 1.5}
      pl={2}
      pr={1}
      data-cy={isOwnTreatment ? 'OwnTreatmentHeader-Box-wrapper' : undefined}
      onClick={() => {
        history.push(`/${userType}/patients/${patientId}/${treatment.id}`)
      }}
    >
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm>
          <Typography variant="h6" color="primary" component="span">
            {treatment.title[selectedLanguage]}
          </Typography>
          {!isOwnTreatment && !isAssistantOfTreatment && (
            <Typography
              variant="body2"
              component="span"
              color="textSecondary"
              sx={{ pl: 1 }}
            >
              ({t('treatment:notMyTratment')}
              {isDraft && `, ${t('treatment:doctor.status.Draft')}`})
            </Typography>
          )}
        </Grid>
        {nextAppointmentDate && (
          <>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {t('common:patientFormattedDate', {
                  date: nextAppointmentDate,
                })}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body2" color="textSecondary">
                {t('treatment:appointmentsCount', {
                  number: ownAppointmentsCount,
                })}
              </Typography>
            </Grid>
          </>
        )}
        {isOwnTreatment && onEdit && (
          <Grid item>
            <IconButton onClick={handleEditClick} size="large">
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export { TreatmentHeader }
