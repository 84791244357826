import React, { useState, useRef } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useMutation } from '@apollo/client'
import { useTreatmentCancel } from '../../hooks/useTreatmentCancel'
import { useStoreActions } from '../../../../../store/store.hooks'
import {
  deleteAppointments as deleteAppointmentsData,
  deleteAppointmentsVariables,
  TreatmentStatus,
} from '../../../../../models/graphqlTypes'
import {
  isAppointment,
  sortAppointmentsAndSurveys,
} from '../../utils/appointment.util'
import { DELETE_APPOINTMENTS } from '../../../../../operations/appointmentOperations'
import { Loading } from '../../../../../common/components/Loading'
import { useSurveysDeleteFromTreatment } from '../../../SurveyStore/hooks/useSurveysDeleteFromTreatment'
import { useDoctorPatientTreatmentsData } from '../../hooks/useDoctorPatientTreatmentsData'
import { Treatment } from '../../types/treatments.types'

interface DeleteTreatmentModalProps {
  isOpen: boolean
  toggleIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  treatment?: Treatment
  onCompleted?: () => void
}

const DeleteTreatmentModal: React.FC<
  React.PropsWithChildren<DeleteTreatmentModalProps>
> = ({ isOpen, toggleIsOpen, treatment, onCompleted }) => {
  const { t } = useTranslation()
  const commentInputRef = useRef<HTMLInputElement>(null)

  const selectedLanguage = useSelectedLanguage()

  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const futureAppointments =
    treatment?.appointments?.filter(
      (appointment) => +new Date(appointment.proposedDate) > Date.now()
    ) || []

  const futureSurveys =
    treatment?.surveys?.filter(
      (survey) => +new Date(survey.fillableFrom) > Date.now()
    ) || []

  const sortedFutureAppointmentsAndSurveys = sortAppointmentsAndSurveys(
    futureAppointments,
    futureSurveys
  )

  const [deleteAllAppointmentsAndSurveys, setDeleteAllAppointmentsAndSurveys] =
    useState(false)

  const { refetch: refetchDoctorPatientTreatmentsData } =
    useDoctorPatientTreatmentsData({
      skip: true,
    })

  const [deleteSurveysFromTreatment] = useSurveysDeleteFromTreatment()

  const [deleteAppointments, { loading: appointmentLoading }] = useMutation<
    deleteAppointmentsData,
    deleteAppointmentsVariables
  >(DELETE_APPOINTMENTS, {
    onCompleted: () => {
      refetchDoctorPatientTreatmentsData()
    },
    update(cache, { data }) {
      cache.modify({
        id: cache.identify({ ...treatment, __typename: 'Treatment' }),
        fields: {
          appointments(existingAppointmentsRefs = [], { readField }) {
            if (data?.deleteAppointments) {
              // remove the deleted appointments from the cached treatment's field
              return existingAppointmentsRefs.filter(
                (ref: { _ref: string }) =>
                  !data.deleteAppointments.find(
                    (deleted) => deleted.id === readField('id', ref)
                  )
              )
            }
          },
        },
      })
    },
  })

  const [cancelTreatment, { loading: treatmentCancelling }] =
    useTreatmentCancel({
      onCompleted,
    })

  const handleClose = () => {
    setDeleteAllAppointmentsAndSurveys(false)
    toggleIsOpen(false)
  }
  const isDraft = treatment?.status === TreatmentStatus.Draft

  const handleSubmit = async () => {
    if (treatment) {
      if (futureAppointments.length > 0) {
        await deleteAppointments({
          variables: {
            appointmentIds: futureAppointments.map(
              (appointment) => appointment.id
            ),
          },
        })
      }

      if (futureSurveys.length > 0) {
        await deleteSurveysFromTreatment({
          variables: {
            treatmentId: treatment.id,
            surveyIds: futureSurveys.map((survey) => survey.id),
          },
        })
      }

      await cancelTreatment({
        variables: {
          treatmentData: {
            treatmentId: treatment.id,
            closingComment: commentInputRef.current?.value || '',
          },
        },
      })

      setToast({
        text: t('notification:successDeleteTreatment'),
        type: 'success',
      })

      handleClose()
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }}
    >
      <DialogTitle>{t('treatment:doctor.deleteTreatment')}</DialogTitle>
      {treatmentCancelling && (
        <Box
          minHeight={300}
          width={660}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Loading />
        </Box>
      )}
      {!treatmentCancelling && (
        <>
          <DialogContent>
            <DialogContentText>
              {sortedFutureAppointmentsAndSurveys.length > 0
                ? t('treatment:doctor.deleteTreatmentDescription')
                : t('treatment:doctor.deleteEmptyTreatmentDescription')}
            </DialogContentText>
            <Box mb={2} mt={2}>
              <List dense={false}>
                {sortedFutureAppointmentsAndSurveys.map(
                  (appointmentOrSurvey) => {
                    return (
                      <ListItem key={appointmentOrSurvey.id}>
                        <Box flex={2}>
                          <ListItemText
                            disableTypography
                            primary={
                              appointmentOrSurvey.__typename ===
                              'Appointment' ? (
                                appointmentOrSurvey.info?.doctorTitle[
                                  selectedLanguage
                                ]
                              ) : (
                                <Typography variant="italic" fontWeight={500}>
                                  {appointmentOrSurvey.surveySchema.title}
                                </Typography>
                              )
                            }
                          />
                        </Box>
                        <Box flex={1} pl={1}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant={
                                  isAppointment(appointmentOrSurvey)
                                    ? 'inherit'
                                    : 'italic'
                                }
                                fontWeight={400}
                              >
                                {t('common:dateFormatted', {
                                  date: appointmentOrSurvey.date,
                                })}
                              </Typography>
                            }
                          />
                        </Box>
                      </ListItem>
                    )
                  }
                )}
              </List>
            </Box>
          </DialogContent>
          {sortedFutureAppointmentsAndSurveys.length > 0 && (
            <DialogActions>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={deleteAllAppointmentsAndSurveys}
                    onChange={() =>
                      setDeleteAllAppointmentsAndSurveys((value) => !value)
                    }
                    name="deleteAllAppointments"
                    color="primary"
                  />
                }
                label={t('treatment:doctor.deleteAppointmentsConfirm')}
              />
            </DialogActions>
          )}

          {!isDraft && (
            <Box px={2} mb={2}>
              <Box mb={0.4}>
                <Typography variant="subtitle2">
                  {t('treatment:doctor.reasonOfClosing')}
                </Typography>
              </Box>
              <TextField
                id="outlined-multiline-static"
                multiline
                minRows={4}
                fullWidth={true}
                variant="outlined"
                inputRef={commentInputRef}
                inputProps={{ maxLength: 500 }}
              />
            </Box>
          )}
        </>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          {t('common:cancel')}
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={handleSubmit}
          disabled={
            (futureAppointments.length > 0 &&
              !deleteAllAppointmentsAndSurveys) ||
            treatmentCancelling ||
            appointmentLoading
          }
          startIcon={
            treatmentCancelling || appointmentLoading ? (
              <Loading inline size={18} color="inherit" />
            ) : undefined
          }
        >
          {t('common:delete')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { DeleteTreatmentModal }
