import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { Button, ButtonProps } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'

interface Props extends ButtonProps {
  onClear: () => void
}

export const ClearFiltersButton: React.FC<React.PropsWithChildren<Props>> = ({
  onClear,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  return (
    <Button
      startIcon={<HighlightOffIcon fontSize="small" />}
      variant="text"
      onClick={onClear}
      {...buttonProps}
    >
      {t('common:deleteFilters')}
    </Button>
  )
}
