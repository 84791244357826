import { MutationHookOptions, useQuery } from '@apollo/client'
import {
  getAssistantProfilesForDoctor,
  getAssistantProfilesForDoctorVariables,
} from '../../models/graphqlTypes'
import { AssistantProfile } from '../../views/doctor/SurveyStore/components/Surveys/SendSurvey/sendSurvey.types'
import { GET_ASSISTANT_PROFILES_FOR_DOCTOR } from '../../operations/assistantProfileOperations'

type Options = MutationHookOptions<
  getAssistantProfilesForDoctor,
  getAssistantProfilesForDoctorVariables
>

type DoctorsListReturnType = {
  loading: boolean
  assistants: AssistantProfile[]
}

export const useAssistantsForSendSurvey = (
  options: Options = {}
): DoctorsListReturnType => {
  const { data, loading } = useQuery<
    getAssistantProfilesForDoctor,
    getAssistantProfilesForDoctorVariables
  >(GET_ASSISTANT_PROFILES_FOR_DOCTOR, options)

  const assistants = data?.getAssistantProfiles.profiles || []

  return { loading, assistants }
}
