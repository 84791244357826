import { Stack, Typography } from '@mui/material'
import React from 'react'
import QuizIcon from '@mui/icons-material/Quiz'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'

interface Props {
  numberOfQuestions: number
}

export const SurveyQuestionNumber: React.FC<React.PropsWithChildren<Props>> = ({
  numberOfQuestions,
}) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" gap={1}>
      <QuizIcon fontSize="large" />
      <Typography variant="italic">
        {numberOfQuestions === 0
          ? t('survey:editor.noQuestions')
          : t('survey:editor.numberOfQuestions', {
              numberOfQuestions,
            })}
      </Typography>
    </Stack>
  )
}
