import { Box, Hidden, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { Avatar } from '../../../../../common/components/Avatar'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { TreatmentLogTypeEnum } from '../../../../../models/graphqlTypes'
import { TreatmentLogs } from '../../types/treatments.types'

type Props = {
  treatmentLogs: TreatmentLogs[]
}

type TreatmentLogDiffRowProps = {
  data: string | Date
  type?: TreatmentLogTypeEnum | null
}
const TreatmentLogDiffRow: React.FC<
  React.PropsWithChildren<TreatmentLogDiffRowProps>
> = ({ data, type }) => {
  const { t } = useTranslation()
  switch (type) {
    case TreatmentLogTypeEnum.TreatmentAppointmentDateUpdated:
      return (
        <Typography variant="subtitle1">
          {t('common:intlDateFormattedWithHours', {
            date: data,
          })}
        </Typography>
      )
    case TreatmentLogTypeEnum.TreatmentAppointmentStatusUpdated:
      return (
        <Typography variant="subtitle1">
          {t(`appointment:status.${data}`)}
        </Typography>
      )
    default:
      return <Typography variant="subtitle1">{data.toString()}</Typography>
  }
}
export const TreatmentLog: React.FC<React.PropsWithChildren<Props>> = ({
  treatmentLogs,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const selectedLanguage = useSelectedLanguage()

  return (
    <>
      {treatmentLogs.length > 0 && (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          flexDirection="column"
          px={2}
          width="100%"
        >
          {treatmentLogs.map((treatmentLog) => {
            return (
              <Box
                key={treatmentLog.id}
                display="flex"
                flexDirection="column"
                alignItems="center"
                py={2}
                width="100%"
                borderBottom={`1px solid ${colorPalette.grey[200]}`}
                data-cy={`TreatmentLog-Box-${treatmentLog.type}`}
              >
                <Box
                  display="flex"
                  flexDirection={{ xs: 'column', md: 'row' }}
                  justifyContent="space-between"
                  alignItems={{ xs: 'flex-start', md: 'center' }}
                  width="100%"
                >
                  <Box display="flex" alignItems="center" width="200px">
                    <Hidden mdDown>
                      <Avatar
                        size="tiny"
                        firstName={treatmentLog.user?.firstName}
                        lastName={treatmentLog.user?.lastName}
                      />
                    </Hidden>
                    <Typography variant="body2">
                      {t('common:formattedNameFull', {
                        title: treatmentLog.user?.title ?? '',
                        firstName: treatmentLog.user?.firstName ?? '',
                        lastName: treatmentLog.user?.lastName ?? '',
                      })}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    flex={1}
                  >
                    <Typography variant="subtitle2">
                      {`${t(`treatment:treatmentLog.${treatmentLog.type}`)}`}
                    </Typography>
                    <Hidden mdDown>
                      <Typography variant="subtitle2">{`-`}</Typography>
                    </Hidden>
                    <Typography color="primary" variant="subtitle2">
                      {`${
                        treatmentLog.title &&
                        treatmentLog.title[selectedLanguage]
                      }`}
                    </Typography>
                  </Box>
                  <Box width="200px">
                    <Typography variant="subtitle2">
                      {t('common:intlDateFormattedWithHours', {
                        date: treatmentLog.date,
                      })}
                    </Typography>
                  </Box>
                </Box>
                {treatmentLog.diff && (
                  <Box
                    display="flex"
                    mt={2}
                    width="100%"
                    flexDirection={{ xs: 'column', md: 'row' }}
                  >
                    <Box
                      flex={1}
                      display="flex"
                      alignItems="center"
                      justifyContent={{ md: 'flex-end' }}
                    >
                      <TreatmentLogDiffRow
                        data={treatmentLog.diff.pre}
                        type={treatmentLog.type}
                      />
                    </Box>
                    {(treatmentLog.diff.pre || treatmentLog.diff.post) && (
                      <Box display="flex" alignItems="center" mx={2}>
                        <Typography variant="body1">{`->`}</Typography>
                      </Box>
                    )}
                    <Box flex={1} display="flex" alignItems="center">
                      <TreatmentLogDiffRow
                        data={treatmentLog.diff.post}
                        type={treatmentLog.type}
                      />
                    </Box>
                  </Box>
                )}
              </Box>
            )
          })}
        </Box>
      )}
    </>
  )
}
