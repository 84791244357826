import React, { useState, useEffect } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Share as ShareIcon } from '@mui/icons-material'
import { ShareProtocolModal } from '../ShareProtocolModal'
import { useDoctorsList } from '../../../../../common/hooks/useDoctorsList'
import { TreatmentSchema } from '../../types/treatmentSchemas.types'

interface Props extends ButtonProps {
  treatmentSchema: TreatmentSchema
}

export const TreatmentSchemaShareButton: React.FC<
  React.PropsWithChildren<Props>
> = ({ treatmentSchema, ...buttonProps }) => {
  const { t } = useTranslation()

  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false)

  const { doctorsWithoutMe } = useDoctorsList()

  useEffect(() => {
    return () => {
      setIsShareModalOpen(false)
    }
  }, [])

  return (
    <>
      <Button
        variant="text"
        startIcon={<ShareIcon fontSize="large" />}
        onClick={() => setIsShareModalOpen(true)}
        {...buttonProps}
      >
        {t('table:body.shareTooltip')}
      </Button>

      <ShareProtocolModal
        doctors={doctorsWithoutMe}
        isOpen={isShareModalOpen}
        toggleShareProtocolModal={setIsShareModalOpen}
        selectedProtocol={treatmentSchema}
      />
    </>
  )
}
