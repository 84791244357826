import { BloodType } from '../models/graphqlTypes'

export const BloodTypeNames = {
  A_plus: 'A+',
  A_minus: 'A-',
  B_plus: 'B+',
  B_minus: 'B-',
  O_plus: '0+',
  O_minus: '0-',
  AB_plus: 'AB+',
  AB_minus: 'AB-',
}

export const prettifyBloodType = (uglyBloodType: BloodType): string => {
  return BloodTypeNames[uglyBloodType] || uglyBloodType
}
