import React from 'react'
import { Theme } from '@mui/material/styles/createTheme'
import {
  Link,
  Typography,
  Stack,
  TypographyProps,
  useMediaQuery,
} from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { Groups as GroupsIcon } from '@mui/icons-material'
import makeStyles from '@mui/styles/makeStyles'
import { AvatarForIcon } from './AvatarForIcon'
import { useTranslation } from '../../hooks/helper/useTranslation'

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.common.white,
  },
}))

interface Props extends TypographyProps {
  isFooterOpen?: boolean
}

export const About: React.FC<React.PropsWithChildren<Props>> = ({
  isFooterOpen = false,
  ...typoProps
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const mdUp = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'))

  return (
    <Stack direction="row" gap={2}>
      {isFooterOpen && mdUp && (
        <AvatarForIcon>
          <GroupsIcon fontSize="inherit" />
        </AvatarForIcon>
      )}
      <Link
        className={classes.link}
        component={RouterLink}
        to="/about"
        underline="hover"
        mt={isFooterOpen ? 2.5 : 0}
      >
        <Typography {...typoProps}>{t('footer:about.title')}</Typography>
      </Link>
    </Stack>
  )
}
