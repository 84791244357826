import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { useMutation } from '@apollo/client'

import { Box, Button, Tab, Tabs, Typography } from '@mui/material'
import {
  CancelOutlined as CancelOutlinedIcon,
  ReplyOutlined as ReplyOutlinedIcon,
} from '@mui/icons-material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTreatmentSend } from '../../hooks/useTreatmentSend'
import { useUserType } from '../../../../../common/hooks/useUserType'

import {
  deleteDraftTreatment,
  deleteDraftTreatmentVariables,
  TreatmentStatus,
} from '../../../../../models/graphqlTypes'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useStoreActions } from '../../../../../store/store.hooks'
import { usePermissions } from '../../../../../common/hooks/usePermissions'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { Loading } from '../../../../../common/components/Loading'
import { DELETE_DRAFT_TREATMENT } from '../../../../../operations/treatmentOperations'
import { DeleteTreatmentModal } from './DeleteTreatmentModal'
import { TreatmentPanelTab } from '../../types/treatmentPanel.types'
import { Treatment } from '../../types/treatments.types'

type Props = {
  treatment: Treatment
  activeTabIndex: TreatmentPanelTab
  setActiveTabIndex: React.Dispatch<React.SetStateAction<TreatmentPanelTab>>
}

type RouteParams = {
  patientId: string
}

const TreatmentPanelHeader: React.FC<React.PropsWithChildren<Props>> = ({
  treatment,
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { patientId } = useParams<RouteParams>()
  const setToast = useStoreActions((actions) => actions.toast.setToast)

  const userType = useUserType()
  const selectedLanguage = useSelectedLanguage()

  const { isOwnTreatment } = usePermissions(treatment?.doctor.id)

  const [deleteTreatmentModal, toggleDeleteTreatmentModal] = useState(false)

  const [sendTreatmentOpen, setSendTreatmentOpen] =
    useState<ConfirmDialogValue>(false)

  const [deleteDraftTreatmentModal, toggleDeleteDraftTreatmentModal] =
    useState<ConfirmDialogValue>(false)

  const isDeleted = treatment?.status === TreatmentStatus.Cancelled
  const isDraft = treatment?.status === TreatmentStatus.Draft

  const [deleteDraftTreatmentMutation] = useMutation<
    deleteDraftTreatment,
    deleteDraftTreatmentVariables
  >(DELETE_DRAFT_TREATMENT, {
    onCompleted: async () => {
      setToast({
        text: t('notification:successDeleteDraftTreatment'),
        type: 'success',
      })
      history.push(`/${userType}/patients/${patientId}`)
    },
  })

  const [sendTreatment, { loading: loadingSendTreatment }] = useTreatmentSend({
    onCompleted: () => {
      setToast({
        text: t('messages:notification.sendTreatment.success'),
        type: 'success',
      })
    },
  })

  const sendTreatmentToPatient = () =>
    sendTreatment({
      variables: {
        treatmentId: treatment.id,
      },
      refetchQueries: ['getTreatment'],
    })

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        pl={2}
      >
        <Box py={0.5}>
          <Typography variant="h6" component="span">
            {treatment?.title[selectedLanguage]}
          </Typography>
          <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
            {treatment.firstAndLastDates?.firstAppointmentDate && (
              <Box mr={1}>
                <Typography variant="body2">
                  {`${t('common:intlDateFormattedLongMonth', {
                    date: treatment.firstAndLastDates.firstAppointmentDate,
                  })} - ${t('common:intlDateFormattedLongMonth', {
                    date: treatment.firstAndLastDates.lastAppointmentDate,
                  })}`}
                </Typography>
              </Box>
            )}
            <Typography variant="body2" color="primary">
              {t('common:formattedNameFull', treatment.doctor)}
            </Typography>
          </Box>
          {treatment.pocId && (
            <Box>
              <Typography
                style={{ opacity: 0.8 }}
                variant="body2"
                component="span"
              >
                POC ID: {treatment.pocId}
              </Typography>
            </Box>
          )}
        </Box>

        <Box display="flex" alignSelf={{ xs: 'center', sm: 'flex-end' }}>
          {!isDraft && (
            <Tabs
              value={activeTabIndex || TreatmentPanelTab.Events}
              onChange={(_e, newValue: number) => setActiveTabIndex(newValue)}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab
                label={
                  <Typography variant="h6">
                    {t('treatment:treatmentpanel.showAppointments')}
                  </Typography>
                }
                id={`treatment-panel-tab-0`}
                aria-controls={`treatment-panel-tabpanel-0`}
              />

              <Tab
                label={
                  <Typography variant="h6">
                    {t('treatment:treatmentpanel.showTreatmentLog')}
                  </Typography>
                }
                id={`treatment-panel-tab-1`}
                aria-controls={`treatment-panel-tabpanel-1`}
                data-cy="TreatmentPanelHeader-Tab-logs"
              />
            </Tabs>
          )}

          {isOwnTreatment && !isDeleted && (
            <Button
              sx={{ mb: 1 }}
              size="small"
              color="error"
              variant="text"
              startIcon={<CancelOutlinedIcon />}
              onClick={() =>
                isDraft
                  ? toggleDeleteDraftTreatmentModal((isOpen) => !isOpen)
                  : toggleDeleteTreatmentModal((isOpen) => !isOpen)
              }
            >
              {t(
                isDraft
                  ? 'treatment:doctor.deleteDraftTreatment'
                  : 'treatment:doctor.removeTreatment'
              )}
            </Button>
          )}

          {isOwnTreatment && isDraft && (
            <Box display="flex" alignItems="center" mr={2}>
              <Button
                sx={{ mb: 1 }}
                size="small"
                disabled={!(treatment.appointments || []).length}
                startIcon={
                  loadingSendTreatment ? (
                    <Loading inline size={12} />
                  ) : (
                    <ReplyOutlinedIcon />
                  )
                }
                onClick={() => setSendTreatmentOpen(true)}
                data-cy="TreatmentPanelHeader-Button-sendTreatment"
              >
                {t('treatment:doctor.sendTreatment')}
              </Button>
            </Box>
          )}
        </Box>
      </Box>

      {deleteTreatmentModal && (
        <DeleteTreatmentModal
          isOpen={deleteTreatmentModal}
          toggleIsOpen={toggleDeleteTreatmentModal}
          treatment={treatment}
        />
      )}

      <ConfirmDialog
        valueState={[sendTreatmentOpen, setSendTreatmentOpen]}
        title={t(`treatment:doctor.sendDialogTitle`)}
        text={t(`treatment:doctor.sendDialogText`)}
        onAccept={sendTreatmentToPatient}
        onCancel={() => setSendTreatmentOpen(false)}
      />

      <ConfirmDialog
        isAlertingDialog
        valueState={[
          deleteDraftTreatmentModal,
          toggleDeleteDraftTreatmentModal,
        ]}
        title={t('treatment:doctor.deleteDraftTreatment')}
        text={t('treatment:doctor.deleteEmptyTreatmentDescription')}
        onAccept={() => {
          deleteDraftTreatmentMutation({
            variables: {
              treatmentData: {
                treatmentId: treatment.id,
              },
            },
          })
        }}
        onCancel={() => toggleDeleteDraftTreatmentModal(false)}
      />
    </>
  )
}

export { TreatmentPanelHeader }
