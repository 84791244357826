import { gql } from '@apollo/client'
import { FRAGMENT_PATIENT_CHORE } from './fragments'

export const GET_ASSISTANT_PATIENTS = gql`
  query getAssistantPatients {
    getAssistantProfile {
      id
      ownPatients {
        id
        closedAt
        createdAt
        patient {
          id
          user {
            id
            email
          }
          ...patientChore
          birthDate
          weight
          height
          isDeletableForDoctor
        }
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const GET_ASSISTANT_DOCTORS = gql`
  query getAssistantDoctors {
    getAssistantProfile {
      id
      ownDoctors {
        id
        closedAt
        createdAt
        doctor {
          id
          title
          firstName
          lastName
        }
      }
    }
  }
`

export const UPDATE_ASSISTANT_PROFILE = gql`
  mutation updateAssistantProfile(
    $updateAssistantInput: UpdateAssistantProfileInput!
  ) {
    updateAssistantProfile(updateAssistantInput: $updateAssistantInput) {
      id
      title
      phone
    }
  }
`

export const GET_ASSISTANT_PROFILES_FOR_DOCTOR = gql`
  query getAssistantProfilesForDoctor(
    $paginationInput: PaginationInput
    $filter: AssistantProfileFilter
  ) {
    getAssistantProfiles(paginationInput: $paginationInput, filter: $filter) {
      profiles {
        id
        firstName
        lastName
        title
        user {
          id
        }
        ownPatients {
          id
          patient {
            id
            firstName
            lastName
            tajNumber
            birthDate
            doctors {
              id
            }
          }
        }
      }
      total
    }
  }
`
