import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const MessageIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (
  props
) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 16}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M15.8181818,6.61818182 C15.8181818,3.11272727 12.3127273,0.269090909 8,0.269090909 C3.68727273,0.269090909 0.181818182,3.11272727 0.181818182,6.61818182 C0.212126053,8.36374979 1.0334808,10.0011084 2.41454545,11.0690909 C2.57665753,11.8277643 2.09583259,12.5749922 1.33818182,12.7418182 C1.21163942,12.7673437 1.09825139,12.8369228 1.01818182,12.9381818 C0.850432683,13.1415915 0.805909792,13.4198596 0.901818182,13.6654545 C1.044047,13.9828148 1.34790002,14.1974633 1.69454545,14.2254545 C1.95845866,14.2738794 2.22622635,14.2982219 2.49454545,14.2981818 C3.85593048,14.2489882 5.15956302,13.7357471 6.18909091,12.8436364 C6.78583446,12.9590572 7.3921971,13.0175017 8,13.0181818 C12.3127273,12.9672727 15.8181818,10.1163636 15.8181818,6.61818182 Z M6.11636364,11.6363636 C5.95633364,11.6088274 5.79217546,11.6511908 5.66545455,11.7527273 C5.62320273,11.7855423 5.58638733,11.824812 5.55636364,11.8690909 C4.77450692,12.5798723 3.79085651,13.029759 2.74181818,13.1563636 C3.42070441,12.5134001 3.69889974,11.5536262 3.46909091,10.6472727 C3.44496846,10.5143934 3.37240837,10.3951875 3.26545455,10.3127273 C2.06174052,9.45906247 1.32490174,8.09295261 1.27272727,6.61818182 C1.27272727,3.70909091 4.29090909,1.35272727 8,1.35272727 C11.7090909,1.35272727 14.7272727,3.71636364 14.7272727,6.61090909 C14.7272727,9.50545455 11.7090909,11.8763636 8,11.8763636 C7.36512049,11.8670745 6.73328833,11.7865708 6.11636364,11.6363636 Z" />
    </SvgIcon>
  )
}

export { MessageIcon }
