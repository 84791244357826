import { isObject } from 'lodash'
import { PatientFilter } from '../../../models/graphqlTypes'
import { isEmpty } from '../../../utils/isEmpty'

export const hasActiveFilter = (filterData: PatientFilter): boolean =>
  Object.values(filterData).some((value) => {
    if (isObject(value)) {
      return Object.values(value).some((v) => !isEmpty(v))
    } else {
      return !isEmpty(value)
    }
  })
