import React, { JSX, useCallback, useEffect, useState } from 'react'
import { BetmenList } from '../../../../common/components/BetmenList/BetmenList'
import { BetmenListActions } from '../../../../common/components/BetmenList/BetmenListActions'
import { BetmenListBody } from '../../../../common/components/BetmenList/BetmenListBody'
import { BetmenListFilter } from '../../../../common/components/BetmenList/BetmenListFilter'
import { BetmenListHeader } from '../../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../../common/components/BetmenList/BetmenListItemCardCell'
import { useMe } from '../../../../common/hooks/useMe'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  SurveyQuestionEnum,
  SurveyQuestionFilterInput,
} from '../../../../models/graphqlTypes'
import { useSurveyQuestions } from '../hooks/useSurveyQuestions'
import {
  canEditQuestion,
  QUESTION_LIST_PAGE_SIZE,
} from '../utils/question.util'
import { EditQuestionModal } from './EditQuestion/EditQuestionModal'
import { CategoryFilter } from '../../../../common/components/Filters/CategoryFilter'
import { QuestionIcon } from './Questions/QuestionIcon'
import { Question } from './Questions/questions.types'
import { ViewQuestionModal } from './ViewQuestion/ViewQuestionModal'
import { BooleanFilter } from '../../../../common/components/Filters/BooleanFilter'
import { QuestionActionsMenu } from './QuestionActions/QuestionActionsMenu'

interface Props {
  isAddModalOpenState: [boolean, React.Dispatch<React.SetStateAction<boolean>>]
}

export const Questions: React.FC<React.PropsWithChildren<Props>> = ({
  isAddModalOpenState,
}) => {
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()
  const [isAddModalOpen, setAddModalOpen] = isAddModalOpenState
  const [questionToEdit, setQuestionToEdit] = useState<Question | null>(null)
  const [shouldCopyQuestion, setShouldCopyQuestion] = useState<boolean>(false)
  const [questionToOpen, setQuestionToOpen] = useState<Question | null>(null)

  const [filterInput, setFilterInput] = useState<SurveyQuestionFilterInput>({})

  const {
    surveyQuestions,
    loading,
    fetchingMore,
    fetchMoreQuestions,
    hasMoreQuestions,
    refetch,
  } = useSurveyQuestions({
    variables: {
      filterInput,
      paginationInput: {
        offset: 0,
        limit: QUESTION_LIST_PAGE_SIZE,
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [refetch, filterInput])

  const onQuestionEnter = useCallback(() => {
    const offset = surveyQuestions.length

    fetchMoreQuestions({
      variables: {
        filterInput,
        paginationInput: {
          offset,
          limit: QUESTION_LIST_PAGE_SIZE,
        },
      },
    })
  }, [surveyQuestions, fetchMoreQuestions, filterInput])

  const Filters: JSX.Element = (
    <>
      <BooleanFilter
        filterInput={!!filterInput.isOwn}
        setFilterInput={(newFilter: boolean) =>
          setFilterInput((prevInput) => ({ ...prevInput, isOwn: newFilter }))
        }
        label={t('survey:question.filter.own')}
      />
      <CategoryFilter
        label={t('survey:question.filter.type')}
        categories={Object.keys(SurveyQuestionEnum)}
        categoryFilter={filterInput.questionType || []}
        setCategoryFilter={(newCategoryFilter) =>
          setFilterInput((prevInput) => ({
            ...prevInput,
            questionType: newCategoryFilter as SurveyQuestionEnum[],
          }))
        }
        translationKey="survey:question.type"
      />
    </>
  )

  return (
    <>
      <BetmenList
        dataSource={surveyQuestions}
        loading={loading && !fetchingMore}
        fetchMore={onQuestionEnter}
        hasMoreItem={hasMoreQuestions}
        gridColumnSizes={[1, 7, 3, 1]}
        filters={
          <BetmenListFilter
            searchPlaceholder={t(`survey:questions.search`)}
            searchTerm={filterInput.searchTerm || ''}
            handleSearch={(searchTerm) =>
              setFilterInput((prevInput) => {
                return { ...prevInput, searchTerm }
              })
            }
          >
            {Filters}
          </BetmenListFilter>
        }
        header={
          <BetmenListHeader>
            <BetmenListHeaderCell justifyContent="center">
              {t(`survey:questions.type`)}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('survey:questions.title')}
            </BetmenListHeaderCell>
            <BetmenListHeaderCell>
              {t('survey:questions.owner')}
            </BetmenListHeaderCell>
          </BetmenListHeader>
        }
      >
        <BetmenListBody>
          {surveyQuestions.map((surveyQuestion) => (
            <BetmenListItemCard
              click={
                canEditQuestion(surveyQuestion, me)
                  ? () => setQuestionToEdit(surveyQuestion)
                  : () => setQuestionToOpen(surveyQuestion)
              }
              key={'wp+' + surveyQuestion.id}
            >
              <BetmenListItemCardCell
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <QuestionIcon
                  questionType={surveyQuestion.type}
                  fontSize="large"
                />
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {surveyQuestion.title}
              </BetmenListItemCardCell>
              <BetmenListItemCardCell>
                {surveyQuestion.isGlobal
                  ? t('survey:question.isGlobal')
                  : surveyQuestion.user?.id === me?.id &&
                    `${t('common:formattedNameFull', {
                      title: me?.profile?.title,
                      firstName: me?.profile?.firstName,
                      lastName: me?.profile?.lastName,
                    })} (${t('survey:question.own')})`}
              </BetmenListItemCardCell>

              <BetmenListActions
                more={
                  <QuestionActionsMenu
                    surveyQuestion={surveyQuestion}
                    setQuestionToEdit={setQuestionToEdit}
                    setQuestionToOpen={setQuestionToOpen}
                    setShouldCopyQuestion={setShouldCopyQuestion}
                  />
                }
              />
            </BetmenListItemCard>
          ))}
        </BetmenListBody>
      </BetmenList>

      {isAddModalOpen && (
        <EditQuestionModal
          open={isAddModalOpen}
          refetchQuestions={refetch}
          onClose={() => setAddModalOpen(false)}
        />
      )}

      {!!questionToEdit && (
        <EditQuestionModal
          open={!!questionToEdit}
          shouldCopyQuestion={shouldCopyQuestion}
          questionToEdit={questionToEdit}
          refetchQuestions={refetch}
          onClose={() => {
            setShouldCopyQuestion(false)
            setQuestionToEdit(null)
          }}
        />
      )}

      {!!questionToOpen && (
        <ViewQuestionModal
          open={!!questionToOpen}
          question={questionToOpen}
          onClose={() => setQuestionToOpen(null)}
        />
      )}
    </>
  )
}
