import { IconButton } from '@mui/material'
import { Help as HelpIcon } from '@mui/icons-material'
import React, { useState } from 'react'
import { HelpModal } from './HelpModal'

export const HelpButton: React.FC<React.PropsWithChildren<unknown>> = () => {
  const storageKey = 'protocolHelpPage'
  const [isOpen, setIsOpen] = useState(!localStorage.getItem(storageKey))

  const handleOpen = () => {
    setIsOpen(true)
  }

  const handleClose = () => {
    localStorage.setItem(storageKey, 'viewed')
    setIsOpen(false)
  }

  return (
    <>
      <IconButton color="primary" onClick={handleOpen} size="large">
        <HelpIcon fontSize="large" />
      </IconButton>
      {isOpen && <HelpModal isOpen={isOpen} onClose={handleClose} />}
    </>
  )
}
