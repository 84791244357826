import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  unSubscribeTreatmentSchema,
  unSubscribeTreatmentSchemaVariables,
} from '../../../../models/graphqlTypes'
import { UNSUBSCRIBE_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  unSubscribeTreatmentSchema,
  unSubscribeTreatmentSchemaVariables
>

type Return = MutationTuple<
  unSubscribeTreatmentSchema,
  unSubscribeTreatmentSchemaVariables
>

export const useTreatmentSchemaUnSubscribe = (
  options: Options = {}
): Return => {
  return useMutation<
    unSubscribeTreatmentSchema,
    unSubscribeTreatmentSchemaVariables
  >(UNSUBSCRIBE_TREATMENT_SCHEMA, {
    refetchQueries: ['getTreatmentSchemas'],
    ...options,
  })
}
