import { gql } from '@apollo/client'

export const FRAGMENT_INVITED_DOCTOR = gql`
  fragment invitedDoctor on User {
    id
    email
    lastLogin
    createdAt
    doctorProfile {
      title
      firstName
      lastName
    }
  }
`

export const GET_INVITED_DOCTORS_BY_ADMIN = gql`
  query getInvitedDoctorsByAdmin {
    getInvitedDoctorsByAdmin {
      ...invitedDoctor
    }
  }
  ${FRAGMENT_INVITED_DOCTOR}
`

export const DELETE_INVITE = gql`
  mutation deleteInvitation($deleteUserId: String!) {
    deleteInvitation(deleteUserId: $deleteUserId)
  }
`
