import { QueryResult, useQuery } from '@apollo/client'
import { isSurveyExpireSoonByIsOwnAndNotFinished } from '../../../../models/graphqlTypes'
import { GET_IS_SURVEY_EXPIRE_SOON_BY_USER_ID_AND_NOT_FINISHED } from '../operations/patientSurveyOperations'

type Return = QueryResult<isSurveyExpireSoonByIsOwnAndNotFinished>

export const usePatientIsSurveyExpireSoon = (): Return => {
  return useQuery<isSurveyExpireSoonByIsOwnAndNotFinished>(
    GET_IS_SURVEY_EXPIRE_SOON_BY_USER_ID_AND_NOT_FINISHED
  )
}
