import React, { FC } from 'react'
import { Box, Typography, Link, Divider } from '@mui/material'
import { ScrollablePanel } from '../common/components/ScrollablePanel'
import { useEesztFeed } from '../common/hooks/eeszt/useEesztFeed'
import { useTranslation } from '../common/hooks/helper/useTranslation'

export const EesztRssView: FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { data: { EesztFeed: feed } = {} } = useEesztFeed()

  return (
    <ScrollablePanel title={feed?.title}>
      <Box px={1} pb={1}>
        {feed?.items.map((item, idx, array) => (
          <Box key={item.guid}>
            <Box py={1}>
              <Link href={item.link} target="_blank">
                {item.title}
              </Link>
              <Box py={0.5}>
                <Typography align="justify">{item.description}</Typography>
              </Box>
              <Typography fontSize={12}>
                {t('common:patientFormattedDate', {
                  date: item.pubDate,
                })}
              </Typography>
            </Box>
            {idx < array.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>
    </ScrollablePanel>
  )
}
