import { ApolloError, MutationFunction, useMutation } from '@apollo/client'
import {
  EesztStsLogin,
  EesztStsLoginVariables,
} from '../../../models/graphqlTypes'
import { EESZT_STS_LOGIN } from '../../../operations/eesztOperations'

type ReturnTypes = {
  loading: boolean
  error: ApolloError | undefined
  loginToEeszt: MutationFunction<EesztStsLogin, EesztStsLoginVariables>
}

/**
 * Hook to handle the simple password authentication to EESZT
 */
export const useEeszPasswordLogin = (): ReturnTypes => {
  const [loginToEeszt, { loading, error }] = useMutation<
    EesztStsLogin,
    EesztStsLoginVariables
  >(EESZT_STS_LOGIN, { refetchQueries: ['hasEESZTToken'] })

  return {
    loading,
    error,
    loginToEeszt,
  }
}
