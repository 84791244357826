import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  deleteSurveyQuestion,
  deleteSurveyQuestionVariables,
} from '../../../../models/graphqlTypes'
import { DELETE_SURVEY_QUESTION } from '../operations/questionOperations'

type Options = MutationHookOptions<
  deleteSurveyQuestion,
  deleteSurveyQuestionVariables
>

type Return = MutationTuple<deleteSurveyQuestion, deleteSurveyQuestionVariables>

export const useSurveyQuestionDelete = (options: Options = {}): Return => {
  return useMutation<deleteSurveyQuestion, deleteSurveyQuestionVariables>(
    DELETE_SURVEY_QUESTION,
    options
  )
}
