import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  updateTreatmentSchema,
  updateTreatmentSchemaVariables,
} from '../../../../models/graphqlTypes'
import { useMutation } from '@apollo/client'
import { UPDATE_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  updateTreatmentSchema,
  updateTreatmentSchemaVariables
>

type Return = MutationTuple<
  updateTreatmentSchema,
  updateTreatmentSchemaVariables
>

export const useTreatmentSchemaUpdate = (options: Options = {}): Return => {
  return useMutation<updateTreatmentSchema, updateTreatmentSchemaVariables>(
    UPDATE_TREATMENT_SCHEMA,
    {
      ...options,
    }
  )
}
