import React from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { TreatmentStatus } from '../../../../../models/graphqlTypes'
import dayjs from 'dayjs'
import { AppointmentHeader } from './AppointmentHeader'
import { AppointmentContent } from './AppointmentContent'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Appointment, Treatment } from '../../types/treatments.types'

interface AppointmentModal {
  appointment?: Appointment
  isOpen: boolean | string
  onClose: () => void
  editAppointment: (appointment: Appointment) => void
  cloneAppointment: (appointment: Appointment) => void
  isOwnTreatment: boolean
  isAssistantOfTreatment: boolean
  treatment: Treatment
}

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    margin: 0,
  },
  paperFullWidth: {
    width: '100%',
  },
  paperScrollPaper: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
}))

const AppointmentModal: React.FC<React.PropsWithChildren<AppointmentModal>> = ({
  appointment,
  isOpen,
  onClose,
  editAppointment,
  cloneAppointment,
  isOwnTreatment,
  isAssistantOfTreatment,
  treatment,
}) => {
  const dialogClasses = useDialogStyles()
  const { t } = useTranslation()

  const isPast = dayjs().startOf('day') > dayjs(appointment?.proposedDate)

  return (
    <Dialog
      open={!!isOpen}
      classes={dialogClasses}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          onClose()
        }
      }}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        {!appointment && (
          <Box
            minHeight={360}
            display="flex"
            flexDirection="column"
            justifyContent="center"
          >
            <EmptyState
              title={t('appointment:patient.invalidAppointmentMessageTitle')}
              description={t(
                'appointment:patient.invalidAppointmentMessageContent'
              )}
            />
          </Box>
        )}
        {appointment && (
          <>
            <AppointmentHeader
              isOpen
              appointment={appointment}
              isPast={isPast}
              isOwnTreatment={isOwnTreatment}
              isDraft={treatment?.status === TreatmentStatus.Draft}
              isAssistantOfTreatment={isAssistantOfTreatment}
              editAppointment={() => editAppointment(appointment)}
              canLinkTo={false}
              disableLinking={false}
              cloneAppointment={() => cloneAppointment(appointment)}
            />
            <AppointmentContent
              isPast={isPast}
              isOwnTreatment={isOwnTreatment}
              isDraft={treatment?.status === TreatmentStatus.Draft}
              isAssistantOfTreatment={isAssistantOfTreatment}
              treatment={treatment}
              appointment={appointment}
              onDeleteComplete={onClose}
              onEditLocation={() => editAppointment(appointment)}
              onDeleteProtocolComplete={onClose}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClose}>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export { AppointmentModal }
