import React from 'react'

import { Box, Typography, Link } from '@mui/material'
import { TypographyVariant as ThemeVariant } from '@mui/material/styles'
import { useSelectedLanguage } from '../../hooks/useSelectedLanguage'
import { useTranslation } from '../../hooks/helper/useTranslation'
import {
  PatientAssistant,
  PatientInstitution,
} from '../../../views/patient/PatientEvents/types/patientEvents.types'

type Props = {
  institutions: PatientInstitution[]
  assistantProfiles: PatientAssistant[]
  typographyVariant?: ThemeVariant
}

const AssistantsInfoView: React.FC<React.PropsWithChildren<Props>> = ({
  assistantProfiles,
  institutions,
  typographyVariant = 'subtitle1',
}) => {
  const { t } = useTranslation()
  const selectedLanguage = useSelectedLanguage()
  const intlCollator = new Intl.Collator(selectedLanguage)

  const mergedAssistants: {
    name: string
    email: string | undefined
    phone: string
  }[] = assistantProfiles.map((assistantProfile) => ({
    name: t('common:formattedNameFull', assistantProfile),
    email: assistantProfile.user?.email,
    phone: assistantProfile.phone || '',
  }))

  const isValidInstitutionAssistant = institutions.length > 0
  if (isValidInstitutionAssistant) {
    institutions.forEach(({ assistant }) => {
      if (assistant) {
        mergedAssistants.push(assistant)
      }
    })
  }

  mergedAssistants.sort((firstAssistant, secondAssistant) =>
    intlCollator.compare(firstAssistant.name, secondAssistant.name)
  )

  const isAnyAssistant = mergedAssistants.length > 0

  return (
    <>
      {isAnyAssistant &&
        mergedAssistants.map(({ name, email, phone }, i) => (
          <Box key={i} mb={2}>
            <Typography variant={typographyVariant} color="primary">
              {name}
            </Typography>
            {email && email.length > 0 && (
              <Typography variant={typographyVariant} noWrap>
                <Link href={`mailto:${email}`} underline="none">
                  {email}
                </Link>
              </Typography>
            )}
            {phone.length > 0 && (
              <Typography variant={typographyVariant}>
                <Link
                  href={`tel:${phone}`}
                  underline="none"
                  color="textPrimary"
                >
                  {phone}
                </Link>
              </Typography>
            )}
          </Box>
        ))}
      {!isAnyAssistant && (
        <Typography variant={typographyVariant} color="textSecondary">
          {t('common:emptyField')}
        </Typography>
      )}
    </>
  )
}

export { AssistantsInfoView }
