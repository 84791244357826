import {
  ApolloError,
  OperationVariables,
  QueryLazyOptions,
  useLazyQuery,
} from '@apollo/client'
import { getEszigIdNoSaml as getEszigIdNoSamlQuery } from '../../../models/graphqlTypes'
import { GET_ESZIG_ID_NO_SAML } from '../../../operations/eesztOperations'

type ReturnTypes = {
  loading: boolean
  error: ApolloError | undefined
  getEszigIdNoSaml: (
    options?: QueryLazyOptions<OperationVariables> | undefined
  ) => void
}

/**
 * Hook to get eszig Id without SAML ticket. This id need for the eSZIG login
 */
export const useEesztEszigLogin = (): ReturnTypes => {
  const [getEszigIdNoSaml, { loading, error }] =
    useLazyQuery<getEszigIdNoSamlQuery>(GET_ESZIG_ID_NO_SAML, {
      onCompleted: ({ getEszigIdNoSaml }) => {
        if (getEszigIdNoSaml) {
          const url = `${process.env.REACT_APP_ESZIG_READER_URL}${getEszigIdNoSaml}`
          window.open(url)
        }
      },
    })

  return {
    loading,
    error,
    getEszigIdNoSaml,
  }
}
