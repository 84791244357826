import { NetworkStatus, QueryHookOptions, useQuery } from '@apollo/client'
import {
  GET_PATIENT_TREATMENTS,
  PATIENT_HOME_DATA,
} from '../../../../operations/treatmentOperations'

import {
  getPatientHomeData,
  getPatientHomeDataVariables,
  getTreatmentsToPatientDashboard,
  getTreatmentsToPatientDashboardVariables,
} from '../../../../models/graphqlTypes'
import {
  PatientHomeAppointment,
  PatientHomeTreatment,
} from '../types/patientEvents.types'

type Options = QueryHookOptions<getPatientHomeData, getPatientHomeDataVariables>

type ReturnData = {
  treatments: PatientHomeTreatment[]
  appointments: PatientHomeAppointment[]
  loading: boolean
  totalAppointments: number
  hasMoreAppointments: boolean
  fetchingMore: boolean
  refetch: () => void
  fetchMoreAppointments: (options: Options, skipTreatments: boolean) => void
}

// receive appointments for all or single treatment (used by patients)
export const usePatientHomeData = (
  options: Options = {},
  skipTreatments: boolean
): ReturnData => {
  // Appointments
  const {
    data: appointmentsData,
    loading: appointmentsLoading,
    refetch,
    networkStatus,
    fetchMore,
  } = useQuery<getPatientHomeData, getPatientHomeDataVariables>(
    PATIENT_HOME_DATA,
    {
      skip: options.skip || !options.variables?.patientId,
      ...options,
    }
  )

  // All selectable treatments
  const { data: treatmentsData, loading: treatmentsLoading } = useQuery<
    getTreatmentsToPatientDashboard,
    getTreatmentsToPatientDashboardVariables
  >(GET_PATIENT_TREATMENTS, {
    variables: { patientId: options.variables?.patientId || '' },
    skip: skipTreatments || !options.variables?.patientId,
  })

  const appointments = appointmentsData?.getAppointments.appointments || []
  const totalAppointments = appointmentsData?.getAppointments.total || 0

  return {
    treatments: treatmentsData?.getTreatmentsToPatientDashboard || [],
    appointments,
    loading: appointmentsLoading || treatmentsLoading,
    refetch,
    fetchingMore: networkStatus === NetworkStatus.fetchMore,
    fetchMoreAppointments: fetchMore,
    totalAppointments,
    hasMoreAppointments: appointments.length < totalAppointments,
  }
}
