import React, { FC, useState } from 'react'
import { Box, Button } from '@mui/material'
import { AddSquareIcon } from '../../../../common/icons/AddSquareIcon'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { Controller, useForm } from 'react-hook-form'
import { AppointmentInfoSelector } from '../../../../common/components/selectors/AppointmentInfoSelector'
import { appointmentInfoChore } from '../../../../models/graphqlTypes'

interface FormValues {
  appointmentInfo: appointmentInfoChore | null
}

interface Props {
  treatmentSchemaId?: string
  addNewSchedule: (appointmentInfoId: string) => void
}

export const TreatmentSchemaAddSchedule: FC<React.PropsWithChildren<Props>> = ({
  treatmentSchemaId,
  addNewSchedule,
}) => {
  const { t } = useTranslation()

  const [showInput, setShowInput] = useState<boolean>(false)

  const formMethods = useForm<FormValues>({
    defaultValues: {
      appointmentInfo: null,
    },
  })
  const { handleSubmit, reset, control, setValue } = formMethods

  const onSubmit = handleSubmit(async (formValues) => {
    if (!formValues.appointmentInfo) {
      return
    }
    await addNewSchedule(formValues.appointmentInfo.id)
    setShowInput(false)
    reset()
  })

  return (
    <Box mt={1} mb={1} width="50%" position="relative" zIndex={2}>
      {showInput ? (
        <Box display="flex">
          <Controller
            control={control}
            name="appointmentInfo"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, name } }) => (
              <AppointmentInfoSelector
                name={name}
                value={value}
                treatmentSchemaId={treatmentSchemaId}
                onChange={(val) => {
                  onChange(val)
                  if (val) {
                    onSubmit()
                  }
                }}
                onBlur={onBlur}
                onAppointmentInfoAdd={(newAppointmentInfo) => {
                  newAppointmentInfo &&
                    setValue('appointmentInfo', newAppointmentInfo)
                  onSubmit()
                }}
                hideTitle={true}
                hintText={t('protocol:schedule.addNewSchedule.hint')}
              />
            )}
          />
        </Box>
      ) : (
        <Button
          onClick={() => setShowInput(true)}
          startIcon={<AddSquareIcon />}
          data-cy="TreatmentSchemaAddScheduleButton-Button-add"
        >
          {t('protocol:schedule.createDialogTitle')}
        </Button>
      )}
    </Box>
  )
}
