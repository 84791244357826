import { useQuery } from '@apollo/client'
import {
  listAllProfessions,
  professionChore,
  UserType,
} from '../../models/graphqlTypes'
import { LIST_ALL_PROFESSIONS } from '../../operations/doctorProfileOperations'
import { useUserType } from './useUserType'

type ReturnTypes = {
  loading: boolean
  professions: professionChore[]
}

export const useListProfessions = (): ReturnTypes => {
  const userType = useUserType()
  const isAssistant = userType === UserType.Assistant
  const { data, loading } = useQuery<listAllProfessions>(LIST_ALL_PROFESSIONS, {
    skip: isAssistant,
  })
  const professions = data?.listAllProfessions || []

  return {
    loading,
    professions,
  }
}
