import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  updatePatientsBloodType,
  updatePatientsBloodTypeVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_PATIENT_BLOOD_TYPE } from '../operations/doctorPatientOperations'

type Options = MutationHookOptions<
  updatePatientsBloodType,
  updatePatientsBloodTypeVariables
>

type Return = MutationTuple<
  updatePatientsBloodType,
  updatePatientsBloodTypeVariables
>

export const useUpdatePatientBloodType = (options: Options = {}): Return => {
  return useMutation<updatePatientsBloodType, updatePatientsBloodTypeVariables>(
    UPDATE_PATIENT_BLOOD_TYPE,
    options
  )
}
