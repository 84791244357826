import { Autocomplete, TextField } from '@mui/material'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDoctorPatientsData } from '../../../../../../common/hooks/useDoctorPatientsData'
import { BoxWithLabel } from '../../../../../../common/components/BoxWithLabel'

export const SendSurveyToPatient: React.FC = () => {
  const { t } = useTranslation()
  const { patientList } = useDoctorPatientsData({
    showOnlyActivePatients: true,
  })

  const {
    control,
    formState: { errors },
  } = useFormContext()

  return (
    <BoxWithLabel label={t('survey:sendToPatientLabel')}>
      <Controller
        control={control}
        name="patients"
        rules={{
          validate: (value) =>
            value?.length < 1
              ? (t('messages:warnings.required') as string)
              : true,
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Autocomplete
            multiple
            options={patientList}
            disableClearable
            filterSelectedOptions
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(patientItem) =>
              `${patientItem.lastName} ${patientItem.firstName} (${dayjs(
                patientItem.birthDate ?? ''
              ).format('YYYY-MM-DD')}) - TAJ: ${patientItem.tajNumber}`
            }
            value={value || []}
            onChange={(_, value) => onChange([...value])}
            onBlur={onBlur}
            data-cy="SendSurveyPatientModal-Autocomplete-patients"
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!errors.patients}
                variant="outlined"
                name={name}
                helperText={errors.patients?.message?.toString()}
              />
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}
