import React, { useState } from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { passwordStrength, Options } from 'check-password-strength'
import { Button, TextField, Typography, Box, Zoom, Link } from '@mui/material'
import { useLogout } from '../hooks/useLogout'

import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import { useSetNewPassword } from '../hooks/useSetNewPassword'

interface RouteParams {
  token: string
}

const passwordStrengthOptions: Options<string> = [
  {
    id: 0,
    value: 'Too weak',
    minDiversity: 0,
    minLength: 0,
  },
  {
    id: 1,
    value: 'Strong',
    minDiversity: 3,
    minLength: 8,
  },
]

const SetNewPassword: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { token } = useParams<RouteParams>()
  const { t } = useTranslation()
  const logout = useLogout()
  const [errorType, setErrorType] = useState<string | null>(null)
  const [completed, setCompleted] = useState<boolean>(false)
  const {
    formState: { errors },
    register,
    handleSubmit,
    getValues,
  } = useForm({
    defaultValues: { newPassword: '', retypePassword: '' },
  })

  const [startPasswordReset] = useSetNewPassword({
    onCompleted: () => setCompleted(true),
    onError: (error) => setErrorType(error.message),
  })

  const onSubmit = handleSubmit((values) => {
    startPasswordReset({
      variables: {
        token,
        password: values?.newPassword,
      },
    })
  })

  return (
    <>
      <Box mt={3}>
        <Typography component="h1" variant="h3">
          {t('auth:setNewPasswordTitle')}
        </Typography>
      </Box>
      <Box mt={1} flexGrow={1} display="flex" alignItems="center">
        <Typography component="p" variant="body1">
          {completed
            ? t('auth:passwordResetCompleted')
            : t('auth:setNewPasswordMessage')}
        </Typography>
      </Box>
      {!completed && (
        <Box width="100%" mt={3}>
          <form onSubmit={onSubmit} noValidate>
            <TextField
              {...register('newPassword', {
                validate: (value) =>
                  passwordStrength(value, passwordStrengthOptions).id > 0 ||
                  'auth:passwordResetErrorType.weakPassword',
                required: {
                  value: true,
                  message: 'messages:warnings.emptyField',
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={t('auth:newPassword')}
              type="password"
              error={!!errors.newPassword}
              helperText={
                errors.newPassword?.message
                  ? t(errors.newPassword?.message)
                  : undefined
              }
              autoComplete="current-newPassword"
            />
            <TextField
              {...register('retypePassword', {
                validate: (value) =>
                  value === getValues('newPassword') ||
                  'auth:passwordsShouldBeSame',
                required: {
                  value: true,
                  message: 'messages:warnings.emptyField',
                },
              })}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={t('auth:retypePassword')}
              type="password"
              error={!!errors.retypePassword}
              helperText={
                errors.retypePassword?.message
                  ? t(errors.retypePassword?.message)
                  : undefined
              }
              autoComplete="current-retypePassword"
            />
            <Zoom in={!!errorType}>
              <Box mt={3}>
                {errorType && (
                  <Typography variant="body2" color="error">
                    {t(`auth:passwordResetErrorType.${errorType}`)}
                  </Typography>
                )}
              </Box>
            </Zoom>
            <Box mt={3}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                fullWidth
              >
                {t('auth:setNewPassword')}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      <Box my={3}>
        <Link component={RouterLink} to="/login" onClick={() => logout()}>
          <Typography variant="subtitle2" color="textSecondary">
            {t('common:back')}
          </Typography>
        </Link>
      </Box>
    </>
  )
}

export { SetNewPassword }
