import React from 'react'
import { Grid, Stack } from '@mui/material'
import { HelpButton } from '../../../../common/components/HelpBox/HelpButton'
import { useMe } from '../../../../common/hooks/useMe'
import { TreatmentSchemaRemoveButton } from './TreatmentSchemaActions/TreatmentSchemaRemoveButton'
import { SaveButton } from '../../../../common/components/SaveButton'
import { TreatmentSchemaPublishButton } from './TreatmentSchemaActions/TreatmentSchemaPublishButton'
import {
  canUnsubscribeFromTreatmentSchema,
  isTreatmentSchemaEditable,
  isTreatmentSchemaShareable,
} from '../../../../utils/treatmentSchemaHelper'
import { useFormContext } from 'react-hook-form'
import { TreatmentSchemaShareButton } from './TreatmentSchemaActions/TreatmentSchemaShareButton'
import { TreatmentSchemaUnsubscribeButton } from './TreatmentSchemaActions/TreatmentSchemaUnsubscribeButton'
import { MoreActionsMenu } from '../../../../common/components/MoreActionsMenu/MoreActionsMenu'
import { TreatmentSchemaById } from '../types/treatmentSchemas.types'

interface Props {
  treatmentSchema: TreatmentSchemaById
  hasAnyScheduleError: boolean
  onSaveAndPublish: () => void
}

export const TreatmentSchemaActions: React.FC<
  React.PropsWithChildren<Props>
> = ({ treatmentSchema, hasAnyScheduleError, onSaveAndPublish }) => {
  const { data: { me } = {} } = useMe({ fetchPolicy: 'cache-only' })
  const userId = me?.id
  const {
    formState: { isSubmitting, isDirty, dirtyFields, isValid },
  } = useFormContext()
  const isEditable = isTreatmentSchemaEditable(treatmentSchema, userId)

  const modified = isDirty && !!Object.keys(dirtyFields).length

  const canShare = isTreatmentSchemaShareable(treatmentSchema, userId)

  const canUnsubscribe = canUnsubscribeFromTreatmentSchema(
    treatmentSchema,
    userId
  )

  const canDelete = isEditable && treatmentSchema.isDeletable

  const isActiveSchema = !treatmentSchema.disabled

  const isSaveAndPublishButtonDisabled =
    (!isActiveSchema && (!isValid || hasAnyScheduleError)) ||
    !treatmentSchema.schedules.length

  return (
    <Stack direction="row" flex={1}>
      {isEditable && (
        <Grid item>
          <HelpButton />
        </Grid>
      )}

      <Grid
        container
        spacing={1}
        flex={1}
        alignItems="center"
        justifyContent="flex-end"
      >
        {isEditable && modified && (
          <Grid item>
            <SaveButton
              form="TreatmentSchema_details_form"
              type="submit"
              isSaving={isSubmitting}
            />
          </Grid>
        )}
        {isEditable && !modified && (
          <Grid item>
            <TreatmentSchemaPublishButton
              treatmentSchemaId={treatmentSchema.id}
              isActiveSchema={isActiveSchema}
              isSubmitting={isSubmitting}
              isDirty={modified}
              disabled={isSaveAndPublishButtonDisabled}
              onSaveAndPublish={onSaveAndPublish}
            />
          </Grid>
        )}

        {(canShare || canUnsubscribe || canDelete) && (
          <Grid item>
            <MoreActionsMenu>
              {canShare && (
                <TreatmentSchemaShareButton treatmentSchema={treatmentSchema} />
              )}
              {canUnsubscribe && (
                <TreatmentSchemaUnsubscribeButton
                  treatmentSchema={treatmentSchema}
                />
              )}
              {canDelete && (
                <TreatmentSchemaRemoveButton
                  treatmentSchemaId={treatmentSchema.id}
                />
              )}
            </MoreActionsMenu>
          </Grid>
        )}
      </Grid>
    </Stack>
  )
}
