import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import { useParams } from 'react-router-dom'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { EmptyState } from '../../../../common/components/EmptyState'
import { TreatmentSchemaPanel } from '..'
import { useSortedSchemaSchedules } from '../hooks/useSchemaSchedules'
import { useTreatmentSchemaData } from '../hooks/useTreatmentSchemaData'

type RouteParams = {
  treatmentOptionId: string
}

const TreatmentSchemaView: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const { treatmentOptionId: treatmentSchemaId } = useParams<RouteParams>()

  const { treatmentSchema, loading, refetch } = useTreatmentSchemaData({
    variables: {
      treatmentSchemaId,
    },
  })

  const refetchTreatmentSchema = useCallback(() => {
    refetch()
  }, [refetch])

  const schedules = useSortedSchemaSchedules(treatmentSchema?.schedules ?? [])

  return (
    <>
      {!treatmentSchema && !loading && (
        <Box
          flexGrow={1}
          display="flex"
          height="100%"
          alignItems="center"
          justifyContent="center"
        >
          <EmptyState
            title={t('treatment:doctor.treatmentSchemaNotFoundTitle')}
            description={t('treatment:doctor.treatmentSchemaNotFoundMessage', {
              treatmentSchemaId,
            })}
          />
        </Box>
      )}
      {treatmentSchema && !loading && (
        <TreatmentSchemaPanel
          treatmentSchema={treatmentSchema}
          schedules={schedules}
          surveys={treatmentSchema.surveySchemas}
          refetchTreatmentSchema={refetchTreatmentSchema}
        />
      )}
    </>
  )
}

export { TreatmentSchemaView }
