import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  updateSurveySchema,
  updateSurveySchemaVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_SURVEY_SCHEMA } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  updateSurveySchema,
  updateSurveySchemaVariables
>

type Return = MutationTuple<updateSurveySchema, updateSurveySchemaVariables>

export const useSurveySchemaUpdate = (options: Options = {}): Return => {
  return useMutation<updateSurveySchema, updateSurveySchemaVariables>(
    UPDATE_SURVEY_SCHEMA,
    options
  )
}
