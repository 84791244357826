import { MeProfile } from '../types/me.type'
import { useMe } from './useMe'

export const useUserProfile = (): MeProfile | undefined => {
  const { data: { me } = {} } = useMe({
    fetchPolicy: 'cache-only',
  })

  return me?.profile
}
