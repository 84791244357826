import React from 'react'
import { QuestionResultChart } from './QuestionResultChart'
import { MultipleChoiceQuestionResults } from '../../types/surveyStore.types'

interface Props {
  questionResult: MultipleChoiceQuestionResults
}

export const MultipleChoiceQuestionResult: React.FC<
  React.PropsWithChildren<Props>
> = ({ questionResult }) => {
  const { choiceResults, totalAnswers } = questionResult

  const data = choiceResults.map((choiceResult) => ({
    name: choiceResult.name,
    amount: choiceResult.total,
  }))

  return <QuestionResultChart data={data} numberOfAnswers={totalAnswers} />
}
