import {
  EditOutlined as EditOutlinedIcon,
  QuestionAnswerTwoTone,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import dayjs from 'dayjs'
import React, { FC, RefObject } from 'react'
import { ArrowUpIcon } from '../../../../../common/icons/ArrowUpIcon'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Appointment, Survey } from '../../types/treatments.types'

const useStyles = makeStyles((theme) => ({
  container: {
    cursor: 'pointer',
    borderWidth: 2,
    borderColor: theme.palette.grey[300],
    '&:hover': {
      background: theme.palette.grey[50],
    },
  },
  hovered: {
    borderColor: theme.palette.grey[800],
    borderWidth: 2,
  },
  past: {
    color: theme.palette.grey[600],
    background: theme.palette.grey[200],
    '&:hover': {
      background: theme.palette.grey[300],
    },
  },
}))

export interface TreatmentSurveyItemComponentViewModel {
  survey: Survey
  nextAppointment?: Appointment
  firstFutureAppointment?: Appointment
  nextSurvey?: Survey
  firstFutureSurvey?: Survey
  hasPast: boolean
  showPast: boolean
  firstRef: RefObject<HTMLElement | null>
  isHoveredInCalendar: boolean
  isOwnTreatment: boolean
  isAssistantOfTreatment: boolean
  isDraft: boolean
  setShowPast: React.Dispatch<React.SetStateAction<boolean>>
  onClick: (survey: Survey) => void
  onHover: (v: string | null) => void
  setHoveredDay: React.Dispatch<React.SetStateAction<string | null>>
  editSurvey: (survey: Survey) => void
}

interface Props {
  vm: TreatmentSurveyItemComponentViewModel
}

const TreatmentSurveyItemComponent: FC<React.PropsWithChildren<Props>> = ({
  vm,
}) => {
  const {
    survey,
    nextAppointment,
    firstFutureAppointment,
    nextSurvey,
    firstFutureSurvey,
    showPast,
    hasPast,
    isHoveredInCalendar,
    isOwnTreatment,
    isAssistantOfTreatment,
    isDraft,
    setShowPast,
    onClick,
    onHover,
    setHoveredDay,
    editSurvey,
  } = vm

  const theme = useTheme()
  const classes = useStyles()
  const { t } = useTranslation()

  const date = survey.fillableFrom
  const isPast = dayjs().startOf('day') > dayjs(date)
  const isNext =
    nextSurvey?.id === survey.id &&
    (!nextAppointment || nextAppointment.proposedDate > nextSurvey.fillableFrom)
  const isFuture =
    firstFutureSurvey?.id === survey.id &&
    (!firstFutureAppointment ||
      firstFutureAppointment.proposedDate > firstFutureSurvey.fillableFrom)
  const dateString = t('common:patientFormattedDate', { date })
  const canEditSurvey =
    (isOwnTreatment || isAssistantOfTreatment) && (!isPast || isDraft)

  return !showPast && isPast ? null : (
    <Box>
      {hasPast && isNext && (
        <Box ml={-1.5} mb={2}>
          <Button
            variant="text"
            size="small"
            disabled={showPast}
            onClick={() => setShowPast(true)}
          >
            <ArrowUpIcon sx={{ mr: 1, fontSize: '1.0rem' }} />
            {t('appointment:range.past')}
          </Button>
        </Box>
      )}
      {isNext && (
        <Box mb={1}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('appointment:range.next').toUpperCase()}
          </Typography>
        </Box>
      )}
      {isFuture && (
        <Box mb={1}>
          <Typography variant="subtitle1" color="textSecondary">
            {t('appointment:range.future').toUpperCase()}
          </Typography>
        </Box>
      )}
      <Box
        className={clsx(classes.container, {
          [classes.past]: isPast,
          [classes.hovered]: isHoveredInCalendar,
        })}
        border={1}
        borderRadius={1.3}
        mb={1}
        pl={2}
        pr={1}
        onMouseEnter={() => {
          onHover(survey.id)
          const date = survey?.fillableFrom
          const hoverDateString = dayjs(date).format('YYYY-MM-DD')
          setHoveredDay(hoverDateString)
        }}
        onMouseLeave={() => {
          onHover(null)
          setHoveredDay(null)
        }}
        onClick={() => onClick(survey)}
      >
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          alignItems={{ xs: 'flex-start', md: 'center' }}
          minHeight={40}
          data-id={survey?.id}
          data-cy={`Survey-Box-${survey?.id}`}
        >
          <Box width={280} flexShrink={0} mt={{ xs: 1, md: 0 }}>
            <Typography variant="italic" color="textSecondary">
              {dateString}
            </Typography>
          </Box>

          <Box flex={1}>
            <Typography sx={{ fontWeight: 700 }} variant="italic">
              {survey?.surveySchema?.title}
            </Typography>
          </Box>

          <Hidden only="md">
            <Typography
              variant="subtitle2"
              sx={{ pl: 0.5, color: theme.palette.success.dark }}
            >
              <QuestionAnswerTwoTone sx={{ mx: 1 }} fontSize="inherit" />
              {t('survey:surveyName')}
            </Typography>
          </Hidden>

          <Box
            display="flex"
            justifyContent="flex-end"
            flexShrink={0}
            ml={{ md: 1 }}
          >
            {canEditSurvey && (
              <Tooltip
                title={t('doctor:tooltips.edit-survey') as string}
                enterDelay={500}
                leaveDelay={200}
              >
                <IconButton
                  onClick={(ev) => {
                    ev.stopPropagation()
                    editSurvey(survey)
                  }}
                  size="large"
                >
                  <EditOutlinedIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

const TreatmentSurveyItem = React.memo(TreatmentSurveyItemComponent)

export { TreatmentSurveyItem }
