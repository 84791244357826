import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const DoctorIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  return (
    <SvgIcon viewBox="0 0 16 16" width="16" height="16" {...props}>
      <g transform="translate(-30.000000, -212.000000)" fillRule="nonzero">
        <g transform="translate(30.000000, 210.000000)">
          <g transform="translate(0.000000, 2.000000)">
            <path d="M8.16666667,16 C7.52333333,16 7,15.4766667 7,14.8333333 L7,10.1666667 C7,9.52333333 7.52333333,9 8.16666667,9 L9.5,9 L9.5,8.16666667 C9.5,7.52333333 10.0233333,7 10.6666667,7 L12.3333333,7 C12.9766667,7 13.5,7.52333333 13.5,8.16666667 L13.5,9 L14.8333333,9 C15.4766667,9 16,9.52333333 16,10.1666667 L16,14.8333333 C16,15.4766667 15.4766667,16 14.8333333,16 L8.16666667,16 Z M8.16666667,10 C8.07466667,10 8,10.0746667 8,10.1666667 L8,14.8333333 C8,14.9253333 8.07466667,15 8.16666667,15 L14.8333333,15 C14.9253333,15 15,14.9253333 15,14.8333333 L15,10.1666667 C15,10.0746667 14.9253333,10 14.8333333,10 L8.16666667,10 Z M12.5,9 L12.5,8.16666667 C12.5,8.07466667 12.4253333,8 12.3333333,8 L10.6666667,8 C10.5746667,8 10.5,8.07466667 10.5,8.16666667 L10.5,9 L12.5,9 Z" />
            <path d="M11.5,14 C11.224,14 11,13.776 11,13.5 L11,13 L10.5,13 C10.224,13 10,12.776 10,12.5 C10,12.224 10.224,12 10.5,12 L11,12 L11,11.5 C11,11.224 11.224,11 11.5,11 C11.776,11 12,11.224 12,11.5 L12,12 L12.5,12 C12.776,12 13,12.224 13,12.5 C13,12.776 12.776,13 12.5,13 L12,13 L12,13.5 C12,13.776 11.776,14 11.5,14 Z" />
            <path d="M5,12 C4.724,12 4.5,11.776 4.5,11.5 L4.5,7.53266667 C3.62266667,7.64266667 2.80533333,8.04133333 2.174,8.67133333 C1.41733333,9.42666667 1.00066667,10.4313333 1,11.5 C1,11.776 0.775333333,12 0.5,12 C0.224,12 0,11.7753333 0,11.5 C0.002,8.75733333 2.23133333,6.518 4.972,6.502 C4.982,6.50066667 4.99266667,6.5 5,6.5 C5.00933333,6.5 5.01866667,6.50066667 5.028,6.50133333 C5.69133333,6.50333333 6.34466667,6.638 6.96266667,6.90133333 C7.216,7.00933333 7.33466667,7.30333333 7.22666667,7.55733333 C7.148,7.742 6.968,7.86133333 6.76666667,7.86133333 C6.69933333,7.86133333 6.63333333,7.848 6.57066667,7.82133333 C6.22733333,7.67533333 5.868,7.578 5.5,7.53266667 L5.5,11.5 C5.5,11.776 5.276,12 5,12 Z" />
            <path d="M5,6.5 C3.208,6.5 1.75,5.042 1.75,3.25 C1.75,2.69066667 1.89733333,2.13733333 2.176,1.64733333 C2.18733333,1.624 2.19933333,1.60666667 2.20666667,1.596 C2.79,0.612666667 3.86133333,0 5,0 C6.592,0 7.96866667,1.18533333 8.20933333,2.75866667 C8.218,2.79333333 8.22333333,2.82733333 8.224,2.86066667 C8.242,3.00533333 8.25,3.12933333 8.25,3.25 C8.25,5.042 6.792,6.5 5,6.5 Z M2.82,2.69133333 C2.77333333,2.87333333 2.75,3.06 2.75,3.25 C2.75,4.49066667 3.75933333,5.5 5,5.5 C6.12933333,5.5 7.06733333,4.66333333 7.226,3.578 C6.8,3.692 6.362,3.75 5.92,3.75 C5.91933333,3.75 5.91666667,3.75 5.91533333,3.75 C4.79266667,3.75 3.70733333,3.37666667 2.82,2.69133333 Z M5.93533333,2.75066667 C6.33866667,2.75 6.74666667,2.68733333 7.14133333,2.562 C6.84533333,1.64533333 5.97733333,1 5,1 C4.342,1 3.71866667,1.29133333 3.29266667,1.78933333 C4.02,2.40066667 4.96133333,2.74866667 5.91,2.75 C5.91266667,2.75 5.91533333,2.75 5.918,2.75 C5.92266667,2.75 5.93,2.75 5.93533333,2.75066667 Z" />
          </g>
        </g>
      </g>
    </SvgIcon>
  )
}

export { DoctorIcon }
