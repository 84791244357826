import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, InputAdornment, Stack, TextField } from '@mui/material'
import { isFunction } from 'lodash'
import React from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { AddButton } from '../AddButton/AddButton'

interface Props {
  searchTerm?: string
  searchPlaceholder?: string
  handleSearch?: (searchTerm: string | null) => void
  addButtonText?: string
  handleAdd?: () => void
  maxWidth?: string
}

export const BetmenListFilter: React.FC<React.PropsWithChildren<Props>> = ({
  searchTerm,
  searchPlaceholder,
  addButtonText,
  children,
  handleSearch,
  handleAdd,
  maxWidth,
}) => {
  const { t } = useTranslation()

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack direction="row" alignItems="center" spacing={1}>
        {isFunction(handleSearch) && (
          <TextField
            size="small"
            variant="outlined"
            placeholder={searchPlaceholder || t('common:search')}
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            sx={{ width: maxWidth ?? '350px' }}
            data-cy={'BetmenListFilter-TextField-search'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  {!!searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearch('')} edge="end">
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )}
                </>
              ),
            }}
          />
        )}
        {children}
      </Stack>
      {isFunction(handleAdd) && (
        <AddButton buttonText={addButtonText} handleAdd={handleAdd} />
      )}
    </Stack>
  )
}
