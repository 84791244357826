import React, { useState, useMemo, useCallback, useRef } from 'react'
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Button,
  Box,
  Tooltip,
} from '@mui/material'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { CloseIcon } from '../../../../common/icons/CloseIcon'
import { DependencyEditorListItem } from './DependencyEditor/DependencyEditorListItem'
import AddIcon from '@mui/icons-material/Add'
import { v4 as uuid } from 'uuid'
import { useSchemaDependencyRemove } from '../hooks/useSchemaDependencyRemove'
import { useSchemaDependencyAdd } from '../hooks/useSchemaDependencyAdd'
import { useSchemaDependencyUpdate } from '../hooks/useSchemaDependencyUpdate'
import {
  getGroupedDependenciesToSync,
  getTimeUnitFromDistanceString,
  getValueFromDistanceString,
  mapScheduleListToLocalDependencyList,
  hasCircularDependency,
  getDependencyTypeFromLocalDependency,
  getDependencyMap,
} from './DependencyEditor/dependencyUtils'
import {
  DEPENDENCY_ERROR,
  LocalDependency,
  MinMax,
  TimeUnit,
} from './DependencyEditor/dependencyEditor.types'
import { LocalSchedule } from './treatmentSchemaSchedule.types'

interface Props {
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  schedules: LocalSchedule[]
  treatmentSchemaId: string
  isEditable: boolean
  refetchTreatmentSchema: () => void
}

export const DependencyEditorModal: React.FC<
  React.PropsWithChildren<Props>
> = ({
  isOpen,
  setIsOpen,
  schedules,
  treatmentSchemaId,
  isEditable,
  refetchTreatmentSchema,
}) => {
  const { t } = useTranslation()

  const [removeDependency] = useSchemaDependencyRemove()
  const [addDependency] = useSchemaDependencyAdd()
  const [updateDependency] = useSchemaDependencyUpdate()

  const existingDependencies = useMemo(
    () => mapScheduleListToLocalDependencyList(schedules),
    [schedules]
  )

  // One empty dependency if no dependencies added
  const [localDependencies, setLocalDependencies] = useState<LocalDependency[]>(
    existingDependencies.length ? existingDependencies : [{ id: uuid() }]
  )

  const dirtyDependencies = useRef<string[]>([])
  const [isFormDirty, setIsFormDirty] = useState(false)
  const [
    isDependenciesHaveUnsavedChanges,
    setIsDependenciesHaveUnsavedChanges,
  ] = useState(false)

  const handleOnClose = useCallback(() => {
    setIsOpen(false)
  }, [setIsOpen])

  const addNewLocalDependency = useCallback(() => {
    setIsDependenciesHaveUnsavedChanges(false)
    setLocalDependencies((oldDeps) => [...oldDeps, { id: uuid() }])
  }, [])

  const setDependencyDirty = useCallback(
    (dependencyId: string, isDirty: boolean) => {
      if (isDirty) {
        if (!dirtyDependencies.current.some((id) => id === dependencyId)) {
          dirtyDependencies.current.push(dependencyId)
        }
      } else {
        dirtyDependencies.current = dirtyDependencies.current.filter(
          (id) => id !== dependencyId
        )
      }
      setIsFormDirty(!!dirtyDependencies.current.length)
    },
    []
  )

  const onDeleteDependency = useCallback(
    (dependencyId: string) => {
      setLocalDependencies((oldDeps) => {
        return oldDeps.filter((d) => d.id !== dependencyId)
      })

      setDependencyDirty(dependencyId, false)
    },
    [setDependencyDirty]
  )

  const setDependencyError = useCallback(
    (dependencyId: string, error: DEPENDENCY_ERROR | null) => {
      setLocalDependencies((oldDeps) => {
        return oldDeps.map((d) => {
          if (d.id === dependencyId) {
            return {
              ...d,
              error,
            }
          }
          return d
        })
      })
    },
    []
  )

  const getDistanceInHours = (distance: string): number => {
    const distanceAsNumber = Number(distance.match(/\d+/)?.[0])
    if (distance.includes(TimeUnit.H)) {
      return distanceAsNumber
    }
    if (distance.includes(TimeUnit.D)) {
      return distanceAsNumber * 24
    }
    return distanceAsNumber * 24 * 7
  }

  // Note: validating a dependency considering other ones. EXTREMELY SLOW WHEN LOT OF DEPENDENCIES
  const validateDependency = useCallback(
    (dependencyToValidate: LocalDependency) => {
      if (!dependencyToValidate.startId || !dependencyToValidate.endId) {
        setDependencyError(dependencyToValidate.id, DEPENDENCY_ERROR.INVALID)
        return false
      }
      // The dependency already exists:
      if (
        localDependencies.some(
          (ld) =>
            ld.id !== dependencyToValidate.id &&
            ld.startId === dependencyToValidate.startId &&
            ld.endId === dependencyToValidate.endId &&
            ld.constraint === dependencyToValidate.constraint
        )
      ) {
        setDependencyError(
          dependencyToValidate.id,
          DEPENDENCY_ERROR.ALREADY_EXISTS
        )
        return false
      }

      // The min max distance is false, because max is less then min
      const showMinMaxDistanceError = localDependencies.some((ld) => {
        const isSameDependencyRelations =
          ld.id !== dependencyToValidate.id &&
          ld.startId === dependencyToValidate.startId &&
          ld.endId === dependencyToValidate.endId

        if (
          !isSameDependencyRelations ||
          ld.constraint === MinMax.NotSpecified ||
          dependencyToValidate.constraint === MinMax.NotSpecified ||
          !ld.distance ||
          !dependencyToValidate.distance
        ) {
          return false
        }

        // Calculate distance to hours for the sake of comparison
        const dependencyToValidateDistance = getDistanceInHours(
          dependencyToValidate.distance
        )
        const ldDistance = getDistanceInHours(ld.distance)

        return dependencyToValidate.constraint === MinMax.MIN
          ? dependencyToValidateDistance > ldDistance
          : dependencyToValidateDistance < ldDistance
      })
      if (showMinMaxDistanceError) {
        setDependencyError(
          dependencyToValidate.id,
          DEPENDENCY_ERROR.MINMAX_DISTANCE
        )
        return false
      }

      // Checking for circular dependency
      const dependencyMap = getDependencyMap(localDependencies)
      if (hasCircularDependency(dependencyToValidate, dependencyMap)) {
        setDependencyError(
          dependencyToValidate.id,
          DEPENDENCY_ERROR.CIRCULAR_DEPENDENCY
        )
        return false
      }
      setDependencyError(dependencyToValidate.id, null)
      return true
    },
    [localDependencies, setDependencyError]
  )

  //Note: After submitting a dependency, validation and saving to the local list
  const onSubmitInList = (dependencyEdited: LocalDependency): boolean => {
    // Validating the dependency
    const isValid = validateDependency(dependencyEdited)
    if (!isValid) {
      return false
    }

    // Allgood
    setLocalDependencies((oldDeps) =>
      oldDeps.map((d) => {
        if (d.id === dependencyEdited.id) {
          return dependencyEdited
        }
        return d
      })
    )
    setIsDependenciesHaveUnsavedChanges(false)
    return true
  }

  const showErrorInDependencyRows = () => {
    setIsDependenciesHaveUnsavedChanges(true)
  }

  const saveDependencies = useCallback(async () => {
    // Validating all the dependencies again
    const hasAnyError = localDependencies
      .map((dep) => validateDependency(dep))
      .some((isValid) => !isValid)
    if (hasAnyError) {
      return
    }

    // Getting the dependencies to add, update or delete
    const { dependenciesToAdd, dependenciesToDelete, dependenciesToUpdate } =
      getGroupedDependenciesToSync(existingDependencies, localDependencies)

    // Actual Mutation calls
    try {
      const removePromises = dependenciesToDelete.map((d) =>
        removeDependency({
          variables: {
            treatmentSchemaId,
            dependencyId: d.id,
          },
        })
      )
      const addPromises = dependenciesToAdd.map((d) => {
        if (d.startId && d.endId) {
          const dependencyInput = {
            treatmentSchemaId,
            fromId: d.startId,
            toId: d.endId,
            distance: getValueFromDistanceString(d.distance) || 1,
            unit: getTimeUnitFromDistanceString(d.distance) || 'd',
            dependencyType: getDependencyTypeFromLocalDependency(d),
          }
          return addDependency({
            variables: {
              dependencyInput,
            },
          })
        }
      })

      const updatePromises = dependenciesToUpdate.map((d) => {
        if (d.startId && d.endId) {
          return updateDependency({
            variables: {
              dependencyInput: {
                treatmentSchemaId,
                dependencyId: d.id,
                fromId: d.startId,
                toId: d.endId,
                distance: getValueFromDistanceString(d.distance) || 1,
                unit: getTimeUnitFromDistanceString(d.distance) || 'd',
                dependencyType: getDependencyTypeFromLocalDependency(d),
              },
            },
          })
        }
      })
      // Note: Different generic FetchResult types does not allow to await all promises in one array
      await Promise.all(removePromises)
      await Promise.all(addPromises)
      await Promise.all(updatePromises)
      refetchTreatmentSchema()
    } catch (error) {
      console.warn({ error })
    }
    handleOnClose()
  }, [
    localDependencies,
    existingDependencies,
    validateDependency,
    refetchTreatmentSchema,
    handleOnClose,
    removeDependency,
    treatmentSchemaId,
    addDependency,
    updateDependency,
  ])

  return (
    <Dialog
      open={isOpen}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleOnClose()
        }
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        {isEditable
          ? t('protocol:dependency.addDependencies')
          : t('protocol:dependency.viewDependencies')}
        <IconButton onClick={handleOnClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <Box>
          {localDependencies.map((d, index) => (
            <DependencyEditorListItem
              key={'dlistitem' + (d.id || index)}
              dependency={d}
              schedules={schedules}
              validateDependency={validateDependency}
              submitInList={onSubmitInList}
              deleteDependency={onDeleteDependency}
              setDependencyDirty={setDependencyDirty}
              isUnsavedChangesInForm={isDependenciesHaveUnsavedChanges}
              isEditable={isEditable}
            />
          ))}
        </Box>

        <Button
          onClick={addNewLocalDependency}
          variant="text"
          disabled={!isEditable}
          startIcon={<AddIcon />}
        >
          {t('protocol:dependency.addMoreDependencies')}
        </Button>
      </DialogContent>
      {isEditable && (
        <DialogActions>
          <Button
            style={{ marginRight: 10 }}
            variant="outlined"
            onClick={handleOnClose}
          >
            {t('common:cancel')}
          </Button>

          <Tooltip
            placement="top"
            title={
              isFormDirty
                ? (t('protocol:dependency.notSavedChanges') as string)
                : ''
            }
          >
            <span>
              <Button
                onClick={
                  isFormDirty ? showErrorInDependencyRows : saveDependencies
                }
              >
                {t('common:save')}
              </Button>
            </span>
          </Tooltip>
        </DialogActions>
      )}
    </Dialog>
  )
}
