import React, { FC } from 'react'

import AppointmentHeader from './AppointmentHeader'
import AppointmentContent from './AppointmentContent'
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { Loading } from '../../../../../common/components/Loading'
import { EmptyState } from '../../../../../common/components/EmptyState'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { PatientAppointment } from '../../types/patientEvents.types'

type Props = {
  open: boolean
  handleClose: () => void
  appointment?: PatientAppointment
  loadingAppointment: boolean
}

const useDialogStyles = makeStyles((theme) => ({
  paper: {
    margin: 0,
  },
  paperFullWidth: {
    width: '100%',
  },
  paperScrollPaper: {
    [theme.breakpoints.down('md')]: {
      maxHeight: '100%',
    },
  },
}))

export const AppointmentModal: FC<React.PropsWithChildren<Props>> = (props) => {
  const { appointment, open = false, handleClose, loadingAppointment } = props
  const { t } = useTranslation()
  const dialogClasses = useDialogStyles()
  return (
    <Dialog
      open={open}
      classes={dialogClasses}
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogContent>
        {loadingAppointment && <Loading />}
        {!loadingAppointment && !appointment && open && (
          <EmptyState
            title={t('appointment:patient.appointmentNotFoundMessageTitle')}
            description={t(
              'appointment:patient.appointmentNotFoundMessageContent'
            )}
          />
        )}
        {!loadingAppointment && appointment && (
          <>
            <AppointmentHeader appointment={appointment} />
            <AppointmentContent appointment={appointment} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
