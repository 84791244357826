import { useUserType } from '../../../../../../common/hooks'
import { useHistory, useParams } from 'react-router-dom'
import {
  AddAppointmentModalParams,
  RouteParams,
} from '../../../types/treatmentPanel.types'
import { useCallback } from 'react'
import { Appointment } from '../../../types/treatments.types'

interface UseAppointmentOperationsReturn {
  openAppointment: (appointmentId: string) => void
  editAppointment: (appointment: Appointment) => void
  cloneAppointment: (appointment: Appointment) => void
}

interface UseAppointmentOperationsProps {
  clearFilterValue: () => void
  toggleAddAppointmentModal: React.Dispatch<
    React.SetStateAction<AddAppointmentModalParams>
  >
}

export const useAppointmentOperations = ({
  clearFilterValue,
  toggleAddAppointmentModal,
}: UseAppointmentOperationsProps): UseAppointmentOperationsReturn => {
  const history = useHistory()
  const userType = useUserType()
  const { patientId, treatmentId } = useParams<RouteParams>()

  const openAppointment = useCallback(
    (appointmentId: string) => {
      history.push(
        `/${userType}/patients/${patientId}/${treatmentId}/${appointmentId}`
      )
    },
    [history, patientId, treatmentId, userType]
  )

  const editAppointment = useCallback(
    (appointment: Appointment) => {
      clearFilterValue()
      toggleAddAppointmentModal({
        isOpen: true,
        appointment,
      })
    },
    [clearFilterValue, toggleAddAppointmentModal]
  )

  const cloneAppointment = useCallback(
    (appointment: Appointment) => {
      clearFilterValue()
      toggleAddAppointmentModal({
        isOpen: true,
        appointmentType: appointment.appointmentInfo?.id,
        doctor: appointment.doctor,
        proposedDate: new Date(appointment.proposedDate),
      })
    },
    [clearFilterValue, toggleAddAppointmentModal]
  )

  return { openAppointment, editAppointment, cloneAppointment }
}
