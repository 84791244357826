import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const PhoneIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 16}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M10.1024,15.37344 C9.46432,15.37344 8.83904,15.18976 8.29376,14.84288 L8.26944,14.82624 C5.45984,12.928 3.06752,10.53568 1.16544,7.72096 C1.1616,7.7152 1.15072,7.69792 1.14688,7.69088 C0.30016,6.3616 0.49024,4.64448 1.6128,3.52192 L2.016,3.11936 C2.28736,2.848 2.64896,2.69888 3.0336,2.69888 C3.41888,2.69888 3.78048,2.84864 4.05184,3.12 L5.74912,4.81664 C6.02112,5.08864 6.17088,5.45024 6.17024,5.83552 C6.17024,6.2208 6.02048,6.5824 5.74848,6.85376 C5.5616,7.04064 5.5616,7.34528 5.74848,7.53216 L8.46336,10.24768 C8.5536,10.33792 8.67392,10.38784 8.80256,10.38784 C8.9312,10.38784 9.05152,10.33792 9.14176,10.24768 C9.41376,9.97568 9.77536,9.82656 10.16064,9.82656 C10.54592,9.82656 10.90752,9.97632 11.17888,10.24768 L12.87168,11.93984 C13.1488,12.21696 13.29856,12.57856 13.29856,12.9632 C13.29856,13.34784 13.14816,13.70944 12.87616,13.98144 L12.47424,14.384 C11.83744,15.02208 10.99456,15.37344 10.1024,15.37344 Z M3.03424,3.65824 C2.9056,3.65824 2.78528,3.70816 2.69504,3.7984 L2.2912,4.2016 C1.48928,5.00352 1.35488,6.23168 1.96416,7.18848 C3.79456,9.89696 6.10048,12.20224 8.81408,14.03584 C8.81536,14.03648 8.82752,14.04544 8.8288,14.04608 C9.20384,14.2848 9.64672,14.41408 10.09856,14.41408 C10.736,14.41408 11.33888,14.16192 11.79648,13.70496 L12.19904,13.3024 C12.28992,13.21152 12.33984,13.0912 12.33984,12.96256 C12.33984,12.83584 12.2912,12.71616 12.20224,12.62592 L10.50112,10.92608 C10.41024,10.8352 10.28992,10.78592 10.16128,10.78592 C10.03264,10.78592 9.91232,10.83584 9.82208,10.92608 C9.55008,11.19808 9.18848,11.3472 8.8032,11.3472 C8.41856,11.3472 8.05696,11.19744 7.7856,10.92608 L5.07072,8.21056 C4.50944,7.64928 4.50944,6.736 5.07072,6.17408 C5.1616,6.0832 5.21152,5.96288 5.21152,5.83488 C5.21152,5.70688 5.1616,5.58592 5.07136,5.49504 L3.37344,3.79904 C3.2832,3.70816 3.16224,3.65824 3.03424,3.65824 Z" />
      <path d="M9.26848,7.20768 C9.14048,7.20768 9.01952,7.15776 8.92928,7.06688 C8.8384,6.97664 8.78848,6.85568 8.78848,6.72768 C8.78848,6.59968 8.8384,6.47872 8.92928,6.38848 L14.35008,0.96768 L11.66912,0.96768 C11.40416,0.96768 11.18912,0.75264 11.18912,0.48768 C11.18912,0.22272 11.40416,0.00768 11.66912,0.00768 L15.50912,0.00768 C15.56608,0.00768 15.62176,0.01792 15.67616,0.0384 C15.6832,0.04096 15.69024,0.04352 15.69664,0.04608 C15.80928,0.09216 15.904,0.18752 15.95264,0.30464 C15.95456,0.30976 15.95712,0.31552 15.95904,0.32128 C15.97888,0.37312 15.98912,0.43008 15.98912,0.48768 L15.98912,4.32768 C15.98912,4.59264 15.77408,4.80768 15.50912,4.80768 C15.24416,4.80768 15.02912,4.59264 15.02912,4.32768 L15.02912,1.64672 L9.60832,7.06752 C9.51744,7.15776 9.39648,7.20768 9.26848,7.20768 Z" />
    </SvgIcon>
  )
}

export { PhoneIcon }
