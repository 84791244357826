import { useMutation } from '@apollo/client'
import {
  createSchedules as CreateSchedulesData,
  createSchedulesVariables as CreateSchedulesVariables,
} from '../../../../models/graphqlTypes'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { CREATE_SCHEDULES } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  CreateSchedulesData,
  CreateSchedulesVariables
>

type Return = MutationTuple<CreateSchedulesData, CreateSchedulesVariables>

export const useSchedulesCreate = (options: Options = {}): Return => {
  return useMutation<CreateSchedulesData, CreateSchedulesVariables>(
    CREATE_SCHEDULES,
    {
      refetchQueries: ['getTreatmentSchema'],
      ...options,
    }
  )
}
