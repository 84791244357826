import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  Select,
  Theme,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useKatEvents } from '../../hooks/useKatEvents'
import { useUpdatePoc } from '../../hooks/useUpdatePoc'

const useStyles = makeStyles((theme: Theme) => ({
  dialogActions: {
    padding: theme.spacing(2),
    justifyContent: 'flex-end',
  },
}))

export interface ConnectToPocDialogProps {
  pocId: string
  appointmentId: string
  tajNumber: string
  isDialogOpen: boolean
  close: () => void
}

export const ConnectToPocDialog: React.FC<
  React.PropsWithChildren<ConnectToPocDialogProps>
> = ({ pocId, appointmentId, tajNumber, isDialogOpen, close }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { katEvents } = useKatEvents({ variables: { tajNumber } })
  const [updatePoc] = useUpdatePoc()
  const selectedLanguage = useSelectedLanguage()

  const formMethods = useForm({
    defaultValues: {
      pocId,
      katId: '',
    },
  })

  const { control, handleSubmit, watch } = formMethods

  const onSubmit = handleSubmit(async (formValues) => {
    if (!formValues.katId) {
      return
    }

    updatePoc({
      variables: {
        pocId,
        katId: formValues.katId ?? '',
        appointmentId,
      },
      onCompleted: close,
      refetchQueries: ['getTreatment'],
    })
  })

  return (
    <FormProvider {...formMethods}>
      <Dialog open={isDialogOpen} onClose={close}>
        <DialogTitle>{t('appointment:connectToPoc')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('appointment:connectToPocDescription')}
          </DialogContentText>
          {katEvents && (
            <Controller
              name="katId"
              control={control}
              render={({ field }) => (
                <Select fullWidth {...field}>
                  {katEvents.map((katEvent, i) => (
                    <MenuItem
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                      key={i}
                      value={katEvent.katId}
                    >
                      {katEvent.doctor} - {katEvent.eventType}
                      <span style={{ fontSize: '10px', opacity: 0.8 }}>
                        {katEvent.room[selectedLanguage]} -{' '}
                        {dayjs(katEvent.eventStart).format('DD.MM.YYYY HH:mm')}{' '}
                      </span>
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={close} variant="outlined">
            {t(`common:cancel`)}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={onSubmit}
            data-cy="ConfirmDialog-Button-confirm"
            disabled={!watch('katId')}
          >
            {t(`common:confirm`)}
          </Button>
        </DialogActions>
      </Dialog>
    </FormProvider>
  )
}
