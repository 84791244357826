import { Box, Link, Theme, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import clsx from 'clsx'
import React from 'react'
import { Link as RouterLink, useParams } from 'react-router-dom'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { Language } from '../../../../../models/graphqlTypes'
import { appointmentDistanceFormatter } from '../../../../../utils/appointmentDistanceFromatter'
import { Appointment } from '../../types/treatments.types'

const useStyles = makeStyles((theme: Theme) => ({
  broken: {
    border: `1px solid ${theme.palette.error.light}`,
    borderRadius: '4px',
    backgroundColor: `${theme.palette.error.main}08`,
  },
}))

type Props = {
  dependencies?: {
    id: string
    appointment?: Appointment
    distance: string
    isBroken: boolean
    isBefore: boolean
  }[]
  title: string
  selectedLanguage: Language
}

type RouteParams = {
  patientId: string
  treatmentId: string
}

export const AppointmentDependency: React.FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { dependencies, title, selectedLanguage } = props
  const { t } = useTranslation()
  const classes = useStyles()
  const { patientId, treatmentId } = useParams<RouteParams>()
  const userType = useUserType()

  return (
    <>
      {!!dependencies?.length && (
        <Box mt={2}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      {!!dependencies?.length && (
        <>
          {dependencies
            .slice()
            .filter((item) => !!item.appointment)
            .sort((a, b) =>
              a.appointment?.proposedDate > b.appointment?.proposedDate ? 1 : -1
            )
            .map(({ id, appointment, distance, isBroken, isBefore }) => {
              const prefix = isBefore ? '-' : '+'
              const formattedDistance = `${prefix}${appointmentDistanceFormatter(
                distance,
                t
              )}`
              const dependencyTimingString = isBroken
                ? `${t('appointment:dependencyBroken')} ${formattedDistance}`
                : formattedDistance
              const formattedDate = t('common:patientFormattedDate', {
                date: appointment?.proposedDate,
              })
              const appointmentLink = `/${userType}/patients/${patientId}/${treatmentId}/${appointment?.id}`
              return (
                <Box
                  key={id}
                  px={1}
                  mt={0.5}
                  py={0.5}
                  className={clsx({
                    [classes.broken]: isBroken,
                    isBroken,
                  })}
                >
                  <Box display="flex" justifyContent="space-between">
                    <Typography variant="subtitle1">{formattedDate}</Typography>
                    <Box textAlign="right" px={1}>
                      <Typography variant="subtitle1">
                        {dependencyTimingString}
                      </Typography>
                    </Box>
                  </Box>
                  <Link component={RouterLink} to={appointmentLink}>
                    <Typography variant="subtitle1" color="primary">
                      {appointment?.info?.doctorTitle[selectedLanguage]}
                    </Typography>
                  </Link>
                </Box>
              )
            })}
        </>
      )}
    </>
  )
}
