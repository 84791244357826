import { Box, Badge, Avatar, Popover, Typography, Stack } from '@mui/material'
import React, { useState } from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useCalendarColors } from './calendarUtils'
import { Day } from './scheduleCalendar.types'

interface Props {
  day: Day
}

export const ScheduleCalendarDay: React.FC<React.PropsWithChildren<Props>> = ({
  day,
}) => {
  const { t } = useTranslation()
  const calendarColors = useCalendarColors()
  const selectedLanguage = useSelectedLanguage()
  const colorPalette = useColorPalette()

  const { dayFromStart, schedules, isOptimalDuration, isDependency, error } =
    day
  const isError = !!error
  const isSchedule = !!schedules.length && !isError
  const isEvent = isSchedule || isOptimalDuration || isError

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const popoverOpen = Boolean(anchorEl)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Box px={1} display="flex" alignItems="center">
        {isSchedule && (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            badgeContent={schedules.length < 2 ? 'i' : schedules.length}
            invisible={schedules.length < 2 ? !isOptimalDuration : false}
            color="info"
            sx={{ color: colorPalette.common.white }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-cy="ScheduleCalendarDay-Badge-schedule"
          >
            <Avatar
              sx={{
                bgcolor: calendarColors.event,
                color: colorPalette.common.white,
                borderRadius: 0.5,
                px: 1,
                py: 0.5,
                height: '24px',
              }}
            >
              {dayFromStart}
            </Avatar>
          </Badge>
        )}
        {isOptimalDuration && !isSchedule && (
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            badgeContent="i"
            color="warning"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            data-cy="ScheduleCalendarDay-Badge-optimalDuration"
          >
            <Avatar
              sx={{
                bgcolor: calendarColors.optimalDuration,
                color: colorPalette.common.white,
                borderRadius: 0.5,
                px: 1,
                py: 0.5,
                height: '24px',
              }}
            >
              {dayFromStart}
            </Avatar>
          </Badge>
        )}
        {isError && (
          <Avatar
            sx={{
              bgcolor: calendarColors.error,
              color: colorPalette.common.white,
              borderRadius: 0.5,
              px: 1,
              py: 0.5,
              height: '24px',
            }}
          >
            {dayFromStart}
          </Avatar>
        )}
        {!isEvent && (
          <Avatar
            sx={{
              bgcolor: isDependency ? colorPalette.grey[200] : 'transparent',
              color: colorPalette.grey[400],
              borderRadius: 0.5,
              px: 1,
              py: 0.5,
              height: '24px',
            }}
          >
            {dayFromStart}
          </Avatar>
        )}
      </Box>

      <Popover
        sx={{
          pointerEvents: 'none',
          m: -1,
        }}
        PaperProps={{
          sx: {
            bgcolor: calendarColors.tooltip,
          },
        }}
        open={popoverOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
      >
        <Box sx={{ maxWidth: '250px', p: 1.5 }}>
          {isOptimalDuration && (
            <Typography variant="body2">
              {t('treatment:protocol.length')}
            </Typography>
          )}

          {isOptimalDuration && isSchedule && <hr />}

          <Stack gap={1}>
            {isSchedule &&
              schedules.map((schedule, i) => (
                <Typography key={i} variant="body2">
                  {schedule.__typename === 'PlainTreatmentElement'
                    ? schedule.doctorTitle
                      ? schedule.doctorTitle[selectedLanguage]
                      : ''
                    : schedule.surveySchema.title}
                </Typography>
              ))}

            {isError && (
              <Typography variant="body2">
                {t(`treatment:schedule.error.${error}`)}
              </Typography>
            )}
          </Stack>
        </Box>
      </Popover>
    </>
  )
}
