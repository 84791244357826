import { Box } from '@mui/material'
import React from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { Loading } from './common/components/Loading'
import { useMe } from './common/hooks/useMe'
import { AppRoutes } from './router/AppRoutes'
import { Login } from './components/Login'
import { MainLayout } from './common/layout'
import { CookieComponent } from './components/CookieComponent/CookieComponent'
import { CookiePolicy } from './components/CookieComponent/CookiePolicy'
import { LoginViews } from './components/Login/Login'

function App() {
  const { pathname } = useLocation()

  const token = localStorage.getItem('token')
  const cookie = localStorage.getItem('cookie')

  const { data: { me } = {}, loading: isUserLoading } = useMe()

  const urlToRedirect = pathname.length > 1 ? encodeURIComponent(pathname) : ''
  const isPasswordReset = pathname.includes('password-reset')
  const readyToLoad = token && me?.userType && !isUserLoading

  if (isUserLoading) {
    return (
      <Box
        display="flex"
        height="100vh"
        justifyContent="center"
        flexDirection="column"
        mt={5}
        color="#32C8FF"
      >
        <Loading color="inherit" />
      </Box>
    )
  }

  return (
    <>
      {readyToLoad && !isPasswordReset ? (
        <MainLayout>
          <AppRoutes />
        </MainLayout>
      ) : (
        <Switch>
          <Route path="/cookie-policy">
            <CookiePolicy />
          </Route>
          <Route path="/password-reset/:token">
            <Login view={LoginViews.setNewPassword} />
          </Route>
          <Route path="/password-reset">
            <Login view={LoginViews.requestPasswordReset} />
          </Route>
          <Route path="/login/verify">
            <Login view={LoginViews.twoFactor} />
          </Route>
          <Route path="/login/:pathToLoad">
            <Login />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route>
            <Redirect to={`/login/${urlToRedirect}`} />
          </Route>
        </Switch>
      )}
      {!cookie && <CookieComponent />}
    </>
  )
}

export default withTranslation()(App)
