import React, { useState, useEffect } from 'react'
import { Box, Card, IconButton, Popover, Stack, Tab, Tabs } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { SurveyActionsMenu } from '../SurveyActions/SurveyActionsMenu'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useFormContext } from 'react-hook-form'
import { ViewSurveyDetailsCore } from '../ViewSurvey/ViewSurveyDetailsCore'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'
import { SurveyResultDateIntervalFilter } from './SurveyResultDateIntervalFilter'
import { SurveyQuestionNumber } from '../SurveyDetails/SurveyQuestionNumber'
import { SurveyFillTime } from '../SurveyDetails/SurveyFillTime'

interface Props {
  surveySchema: SurveySchemaWithoutUser
  tabState: [number, React.Dispatch<React.SetStateAction<number>>]
}

export const SurveyResultHeader: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  tabState,
}) => {
  const { t } = useTranslation()
  const [actionsMenuOpenAnchor, setActionsMenuAnchor] =
    useState<HTMLButtonElement | null>(null)
  const [selectedTabIndex, setSelectedTabIndex] = tabState

  const selectTab = (event: React.SyntheticEvent, newTabIndex: number) => {
    setSelectedTabIndex(newTabIndex)
  }

  const numberOfQuestionsInSurvey = surveySchema.surveySchemaQuestions.length

  const { reset, watch } = useFormContext()

  useEffect(() => {
    reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [reset, surveySchema])

  const status = watch('status')

  return (
    <>
      <Card>
        <Stack direction="row">
          <Stack flex={1}>
            <Stack
              direction={{ xs: 'column', md: 'row' }}
              sx={{ px: 3, pb: 1 }}
            >
              <ViewSurveyDetailsCore
                surveySchema={surveySchema}
                surveySchemaStatus={status}
              />
            </Stack>
          </Stack>
          <Box m={1}>
            <IconButton onClick={(e) => setActionsMenuAnchor(e.currentTarget)}>
              <MoreVertIcon fontSize="large" />
            </IconButton>
          </Box>
        </Stack>
        <hr />

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: 2, sm: 5 }}
          sx={{ px: 3, pt: 1, pb: 2 }}
        >
          <SurveyQuestionNumber numberOfQuestions={numberOfQuestionsInSurvey} />
          <SurveyFillTime numberOfQuestions={numberOfQuestionsInSurvey} />
        </Stack>
      </Card>

      <Stack direction={'row'} pt={1} alignItems={'center'}>
        <Tabs value={selectedTabIndex} onChange={selectTab}>
          <Tab label={t('survey:result.tabs.questions')} />
          <Tab label={t('survey:result.tabs.sumResults')} />
          <Tab label={t('survey:result.tabs.patientResults')} />
        </Tabs>
        {selectedTabIndex !== 0 && <SurveyResultDateIntervalFilter />}
      </Stack>

      {actionsMenuOpenAnchor && (
        <Popover
          open={!!actionsMenuOpenAnchor}
          anchorEl={actionsMenuOpenAnchor}
          onClose={() => setActionsMenuAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <SurveyActionsMenu surveySchema={surveySchema} />
        </Popover>
      )}
    </>
  )
}
