import EventIcon from '@mui/icons-material/Event'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import PreviewIcon from '@mui/icons-material/Preview'
import { alpha, Box, Button, Stack, TextField, Typography } from '@mui/material'
import dayjs from 'dayjs'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useColorPalette } from '../../../../common/hooks/helper/useColor'
import { BetmenList } from '../../../../common/components/BetmenList/BetmenList'
import { BetmenListBody } from '../../../../common/components/BetmenList/BetmenListBody'
import { BetmenListHeader } from '../../../../common/components/BetmenList/BetmenListHeader'
import { BetmenListHeaderCell } from '../../../../common/components/BetmenList/BetmenListHeaderCell'
import { BetmenListItemCard } from '../../../../common/components/BetmenList/BetmenListItemCard'
import { BetmenListItemCardCell } from '../../../../common/components/BetmenList/BetmenListItemCardCell'
import { AppointmentLocation } from './PatientAppointments/AppointmentLocation'
import AppointmentTimeAndStatus from './PatientAppointments/AppointmentTimeAndStatus'
import { DoctorInfo } from '../../../../common/components/DoctorInfo/DoctorInfo'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserProfile } from '../../../../common/hooks/useUserProfile'
import { usePatientAppointment } from '../hooks/usePatientAppointment'
import { usePatientHomeData } from '../hooks/usePatientHomeData'
import { AppointmentModal } from './PatientAppointments/AppointmentModal'
import { TreatmentFilter } from './PatientAppointments/TreatmentFilter'
import makeStyles from '@mui/styles/makeStyles'
import {
  PatientHomeAppointment,
  PatientHomeTreatment,
} from '../types/patientEvents.types'

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
})

type RouteParams = {
  appointmentId?: string
  treatmentId?: string
}

export const PatientAppointments: React.FC<
  React.PropsWithChildren<unknown>
> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const colorPalette = useColorPalette()
  const selectedLanguage = useSelectedLanguage()
  const profile = useUserProfile()
  const timeout = useRef<NodeJS.Timeout | null>(null)
  const history = useHistory()
  const { appointmentId, treatmentId } = useParams<RouteParams>()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [showPast, setShowPast] = useState<boolean>(false)
  const [treatments, setTreatments] = useState<PatientHomeTreatment[]>([])

  const PAGE_SIZE = 20
  const variables = useMemo(
    () => ({
      patientId: profile?.id || '',
      paginationInput: {
        offset: 0,
        limit: PAGE_SIZE,
      },
      searchTerm,
      treatmentId,
      isPast: showPast,
    }),
    [profile?.id, searchTerm, showPast, treatmentId]
  )

  const {
    appointments,
    loading,
    treatments: loadedTreatments,
    fetchingMore,
    fetchMoreAppointments,
    hasMoreAppointments,
    refetch,
  } = usePatientHomeData(
    {
      variables,
    },
    !!treatments.length
  )

  const onAppointmentEnter = useCallback(() => {
    const offset = appointments.length

    fetchMoreAppointments(
      {
        variables: {
          ...variables,
          paginationInput: {
            offset,
            limit: PAGE_SIZE,
          },
        },
      },
      true
    )
  }, [appointments.length, fetchMoreAppointments, variables])

  useEffect(() => {
    refetch()
  }, [refetch, searchTerm, showPast, treatmentId])

  useEffect(() => {
    if (!!loadedTreatments.length) {
      setTreatments(loadedTreatments)
    }
  }, [loadedTreatments])

  const currentTreatment = treatments.find((t) => t.id === treatmentId)
  const invalidTreatmentInUrl = treatmentId && !currentTreatment

  const { appointment: selectedAppointment, loading: loadingAppointment } =
    usePatientAppointment({
      appointmentId,
    })

  const handleClose = () => {
    history.push(`/patient/home/treatments/${treatmentId || ''}`)
  }

  const onAppointmentClick = (appointment: PatientHomeAppointment) => {
    history.push(
      `/patient/home/treatments/${appointment.treatment.id}/${appointment.id}`
    )
  }

  const onSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // copy the value for the timeout
    const value = e.target.value

    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      setSearchTerm(value.toLocaleLowerCase())
    }, 300)
  }

  const getIsPast = (appointment: PatientHomeAppointment) =>
    dayjs().startOf('day') > dayjs(appointment.proposedDate)

  return (
    <>
      <Box data-cy="PatientHome-Box-appointmentsContainer">
        <BetmenList
          dataSource={appointments}
          fetchMore={onAppointmentEnter}
          hasMoreItem={hasMoreAppointments}
          fetchMoreLoading={fetchingMore}
          loading={loading}
          notFoundMessage={
            invalidTreatmentInUrl
              ? t('treatment:patient.invalidTreatmentMessageContent')
              : t('appointment:patient.noAppointmentsMessageContent')
          }
          gridColumnSizes={[3, 3, 2, 2, 2]}
          filters={
            <>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Stack sx={{ width: '80%' }}>
                  <TreatmentFilter
                    selectedTreatmentId={treatmentId}
                    treatments={treatments}
                  />
                  <Box
                    pr={2}
                    pl={{ xs: 2, md: 9 }}
                    pb={1.5}
                    width="100%"
                    maxWidth={440}
                  >
                    <TextField
                      size="small"
                      name="filter"
                      variant="outlined"
                      fullWidth
                      placeholder={t('home:appointments.searchexample')}
                      onChange={onSearchTermChange}
                    />
                  </Box>
                </Stack>
                <Button
                  variant="text"
                  sx={{ my: 'auto', mx: 2, whiteSpace: 'nowrap' }}
                  onClick={() => setShowPast(!showPast)}
                  startIcon={<PreviewIcon />}
                  data-cy="Patient-Appointment-List-Button-showPast"
                >
                  {t(
                    `home:appointments.${showPast ? 'showFuture' : 'showPast'}`
                  )}
                </Button>
              </Stack>
            </>
          }
          header={
            <BetmenListHeader>
              <BetmenListHeaderCell>
                {t('treatment:header.dateAndtime')}
              </BetmenListHeaderCell>
              <BetmenListHeaderCell>
                {t('treatment:header.locationInfo')}
              </BetmenListHeaderCell>
              <BetmenListHeaderCell>
                {t('treatment:header.name')}
              </BetmenListHeaderCell>
              <BetmenListHeaderCell>
                {t('treatment:header.doctor')}
              </BetmenListHeaderCell>
              <BetmenListHeaderCell>
                {t('treatment:header.goodToKnow')}
              </BetmenListHeaderCell>
            </BetmenListHeader>
          }
        >
          <BetmenListBody>
            {appointments.map((appointment) => (
              <BetmenListItemCard
                click={() => onAppointmentClick(appointment)}
                key={'wp+' + appointment.id}
                sx={{
                  bgcolor: showPast
                    ? alpha(colorPalette.common.black, 0.1)
                    : colorPalette.common.white,
                }}
              >
                <BetmenListItemCardCell>
                  <Box display="flex" gap={1}>
                    <Box
                      display="flex"
                      gap={0.5}
                      alignItems="center"
                      sx={{ height: 'min-content' }}
                    >
                      <EventIcon />
                      <Typography variant="body2">
                        {t('treatment:dateAndTime')}:
                      </Typography>
                    </Box>
                    <AppointmentTimeAndStatus {...appointment} />
                  </Box>
                  {!!appointment?.institution?.name && (
                    <Box display="flex" gap={1} mt={2}>
                      <Box
                        display="flex"
                        gap={0.5}
                        alignItems="center"
                        sx={{ height: 'min-content' }}
                      >
                        <LocationCityIcon />
                        <Typography variant="body2">
                          {t('treatment:location')}:
                        </Typography>
                      </Box>
                      <AppointmentLocation appointment={appointment} />
                    </Box>
                  )}
                </BetmenListItemCardCell>
                <BetmenListItemCardCell>
                  <Typography
                    variant="subtitle1"
                    className={classes.multiLineEllipsis}
                  >
                    {appointment.room?.description?.[selectedLanguage]}
                  </Typography>
                </BetmenListItemCardCell>
                <BetmenListItemCardCell>
                  <Typography
                    variant="body1"
                    color={!getIsPast(appointment) ? 'primary' : undefined}
                    fontWeight={600}
                  >
                    {appointment.info?.patientTitle[selectedLanguage]}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {appointment.treatment.title[selectedLanguage]}
                  </Typography>
                </BetmenListItemCardCell>
                <BetmenListItemCardCell>
                  <DoctorInfo
                    doctor={appointment.doctor}
                    hideAssistants
                    hideDetails
                    hideTitle
                    hideAvatar
                    avatarSize="small"
                    hideContactButtons
                    linkToDoctor={`/patient/doctors/${appointment.doctor.id}`}
                  />
                </BetmenListItemCardCell>
                <BetmenListItemCardCell>
                  <Typography
                    variant="subtitle1"
                    className={classes.multiLineEllipsis}
                  >
                    {appointment.info?.goodToKnow[selectedLanguage]}
                  </Typography>
                </BetmenListItemCardCell>
              </BetmenListItemCard>
            ))}
          </BetmenListBody>
        </BetmenList>
      </Box>
      <AppointmentModal
        open={!!appointmentId}
        handleClose={handleClose}
        appointment={selectedAppointment}
        loadingAppointment={loadingAppointment}
      />
    </>
  )
}
