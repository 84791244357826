import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import { Autocomplete, Button, TextField } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { SxProps, Theme } from '@mui/system'
import React, { FC, useEffect } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useTranslation } from '../../../hooks/helper/useTranslation'
import {
  SurveyCategoryEnum,
  SurveySchemaStatusEnum,
} from '../../../../models/graphqlTypes'
import { useSurveySchemas } from '../../../../views/doctor/SurveyStore/hooks/useSurveySchemas'
import { BoxWithLabel } from '../../BoxWithLabel'
import { SurveySchema } from '../../../../views/doctor/SurveyStore/types/surveyStore.types'

const useStyles = makeStyles((theme) => ({
  listBox: {
    '& > *:first-child': {
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}))

interface SurveySchemaSelectorFilter {
  categories: SurveyCategoryEnum[]
}

type Props = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<any, 'surveySchema'>
  onSubmit?: () => void
  filter?: SurveySchemaSelectorFilter
  error?: unknown
  label?: string
  helperText?: string
  disabled?: boolean
  hintText?: string
  sx?: SxProps<Theme> | undefined
}

export const SurveySchemaSelector: FC<React.PropsWithChildren<Props>> = ({
  field: { name, value, onChange, onBlur },
  onSubmit,
  filter,
  error,
  label,
  helperText,
  disabled,
  hintText,
  sx,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()

  const { loading, surveySchemas, refetch } = useSurveySchemas({
    variables: {
      filterData: {
        statuses: [SurveySchemaStatusEnum.Active],
        categories: filter?.categories ?? [],
      },
      paginationInput: {
        offset: 0,
        // TODO - add limit if Autocomplete can filter on backend
      },
    },
  })

  useEffect(() => {
    refetch()
  }, [filter, refetch])

  return (
    <BoxWithLabel label={label}>
      <Autocomplete<SurveySchema, false, false, true>
        classes={{ listbox: classes.listBox }}
        freeSolo
        fullWidth
        loading={loading}
        ListboxProps={{ style: { overflowX: 'hidden' } }}
        options={surveySchemas}
        filterSelectedOptions
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option?.title || ''
        }
        value={value || null}
        onChange={(event, newValue) => {
          if (newValue) {
            onChange(newValue)
          }
          if (onSubmit) {
            onSubmit()
          }
        }}
        onBlur={onBlur}
        filterOptions={(options, state) => {
          const filtered = options.filter((option) =>
            option?.title
              ?.toLowerCase()
              .includes(state.inputValue.toLowerCase())
          )

          return [null as unknown as SurveySchema, ...filtered]
        }}
        data-cy="SurveySchemaSelector-AutoComplete"
        renderOption={(renderProps, option) => {
          if (option === undefined) {
            return null
          }
          if (!option) {
            return (
              <li {...renderProps} key={'option-add-key'}>
                <Button
                  component={Link}
                  fullWidth
                  variant="text"
                  to="/doctor/add-survey"
                  startIcon={<AddCircleOutlineIcon color="primary" />}
                  onClick={(event: React.MouseEvent<HTMLAnchorElement>) => {
                    event.stopPropagation()
                  }}
                >
                  {t('protocol:addSurvey')}
                </Button>
              </li>
            )
          }
          return (
            <li {...renderProps} key={option.id}>
              {option.title}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={hintText || t('common:search')}
            error={!!error}
            helperText={error ? helperText : undefined}
            variant="outlined"
            name={name}
            sx={sx}
          />
        )}
      />
    </BoxWithLabel>
  )
}
