import { ApolloError, useQuery } from '@apollo/client'
import {
  getFreeSlotsByInstitution,
  getFreeSlotsByInstitutionVariables,
} from '../../../models/graphqlTypes'
import { EESZT_FREE_SLOTS_BY_INSTITUTION } from '../../../operations/eesztOperations'
import { GetFreeSlotsByInstitution } from './eeszt.types'

type ReturnTypes = {
  loading: boolean
  error: ApolloError | undefined
  data: GetFreeSlotsByInstitution[]
}

export const useEesztFreeSlotsByInstitution = (
  variables: getFreeSlotsByInstitutionVariables,
  skip: boolean
): ReturnTypes => {
  const { loading, data, error } = useQuery<
    getFreeSlotsByInstitution,
    getFreeSlotsByInstitutionVariables
  >(EESZT_FREE_SLOTS_BY_INSTITUTION, {
    skip,
    variables,
    fetchPolicy: 'network-only',
  })

  return {
    loading,
    error,
    data: data?.getFreeSlotsByInstitution || [],
  }
}
