import { QueryResult, useQuery } from '@apollo/client'
import { QueryHookOptions } from '@apollo/client/react/types/types'
import {
  getLocationByPostalCode,
  getLocationByPostalCodeVariables,
} from '../../models/graphqlTypes'
import { GET_CITIES_BY_POSTAL_CODE } from '../../operations/cityOperations'

type Options = QueryHookOptions<
  getLocationByPostalCode,
  getLocationByPostalCodeVariables
>

type Return = {
  loading: boolean
  cities: string[]
  refetch: QueryResult<
    getLocationByPostalCode,
    getLocationByPostalCodeVariables
  >['refetch']
}

export const useCitiesByPostalCode = (options: Options = {}): Return => {
  const { data, loading, refetch } = useQuery<
    getLocationByPostalCode,
    getLocationByPostalCodeVariables
  >(GET_CITIES_BY_POSTAL_CODE, { ...options })

  return {
    cities: data?.getLocationByPostalCode ?? [],
    loading,
    refetch,
  }
}
