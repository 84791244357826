import { Card, CardHeader } from '@mui/material'
import React from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { Section } from '../EditSurvey/editSurvey.types'
import { ViewQuestionCard } from './ViewQuestionCard'

interface Props {
  section: Section
}

export const ViewSection: React.FC<React.PropsWithChildren<Props>> = ({
  section,
}) => {
  const { t } = useTranslation()

  return (
    <Card sx={{ mt: 3, pb: 2 }}>
      <CardHeader
        sx={{ px: 3 }}
        title={
          section.name ||
          t('survey:editor.section.title', { index: section.order })
        }
      />
      {section.questions.map((question, i) => (
        <ViewQuestionCard key={i} question={question} />
      ))}
    </Card>
  )
}
