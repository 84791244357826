import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@mui/x-date-pickers'
import { Controller, useFormContext } from 'react-hook-form'

export const SurveyResultDateIntervalFilter: React.FC = () => {
  const { t } = useTranslation()

  const { watch, control } = useFormContext()

  return (
    <Stack
      sx={{
        flex: 1,
      }}
    >
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap={1.5}
        sx={{
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: { xs: 2, md: 0 },
        }}
      >
        <Typography variant="subtitle1">
          {t('survey:result.dateRange')}
        </Typography>
        <Controller
          render={({ field }) => (
            <DatePicker
              {...field}
              value={field.value || null}
              onChange={field.onChange}
              slotProps={{ textField: { size: 'small' } }}
              maxDate={watch('endDate')}
            />
          )}
          name="startDate"
          control={control}
        />
        <Typography variant="body1">-</Typography>
        <Controller
          render={({ field }) => (
            <DatePicker
              {...field}
              value={field.value || null}
              onChange={field.onChange}
              slotProps={{ textField: { size: 'small' } }}
              minDate={watch('startDate')}
            />
          )}
          name="endDate"
          control={control}
        />
      </Stack>
    </Stack>
  )
}
