import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import ReactMarkdown from 'react-markdown'
import { useTranslation } from '../../hooks/helper/useTranslation'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const HelpModal: React.FC<React.PropsWithChildren<Props>> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation()

  const content = t('protocol:helpModal.content')

  return (
    <Dialog data-cy="HelpModal" open={isOpen} fullWidth>
      {<DialogTitle>{t('protocol:helpModal.title')}</DialogTitle>}

      <DialogContent>
        <ReactMarkdown>{content}</ReactMarkdown>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{t('common:close')}</Button>
      </DialogActions>
    </Dialog>
  )
}
