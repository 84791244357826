import React from 'react'
import { Box, IconButton, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { LocationIcon } from '../../icons/LocationIcon'
import {
  PatientAssistant,
  PatientInstitution,
} from '../../../views/patient/PatientEvents/types/patientEvents.types'

const useStyles = makeStyles((theme) => ({
  button: {
    '&:hover': {
      background: `${theme.palette.primary.main}11`,
    },
  },
}))

type DoctorProfileInstitutionProps = {
  institution: PatientInstitution
  assistants: PatientAssistant[]
}
const DoctorProfileInstitution: React.FC<
  React.PropsWithChildren<DoctorProfileInstitutionProps>
> = ({ institution }) => {
  const { t } = useTranslation()
  const { workingHours } = institution
  const classes = useStyles()

  return (
    <>
      <Box display="flex" alignItems="center">
        <Box flexGrow={1}>
          <Typography variant="body2" color="textSecondary">
            {institution.institution?.name}
          </Typography>
        </Box>
        <Box width={50} flexShrink={0}>
          <IconButton
            className={classes.button}
            target="_blank"
            rel="noreferrer"
            href={institution.institution?.mapLink || ''}
            size="large"
          >
            <LocationIcon />
          </IconButton>
        </Box>
      </Box>

      <Box ml={4}>
        {(workingHours || []).length > 0 && (
          <Box>
            <Typography variant="subtitle2">
              {t('institution:workingHours')}
            </Typography>
            <Typography variant="subtitle1" style={{ whiteSpace: 'pre-line' }}>
              {workingHours?.join(', ')}
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
}

export { DoctorProfileInstitution }
