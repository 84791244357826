import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { SendSurveyToTypeSelector } from './SendSurvey/SendSurveyToTypeSelector'
import {
  SendSurveyForm,
  SendSurveyStep,
  SendSurveyToType,
} from './SendSurvey/sendSurvey.types'
import { SendSurveyRecipientSelector } from './SendSurvey/SendSurveyRecipientSelector'
import {
  DEFAULT_FILLOUT_TIME_IN_DAYS,
  SurveyDatePicker,
} from './SurveyDatePicker'
import dayjs from 'dayjs'
import { useSendSurveyToUsers } from '../../hooks/useSendSurveyToPatients'
import { useNotifications } from '../../../../../common/providers'
import { sendSurveyToUsersVariables } from '../../../../../models/graphqlTypes'
import { SendSurveySuccess } from './SendSurvey/SendSurveySuccess'
import CloseIcon from '@mui/icons-material/Close'

interface Props extends DialogProps {
  surveySchemaId: string
  onClose: () => void
}

export const SendSurveyModal: React.FC<Props> = ({
  onClose,
  surveySchemaId,
  ...props
}) => {
  const { t } = useTranslation('survey')
  const { setErrorToast } = useNotifications()

  const [sendSurveyStep, setSendSurveyStep] = React.useState(
    SendSurveyStep.SelectToType
  )

  const today = dayjs().startOf('day')
  const oneWeekLater = today.add(DEFAULT_FILLOUT_TIME_IN_DAYS, 'day')

  const formMethods = useForm<SendSurveyForm>({
    defaultValues: {
      sendTo: SendSurveyToType.Patient,
      patients: [],
      doctors: [],
      assistants: [],
      fillableFromDate: today,
      fillableUntilDate: oneWeekLater,
    },
  })

  const { handleSubmit } = formMethods

  const [sendSurveyToUsers] = useSendSurveyToUsers({
    onCompleted: () => {
      setSendSurveyStep(SendSurveyStep.Success)
    },
    onError: (e) => setErrorToast(e),
  })

  const onSubmit = handleSubmit(async (formValues) => {
    const variables: sendSurveyToUsersVariables = {
      surveySchemaId,
      userIds: [] as string[],
      fillableFrom: formValues.fillableFromDate,
      fillableUntil: formValues.fillableUntilDate,
    }

    if (formValues.sendTo === SendSurveyToType.Patient) {
      variables.userIds =
        formValues.patients?.map(
          (patientOption) => patientOption?.user?.id ?? ''
        ) ?? []
    } else if (formValues.sendTo === SendSurveyToType.Doctor) {
      variables.userIds =
        formValues.doctors?.map((doctor) => doctor.user?.id ?? '') ?? []
      variables.patientId = formValues.aboutPatient?.id
    } else if (formValues.sendTo === SendSurveyToType.Assistant) {
      variables.userIds =
        formValues.assistants?.map((assistant) => assistant.user?.id ?? '') ??
        []
      variables.patientId = formValues.aboutPatient?.id
    }

    await sendSurveyToUsers({
      variables,
    })
  })

  return (
    <>
      <FormProvider {...formMethods}>
        <Dialog fullWidth {...props}>
          <DialogTitle
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t(`sendSurvey.title`)}

            <IconButton
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 10,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {sendSurveyStep === SendSurveyStep.SelectToType && (
              <SendSurveyToTypeSelector />
            )}
            {sendSurveyStep === SendSurveyStep.SelectRecipient && (
              <Stack>
                <SendSurveyRecipientSelector />
                <SurveyDatePicker
                  fillableUntilName="fillableUntilDate"
                  fillableFromName="fillableFromDate"
                />
              </Stack>
            )}
            {sendSurveyStep === SendSurveyStep.Success && <SendSurveySuccess />}
          </DialogContent>
          <DialogActions sx={{ pb: 2, px: 3 }}>
            {sendSurveyStep != SendSurveyStep.Success && (
              <Button variant="outlined" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            )}
            {sendSurveyStep === SendSurveyStep.SelectToType && (
              <Button
                onClick={() =>
                  setSendSurveyStep(SendSurveyStep.SelectRecipient)
                }
              >
                {t('common:next')}
              </Button>
            )}

            {sendSurveyStep === SendSurveyStep.SelectRecipient && (
              <Button onClick={onSubmit}>{t('common:send')}</Button>
            )}
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  )
}
