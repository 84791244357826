import { CancelOutlined as CancelOutlinedIcon } from '@mui/icons-material'
import ArchiveIcon from '@mui/icons-material/Archive'
import { Button, ButtonProps } from '@mui/material'
import isFunction from 'lodash/isFunction'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import { useNotifications } from '../../../../../common/providers/NotificationProvider'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { useSurveySchemaDelete } from '../../hooks/useSurveySchemaDelete'
import { useSurveySchemaStatusUpdate } from '../../hooks/useSurveySchemaStatusUpdate'
import { useUserType } from '../../../../../common/hooks/useUserType'
import { SurveySchemaWithoutUser } from '../../types/surveyStore.types'

interface Props extends ButtonProps {
  surveySchema: SurveySchemaWithoutUser
  refetchSurveys?: () => void
  refetchSurveySchema?: () => void
}

export const DeleteSurveyButton: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  refetchSurveys,
  refetchSurveySchema,
  ...buttonProps
}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const userType = useUserType()
  const { setSuccessToast, setErrorToast } = useNotifications()

  const [confirmDelete, setConfirmDelete] = useState<ConfirmDialogValue>(false)
  const [confirmArchive, setConfirmArchive] =
    useState<ConfirmDialogValue>(false)

  const { setValue, watch } = useFormContext()
  const status = watch('status')
  const canBeDeleted = watch('canBeDeleted')

  const [deleteSurvey] = useSurveySchemaDelete()
  const [updateSurveyStatus] = useSurveySchemaStatusUpdate()

  const onDelete = async () =>
    await deleteSurvey({
      variables: { surveySchemaId: surveySchema.id },
      onCompleted: () => {
        history.push(`/${userType?.toLowerCase()}/surveys`)
        setSuccessToast('successRemoveSurvey')
        isFunction(refetchSurveys) && refetchSurveys()
      },
      onError: (e) => setErrorToast(e),
    })

  const onArchive = async () =>
    await updateSurveyStatus({
      variables: {
        status: SurveySchemaStatusEnum.Archived,
        surveySchemaId: surveySchema.id,
      },
      onCompleted: () => {
        setValue('status', SurveySchemaStatusEnum.Archived)
        setSuccessToast('successArchiveSurvey')
        isFunction(refetchSurveySchema) && refetchSurveySchema()
      },
      onError: (e) => setErrorToast(e),
    })

  return (
    <>
      {canBeDeleted ? (
        <Button
          color="error"
          variant="text"
          startIcon={<CancelOutlinedIcon />}
          onClick={() => setConfirmDelete(true)}
          {...buttonProps}
          data-cy="DeleteSurveyButton"
        >
          {t('common:delete')}
        </Button>
      ) : (
        status !== SurveySchemaStatusEnum.Archived && (
          <Button
            color="error"
            variant="text"
            startIcon={<ArchiveIcon />}
            onClick={() => setConfirmArchive(true)}
            {...buttonProps}
          >
            {t('common:archive')}
          </Button>
        )
      )}

      {confirmDelete && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmDelete, setConfirmDelete]}
          text={t(`survey:survey.confirmSurveyRemoval`)}
          onAccept={onDelete}
        />
      )}

      {confirmArchive && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[confirmArchive, setConfirmArchive]}
          text={t(`survey:survey.confirmSurveyArchive`)}
          onAccept={onArchive}
        />
      )}
    </>
  )
}
