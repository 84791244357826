import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const AddSquareIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (
  props
) => {
  const viewBox = `0 0 ${props.width ?? 30} ${props.height ?? 30}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M24 4H6C4.895 4 4 4.895 4 6V24C4 25.105 4.895 26 6 26H24C25.105 26 26 25.105 26 24V6C26 4.895 25.105 4 24 4ZM21 16H16V21C16 21.552 15.553 22 15 22C14.447 22 14 21.552 14 21V16H9C8.447 16 8 15.552 8 15C8 14.448 8.447 14 9 14H14V9C14 8.448 14.447 8 15 8C15.553 8 16 8.448 16 9V14H21C21.553 14 22 14.448 22 15C22 15.552 21.553 16 21 16Z" />
    </SvgIcon>
  )
}

export { AddSquareIcon }
