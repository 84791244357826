import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  sendDraftTreatment,
  sendDraftTreatmentVariables,
} from '../../../../models/graphqlTypes'
import { SEND_DRAFT_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<
  sendDraftTreatment,
  sendDraftTreatmentVariables
>

type Return = MutationTuple<sendDraftTreatment, sendDraftTreatmentVariables>

export const useTreatmentSend = (options: Options = {}): Return => {
  return useMutation<sendDraftTreatment, sendDraftTreatmentVariables>(
    SEND_DRAFT_TREATMENT,
    options
  )
}
