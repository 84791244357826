import omit from 'lodash/omit'
import React, { createContext, useEffect, useMemo, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { FormProvider, useForm, UseFormReturn } from 'react-hook-form'
import { ScrollablePanel } from '../../../../common/components/ScrollablePanel'
import { useMe } from '../../../../common/hooks/useMe'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import {
  SurveyQuestionEnum,
  SurveyQuestionFilterInput,
  SurveySchemaStatusEnum,
} from '../../../../models/graphqlTypes'
import { useSurveyQuestions } from '../hooks/useSurveyQuestions'
import {
  getDefaultValues,
  getExistingQuestionsWithoutUsedQuestions,
  QUESTION_LIST_PAGE_SIZE,
} from '../utils/question.util'
import { mapSurveySchemaQuestionsToSections } from '../utils/survey.mapper'
import {
  getNumberOfQuestionsInSurvey,
  getOrderedSections,
  getReorderedSections,
  getSectionsAfterQuestionAdded,
  getSectionsAfterQuestionRemoved,
  getSectionsAfterQuestionUpdated,
  NEW_QUESTION_KEY_PREFIX,
  REORDER_QUESTIONS_KEY_PREFIX,
} from '../utils/survey.util'
import { EditSurvey } from './EditSurvey/EditSurvey'
import { QuestionInSection, Section } from './EditSurvey/editSurvey.types'
import { EditSurveyActions } from './EditSurvey/EditSurveyActions'
import { FillSurvey } from './FillSurvey/FillSurvey'
import {
  AspectInForm,
  ChoiceInForm,
  QuestionForm,
} from './Questions/questions.types'
import { SurveySchemaById } from '../types/surveyStore.types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type QuestionFormMethodType = UseFormReturn<QuestionForm, any>

interface Props {
  surveySchema?: SurveySchemaById
  refetchSurveySchema?: () => void
}

// TODO - create separated SurveyProvider.tsx
interface SurveyContext {
  surveySchemaId?: string | null
  isReadonly: boolean
  questionFormMethods: QuestionFormMethodType[]
  updateQuestionFormMethods: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    q: QuestionFormMethodType,
    isRemove?: boolean
  ) => void
  shouldSave: boolean
  setShouldSave: React.Dispatch<React.SetStateAction<boolean>>
}

export const SurveyContext = createContext<SurveyContext>({
  surveySchemaId: null,
  isReadonly: false,
  questionFormMethods: [],
  updateQuestionFormMethods: () => {
    //
  },
  shouldSave: false,
  setShouldSave: () => {
    //
  },
})

export const EditSurveyState: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  refetchSurveySchema,
}) => {
  const newQuestionTypes = Object.keys(SurveyQuestionEnum)
  const { t } = useTranslation()
  const { data: { me } = {} } = useMe()
  const [questionFormMethods, setQuestionFormMethods] = useState<
    QuestionFormMethodType[]
  >([])

  const [shouldSave, setShouldSave] = useState<boolean>(false)

  const [isPreviewOn, setIsPreviewOn] = useState<boolean>(false)
  const [sections, setSections] = useState<Section[]>(
    !!surveySchema?.surveySchemaQuestions.length
      ? mapSurveySchemaQuestionsToSections(surveySchema?.surveySchemaQuestions)
      : [{ order: 1, questions: [], name: '' }]
  )

  const orderedSections = getOrderedSections(sections)
  const numberOfQuestionsInSurvey = getNumberOfQuestionsInSurvey(sections)

  const [filterInput, setFilterInput] = useState<SurveyQuestionFilterInput>({})

  const isReadonly = useMemo(
    () =>
      (!!me &&
        !!surveySchema &&
        !me.isAdmin &&
        me.id !== surveySchema.user.id) ||
      surveySchema?.status === SurveySchemaStatusEnum.Active,
    [me, surveySchema]
  )

  const surveyQuestionListData = useSurveyQuestions({
    variables: {
      filterInput,
      paginationInput: {
        offset: 0,
        limit: QUESTION_LIST_PAGE_SIZE,
      },
    },
  })
  const { refetch: refetchQuestions, surveyQuestions } = surveyQuestionListData

  useEffect(() => {
    refetchQuestions()
  }, [refetchQuestions, filterInput])

  const addQuestionToSections = (
    newQuestion: QuestionInSection,
    sectionOrder: number
  ) => {
    const newSectionList = getSectionsAfterQuestionAdded(
      sections,
      newQuestion,
      sectionOrder
    )
    setSections(newSectionList)
    setShouldSave(true)
  }

  const updateQuestionInSections = (
    newQuestion: QuestionInSection,
    sectionOrder: number
  ) => {
    const newSectionList = getSectionsAfterQuestionUpdated(
      sections,
      newQuestion,
      sectionOrder,
      surveySchema?.id
    )
    setSections(newSectionList)
    setShouldSave(true)
  }

  const removeQuestionFromSections = (
    questionToRemove: QuestionInSection,
    sectionOrder: number
  ) => {
    const newSectionList = getSectionsAfterQuestionRemoved(
      sections,
      questionToRemove,
      sectionOrder
    )
    setSections(newSectionList)
    setShouldSave(true)
  }

  const addNewQuestionToSection = (
    sectionOrder: number,
    indexInSection: number,
    questionType: SurveyQuestionEnum
  ) => {
    const newQuestion: QuestionInSection = {
      title: '',
      description: '',
      type: questionType,
      isNew: true,
      isRequired: true,
      isSurveySpecific: true,
      surveySchemaId: surveySchema?.id,
      orderInSection: indexInSection,
      idForRender: Math.random().toString(16).slice(2),
      isJumpToNextSectionAvailable: false,
    }
    addQuestionToSections(newQuestion, sectionOrder)
  }

  const addExistingQuestionToSection = (
    sectionOrder: number,
    indexInSection: number,
    indexInQuestionList: number
  ) => {
    const existingQuestionData = getExistingQuestionsWithoutUsedQuestions(
      surveyQuestions,
      orderedSections
    )[indexInQuestionList]

    const newQuestion: QuestionInSection = {
      ...existingQuestionData,
      choices: getDefaultValues(existingQuestionData.choices, {
        shouldAddDoubleEmptyField: true,
      }) as ChoiceInForm[],
      aspects: getDefaultValues(existingQuestionData.aspects) as AspectInForm[],
      isNew: false,
      isRequired: true,
      isSurveySpecific: true,
      surveySchemaId: surveySchema?.id,
      orderInSection: indexInSection,
      idForRender: Math.random().toString(16).slice(2),
      isJumpToNextSectionAvailable: false,
    }
    addQuestionToSections(newQuestion, sectionOrder)
  }

  const reorderQuestionsInSection = (
    sectionOrder: number,
    currQuestionIndex: number,
    targetQuestionIndex: number
  ) => {
    setSections(
      getReorderedSections(
        sections,
        sectionOrder,
        currQuestionIndex,
        targetQuestionIndex
      )
    )
    setShouldSave(true)
  }

  const setSectionName = (order: number, name: string) => {
    setSections((sections) =>
      sections.map((section) =>
        section.order === order ? { ...section, name } : section
      )
    )
    setShouldSave(true)
  }

  const onDragEnd = (result: DropResult) => {
    const targetSectionOrder = Number(
      result.destination?.droppableId.split(':')[1]
    )
    const indexInSection = result.destination?.index

    if (
      isNaN(targetSectionOrder) ||
      (!indexInSection && indexInSection !== 0)
    ) {
      return
    }

    if (result.draggableId.startsWith(REORDER_QUESTIONS_KEY_PREFIX)) {
      const currQuestionIndex = result.source.index
      return reorderQuestionsInSection(
        targetSectionOrder,
        currQuestionIndex,
        indexInSection
      )
    }

    const isNewQuestion = result.draggableId.startsWith(NEW_QUESTION_KEY_PREFIX)

    if (isNewQuestion) {
      const questionType = newQuestionTypes[result.source.index]
      addNewQuestionToSection(
        targetSectionOrder,
        indexInSection,
        SurveyQuestionEnum[questionType as keyof typeof SurveyQuestionEnum]
      )
    } else {
      // Subtract newQuestionsTypes length (3) needed, because existing question indexes started
      // from i + newQuestionTypes.length
      const indexInQuestionList = result.source.index - newQuestionTypes.length
      addExistingQuestionToSection(
        targetSectionOrder,
        indexInSection,
        indexInQuestionList
      )
    }
  }

  const formMethods = useForm<SurveySchemaById>({
    defaultValues: {
      title: surveySchema?.title || '',
      description: surveySchema?.description || '',
      patientDescription:
        surveySchema?.patientDescription ||
        t('survey:patientDescription.placeholder'),
      categories: surveySchema?.categories || [],
      professions: surveySchema?.professions ?? [],
      referenceUrls:
        surveySchema?.referenceUrls?.map((url) => omit(url, '__typename')) ||
        [],
      snomedCode: surveySchema?.snomedCode ?? '',
      bnoCodes: surveySchema?.bnoCodes ?? [],
      status: surveySchema?.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema?.canBeDeleted ?? true,
    },
  })

  // This function needed to validate each question form in survey form
  const updateQuestionFormMethods = (
    questionFormMethod: QuestionFormMethodType,
    isRemove = false
  ) => {
    if (isRemove) {
      setQuestionFormMethods((questionFormMethods) =>
        questionFormMethods.filter((qf) => qf !== questionFormMethod)
      )
      return
    }

    if (questionFormMethods.find((qf) => qf === questionFormMethod)) {
      setQuestionFormMethods((questionFormMethods) =>
        questionFormMethods.map((qf) =>
          qf === questionFormMethod ? questionFormMethod : qf
        )
      )
    } else {
      setQuestionFormMethods((questionFormMethods) => [
        ...questionFormMethods,
        questionFormMethod,
      ])
    }
  }

  useEffect(() => {
    if (
      formMethods.formState.isDirty &&
      Object.values(formMethods.formState.dirtyFields).length
    ) {
      setShouldSave(true)
    }
  }, [formMethods.formState.isDirty, formMethods.formState.dirtyFields])

  return (
    <SurveyContext.Provider
      value={{
        surveySchemaId: surveySchema?.id,
        isReadonly,
        questionFormMethods,
        updateQuestionFormMethods,
        shouldSave,
        setShouldSave,
      }}
    >
      <FormProvider {...formMethods}>
        <ScrollablePanel
          smoothScroll={true}
          title={
            isReadonly ? t('survey:editor.titleView') : t('survey:editor.title')
          }
          headerRightContent={
            <EditSurveyActions
              surveySchema={surveySchema}
              refetchSurveySchema={refetchSurveySchema}
              sections={sections}
              isPreviewOnState={[isPreviewOn, setIsPreviewOn]}
            />
          }
        >
          {isPreviewOn ? (
            <FillSurvey
              orderedSections={orderedSections}
              numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
            />
          ) : (
            <EditSurvey
              orderedSections={orderedSections}
              numberOfQuestionsInSurvey={numberOfQuestionsInSurvey}
              surveyQuestionListData={surveyQuestionListData}
              filterInputState={[filterInput, setFilterInput]}
              newQuestionTypes={newQuestionTypes}
              setSections={setSections}
              updateQuestionInSections={updateQuestionInSections}
              removeQuestionFromSections={removeQuestionFromSections}
              onDragEnd={onDragEnd}
              setSectionName={setSectionName}
            />
          )}
        </ScrollablePanel>
      </FormProvider>
    </SurveyContext.Provider>
  )
}
