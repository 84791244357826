import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  updateSchemaDependency,
  updateSchemaDependencyVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_SCHEMA_DEPENDENCY } from '../operations/treatmentSchemaOperations'

type Options = MutationHookOptions<
  updateSchemaDependency,
  updateSchemaDependencyVariables
>

type Return = MutationTuple<
  updateSchemaDependency,
  updateSchemaDependencyVariables
>

export const useSchemaDependencyUpdate = (options: Options = {}): Return => {
  return useMutation<updateSchemaDependency, updateSchemaDependencyVariables>(
    UPDATE_SCHEMA_DEPENDENCY,
    {
      ...options,
    }
  )
}
