import { useMutation } from '@apollo/client'
import { REMOVE_TREATMENT_SCHEMA } from '../operations/treatmentSchemaOperations'
import {
  removeTreatmentSchema as RemoveTreatmentSchemaData,
  removeTreatmentSchemaVariables as RemoveTreatmentSchemaVariables,
} from '../../../../models/graphqlTypes'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type Options = MutationHookOptions<
  RemoveTreatmentSchemaData,
  RemoveTreatmentSchemaVariables
>

type Return = MutationTuple<
  RemoveTreatmentSchemaData,
  RemoveTreatmentSchemaVariables
>

export const useTreatmentSchemaRemove = (options: Options = {}): Return => {
  return useMutation<RemoveTreatmentSchemaData, RemoveTreatmentSchemaVariables>(
    REMOVE_TREATMENT_SCHEMA,
    {
      ...options,
    }
  )
}
