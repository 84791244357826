import React, { useRef, useState } from 'react'
import { useTranslation } from '../../hooks/helper/useTranslation'

import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  Paper,
  Popper,
  Typography,
  useTheme,
} from '@mui/material'
import EESZTLogoIcon from '../../../assets/icons/eeszt.gif'
import { useEesztTokenState } from '../../hooks/eeszt/useEesztTokenState'
import { EesztLogin } from './EesztLogin'

const EesztPanel: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [open, toggleOpen] = useState(false)
  const anchorEl = useRef(null)

  const isEesztTokenValid = useEesztTokenState()

  return (
    <ClickAwayListener onClickAway={() => toggleOpen(false)}>
      {/** The listener accepts only one child and it cant be a react fragment  */}
      <Box>
        <Box color="common.white">
          <IconButton
            ref={anchorEl}
            onClick={() => toggleOpen(!open)}
            color="inherit"
            size="large"
            style={{ padding: 0 }}
          >
            <Badge color="error" variant="dot" invisible={!!isEesztTokenValid}>
              <img
                src={EESZTLogoIcon}
                width={40}
                style={{ padding: 0 }}
                alt="eesztLogoIcon"
              />
            </Badge>
          </IconButton>
        </Box>
        <Popper
          style={{ zIndex: 100 }}
          open={open}
          anchorEl={anchorEl?.current}
          placement="bottom-end"
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper
                elevation={2}
                sx={{
                  maxWidth: '550px',
                  maxHeight: '80vh',
                  display: 'flex',
                  flexDirection: 'column',
                  border: 1,
                  borderColor: `${theme.palette.common.black}34`,
                }}
              >
                <Box
                  p={2}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">{t('eeszt:auth.title')}</Typography>
                </Box>
                <Divider />
                <EesztLogin />
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export { EesztPanel }
