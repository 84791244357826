import React from 'react'
import BorderColorIcon from '@mui/icons-material/BorderColor'
import ListAltIcon from '@mui/icons-material/ListAlt'
import LinearScaleIcon from '@mui/icons-material/LinearScale'
import { SvgIconProps } from '@mui/material'
import { SurveyQuestionEnum } from '../../../../../models/graphqlTypes'
import UploadFileIcon from '@mui/icons-material/UploadFile'

interface Props extends SvgIconProps {
  questionType: SurveyQuestionEnum
}

export const QuestionIcon: React.FC<React.PropsWithChildren<Props>> = ({
  questionType,
  ...props
}) => {
  switch (questionType) {
    case SurveyQuestionEnum.SimpleText:
      return <BorderColorIcon {...props} />
    case SurveyQuestionEnum.MultipleChoice:
      return <ListAltIcon {...props} />
    case SurveyQuestionEnum.Scale:
      return <LinearScaleIcon {...props} />
    case SurveyQuestionEnum.FileUpload:
      return <UploadFileIcon {...props} />
  }
}
