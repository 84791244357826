import { Edit as EditIcon } from '@mui/icons-material'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Link,
  TextField,
  Typography,
} from '@mui/material'
import React, { FC, useEffect, useState } from 'react'
import { useDoctorPatientEESZTData } from '../hooks/useDoctorPatientEESZTData'
import { useSelectedLanguage } from '../../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'
import {
  BloodType as BloodTypeEnum,
  UserType,
} from '../../../../models/graphqlTypes'
import theme from '../../../../theme'
import { EHRDocumentTypes } from '../../../../utils/ehr'
import { useUpdatePatientBloodType } from '../hooks/useUpdatePatientBloodType'
import { prettifyBloodType } from '../../../../utils/bloodType'
import { PatientEESZTEhrDocument } from '../types/doctorPatients.types'

const BLOOD_TYPE_VALUES = Object.values(BloodTypeEnum)

type Props = {
  patientId: string
  defaultBloodType?: BloodTypeEnum | null
  defaultAttachmentId?: string | null
}

export const BloodTypeDropdown: FC<React.PropsWithChildren<Props>> = ({
  patientId,
  defaultBloodType,
  defaultAttachmentId,
}) => {
  const userType = useUserType()
  const selectedLanguage = useSelectedLanguage()
  const { t } = useTranslation()
  const [isEditing, toggleEditing] = useState(false)
  const [selectedBloodType, setSelectedBloodType] =
    useState<BloodTypeEnum | null>(defaultBloodType || null)
  const [attachment, setAttachment] = useState<PatientEESZTEhrDocument | null>(
    null
  )

  const isChanged =
    selectedBloodType !== defaultBloodType ||
    !!defaultAttachmentId !== !!attachment?.EESZTId

  const jwtToken = localStorage.getItem('token')

  const [updatePatientBloodType] = useUpdatePatientBloodType({
    onCompleted: () => toggleEditing(false),
  })

  const {
    loading: patientEesztLoading,
    permissionError,
    patientEeszt,
  } = useDoctorPatientEESZTData({ skip: false, patientId })

  useEffect(() => {
    // if a /patient/:id is a startup route
    // the blood type wont be available on the first render
    if (defaultBloodType) {
      setSelectedBloodType(defaultBloodType)
    }
  }, [defaultBloodType])

  useEffect(() => {
    if (!attachment && defaultAttachmentId) {
      const record = patientEeszt.find(
        (record) => record.EESZTId === defaultAttachmentId
      )
      if (record) {
        setAttachment(record)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEeszt])

  const handleSubmit = async () => {
    const selected = selectedBloodType || BLOOD_TYPE_VALUES[0]
    if (selected) {
      await updatePatientBloodType({
        variables: {
          patientId: patientId,
          bloodType: selected,
          bloodTypeAttachmentEESZTId: attachment?.EESZTId || null,
        },
      })
      setSelectedBloodType(selected)
    }
  }

  const onToggle = () => {
    if (isEditing) {
      setSelectedBloodType(defaultBloodType || null)
      const record =
        defaultAttachmentId &&
        patientEeszt.find((record) => record.EESZTId === defaultAttachmentId)

      setAttachment(record || null)
    }
    toggleEditing((editing) => !editing)
  }

  return (
    <>
      <Box my={1}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          color={theme.palette.grey[600]}
          pb={1}
        >
          <Typography variant="subtitle1">
            {t('patients:assumedloodType')}
          </Typography>

          <Box ml={1} display="flex" alignItems="center">
            {!isEditing && userType === UserType.Doctor && (
              <Button
                variant="text"
                size="small"
                style={{ padding: 0 }}
                onClick={onToggle}
              >
                <EditIcon sx={{ width: 24, height: 24 }} />
              </Button>
            )}
          </Box>
        </Box>

        {isEditing && permissionError && (
          <Box py={1}>
            <Alert severity="error">{t('eeszt:missingKATEvent')}</Alert>
          </Box>
        )}

        <Box display="flex" alignItems="center" justifyContent="space-between">
          {isEditing && (
            <TextField
              placeholder="Placeholder text"
              value={selectedBloodType || ''}
              select
              size="small"
              onChange={(e) =>
                setSelectedBloodType(e.target.value as BloodTypeEnum)
              }
              SelectProps={{
                native: true,
              }}
            >
              {BLOOD_TYPE_VALUES.map((value) => (
                <option key={value} value={value}>
                  {prettifyBloodType(value)}
                </option>
              ))}
            </TextField>
          )}
          {!isEditing && (
            <Typography variant="subtitle2">
              {selectedBloodType
                ? BLOOD_TYPE_VALUES.find((value) => value === selectedBloodType)
                : t('common:emptyField')}
            </Typography>
          )}
        </Box>
      </Box>
      <Box my={1} display="flex" flexDirection="row" alignItems="center">
        <Box flex={1}>
          {isEditing && (
            <Autocomplete
              loading={patientEesztLoading}
              options={patientEeszt}
              value={attachment}
              getOptionLabel={(document) => {
                const documentDate = t('common:datePrintedDayWithHours', {
                  date: document.timestamp,
                })
                const documentType =
                  EHRDocumentTypes.find((dt) => dt.id === document.documentType)
                    ?.name[selectedLanguage] || ''
                return `${documentDate} - ${documentType}`
              }}
              onChange={(_, newValue) => {
                setAttachment(newValue)
              }}
              noOptionsText={t('common:emptyDocumentOptions')}
              size="small"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('patient.chooseEesztRecord')}
                  variant="outlined"
                />
              )}
            />
          )}
          {!isEditing && (
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle2">
                {attachment
                  ? attachment.documentType
                  : t('common:emptyAttachmentField')}
              </Typography>
              {attachment && attachment?.EESZTId && (
                <Link
                  target="_blank"
                  rel="noreferrer"
                  href={`/api/eeszt_record/${attachment.EESZTId}/${jwtToken}`}
                >
                  <Typography variant="subtitle1">
                    {t('common:download')}
                  </Typography>
                </Link>
              )}
            </Box>
          )}
        </Box>
      </Box>
      {isEditing && isChanged && (
        <Box display="flex" justifyContent="flex-end" py={1}>
          <Box mr={1}>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleSubmit}
            >
              {t('common:save')}
            </Button>
          </Box>

          <Button size="small" variant="outlined" onClick={onToggle}>
            {t('common:cancel')}
          </Button>
        </Box>
      )}
    </>
  )
}
