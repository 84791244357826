import { RemoveCircleOutline as RemoveIcon } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import React, { FC, useState } from 'react'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { DeletePatientModal } from './DeletePatientModal'

interface Props {
  patientId: string
  patientName: string
  tajNumber: string
  onRemove?: () => void
}

/**
 * Patient deletion section
 */
export const DeleteAction: FC<React.PropsWithChildren<Props>> = ({
  patientId,
  patientName,
  tajNumber,
  onRemove,
}) => {
  const { t } = useTranslation()
  const [isModalOpen, setModalOpen] = useState(false)

  const toggleModal = () => setModalOpen((isOpen) => !isOpen)

  return (
    <>
      <Box display="flex" alignItems="center" mt={3} px={2}>
        <Box flex={1}>
          <Button
            color="error"
            variant="outlined"
            fullWidth={true}
            startIcon={<RemoveIcon />}
            size="small"
            onClick={toggleModal}
          >
            {t('treatment:doctor.deletePatient')}
          </Button>
        </Box>
      </Box>
      <Box mb={3}>
        <DeletePatientModal
          isOpen={isModalOpen}
          toggleIsOpen={toggleModal}
          patientId={patientId}
          name={patientName}
          tajNumber={tajNumber}
          refetch={onRemove}
        />
      </Box>
    </>
  )
}
