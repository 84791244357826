import React from 'react'
import { useTranslation } from '../../common/hooks/helper/useTranslation'
import { Typography } from '@mui/material'

import { ScrollablePanel } from '../../common/components/ScrollablePanel'

const CookiePolicy: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()

  return (
    <ScrollablePanel title={t('cookie:title')}>
      <Typography variant="body1">{t('cookie:policy')}</Typography>
    </ScrollablePanel>
  )
}

export { CookiePolicy }
