import { Box, Tab, Tabs, Typography } from '@mui/material'
import React, { FC, useEffect } from 'react'
import { useHistory } from 'react-router'
import { useTranslation } from '../../../../common/hooks/helper/useTranslation'
import { useUserType } from '../../../../common/hooks/useUserType'

const tabs = [
  {
    key: 'treatments',
    translationKey: 'treatment:doctor.patientsTreatmentPlans',
  },
  {
    key: 'profile',
    translationKey: 'patients:profileTitle',
  },
  {
    key: 'files',
    translationKey: 'treatment:files',
  },
  {
    key: 'doctors',
    translationKey: 'patients:doctors',
  },
  {
    key: 'surveys',
    translationKey: 'patients:surveys.title',
  },
  {
    key: 'notifications',
    translationKey: 'notification:notifications',
  },
]

type Props = {
  patientId: string
  activeTabIndex: number
  setActiveTabIndex: (index: number) => void
}
export const PatientTabNavigation: FC<React.PropsWithChildren<Props>> = ({
  patientId,
  activeTabIndex,
  setActiveTabIndex,
}) => {
  const { t } = useTranslation()
  const history = useHistory()

  const userType = useUserType()

  // navigate to tab 0 on dismount
  useEffect(() => () => setActiveTabIndex(0), [setActiveTabIndex])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue)
  }

  const handleClick = () => {
    history.push(`/${userType}/patients/${patientId}`)
  }

  return (
    <Box
      display="flex"
      flexShrink={0}
      alignItems="center"
      justifyContent="space-between"
      maxWidth="100vw"
    >
      <Tabs
        value={activeTabIndex}
        onClick={handleClick}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        scrollButtons={false}
      >
        {tabs.map(({ key, translationKey }) => {
          return (
            <Tab
              key={key}
              label={<Typography variant="h6">{t(translationKey)}</Typography>}
              id={`treatment-tab-${key}`}
              aria-controls={`treatment-tabpanel-${key}`}
            />
          )
        })}
      </Tabs>
    </Box>
  )
}
