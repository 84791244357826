import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { SurveySchemaStatusEnum } from '../../../../../models/graphqlTypes'
import { SurveyActionsMenu } from '../SurveyActions/SurveyActionsMenu'
import {
  SurveyResultHeaderForm,
  SurveySchema,
} from '../../types/surveyStore.types'
import { MeType } from '../../../../../common/types/me.type'

interface Props {
  surveySchema: SurveySchema
  refetchSurveys: () => void
  me?: MeType
}

export const SurveyListMore: React.FC<React.PropsWithChildren<Props>> = ({
  surveySchema,
  refetchSurveys,
  me,
}) => {
  const isOwnSurvey = useMemo(
    () => surveySchema.user?.id === me?.id,
    [me?.id, surveySchema.user?.id]
  )

  const formMethods = useForm<SurveyResultHeaderForm>({
    defaultValues: {
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    },
  })

  useEffect(() => {
    formMethods.reset({
      status: surveySchema.status || SurveySchemaStatusEnum.InActive,
      canBeDeleted: surveySchema.canBeDeleted ?? true,
    })
  }, [formMethods, surveySchema])

  return (
    <FormProvider {...formMethods}>
      <SurveyActionsMenu
        surveySchema={surveySchema}
        refetchSurveys={refetchSurveys}
        hideSensitiveMenuItems={!isOwnSurvey && !me?.isAdmin}
      />
    </FormProvider>
  )
}
