import React, { FC } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  PaperProps,
} from '@mui/material'
import { SaveButton } from '../../../common/components/SaveButton'
import Autocomplete from '@mui/material/Autocomplete'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'
import {
  UserType,
  institutionChore,
  profileChore,
} from '../../../models/graphqlTypes'
import { useProfileList } from '../../../common/hooks/useProfileList'
import { useAdminsToInstitutionAssign } from '../hooks/useAdminsToInstitutionAssign'
import { useInstitutionAdminProfileList } from '../hooks/useInstitutionAdminProfileList'

type FormValues = {
  profiles: profileChore[]
}

type Props = {
  institution: institutionChore
  isOpen: boolean
  onClose: () => void
}

export const InstitutionAdminModal: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const { institution, isOpen, onClose } = props
  const { t } = useTranslation()
  const { users, loading } = useProfileList({
    skip: !institution,
    variables: { types: [UserType.Doctor, UserType.Assistant] },
  })

  const [assignAdminsToInstitution] = useAdminsToInstitutionAssign()

  const { control, formState, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      profiles: [],
    },
  })
  const { isSubmitting } = formState

  useInstitutionAdminProfileList({
    notifyOnNetworkStatusChange: true,
    variables: {
      institutionId: institution.id,
    },
    onCompleted: (data) => {
      reset({
        profiles: data.institutionAdminProfiles,
      })
    },
  })

  const onSubmit = handleSubmit(async (formValues, event) => {
    event?.stopPropagation()
    if (!institution) {
      return
    }
    const userIds = formValues.profiles
      .map((item) => item.user?.id)
      .filter((item): item is string => !!item)

    await assignAdminsToInstitution({
      variables: {
        userIds,
        institutionId: institution.id,
      },
    })
    onClose()
  })

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      fullWidth
      PaperProps={{ component: 'form' } as Partial<PaperProps<'div'>>}
      onSubmit={onSubmit}
    >
      <DialogTitle>
        {t('institution:locationAdmin.userDialogTitle')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('institution:locationAdmin.userDialogText')}
        </DialogContentText>
        <Controller
          control={control}
          name="profiles"
          defaultValue={[]}
          render={({ field: { onChange, onBlur, value, name } }) => {
            return (
              <Autocomplete<profileChore, true, true>
                fullWidth
                multiple
                loading={loading}
                options={users}
                disableClearable
                filterSelectedOptions
                getOptionLabel={(profile) =>
                  t('common:formattedNameFull', {
                    title: profile.title,
                    firstName: profile.firstName,
                    lastName: profile.lastName,
                  })
                }
                isOptionEqualToValue={(option, value) => option.id === value.id}
                value={value || []}
                onChange={(event, value) => {
                  onChange(value)
                }}
                onBlur={onBlur}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder={t('common:search')}
                    variant="outlined"
                    name={name}
                  />
                )}
              />
            )
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t('common:cancel')}
        </Button>
        <SaveButton type="submit" isSaving={isSubmitting} />
      </DialogActions>
    </Dialog>
  )
}
