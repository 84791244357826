import { QueryHookOptions, useQuery } from '@apollo/client'
import {
  aggregatedSurveyResults,
  aggregatedSurveyResultsVariables,
} from '../../../../models/graphqlTypes'

import { GET_AGGREGATED_SURVEY_RESULTS } from '../operations/surveyOperations'
import { AggregatedSurveyResults } from '../types/surveyStore.types'

type Options = QueryHookOptions<
  aggregatedSurveyResults,
  aggregatedSurveyResultsVariables
>

type Return = {
  aggregatedSurveyResults?: AggregatedSurveyResults
  loading: boolean
  refetch: () => void
  fetchMore: (option: Options) => void
}
export const useAggregatedSurveyResults = (options: Options = {}): Return => {
  const { data, loading, refetch, fetchMore } = useQuery<
    aggregatedSurveyResults,
    aggregatedSurveyResultsVariables
  >(GET_AGGREGATED_SURVEY_RESULTS, options)

  return {
    aggregatedSurveyResults: data?.getAggregatedSurveyResults,
    loading,
    refetch,
    fetchMore,
  }
}
