import React, { useState } from 'react'
import { Box, useTheme } from '@mui/material'
import { OpenFooter } from './Footer/OpenFooter'
import { ClosedFooter } from './Footer/ClosedFooter'

const Footer: React.FC<React.PropsWithChildren<unknown>> = () => {
  const theme = useTheme()

  const [isFooterOpen, setIsFooterOpen] = useState(false)

  return (
    <Box
      height={isFooterOpen ? { xs: 370, sm: 340, md: 200 } : 45}
      sx={{ transition: 'height 400ms' }}
      color="common.white"
      bgcolor={theme.palette.grey[500]}
    >
      {isFooterOpen ? (
        <OpenFooter setIsFooterOpen={setIsFooterOpen} />
      ) : (
        <ClosedFooter setIsFooterOpen={setIsFooterOpen} />
      )}
    </Box>
  )
}

export { Footer }
