import React, { useState } from 'react'

import { Box, Collapse } from '@mui/material'

import { Alert } from '@mui/material'
import { useMutation } from '@apollo/client'

import { useTranslation } from '../../hooks/helper/useTranslation'
import { useEesztTokenState } from '../../hooks/eeszt/useEesztTokenState'
import { EesztEszigLogin } from './EesztEszigLogin'
import { EesztLoginTabs } from './EesztLoginTabs'
import { EesztPasswordLogin } from './EesztPasswordLogin'
import { SaveButton } from '../SaveButton'
import { EESZTStsLoginInput } from '../../../models/graphqlTypes'
import { EESZT_LOGOUT } from '../../../operations/eesztOperations'
import { EesztMobileToken } from './EesztMobileToken'

const EesztLogin: React.FC<React.PropsWithChildren<unknown>> = () => {
  const isPasswordLoginEnabled =
    process.env.REACT_APP_EESZT_PASSWORD_LOGIN === 'true'
  const { t } = useTranslation()

  const isEesztTokenValid = useEesztTokenState()

  const [selectedTabIndex, setSelectedTabIndex] = useState(0)

  const [logoutFromEeszt, { loading }] = useMutation<EESZTStsLoginInput>(
    EESZT_LOGOUT,
    { refetchQueries: ['hasEESZTToken'] }
  )

  return (
    <Box width={340}>
      <form>
        <Collapse in={!isEesztTokenValid}>
          <EesztLoginTabs
            selectedTabIndex={selectedTabIndex}
            setSelectedTabIndex={setSelectedTabIndex}
          />
          <Box p={2}>
            {/* <BoxWithLabel label={t(`eeszt:auth.otp`)}>
            <TextField
              size="small"
              placeholder={t(`eeszt:auth.placeholder.otp`)}
              name="otp"
              error={!!errors.otp}
              helperText={
                errors.otp?.message ? t(errors.otp?.message) : undefined
              }
              value={watch('otp')}
              onChange={() => clearErrors('otp')}
              required
              fullWidth
              inputRef={register({
                required: {
                  value: true,
                  message: 'messages:warnings.emptyField',
                },
              })}
            />
          </BoxWithLabel> */}

            {selectedTabIndex === 0 && <EesztMobileToken />}
            {selectedTabIndex === 1 && <EesztEszigLogin />}
            {isPasswordLoginEnabled && selectedTabIndex === 2 && (
              <EesztPasswordLogin />
            )}
          </Box>
        </Collapse>

        <Collapse in={isEesztTokenValid}>
          <Box p={2}>
            <Alert severity="success">{t(`eeszt:auth.connected`)}</Alert>

            <Box display="flex" justifyContent="flex-end" mt={2}>
              <SaveButton
                fullWidth
                isSaving={loading}
                onClick={() => logoutFromEeszt()}
                text={t('eeszt:auth.logout')}
                inProgressText={t('eeszt:auth.inProgress')}
              />
            </Box>
          </Box>
        </Collapse>
      </form>
    </Box>
  )
}

export { EesztLogin }
