import { Stack, TextField } from '@mui/material'
import React, { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { QuestionForm } from '../Questions/questions.types'

interface Props {
  disabled?: boolean
}

export const EditFreeTextQuestion: FC<React.PropsWithChildren<Props>> = ({
  disabled,
}) => {
  const { t } = useTranslation()
  const {
    register,
    formState: { errors },
  } = useFormContext<QuestionForm>()

  return (
    <Stack data-cy="EditFreeTextQuestion" gap={2} flex={1}>
      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.title')}
        {...register('title', {
          required: true,
        })}
        error={!!errors.title}
        helperText={
          !!errors.title && (t('messages:warnings.required') as string)
        }
        disabled={disabled}
      />
      <TextField
        fullWidth
        variant="outlined"
        minRows={2}
        multiline
        placeholder={t('survey:question.description')}
        {...register('description')}
        disabled={disabled}
      />
    </Stack>
  )
}
