import CloseIcon from '@mui/icons-material/Close'
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import React, { useContext, useState } from 'react'
import { Draggable, Droppable } from 'react-beautiful-dnd'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { ConfirmDialog } from '../../../../../common/components/dialogs/ConfirmDialog'
import { ConfirmDialogValue } from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { useStylesForEditableTitles } from '../../utils/style.util'
import { REORDER_QUESTIONS_KEY_PREFIX } from '../../utils/survey.util'
import { SurveyContext } from '../EditSurveyState'
import { EditQuestionCard } from './EditQuestionCard'
import { QuestionInSection, Section } from './editSurvey.types'

interface Props {
  section: Section
  sections: Section[]
  updateQuestionInSections: (
    newQuestion: QuestionInSection,
    sectionOrder: number
  ) => void
  removeQuestionFromSections: (
    questionToRemove: QuestionInSection,
    sectionOrder: number
  ) => void
  setSectionName: (order: number, name: string) => void
  deleteSection: (order: number) => void
}

export const SurveySection: React.FC<React.PropsWithChildren<Props>> = ({
  section,
  sections,
  updateQuestionInSections,
  removeQuestionFromSections,
  setSectionName,
  deleteSection,
}) => {
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const classes = useStylesForEditableTitles()
  const [showConfirmDialog, setShowConfirmDialog] =
    useState<ConfirmDialogValue>(false)
  const { isReadonly } = useContext(SurveyContext)

  return (
    <>
      <Card>
        <CardHeader
          title={t('survey:editor.section.title', { index: section.order })}
          sx={{ px: 3, pb: 0, pt: 3 }}
          action={
            <>
              {!isReadonly && (
                <IconButton
                  size="large"
                  onClick={() => setShowConfirmDialog(true)}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          }
        ></CardHeader>
        <CardContent
          sx={{
            p: 3,
            pt: 1,
          }}
        >
          <Stack alignItems="center">
            <TextField
              onChange={(e) => setSectionName(section.order, e.target.value)}
              value={section.name}
              fullWidth
              variant="outlined"
              placeholder={t('survey:section.title.placeholder')}
              disabled={isReadonly}
              InputProps={{
                classes: { input: classes.input },
              }}
              sx={{ pb: 2 }}
            />
          </Stack>

          <Droppable
            droppableId={`section:${section.order}`}
            isDropDisabled={false}
            isCombineEnabled={false}
            ignoreContainerClipping={true}
          >
            {(provided) => (
              <Stack
                gap={1}
                mt={2}
                {...provided.droppableProps}
                ref={provided.innerRef}
                data-cy="SurveySchema-DropZone"
              >
                {!section.questions.length && (
                  <Card
                    elevation={0}
                    sx={{
                      mt: 2,
                      backgroundColor: colorPalette.grey[200],
                      p: 4,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="body1">
                      {t('survey:editor.empty.section')}
                    </Typography>
                  </Card>
                )}
                {section.questions.map(
                  (question: QuestionInSection, i: number) => (
                    <Draggable
                      key={`${REORDER_QUESTIONS_KEY_PREFIX}:${question.idForRender}`}
                      draggableId={`${REORDER_QUESTIONS_KEY_PREFIX}:${question.idForRender}`}
                      index={i}
                    >
                      {(provided, snapshot) => (
                        <Stack
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <EditQuestionCard
                            key={question.idForRender}
                            question={question}
                            indexOfQuestion={i + 1}
                            dragHandleProps={provided.dragHandleProps}
                            isDragging={snapshot.isDragging}
                            sections={sections}
                            sectionOrder={section.order}
                            updateQuestionInSections={(newQuestion) =>
                              updateQuestionInSections(
                                newQuestion,
                                section.order
                              )
                            }
                            removeQuestionFromSections={(questionToRemove) =>
                              removeQuestionFromSections(
                                questionToRemove,
                                section.order
                              )
                            }
                          />
                        </Stack>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </Stack>
            )}
          </Droppable>
        </CardContent>
      </Card>
      {showConfirmDialog && (
        <ConfirmDialog
          isAlertingDialog
          valueState={[showConfirmDialog, setShowConfirmDialog]}
          text={t(`survey:confirmSurveySectionRemoval`)}
          onAccept={() => deleteSection(section.order)}
        />
      )}
    </>
  )
}
