import React, { useRef, useState } from 'react'
import { useLogout } from '../../components/Login/hooks/useLogout'
import { useTranslation } from '../hooks/helper/useTranslation'
import {
  Divider,
  Box,
  Typography,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useUserProfile } from '../hooks/useUserProfile'
import { LogoutIcon } from '../icons/LogoutIcon'
import { PROFILE_POPPER_WIDTH } from './constants'
import { LanguageSelector } from '../components/selectors/LanguageSelector'
import { Avatar } from '../components/Avatar'
import { ProfileMenuItem } from './ProfileMenuItem'
import { useMenuItems } from '../hooks/useMenuItems'

const useStyles = makeStyles(() => ({
  menuContainer: {
    zIndex: 1,
  },
}))

const ProfileMenu: React.FC<React.PropsWithChildren<unknown>> = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const logout = useLogout()
  const profile = useUserProfile()
  const menuItems = useMenuItems()

  const [open, toggleOpen] = useState(false)

  const anchorEl = useRef(null)

  return (
    <ClickAwayListener onClickAway={() => toggleOpen(false)}>
      {/** The listener accepts only one child and it cant be a react fragment  */}
      <Box>
        <Box mr={-1}>
          <Button
            ref={anchorEl}
            variant="text"
            data-cy="ProfileMenu-Button-toggle"
            onClick={() => toggleOpen((open) => !open)}
          >
            <Box
              color="common.white"
              display="flex"
              flexGrow={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box mx={2} display={{ xs: 'none', sm: 'block' }}>
                <Typography>{profile?.firstName} ▼</Typography>
              </Box>

              <Avatar
                firstName={profile?.firstName ?? ''}
                lastName={profile?.lastName ?? ''}
                size="tiny"
              />
            </Box>
          </Button>
        </Box>

        <Popper
          keepMounted
          placement="bottom-end"
          disablePortal
          anchorEl={anchorEl?.current}
          open={open}
          className={classes.menuContainer}
        >
          <Paper elevation={2} sx={{ width: PROFILE_POPPER_WIDTH }}>
            <Box p={2.5} display="flex" alignItems="center">
              <Avatar
                firstName={profile?.firstName ?? ''}
                lastName={profile?.lastName ?? ''}
              />
              <Box ml={2}>
                <Typography variant="body2">{t('common:greeting')}</Typography>
                <Box fontWeight={600}>
                  <Typography variant="h5">
                    {t('common:formattedNameFull', {
                      title: profile?.title ?? '',
                      firstName: profile?.firstName ?? '',
                      lastName: profile?.lastName ?? '',
                    })}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box py={1}>
              {menuItems.map(
                ({ link, translationKey, IconComponent, slug }) => (
                  <ProfileMenuItem
                    key={translationKey}
                    slug={slug}
                    link={link}
                    IconComponent={IconComponent}
                    onClick={() => toggleOpen(false)}
                  >
                    {translationKey}
                  </ProfileMenuItem>
                )
              )}
              <ProfileMenuItem
                slug="logout"
                IconComponent={LogoutIcon}
                onClick={() => {
                  logout()
                  toggleOpen(false)
                }}
              >
                auth:logout
              </ProfileMenuItem>
            </Box>
            <Divider />
            <Box py={1}>
              <LanguageSelector />
            </Box>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  )
}

export { ProfileMenu }
