import React from 'react'
import { Box, Grid, Hidden, Typography } from '@mui/material'
import { useSelectedLanguage } from '../../../../../common/hooks/useSelectedLanguage'
import AppointmentTimeAndStatus from './AppointmentTimeAndStatus'
import { DoctorInfo } from '../../../../../common/components/DoctorInfo/DoctorInfo'
import EventIcon from '@mui/icons-material/Event'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { AppointmentLocation } from './AppointmentLocation'
import makeStyles from '@mui/styles/makeStyles'
import { PatientAppointment } from '../../types/patientEvents.types'

const useStyles = makeStyles({
  multiLineEllipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },
})

type Props = {
  appointment: PatientAppointment
  isPast?: boolean
  hideContactButtons?: boolean
  showGoodToKnow?: boolean
}

const AppointmentHeader: React.FC<React.PropsWithChildren<Props>> = ({
  appointment,
  isPast,
  hideContactButtons,
  showGoodToKnow,
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const selectedLanguage = useSelectedLanguage()

  const doctorProfile = appointment.doctorProfile

  return (
    <Grid
      container
      display="flex"
      flexDirection={{ xs: 'column', md: 'row' }}
      alignItems="center"
    >
      <Hidden mdDown>
        <Grid
          item
          xs
          display="flex"
          flexDirection="column"
          alignSelf={{ xs: 'flex-start' }}
        >
          <Box display="flex" gap={1}>
            <Box
              display="flex"
              gap={0.5}
              alignItems="center"
              sx={{ height: 'min-content' }}
            >
              <EventIcon />
              <Typography variant="body2">
                {t('treatment:dateAndTime')}:
              </Typography>
            </Box>
            <AppointmentTimeAndStatus {...appointment} />
          </Box>
          {!!appointment?.institution?.name && (
            <Box display="flex" gap={1} mt={2}>
              <Box
                display="flex"
                gap={0.5}
                alignItems="center"
                sx={{ height: 'min-content' }}
              >
                <LocationCityIcon />
                <Typography variant="body2">
                  {t('treatment:location')}:
                </Typography>
              </Box>
              <AppointmentLocation
                appointment={appointment as PatientAppointment}
              />
            </Box>
          )}
        </Grid>
      </Hidden>
      <Hidden mdUp>
        <AppointmentTimeAndStatus {...appointment} isMobile />
        {!!appointment?.institution?.name && (
          <Box display="flex" width="100%">
            <AppointmentLocation
              appointment={appointment as PatientAppointment}
            />
          </Box>
        )}
      </Hidden>
      <Grid
        item
        xs
        pt={{ xs: 0.5, md: 0 }}
        display="flex"
        flexDirection="column"
        alignSelf={{ xs: 'flex-start' }}
      >
        <Typography variant="body1" color={!isPast ? 'textPrimary' : undefined}>
          <strong>{appointment.info?.patientTitle[selectedLanguage]}</strong>
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {appointment.treatment.title[selectedLanguage]}
        </Typography>
      </Grid>
      <Hidden lgDown implementation="js">
        <Grid
          item
          xs={3}
          display="flex"
          alignSelf={{ xs: 'flex-start' }}
          justifyContent={showGoodToKnow ? 'flex-start' : 'flex-end'}
        >
          <DoctorInfo
            doctor={doctorProfile}
            hideAssistants
            hideDetails
            hideTitle
            hideAvatar={hideContactButtons}
            avatarSize="small"
            hideContactButtons={hideContactButtons}
            hideEmail={!hideContactButtons}
            linkToDoctor={`/patient/doctors/${doctorProfile.id}`}
          />
        </Grid>
      </Hidden>

      {showGoodToKnow && (
        <Hidden lgDown>
          <Grid item xs={3} display="flex" alignSelf={{ xs: 'flex-start' }}>
            <Typography
              variant="subtitle1"
              className={classes.multiLineEllipsis}
            >
              {appointment.info?.goodToKnow[selectedLanguage]}
            </Typography>
          </Grid>
        </Hidden>
      )}
    </Grid>
  )
}

export default AppointmentHeader
