import React from 'react'
import { useFormContext } from 'react-hook-form'
import { SurveyQuestionEnum } from '../../../../../models/graphqlTypes'
import { QuestionInSection } from '../EditSurvey/editSurvey.types'
import { FillFileUploadQuestion } from './FillFileUploadQuestion'
import { FillMultipleChoiceQuestion } from './FillMultipleChoiceQuestion'
import { FillScaleQuestion } from './FillScaleQuestion'
import { FillSimpleTextQuestion } from './FillSimpleTextQuestion'

interface Props {
  question: QuestionInSection
  setNextSectionOrder?: (order: number) => void
  isPreview: boolean
  readOnly?: boolean
}

export const FillQuestionByType: React.FC<React.PropsWithChildren<Props>> = ({
  question,
  setNextSectionOrder,
  isPreview,
  readOnly,
}) => {
  const { getValues } = useFormContext()

  const answers = getValues().answers
  const answerIndex = answers?.length
    ? answers.findIndex(
        (a: { surveyQuestionId: string }) => a.surveyQuestionId === question.id
      )
    : 0

  switch (question.type) {
    case SurveyQuestionEnum.SimpleText:
      return (
        <FillSimpleTextQuestion
          question={question}
          answerIndex={answerIndex}
          isPreview={isPreview}
          readOnly={readOnly}
        />
      )
    case SurveyQuestionEnum.MultipleChoice:
      return (
        <FillMultipleChoiceQuestion
          question={question}
          setNextSectionOrder={setNextSectionOrder}
          answerIndex={answerIndex}
          isPreview={isPreview}
          readOnly={readOnly}
        />
      )
    case SurveyQuestionEnum.Scale:
      return (
        <FillScaleQuestion
          question={question}
          answerIndex={answerIndex}
          isPreview={isPreview}
          readOnly={readOnly}
        />
      )
    case SurveyQuestionEnum.FileUpload:
      return (
        <FillFileUploadQuestion
          question={question}
          answerIndex={answerIndex}
          isPreview={isPreview}
          readOnly={readOnly}
        />
      )
    default:
      return null
  }
}
