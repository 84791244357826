import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import {
  shareSurveySchema,
  shareSurveySchemaVariables,
} from '../../../../models/graphqlTypes'
import { SHARE_SURVEY_SCHEMA } from '../operations/surveyOperations'

type Options = MutationHookOptions<
  shareSurveySchema,
  shareSurveySchemaVariables
>

type Return = MutationTuple<shareSurveySchema, shareSurveySchemaVariables>

export const useSurveySchemaShare = (options: Options = {}): Return => {
  return useMutation<shareSurveySchema, shareSurveySchemaVariables>(
    SHARE_SURVEY_SCHEMA,
    options
  )
}
