import { useMutation } from '@apollo/client'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  updateScheduleAppointmentInfo,
  updateScheduleAppointmentInfoVariables,
} from '../../../../models/graphqlTypes'
import { UPDATE_SCHEDULE_APPOINTMENT_INFO } from '../../../../operations/appointmentOperations'

type Options = MutationHookOptions<
  updateScheduleAppointmentInfo,
  updateScheduleAppointmentInfoVariables
>

type Return = MutationTuple<
  updateScheduleAppointmentInfo,
  updateScheduleAppointmentInfoVariables
>

export const useUpdateScheduleAppointmentInfo = (
  options: Options = {}
): Return => {
  return useMutation<
    updateScheduleAppointmentInfo,
    updateScheduleAppointmentInfoVariables
  >(UPDATE_SCHEDULE_APPOINTMENT_INFO, {
    ...options,
  })
}
