import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { StoreProvider } from 'easy-peasy'
import { Box, CssBaseline, ThemeProvider, Theme } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import 'dayjs/locale/hu'
import { StyledEngineProvider } from '@mui/material/styles'
import './i18n'
import ApolloApp from './ApolloProvider'

import App from './App'
import theme from './theme'
import { store } from './store'
import * as serviceWorker from './serviceWorker'
import { Loading } from './common/components/Loading'
import { printBetmenConsole } from './console'
import { NotificationProvider } from './common/providers/NotificationProvider'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-object-type
  interface DefaultTheme extends Theme {}
}

const container = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)

printBetmenConsole()
root.render(
  <Suspense
    fallback={
      <Box
        display="flex"
        height="100%"
        justifyContent="center"
        flexDirection="column"
        mt={5}
        color="#32C8FF"
      >
        <Loading color="inherit" />
      </Box>
    }
  >
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'hu'}>
      <StoreProvider store={store}>
        <NotificationProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <BrowserRouter>
                <CssBaseline />
                <ApolloApp>
                  <App />
                </ApolloApp>
              </BrowserRouter>
            </ThemeProvider>
          </StyledEngineProvider>
        </NotificationProvider>
      </StoreProvider>
    </LocalizationProvider>
  </Suspense>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
