import { Box, Link } from '@mui/material'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import { useUserType } from '../hooks/useUserType'

import { UserType } from '../../models/graphqlTypes'
import { useColorPalette } from '../hooks/helper/useColor'
import { useTranslation } from '../hooks/helper/useTranslation'
import {
  adminMenuItems,
  assistantMenuItems,
  doctorMenuItems,
  MenuItem,
  patientMenuItems,
} from './menuItems'

type NavProps = {
  isColumn?: boolean
  onClick?: () => void
}

const Nav: React.FC<React.PropsWithChildren<NavProps>> = ({
  isColumn,
  onClick,
}) => {
  const userType = useUserType()
  const { t } = useTranslation()
  const colorPalette = useColorPalette()

  const location = useLocation()

  const menuItemsMap: Record<UserType, MenuItem[]> = {
    Doctor: doctorMenuItems,
    Patient: patientMenuItems,
    Assistant: assistantMenuItems,
    Admin: adminMenuItems,
  }

  const menuItems = !userType ? [] : menuItemsMap[userType]

  return (
    <Box display="flex" flexDirection={isColumn ? 'column' : 'row'}>
      {menuItems.map(({ link, translationKey, slug }) => {
        const isActive = location.pathname.includes(link)
        return (
          <Box
            key={link}
            ml={isColumn ? 0 : 4}
            mb={isColumn ? 3 : 0}
            color="common.white"
          >
            <Link
              component={RouterLink}
              to={link}
              onClick={onClick}
              variant={isColumn ? 'h5' : 'h6'}
              data-cy={`Nav-Link-${slug}`}
              sx={{
                color: isActive
                  ? colorPalette.primary.main
                  : colorPalette.common.white,
                ':hover': {
                  transition: 'all 0.3s ease',
                  textDecoration: 'none',
                },
              }}
            >
              {t(translationKey)}
            </Link>
          </Box>
        )
      })}
    </Box>
  )
}

export { Nav }
