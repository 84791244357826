import React, { useState } from 'react'
import { Button, ButtonProps } from '@mui/material'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { DeleteForever as DeleteIcon } from '@mui/icons-material'
import {
  ConfirmDialog,
  ConfirmDialogValue,
} from '../../../../../common/components/dialogs/ConfirmDialog/ConfirmDialog'
import { useTreatmentSchemaUnSubscribe } from '../../hooks/useTreatmentSchemaUnSubscribe'
import { useUserProfile } from '../../../../../common/hooks/useUserProfile'
import { useHistory } from 'react-router-dom'
import { TreatmentSchema } from '../../types/treatmentSchemas.types'

interface Props extends ButtonProps {
  treatmentSchema: TreatmentSchema
}

export const TreatmentSchemaUnsubscribeButton: React.FC<
  React.PropsWithChildren<Props>
> = ({ treatmentSchema, ...buttonProps }) => {
  const history = useHistory()
  const { t } = useTranslation()
  const [isUnSubscribeConfirm, setIsUnSubscribeConfirm] =
    useState<ConfirmDialogValue>(false)

  const profile = useUserProfile()
  const doctorInstitutions =
    profile?.__typename === 'DoctorProfile' ? profile.institutionToDoctor : []

  const doctorInstitutionIds = (doctorInstitutions || []).map(
    (inst) => inst.institution.id
  )
  const treatmentInstitutionIds = treatmentSchema.institutions.map(
    ({ id }) => id
  )

  const subscribedViaInstitution = doctorInstitutionIds.some((institutionId) =>
    treatmentInstitutionIds?.includes(institutionId)
  )

  const [unSubscribeTreatmentSchema] = useTreatmentSchemaUnSubscribe({
    onCompleted: () => {
      if (!subscribedViaInstitution) {
        history.push('/doctor/treatment-options')
      }
    },
  })
  return (
    <>
      <Button
        startIcon={<DeleteIcon fontSize="large" />}
        onClick={() => setIsUnSubscribeConfirm(true)}
        variant="text"
        {...buttonProps}
      >
        {t('table:body.unSubscribeTooltip')}
      </Button>

      <ConfirmDialog
        isAlertingDialog
        valueState={[isUnSubscribeConfirm, setIsUnSubscribeConfirm]}
        text={t(`treatment:protocol.confirmUnSubscribe`)}
        onAccept={() => {
          return unSubscribeTreatmentSchema({
            variables: {
              treatmentData: {
                schemaId: treatmentSchema.id,
              },
            },
          })
        }}
      />
    </>
  )
}
