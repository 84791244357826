import { Box, Button, Typography } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useColorPalette } from '../hooks/helper/useColor'
import { useTranslation } from '../hooks/helper/useTranslation'
import { ProfileMenuItemProps } from './menuItems'

const useStyles = makeStyles(() => ({
  menuItem: {
    justifyContent: 'left',
  },
}))

type Props = Omit<ProfileMenuItemProps, 'translationKey'> & {
  children: string
  onClick: () => void
}

export const ProfileMenuItem: FC<React.PropsWithChildren<Props>> = (props) => {
  const { children, slug, IconComponent, link, onClick } = props
  const history = useHistory()
  const { t } = useTranslation()
  const colorPalette = useColorPalette()
  const classes = useStyles()

  const handleClick = () => {
    onClick()
    if (link) {
      history.push(link)
    }
  }

  return (
    <Box mx={2} my={1}>
      <Button
        sx={{ ':hover': { background: colorPalette.grey[200] } }}
        className={classes.menuItem}
        variant="text"
        color="inherit"
        fullWidth
        data-cy={`ProfileMenu-Button-${slug}`}
        onClick={handleClick}
        startIcon={
          <Box mx={1} my={-1}>
            <IconComponent />
          </Box>
        }
      >
        <Typography variant="body2">{t(children)}</Typography>
      </Button>
    </Box>
  )
}
