import { Button, TextField, AutocompleteValue } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material'
import { Autocomplete } from '@mui/material'
import React, { FC, useState } from 'react'
import { useAppointmentTypes } from '../../../common/hooks/useAppointmentTypes'
import { useSelectedLanguage } from '../../hooks/useSelectedLanguage'
import { useTranslation } from '../../hooks/helper/useTranslation'
import { AppointmentAdminModal } from '../../../views/admin/components/AppointmentAdminModal'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { appointmentInfoChore } from '../../../models/graphqlTypes'

const useStyles = makeStyles((theme) => ({
  listBox: {
    '& > *:first-child': {
      padding: 0,
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}))

type Props = {
  name: string
  value?: AutocompleteValue<appointmentInfoChore, false, false, true>
  onChange: (
    value: AutocompleteValue<appointmentInfoChore, false, false, true>
  ) => void
  onBlur?: () => void
  error?: unknown
  onAppointmentInfoAdd: (info?: appointmentInfoChore) => void
  hideTitle?: boolean
  disabled?: boolean
  hintText?: string
  treatmentSchemaId?: string
}

export const AppointmentInfoSelector: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const {
    name,
    value,
    onBlur,
    onChange,
    error,
    onAppointmentInfoAdd,
    hideTitle,
    disabled,
    hintText,
    treatmentSchemaId,
  } = props

  const { t } = useTranslation()
  const classes = useStyles()

  const selectedLanguage = useSelectedLanguage()

  const [addingNewAppointmentType, setAddingNewAppointmentType] = useState<
    string | boolean
  >(false)

  const { loading, orderedAppointmentTypes } = useAppointmentTypes({
    variables: { treatmentSchemaId },
  })
  const handleAddComplete = (newAppointmentInfo?: appointmentInfoChore) => {
    if (!newAppointmentInfo) {
      return
    }
    onAppointmentInfoAdd(newAppointmentInfo)
  }

  const label = hideTitle ? undefined : t('treatment:doctor.chooseExamination')

  return (
    <BoxWithLabel label={label}>
      <Autocomplete<appointmentInfoChore, false, false, true>
        classes={{ listbox: classes.listBox }}
        freeSolo
        fullWidth
        loading={loading}
        ListboxProps={{ style: { overflowX: 'hidden' } }}
        options={orderedAppointmentTypes}
        filterSelectedOptions
        disabled={disabled}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        getOptionLabel={(option) =>
          typeof option === 'string'
            ? option
            : (option?.doctorTitle[selectedLanguage] ?? '')
        }
        value={value || null}
        onChange={(event, newValue) => {
          onChange(newValue)
        }}
        onBlur={onBlur}
        filterOptions={(options, state) => {
          const filtered = options.filter((option) =>
            (option.doctorTitle[selectedLanguage] ?? '')
              .toLowerCase()
              .includes(state.inputValue.toLowerCase())
          )

          return [null as unknown as appointmentInfoChore, ...filtered]
        }}
        data-cy="AddAppointmentModal-TextField-selectAppointmentType"
        renderOption={(renderProps, option, state) => {
          if (option === undefined) {
            return null
          }
          if (!option) {
            return (
              <li {...renderProps} key={'option-add-key'}>
                <Button
                  fullWidth
                  variant="text"
                  startIcon={<AddCircleOutlineIcon color="primary" />}
                  onClick={(event) => {
                    event.stopPropagation()
                    setAddingNewAppointmentType(state.inputValue || true)
                  }}
                  data-cy="AddAppointmentModal-Button-addAppointmentType"
                >
                  {t('appointment:doctor.addAppointmentType')}
                </Button>
              </li>
            )
          }
          return (
            <li {...renderProps} key={option.id}>
              {option.doctorTitle[selectedLanguage]}
            </li>
          )
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={hintText || t('common:search')}
            error={!!error}
            helperText={error ? t('messages:warnings.required') : undefined}
            variant="outlined"
            name={name}
          />
        )}
      />
      {!!addingNewAppointmentType && (
        <AppointmentAdminModal
          initName={
            typeof addingNewAppointmentType === 'string'
              ? addingNewAppointmentType
              : ''
          }
          treatmentSchemaId={treatmentSchemaId}
          isOpen={!!addingNewAppointmentType}
          setIsOpen={() => setAddingNewAppointmentType(false)}
          onComplete={handleAddComplete}
        />
      )}
    </BoxWithLabel>
  )
}
