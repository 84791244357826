import { Button, DialogActions } from '@mui/material'
import React, { FC } from 'react'
import { useTranslation } from '../../../../../common/hooks/helper/useTranslation'
import { SaveButton } from '../../../../../common/components/SaveButton'

type Props = {
  hideSubmitButton?: boolean
  hideRemoveButton?: boolean
  disableSubmitButton?: boolean
  disableRemoveButton?: boolean
  onCancel: () => void
  onRemove: () => void
}

export const DependencyModalActions: FC<React.PropsWithChildren<Props>> = (
  props
) => {
  const {
    onCancel,
    onRemove,
    hideRemoveButton = false,
    hideSubmitButton = false,
    disableSubmitButton = false,
    disableRemoveButton = false,
  } = props
  const { t } = useTranslation()

  return (
    <DialogActions>
      <Button variant="outlined" onClick={onCancel}>
        {t('common:cancel')}
      </Button>
      {!hideRemoveButton && (
        <SaveButton
          disabled={disableRemoveButton}
          color="error"
          text={t('common:delete')}
          onClick={onRemove}
        />
      )}
      {!hideSubmitButton && (
        <SaveButton
          disabled={disableSubmitButton}
          type="submit"
          text={hideRemoveButton ? t('common:add') : t('common:save')}
        />
      )}
    </DialogActions>
  )
}
