import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import { useMutation } from '@apollo/client'
import {
  createTreatment,
  createTreatmentVariables,
} from '../../../../models/graphqlTypes'
import { CREATE_TREATMENT } from '../../../../operations/treatmentOperations'

type Options = MutationHookOptions<createTreatment, createTreatmentVariables>

type Return = MutationTuple<createTreatment, createTreatmentVariables>

export const useTreatmentCreate = (options: Options = {}): Return => {
  return useMutation<createTreatment, createTreatmentVariables>(
    CREATE_TREATMENT,
    options
  )
}
