import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'
import {
  initiatePasswordReset,
  initiatePasswordResetVariables,
} from '../../models/graphqlTypes'
import { useMutation } from '@apollo/client'
import { INITIATE_PASSWORD_RESET } from '../../operations/userOperations'

type Options = MutationHookOptions<
  initiatePasswordReset,
  initiatePasswordResetVariables
>

type Return = MutationTuple<
  initiatePasswordReset,
  initiatePasswordResetVariables
>

export const useInitiatePasswordReset = (options: Options = {}): Return => {
  return useMutation<initiatePasswordReset, initiatePasswordResetVariables>(
    INITIATE_PASSWORD_RESET,
    options
  )
}
