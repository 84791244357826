import { useMutation } from '@apollo/client'
import {
  addSchedule as AddScheduleData,
  addScheduleVariables as AddScheduleVariables,
} from '../../../../models/graphqlTypes'
import { ADD_SCHEDULE } from '../operations/treatmentSchemaOperations'
import {
  MutationHookOptions,
  MutationTuple,
} from '@apollo/client/react/types/types'

type Options = MutationHookOptions<AddScheduleData, AddScheduleVariables>

type Return = MutationTuple<AddScheduleData, AddScheduleVariables>

export const useScheduleAdd = (options: Options = {}): Return => {
  return useMutation<AddScheduleData, AddScheduleVariables>(ADD_SCHEDULE, {
    refetchQueries: ['getTreatmentSchema'],
    ...options,
  })
}
