import { Grid, Radio, Stack, Typography } from '@mui/material'
import React from 'react'
import { useColorPalette } from '../../../../../common/hooks/helper/useColor'
import { getDefaultScaleMinMax } from '../../utils/question.util'
import { Question } from '../Questions/questions.types'
import { Aspect } from '../../types/surveyStore.types'

interface Props {
  question: Question
}

export const ViewScaleQuestion: React.FC<React.PropsWithChildren<Props>> = ({
  question,
}) => {
  const colorPalette = useColorPalette()
  const scaleOptions = question.data?.scaleData?.options ?? []
  const { scaleMax } = scaleOptions.length
    ? getDefaultScaleMinMax(scaleOptions)
    : { scaleMax: 1 }

  return (
    <Stack py={1} ml={5}>
      <Typography variant="italic" color={colorPalette.grey[300]}>
        {question.description}
      </Typography>

      <Grid container alignItems="flex-end">
        <Grid item xs={2} />

        {scaleOptions.map((option) => (
          <Grid item xs={1} key={option.value}>
            <Stack spacing={1}>
              <Typography
                key={option.value}
                textAlign="center"
                variant="subtitle2"
              >
                {option.label}
              </Typography>
              <Typography textAlign="center" variant="body1">
                {option.value}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>

      {question.aspects?.map((aspect: Aspect, i: number) => (
        <Grid key={`aspect_${i}`} container alignItems="center">
          <Grid item xs={2} display="flex" justifyContent="flex-end">
            <Typography variant="body1">{aspect.name}</Typography>
          </Grid>

          {Array(scaleMax)
            .fill(null)
            .map((e, i: number) => (
              <Grid item xs={1} key={i} display="flex" justifyContent="center">
                <Radio disabled={true} />
              </Grid>
            ))}
        </Grid>
      ))}
    </Stack>
  )
}
