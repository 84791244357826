import React from 'react'
import { SvgIcon, SvgIconProps } from '@mui/material'

const TimeIcon: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const viewBox = `0 0 ${props.width ?? 16} ${props.height ?? 16}`
  return (
    <SvgIcon viewBox={viewBox} {...props}>
      <path d="M8,15.5 C3.86466667,15.5 0.5,12.1353333 0.5,8 C0.5,3.86466667 3.86466667,0.5 8,0.5 C12.1353333,0.5 15.5,3.86466667 15.5,8 C15.5,12.1353333 12.1353333,15.5 8,15.5 Z M8,1.5 C4.416,1.5 1.5,4.416 1.5,8 C1.5,11.584 4.416,14.5 8,14.5 C11.584,14.5 14.5,11.584 14.5,8 C14.5,4.416 11.584,1.5 8,1.5 Z" />
      <path d="M11.1246667,11.6253333 C10.9913333,11.6253333 10.8653333,11.5733333 10.7713333,11.4786667 L7.64666667,8.35333333 C7.6,8.30666667 7.56333333,8.252 7.538,8.19 C7.536,8.18466667 7.534,8.17866667 7.532,8.17333333 C7.51066667,8.116 7.50066667,8.05866667 7.50066667,8 L7.50066667,5.5 C7.50066667,5.224 7.72466667,5 8.00066667,5 C8.27666667,5 8.50066667,5.224 8.50066667,5.5 L8.50066667,7.79266667 L11.4786667,10.7713333 C11.5733333,10.866 11.6253333,10.9913333 11.6253333,11.1246667 C11.6253333,11.258 11.5733333,11.384 11.4786667,11.478 C11.384,11.572 11.258,11.6253333 11.1246667,11.6253333 Z" />
    </SvgIcon>
  )
}

export { TimeIcon }
