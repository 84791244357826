import { gql } from '@apollo/client'
import {
  FRAGMENT_MULTILANGTEXT,
  FRAGMENT_PATIENT_CHORE,
  FRAGMENT_SURVEY_LIST_ITEM,
  FRAGMENT_TREATMENT_CHORE,
} from './fragments'

export const GET_DOCTOR_PATIENT_TREATMENTS = gql`
  query getDoctorPatientTreatments($patientId: String!) {
    getPatientProfile(patientId: $patientId) {
      id
      treatments {
        ...treatmentChore
        firstAndLastDates {
          firstAppointmentDate
          lastAppointmentDate
        }
        doctorAppointments {
          id
          proposedDate
        }
      }
    }
  }
  ${FRAGMENT_MULTILANGTEXT}
  ${FRAGMENT_TREATMENT_CHORE}
`
export const GET_PATIENT_PROFILE_EHR_DOCUMENT_LIST = gql`
  query GetPatientProfileWithEhr(
    $patientId: String!
    $paginationInput: PaginationInput
  ) {
    getPatientProfile(patientId: $patientId) {
      id
      ehrDocumentList(paginationInput: $paginationInput) {
        total
        hasDORError
        documents {
          EESZTId
          hospitalDescr
          documentType
          documentId
          doctor {
            firstName
            lastName
            stampNo
          }
          timestamp
        }
      }
    }
  }
`

export const FIND_PATIENT_PROFILE = gql`
  query findPatientProfile($tajNumber: String!) {
    findPatientProfile(tajNumber: $tajNumber) {
      ... on PatientProfile {
        ...patientChore
        birthDate
        gender
      }
      ... on PatientProfileNotFoundError {
        message
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const INVITE_PATIENT = gql`
  mutation invitePatient(
    $createPatientArgs: CreatePatientProfileData!
    $doctorUserIds: [String!]
  ) {
    invitePatient(
      createPatientArgs: $createPatientArgs
      doctorUserIds: $doctorUserIds
    ) {
      id
      user {
        id
        email
      }
      ...patientChore
      birthDate
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const INVITE_EXISTING_PATIENT = gql`
  mutation inviteExistingPatient(
    $patientId: String!
    $doctorUserIds: [String!]
  ) {
    inviteExistingPatient(
      patientId: $patientId
      doctorUserIds: $doctorUserIds
    ) {
      id
      patient {
        id
        user {
          id
          email
        }
        ...patientChore
        birthDate
      }
    }
  }
  ${FRAGMENT_PATIENT_CHORE}
`

export const GET_DOCTOR_PATIENT_SURVEYS = gql`
  query getDoctorPatientSurveys(
    $patient: SurveysForPatient!
    $paginationInput: PaginationInput
  ) {
    getSurveysForPatient(patient: $patient, paginationInput: $paginationInput) {
      total
      surveys {
        ...surveyListItem
      }
    }
  }
  ${FRAGMENT_SURVEY_LIST_ITEM}
`
