import React from 'react'
import { TextField } from '@mui/material'
import { useSelectedLanguage } from '../../../common/hooks/useSelectedLanguage'
import { useTranslation } from '../../../common/hooks/helper/useTranslation'

import { Autocomplete } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { BoxWithLabel } from '../../../common/components/BoxWithLabel'
import { useListProfessions } from '../../../common/hooks/useListProfessions'

interface Props {
  name: string
  label?: string
  required?: boolean
  hasError?: boolean
  helperText?: string
  disabled?: boolean
}

const ProfessionSelector: React.FC<React.PropsWithChildren<Props>> = ({
  name,
  label,
  required,
  hasError,
  helperText,
  disabled,
}) => {
  const { t } = useTranslation()

  const { control } = useFormContext()

  const { professions, loading } = useListProfessions()

  const selectedLanguage = useSelectedLanguage()

  const intlCollator = new Intl.Collator(selectedLanguage)

  return (
    <BoxWithLabel label={label}>
      <Controller
        control={control}
        name={name}
        defaultValue={[]}
        rules={{
          validate: (value) =>
            required && value?.length < 1
              ? (t('messages:warnings.required') as string)
              : true,
        }}
        render={({ field: { onChange, onBlur, value, name } }) => (
          <Autocomplete
            disabled={disabled}
            multiple
            loading={loading}
            loadingText={t('common:loading')}
            noOptionsText={t('common:noOptions')}
            options={professions
              .slice()
              .sort((a, b) => intlCollator.compare(a.name, b.name))
              .sort((prof) => (prof.code === 'BTM1' ? -1 : 1))}
            disableClearable
            filterSelectedOptions
            getOptionLabel={(profession) => profession.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={value || []}
            onChange={(_, value) => onChange([...value])}
            onBlur={onBlur}
            data-cy="ProfessionSelector-Autocomplete"
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={t('common:search')}
                error={hasError}
                helperText={helperText}
                variant="outlined"
                name={name}
              />
            )}
          />
        )}
      />
    </BoxWithLabel>
  )
}

export { ProfessionSelector }
