import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { useUserType } from '../common/hooks/useUserType'
import { UserType } from '../models/graphqlTypes'

import { PatientDoctors } from '../views/patient/PatientDoctors/PatientDoctors'
import { PatientProfile } from '../views/patient/PatientProfile/PatientProfile'
import { RedirectHandler } from './RedirectHandler'
import { PatientEvents } from '../views/patient/PatientEvents/PatientEvents'
import { GetFillSurvey } from '../views/patient/PatientEvents/components/GetFillSurvey'

const PatientRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
  const userType = useUserType()

  if (userType !== UserType.Patient) {
    return <Redirect to={`/${userType}/home`} />
  }

  return (
    <>
      <Switch>
        {/* legacy routes (so notifications will work) */}
        <Redirect
          from="/patient/appointments/:treatmentId/:appointmentId"
          to="/patient/home/treatments/:treatmentId/:appointmentId"
        />
        <Redirect
          from="/patient/appointments/:treatmentId"
          to="/patient/home/treatments/:treatmentId"
        />
        <Redirect from="/patient/appointments" to="/patient/home/treatments" />
        <Redirect
          from="/patient/treatment/:treatmentId/:appointmentId"
          to="/patient/home/treatments/:treatmentId/:appointmentId"
        />
        <Redirect
          from="/patient/treatment/:treatmentId"
          to="/patient/home/treatments/:treatmentId"
        />
        <Redirect from="/patient/treatment" to="/patient/home/treatments" />
        {/* end of legacy routes */}

        {/* routes in use */}
        <Route path="/patient/home/treatments/:treatmentId/:appointmentId">
          <PatientEvents />
        </Route>
        <Route path="/patient/home/treatments/:treatmentId">
          <PatientEvents />
        </Route>

        <Route path="/patient/home/treatments">
          <PatientEvents />
        </Route>

        <Route path="/patient/home/surveys/:surveyId">
          <GetFillSurvey />
        </Route>
        <Route path="/patient/home/surveys">
          <PatientEvents />
        </Route>
        {/* end of routes in use */}

        {/* legacy routes (so notifications will work) */}
        <Redirect
          from="/patient/home/:treatmentId/:appointmentId"
          to="/patient/home/treatments/:treatmentId/:appointmentId"
        />
        <Redirect
          from="/patient/home/:treatmentId"
          to="/patient/home/treatments/:treatmentId"
        />
        {/* end of legacy routes */}

        {/* routes in use */}
        <Redirect from="/patient/home" to="/patient/home/treatments" />

        <Route path="/patient/doctors/:doctorProfileId">
          <PatientDoctors />
        </Route>
        <Route path="/patient/doctors">
          <PatientDoctors />
        </Route>

        <Route path="/patient/profile">
          <PatientProfile />
        </Route>
        <Route>
          <RedirectHandler />
        </Route>
      </Switch>
    </>
  )
}

export { PatientRoutes }
