import React, { Dispatch } from 'react'
import { Box, Typography, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { SelectedSlotType } from '../Appointment/AddAppointmentModal'
import { EESZTSlot } from '../../../../../models/graphqlTypes'

// this to be props as could change by room
const startAtHour = 7
const finishAtHour = 20
const slots = ['00', '15', '30', '45']

interface CalendarDayProps {
  selectedSlot: SelectedSlotType | null
  setSelectedSlot: Dispatch<SelectedSlotType | null>
  bookedSlot?: string
  disabled: boolean
  eesztSlots: EESZTSlot[]
  isBookingToEeszt: boolean
}

const useStyles = makeStyles((theme) => ({
  current: {
    borderColor: theme.palette.primary.main,
    '&.Mui-disabled': {
      borderColor: theme.palette.primary.main,
      color: theme.palette.common.black,
    },
  },
  button: {
    paddingY: theme.spacing(0.5),
    width: 50,
    height: 28,
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',
  },
}))

const CalendarDay: React.FC<React.PropsWithChildren<CalendarDayProps>> = ({
  selectedSlot,
  setSelectedSlot,
  bookedSlot,
  disabled,
  eesztSlots,
  isBookingToEeszt,
}) => {
  const classes = useStyles()

  const zeroPad = (num: number, places: number) =>
    String(num).padStart(places, '0')

  const hoursAvailable = Array.from({ length: finishAtHour - startAtHour }).map(
    (_, index) => zeroPad(startAtHour + index, 2)
  )

  const freeSlots = eesztSlots.map((slot) => ({
    start: dayjs(`${slot.date} ${slot.startTime}`).format('HH:mm'),
    end: dayjs(`${slot.date} ${slot.endTime}`).format('HH:mm'),
    consultationId: slot.consultationData.id,
  }))

  return (
    <Box display="flex" flexDirection="column" mt={-0.5} ml={-0.5}>
      {hoursAvailable.map((hour) => (
        <Box key={hour} display="flex">
          {slots.map((slot) => {
            const slotKey = `${hour}:${slot}`
            const isSelected = slotKey === selectedSlot?.slot
            const isCurrentBookedSlot = bookedSlot === slotKey
            const hasSlot = freeSlots.find(({ start, end }) => {
              const isBookingInsideSlot = start <= slotKey && slotKey < end
              return isBookingInsideSlot
            })
            const disabledByEeszt = isBookingToEeszt && !hasSlot
            return (
              <Box key={slot} ml={0.5} mt={0.5}>
                <Button
                  className={clsx(classes.button, {
                    [classes.current]: isCurrentBookedSlot,
                  })}
                  onClick={() =>
                    setSelectedSlot(
                      isSelected
                        ? null
                        : {
                            slot: slotKey,
                            consultationId: hasSlot?.consultationId || '',
                          }
                    )
                  }
                  disabled={disabled || !!disabledByEeszt}
                  variant={!disabled && isSelected ? 'contained' : 'outlined'}
                  data-cy="CalendarDay-Button-slot"
                >
                  <Typography variant="subtitle1" component="span">
                    {slotKey}
                  </Typography>
                </Button>
              </Box>
            )
          })}
        </Box>
      ))}
    </Box>
  )
}

export { CalendarDay }
